import { useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(104, 104, 104, 0.70)',
    zIndex: 999
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '100vw',
    maxHeight: '100vh',
    padding: '0',
    borderRadius: '8px',
    boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#F7F8FC',
    overflow: 'auto',
    transform: 'none'
  }
};

const Popup = ({ isOpen, setIsOpen, children, style, className }) => {
  const [isClosing, setIsClosing] = useState(false);
  const closePopup = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 500);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closePopup}
      style={style ? style : customStyles}
      contentLabel="Popup"
      ariaHideApp={false}
      className={` ${className}${
        isClosing
          ? 'animate__animated animate__fadeOutDown animate__faster'
          : 'animate__animated animate__fadeInDown animate__faster'
      }`}
    >
      {children && <>{children}</>}
    </Modal>
  );
};

export default Popup;
