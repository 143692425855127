import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCloseModalOnOutsideClick from '../OnOutsideClick/OnOutsideClick';
import Icon from './Icon';

const Filter = ({ filterList = [], filterName, setFilterName, arrange, setArrange, setCurrentPage }) => {
  const [selectFilter, setSelectFilter] = useState(false);
  const modalRef = useRef(null);
  useCloseModalOnOutsideClick(modalRef, setSelectFilter);
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div
        className={`flex h-[36px] cursor-pointer items-center justify-between gap-2 rounded-lg border border-white bg-white ${
          selectFilter === true && 'border border-primary-100'
        } p-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)]`}
      >
        <div className="flex h-5 w-5 items-center justify-center">
          <Icon
            name={`${arrange === 'desc' ? 'sort_up_alt' : 'sort_down_alt'}`}
            className="h-full w-full stroke-black"
            onClick={() => {
              if (filterName?.name) {
                setCurrentPage(0);
                setArrange(arrange === 'desc' ? 'asc' : 'desc');
              }
            }}
          />
        </div>

        <div
          className="flex w-full justify-between gap-3 border-l border-black pl-2"
          onClick={() => setSelectFilter(!selectFilter)}
        >
          <h2 className="text-sm font-medium">{filterName?.name ? filterName?.name : t('project.select')}</h2>
          <Icon name="chevron_down" className="fill-none  stroke-[#111111]" />
        </div>
      </div>

      {selectFilter === true && (
        <div
          className="absolute z-30 mt-1 w-full rounded bg-white p-2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)]"
          ref={modalRef}
        >
          {filterList?.map((item, index) => {
            return (
              <h3
                key={index}
                className={`my-1 w-full cursor-pointer rounded-md py-1.5 px-2 text-left text-sm font-medium hover:bg-neu-600 ${
                  filterName?.order === item?.order && 'bg-neu-600'
                }`}
                onClick={() => {
                  setFilterName(item);
                  setSelectFilter(false);
                }}
              >
                {item.name}
              </h3>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Filter;
