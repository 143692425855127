export const toDateString = (date) => {
  const myArray = date.split('Z');
  const newDate = new Date(myArray[0]);
  const getHouse = newDate?.getHours();
  const getMinute = newDate?.getMinutes();
  const fullHouse = (getHouse < 10 ? '0' : '') + getHouse + ':' + (getMinute < 10 ? '0' : '') + getMinute;
  return fullHouse;
};
export const toDatesStrings = (date) => {
  const newDate = new Date(date);
  const getHouse = newDate?.getHours();
  const getMinute = newDate?.getMinutes();
  const fullHouse = (getHouse < 10 ? '0' : '') + getHouse + ':' + (getMinute < 10 ? '0' : '') + getMinute;
  return fullHouse;
};

export const toDateStringYear = (date) => {
  const myArray = date.split('Z');
  const newDate = new Date(myArray[0]);
  const date1 = newDate.getDate();
  const month = newDate.getMonth() + 1; // Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = newDate.getFullYear();
  const getHouse = newDate?.getHours();
  const getMinute = newDate?.getMinutes();
  const fullHouse =
    (date1 < 10 ? '0' : '') +
    date1 +
    '-' +
    (month < 10 ? '0' : '') +
    month +
    '-' +
    year +
    ' ' +
    (getHouse < 10 ? '0' : '') +
    getHouse +
    ':' +
    (getMinute < 10 ? '0' : '') +
    getMinute;
  return fullHouse;
};
export const toDateStringYearTask = (date) => {
  const myArray = date.split('Z');
  const newDate = new Date(myArray[0]);
  const date1 = newDate.getDate();
  const month = newDate.getMonth() + 1; // Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = newDate.getFullYear();
  const getHouse = newDate?.getHours();
  const getMinute = newDate?.getMinutes();
  const fullHouse =
    year +
    '-' +
    (month < 10 ? '0' : '') +
    month +
    '-' +
    (date1 < 10 ? '0' : '') +
    date1 +
    'T' +
    (getHouse < 10 ? '0' : '') +
    getHouse +
    ':' +
    (getMinute < 10 ? '0' : '') +
    getMinute;
  return fullHouse;
};

export const toDateStringYears = (date) => {
  const newDate = new Date(date);

  // Lấy các thành phần ngày giờ theo giờ địa phương
  const date1 = newDate.getDate();
  const month = newDate.getMonth() + 1; // Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = newDate.getFullYear();
  const getHour = newDate.getHours();
  const getMinute = newDate.getMinutes();

  // Định dạng thành chuỗi đầy đủ với các số có 2 chữ số khi cần
  const fullHour =
    (getHour < 10 ? '0' : '') +
    getHour +
    ':' +
    (getMinute < 10 ? '0' : '') +
    getMinute +
    '  ' +
    (date1 < 10 ? '0' : '') +
    date1 +
    '-' +
    (month < 10 ? '0' : '') +
    month +
    '-' +
    year;

  return fullHour;
};

export const toDateCalendar = (date) => {
  const newDate = new Date(date);
  const date1 = newDate.getDate();
  const month = newDate.getMonth() + 1; // Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = newDate.getFullYear();
  const getHouse = newDate?.getHours();
  const getMinute = newDate?.getMinutes();
  const fullHouse =
    (date1 < 10 ? '0' : '') +
    date1 +
    '-' +
    (month < 10 ? '0' : '') +
    month +
    '-' +
    year +
    ' - ' +
    ' ' +
    (getHouse < 10 ? '0' : '') +
    getHouse +
    ':' +
    (getMinute < 10 ? '0' : '') +
    getMinute;
  return fullHouse;
};

export const toDateStrings = (date, userInfo) => {
  const myArray = date?.split('Z');
  const currentDate = new Date(myArray[0]);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  let formattedDate;
  if (userInfo?.language === 'vi') {
    formattedDate = `${day}-${month}-${year}`;
  } else {
    formattedDate = `${month}-${day}-${year}`;
  }
  return formattedDate;
};
export const toDateStringHouse = (date) => {
  const newDate = new Date(date);
  const date1 = newDate.getDate();
  const month = newDate.getMonth() + 1; // Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = newDate.getFullYear();
  // const getHouse = newDate?.getHours();
  const fullHouse = (date1 < 10 ? '0' : '') + date1 + '-' + (month < 10 ? '0' : '') + month + '-' + year;
  return fullHouse;
};
export const toDateStringss = (date, userInfo) => {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  let formattedDate;
  if (userInfo?.language === 'vi') {
    formattedDate = `${day}-${month}-${year}`;
  } else {
    formattedDate = `${month}-${day}-${year}`;
  }
  return formattedDate;
};
export const calendarDateModal = (newDate) => {
  let date = new Date(newDate);

  let day = date.getDate();
  let month = date.getMonth() + 1; // Lưu ý: tháng bắt đầu từ 0, nên cần cộng thêm 1
  let year = date.getFullYear();

  // Đảm bảo rằng các số ngày và tháng có 2 chữ số bằng cách thêm số 0 phía trước nếu cần
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  let formattedDate = (year ? year : 'dd') + '-' + (month ? month : 'mm') + '-' + (day ? day : 'yyyy');
  return formattedDate;
};

export const calendarDateModalInput = (newDate, userInfo) => {
  let date = newDate === null ? new Date(NaN) : new Date(newDate);
  let day = date.getDate();
  let month = date.getMonth() + 1; // Lưu ý: tháng bắt đầu từ 0, nên cần cộng thêm 1
  let year = date.getFullYear();

  // Đảm bảo rằng các số ngày và tháng có 2 chữ số bằng cách thêm số 0 phía trước nếu cần
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  let formattedDate;

  if (userInfo?.language === 'vi') {
    formattedDate = (day ? day : 'dd') + '/' + (month ? month : 'mm') + '/' + (year ? year : 'yyyy');
  } else {
    formattedDate = (month ? month : 'mm') + '/' + (day ? day : 'dd') + '/' + (year ? year : 'yyyy');
  }

  return formattedDate;
};
