import { t } from 'i18next';
import backendURL from '../../../config';
import { toDateStringYears } from '../../Calendar/CalendarComponent';
import Icon from '../../Common/Icon';

const ProjectTaskFile = (props) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const isImageFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  if (props.viewMode) {
    if (props.mode === 'form') {
      return (
        <div className="relative">
          <button type="button" onClick={() => props.handleRemove(0)} className="absolute">
            <Icon name="close_cross" className="stroke-white" />
          </button>
          <div>
            <img
              id="display-img"
              src={require('../../../Assets/Images//cloud-upload.png')}
              alt="avatar"
              className={props.className}
            />
          </div>
        </div>
      );
    } else if (props.mode === 'download') {
      return (
        <div className=" ml-10 mt-3 ">
          {props.files?.map((item, index) => {
            return (
              <div key={index} className=" mt-4 flex gap-4 ">
                <div className="">
                  <img
                    id={`img-${props.mode}-${item.fileSeq}`}
                    src={`${backendURL + '/file/' + 'displayImg/' + item.fileSeq}`}
                    alt="avatar"
                    className="relative h-[100px] w-[180px]  object-cover"
                  />
                </div>
                <div>
                  <p className=" whitespace-normal font-bold ">{item.fileNm}</p>
                  <div className="mt-2 flex gap-3">
                    <p>{toDateStringYears(item.createdAt)}</p>
                    <p className="  font-extrabold">.</p>
                    <button type="button" onClick={() => props.handleRemove(index)} className="border-b border-black">
                      Xóa
                    </button>
                  </div>
                  <div>
                    <button
                      className="  mr-2 flex h-[35px] w-[240px] items-center justify-center rounded-md  bg-white px-4"
                      type="submit"
                      onClick={() => props.handleDownLoad(index)}
                    >
                      Tải
                    </button>
                  </div>

                  <p className="mt-2">Đặt làm ảnh bìa</p>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (props.mode === 'downloadFile') {
      return (
        <div className="  mt-3 ml-10  flex flex-wrap gap-x-3 gap-y-6 overflow-y-auto">
          {props.files?.map((item, index) => {
            const listImage = isImageFile(item.fileNm); //Kiểm tả định dạng file
            if (listImage) {
              return (
                <div key={index} className="   ">
                  <div className=" rounded-lg ">
                    <div className="">
                      {props.uploadKey !== '' && (
                        <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
                      )}
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        props?.setSelectLinkImage(backendURL + '/file/' + 'displayImg/' + item.fileSeq),
                          props.setOpenImage(false);
                      }}
                    >
                      <img
                        id={`img-${props.mode}-${item.fileSeq}`}
                        src={`${backendURL + '/file/' + 'displayImg/' + item.fileSeq}`}
                        alt="avatar"
                        className="relative h-[100px] w-[180px]  object-cover"
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      return (
        <div>
          <img id={`img-${props.mode}-${props.seq}`} src="" alt="avatar" hidden className={props.className} />
          {props.hiddenFile ? (
            <img
              src={backendURL + '/file/' + 'displayImg/' + props.hiddenFile}
              alt="avatar"
              className={props.className}
            />
          ) : (
            <div></div>
          )}
        </div>
      );
    }
  } else {
    return (
      <div className="w-full">
        {props.uploadKey !== '' && <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />}
        <button
          className={` upload-btn  h-[36px] w-full  rounded-lg  border bg-primary-100  px-3 text-sm font-normal text-[#FFFFFF]`}
        >
          <span type="button">{t('project.select-file')}</span>
        </button>
      </div>
    );
  }
};

export default ProjectTaskFile;
