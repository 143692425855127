/**
 * Formats a date string based on the specified format.
 *
 * @param {Object} options - Options for formatting the date string.
 * @param {string} options.dateString - The date string to be formatted.
 * @param {string} [options.format='yyyy-mm-dd'] - The desired format for the output.
 * @param {string} [options.alternate='-'] - The alternate separator for the format.
 * @returns {string|null} - The formatted date string, or null if an error occurs.
 */
export const formatDateString = ({ dateString, format = 'dd-mm-yyyy', alternate = '-' }) => {
  if (!dateString) {
    return null;
  }

  const formatParts = format.split('-');
  if (new Set(formatParts).size !== formatParts.length) {
    console.warn('error type: wrong format.');
    return null;
  }

  const formatTokens = {
    yyyy: (date) => date.getFullYear(),
    mm: (date) => String(date.getMonth() + 1).padStart(2, '0'),
    dd: (date) => String(date.getDate()).padStart(2, '0')
  };

  const date = new Date(dateString);

  const formattedDate = formatParts.map((part) => (formatTokens[part] ? formatTokens[part](date) : '')).join(alternate);

  return formattedDate;
};
