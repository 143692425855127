import React, { useContext, useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { BackgroundFileChild } from '../../Apis/Workspace/Background';
import { ListSpacePin, ReadLastSpace, ReadWorkspace } from '../../Apis/Workspace/Workspace';
import {
  AltBookmarkIc,
  CalendarIc,
  CircleMessageIc,
  Space3dIc,
  StartDocumentIc,
  TimerIc
} from '../../Components/Workspace/Icon';
import backendURL from '../../config';
import { useGlobalContext } from '../Context';

const Context = React.createContext();

const WorkspaceContext = ({ children }) => {
  const { userInfo, updateUserInfo } = useGlobalContext();
  const [actToolbar, setActToolbar] = useState([]);
  const [backgroundUrl, setBackgroundUrl] = useState();
  const [lastSpaceId, setLastSpaceId] = useState();
  const [spaceDetail, setSpaceDetail] = useState();
  const [pin, setPin] = useState();
  const [isDataSaved, setIsDataSaved] = useState(true);
  const [saveNote, setSaveNote] = useState(false);
  const Tool = [
    {
      name: 'background',
      status: actToolbar?.includes('background'),
      content: <Translation>{(t) => <>{t('toolbar.background')}</>}</Translation>,
      icon: (
        <Space3dIc
          className={`h-5 w-5   ${actToolbar?.includes('background') ? 'fill-primary-100' : 'fill-neu-700'}`}
        />
      )
    },
    {
      name: 'todo-list',
      status: actToolbar?.includes('todo-list'),
      content: <Translation>{(t) => <>{t('toolbar.todo-list')}</>}</Translation>,
      icon: (
        <StartDocumentIc
          className={`h-5 w-5   ${actToolbar?.includes('todo-list') ? 'fill-primary-100' : 'fill-neu-700'}`}
        />
      )
    },
    {
      name: 'calendar',
      status: actToolbar?.includes('calendar'),
      content: <Translation>{(t) => <>{t('toolbar.calendar')}</>}</Translation>,
      icon: (
        <CalendarIc
          className={`h-5 w-5   ${actToolbar?.includes('calendar') ? 'stroke-primary-100' : 'stroke-neu-700'}`}
        />
      )
    },
    {
      name: 'message',
      status: actToolbar?.includes('message'),
      content: <Translation>{(t) => <>{t('toolbar.chat')}</>}</Translation>,
      icon: (
        <CircleMessageIc
          className={`h-5 w-5   ${actToolbar?.includes('message') ? 'stroke-primary-100' : 'stroke-neu-700'}`}
        />
      )
    },
    {
      name: 'note',
      status: actToolbar?.includes('note'),
      content: <Translation>{(t) => <>{t('toolbar.note')}</>}</Translation>,
      icon: (
        <AltBookmarkIc
          className={`h-5 w-5  ${actToolbar?.includes('note') ? 'stroke-primary-100' : 'stroke-neu-700'} `}
        />
      )
    },
    {
      name: 'timer',
      status: actToolbar?.includes('timer'),
      content: <Translation>{(t) => <>{t('toolbar.timer')}</>}</Translation>,
      icon: (
        <TimerIc className={`h-5 w-5  ${actToolbar?.includes('timer') ? 'stroke-primary-100' : 'stroke-neu-700'}`} />
      )
    }
  ];

  const getLastSpaceId = async () => {
    if (userInfo?.userID) {
      const dataLastSpace = await ReadLastSpace(userInfo?.userID);
      await setLastSpaceId(dataLastSpace?.data?.lastSpaceId);
      await getSpaceDetail(dataLastSpace?.data?.lastSpaceId);
    }
  };
  // List danh sách space ghim
  const Pined = async () => {
    const { data } = await ListSpacePin(userInfo?.userID);
    setPin(data);
  };
  const [audioBG, setAudioBG] = useState();
  const handleTopicOne = async (data) => {
    const newData = await BackgroundFileChild(data);
    // Sử lý ảnh space
    function isImageFile(filename) {
      const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif'];
      const fileExtension = filename?.substring(filename?.lastIndexOf('.'));
      return imageExtensions?.includes(fileExtension);
    }

    const imageFiles = newData?.data?.filter((file) => isImageFile(file?.backgroundNm));
    if (imageFiles !== undefined) {
      const mergedObject1231 = Object.assign({}, ...imageFiles);
      setBackgroundUrl(backendURL + '/background/displayImg/' + mergedObject1231?.backgroundId);
    }
    if (imageFiles.length === 0) {
      setBackgroundUrl('https://s.net.vn/SJNp');
    }

    function isAudioFile(filename) {
      const audioExtensions = ['.mp3', '.wav', '.ogg'];
      const fileExtension = filename?.substring(filename?.lastIndexOf('.'));
      return audioExtensions?.includes(fileExtension);
    }
    const audioFiles = newData?.data?.filter((file) => isAudioFile(file?.backgroundNm));
    if (audioFiles !== undefined) {
      const mergedObject1 = Object?.assign({}, ...audioFiles);
      setAudioBG(backendURL + '/background/displayImg/' + mergedObject1?.backgroundId);
    }
  };

  const getSpaceDetail = async (lastSpaceId) => {
    try {
      const data = await ReadWorkspace(lastSpaceId, false);
      setSpaceDetail(data?.data);
      handleTopicOne(data?.data?.defaultBackground);
    } catch (error) {
      console.log(error);
      setBackgroundUrl('https://s.net.vn/SJNp');
    }
    await updateUserInfo();
  };

  useEffect(() => {
    getLastSpaceId();
  }, [userInfo?.userID, lastSpaceId]);

  const hiddenToolbar = (toolName) => {
    setActToolbar((prev) => prev.filter((arr) => arr !== toolName));
  };

  return (
    <Context.Provider
      value={{
        getLastSpaceId,
        lastSpaceId,
        setLastSpaceId,
        spaceDetail,
        setSpaceDetail,
        Tool,
        actToolbar,
        hiddenToolbar,
        backgroundUrl,
        setBackgroundUrl,
        setActToolbar,
        getSpaceDetail,
        Pined,
        pin,
        setPin,
        audioBG,
        setIsDataSaved,
        saveNote,
        isDataSaved,
        setSaveNote
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useWorkspaceContext = () => {
  return useContext(Context);
};

export { WorkspaceContext };
