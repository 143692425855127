import { useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',

    alignItems: 'start',
    padding: '24px 0',
    backgroundColor: '#ffffff',
    zIndex: 999,
    overflowY: 'auto',
    maxWidth: '100vw',
    maxHeight: '100vh',
    outlineStyle: 'none'
  },
  content: {
    maxWidth: '95vw',
    padding: '0',
    borderRadius: '15px',
    boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    transform: 'none',
    outlineStyle: 'none'
  }
};

const PopupV2 = ({ isOpen, setIsOpen, children, style, className }) => {
  const [isClosing, setIsClosing] = useState(false);
  const closePopup = () => {
    setIsClosing(true);
    setIsOpen(false);
    setIsClosing(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closePopup}
      style={style ? style : customStyles}
      contentLabel="Popup"
      ariaHideApp={false}
      className={` ${className}${
        isClosing
          ? 'animate__animated animate__fadeOutDown animate__faster'
          : 'animate__animated animate__fadeInDown animate__faster'
      }`}
    >
      {children && <>{children}</>}
    </Modal>
  );
};

export default PopupV2;
