import instance from '../instance';

export const SearchMember = (userId, userName) => {
  const url = `/user/selectUserByDisable?userId=${userId}&name=${userName}`;
  return instance.get(url);
};
export const CreateProject = (data) => {
  const url = `/project/save`;
  return instance.post(url, data);
};

export const GetAllProject = (currentPage, searchName, filterName, arrange, userId, flag) => {
  const url = `/project/page?page=${currentPage}&orders=${filterName}+${arrange}&projectName=${searchName}&userId=${userId}&flag=${flag}`;
  return instance.get(url);
};

export const CheckProject = (userId) => {
  const url = `/project/findByUserId/${userId}`;
  return instance.get(url);
};

export const toggleProjectArchiveStatus = (projectId, isArchived) => {
  const url = `/project/archive?projectId=${projectId}&isArchived=${isArchived}`;
  return instance.put(url);
};
