import { useState } from 'react';
import Modal from 'react-modal';
import { CloseButton } from '../Buttons/ButtonComponent';

const customStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',

    alignItems: 'start',
    padding: '24px 0',
    backgroundColor: '#ffffff',
    zIndex: 999,
    overflowY: 'auto',
    maxWidth: '100vw',
    maxHeight: '100vh',
    outlineStyle: 'none'
  },
  content: {
    maxWidth: '95vw',
    padding: '0',
    borderRadius: '15px',
    boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    transform: 'none',
    outlineStyle: 'none'
  }
};

const Modals = ({ isOpen, setIsOpen, children, className, title, isNumDaysLeaveManagement = false, style }) => {
  const [isClosing, setIsClosing] = useState(false);
  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 500);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={style ? style : customStyles}
        className={`${className} ${
          isClosing
            ? 'animate__animated animate__fadeOutDown animate__faster'
            : 'animate__animated animate__fadeInDown animate__faster'
        }  mx-auto  rounded-[20px] border border-white bg-white`}
        ariaHideApp={false}
      >
        <div className="">
          <div className={`flex justify-between px-8 pt-4 ${isNumDaysLeaveManagement ? 'pl-3 pt-6' : ''} `}>
            {title ? <p className="text-lg font-semibold">{title} </p> : <div></div>}
            <CloseButton onClick={closeModal} />
          </div>

          {children}
        </div>
      </Modal>
    </div>
  );
};

export default Modals;
