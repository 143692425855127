export const customStyleMeeting = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '800px',
    height: 'auto',
    maxHeight: '95vh',
    borderRadius: '20px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
export const customStyleEvents = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '640px',
    height: 'auto',
    borderRadius: '20px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
export const customStyleFilter = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '24px',
    width: '660px',
    height: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    backgroundColor: 'white'
  }
};
export const customStyleFilters = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '24px',
    width: 'auto',
    height: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    backgroundColor: 'white'
  }
};
export const customViewCalendar = {
  content: {
    top: 'auto',
    left: 'auto',
    right: '50%',
    bottom: '0%',
    marginRight: '-50%',
    padding: '24px',
    width: '450px',
    height: '85vh',
    // transform: 'translate(44%, 50%)',
    borderRadius: '20px',
    backgroundColor: 'white'
  }
};
export const customDeleteCalendar = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '24px',
    width: '500px',
    height: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    backgroundColor: 'white'
  }
};
