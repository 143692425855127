import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import apiMemberProject from '../../../../Apis/ProjectManagement/Infomation/Member';
import { SearchMember } from '../../../../Apis/ProjectManagement/projectManager';
import requestClient from '../../../../Apis/requestClient';
import SaveIconWhite from '../../../../Assets/Images/project_creation.svg';
import AttachFile from '../../../../Components/AttachFile/AttachFile';
import Icon from '../../../../Components/Common/Icon';
import useCloseModalOnOutsideClick from '../../../../Components/OnOutsideClick/OnOutsideClick';
import { useGlobalContext } from '../../../../Utils/Context';
let isComposing = false; // Biến theo dõi trạng thái nhập liệu tiếng Việt
const CreateMember = ({ projectId, listMember, setListMember, openCloseMember, typeProject }) => {
  console.log(listMember, 'listMember');

  const { t } = useTranslation();
  const [deleteIdMember, setDeleteIdMember] = useState();
  const [searchClick, setSearchClick] = useState(false);
  const [projectRole, setProjectRole] = useState(false);
  const [projectLocation, setProjectLocation] = useState(false);
  const [projectRoleId, setProjectRoleID] = useState();
  const [operation, setOperation] = useState(false);
  const [roleProject, setRoleProject] = useState([]);
  const modalRef = useRef(null);
  useCloseModalOnOutsideClick(modalRef, setOperation);
  useCloseModalOnOutsideClick(modalRef, setSearchClick);
  useCloseModalOnOutsideClick(modalRef, setProjectRole);
  useCloseModalOnOutsideClick(modalRef, setProjectLocation);
  const [member, setMember] = useState();
  console.log(member, 'member');

  const { userInfo, changeIdUserInfomation } = useGlobalContext();
  const userId = userInfo?.userID;
  // Phương thức sử lý API tìm kiếm thành viên

  // Hàm gọi API với thời gian debounce 300ms
  const debouncedChangeSearchMemberAfterVietnamese = _.debounce(async (searchValue) => {
    console.log('searchValue (Vietnamese)', searchValue);

    try {
      if (projectId) {
        const res = await requestClient({
          endPoint: `/user/getAllUserNotInProject/${projectId}?searchName=${searchValue}`,
          method: 'get'
        });
        setMember(searchValue ? res?.data?.dataList : []);
      } else {
        const { data } = await SearchMember(userId, searchValue);
        setMember(searchValue ? data.data : []);
      }
    } catch (error) {
      console.log(error);
    }
  }, 300); // 300ms sau khi kết thúc nhập tiếng Việt

  // Hàm gọi API với thời gian debounce 1000ms
  const debouncedChangeSearchMember = _.debounce(async (searchValue) => {
    console.log('searchValue', searchValue);

    try {
      if (projectId) {
        const res = await requestClient({
          endPoint: `/user/getAllUserNotInProject/${projectId}?searchName=${searchValue}`,
          method: 'get'
        });
        setMember(searchValue ? res?.data?.dataList : []);
      } else {
        const { data } = await SearchMember(userId, searchValue);
        setMember(searchValue ? data.data : []);
      }
    } catch (error) {
      console.log(error);
    }
  }, 300); // 1 giây cho các trường hợp khác

  // Sự kiện bắt đầu/hoàn tất nhập tiếng Việt
  const handleComposition = (event) => {
    if (event.type === 'compositionstart') {
      isComposing = true;
    }
    if (event.type === 'compositionend') {
      isComposing = false;
      debouncedChangeSearchMemberAfterVietnamese(event.target.value); // Gọi API sau 300ms
    }
  };

  // Sự kiện thay đổi input
  const handleInputChange = (event) => {
    if (!isComposing) {
      debouncedChangeSearchMember(event.target.value); // Gọi API sau 1 giây
    }
  };
  const handleDelete = (id) => {
    const updatedListMember = [...listMember];
    let indexToRemove = updatedListMember.findIndex((item) => item.userId === id);
    if (indexToRemove !== -1) {
      updatedListMember.splice(indexToRemove, 1);
      setListMember(updatedListMember);
    }
  };

  // Phương thức thêm thành viên vào mảng
  const handleChangeMember = (member) => {
    const memberProject = {
      fullName: member?.fullName,
      userId: member?.userID,
      userName: member?.userName,
      roleNumber: 2,
      positionDTOList: [
        {
          positionId: typeProject === 'W' ? 108 : typeProject === 'K' ? 177 : 3,
          positionName: typeProject === 'W' ? 'Developer' : typeProject === 'K' ? 'Developer' : 'Develop Team'
        }
      ]
    };
    const isDuplicateId = listMember?.some((item) => item.userId === memberProject.userId);
    if (isDuplicateId === false) {
      setListMember([...listMember, memberProject]);
      setSearchClick(false);
    } else {
      toast.warning(t('project.this-member-has'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  // Chỉnh sủa quyền của member
  const updateRole = (userId, newRole, location, date) => {
    const userIndex = listMember.findIndex((user) => user.userId === userId);
    if (userIndex !== -1) {
      const user = listMember[userIndex];
      const existingLocations = user.positionDTOList || [];
      // Kiểm tra xem vị trí đã tồn tại chưa
      const locationIndex = existingLocations.findIndex((locations) => locations.positionId === location?.positionId);
      if (locationIndex === -1) {
        // Nếu không tồn tại, thêm vào mảng
        const updatedUsers = [...listMember];
        if (location?.positionId === null) {
          const updatedLocations = [...existingLocations];
          updatedUsers[userIndex] = {
            ...user,
            roleNumber: newRole,
            positionDTOList: updatedLocations,
            expirationDate: date ? date + 'T00:00:00' : ''
          };
          setListMember(updatedUsers);
        } else {
          // Tích thêm mới vị trí
          const updatedLocations =
            location !== null
              ? [...existingLocations, { positionId: location?.positionId, positionName: location?.positionName }]
              : existingLocations;
          updatedUsers[userIndex] = {
            ...user,
            roleNumber: newRole,
            positionDTOList: updatedLocations,
            expirationDate: date ? date + 'T00:00:00' : ''
          };
          setListMember(updatedUsers);
        }
      } else {
        // Nếu tồn tại, xóa khỏi mảng
        if (user?.positionDTOList?.length > 1) {
          const updatedUsers = [...listMember];
          const updatedLocations = [...existingLocations];
          updatedLocations.splice(locationIndex, 1);
          updatedUsers[userIndex] = { ...user, roleNumber: newRole, positionDTOList: updatedLocations };
          setListMember(updatedUsers);
        }
      }
    } else {
      console.log(t('project.user-dose-not'));
    }
  };

  // Phương thức chọn thời gian
  const [selectedDates, setSelectedDates] = useState({});
  const handleDateChange = (userId, date) => {
    setSelectedDates((prevState) => ({ ...prevState, [userId]: date }));
  };
  useEffect(() => {
    const getAllPosition = async () => {
      // Lấy dữ liệu vị trí mặc định của các mô hình
      if (typeProject) {
        const listRoleProject = await apiMemberProject.getPosition({
          modelInfo: typeProject,
          projectId: projectId ? projectId : 0
        });
        setRoleProject(listRoleProject?.data?.dataList);

        setListMember(
          // Lưu lại dữ liệu của danh sách thành viên khi thay đổi mô hình
          listMember?.map((list) => {
            return {
              ...list,
              positionDTOList: [
                {
                  positionId: typeProject === 'W' ? 108 : typeProject === 'K' ? 177 : 3,
                  positionName: typeProject === 'W' ? 'Developer' : typeProject === 'K' ? 'Developer' : 'Develop Team'
                }
              ]
            };
          })
        );
      }
    };
    getAllPosition();
  }, [typeProject]);

  return (
    <div
      className={` m-6 overflow-hidden duration-1000  ${openCloseMember === true ? 'max-h-[1000px] ' : 'max-h-[0px]'}`}
    >
      {/* Tìm kiếm thành viên */}
      <div className="relative flex w-full">
        <div className="absolute top-2 left-2">
          <Icon name="zoom_search" className="fill-[#9C9C9C]" />
        </div>

        <input
          type="text"
          placeholder={t('project.username-account')}
          className="h-[36px]  w-full rounded-md border border-[#F1F3FD] bg-white  pl-8 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] placeholder:text-sm focus:border-primary-100 focus:outline-none"
          onClick={() => setSearchClick(true)}
          onChange={handleInputChange}
          onCompositionStart={handleComposition}
          onCompositionEnd={handleComposition}
        />
        {searchClick === true && (
          <div
            className="absolute top-10 z-30  max-h-[350px] w-full overflow-y-auto rounded-xl border  bg-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)]"
            ref={modalRef}
          >
            {member
              ?.filter((user) => !listMember?.map((user) => user?.userId)?.includes(user?.userID))
              ?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex  w-full cursor-pointer gap-4 border-t py-2 px-4 hover:bg-[#D9D9D9]"
                    onClick={() => handleChangeMember(item)}
                  >
                    <AttachFile
                      attachType="UserAvatar"
                      entity="users"
                      seq={item?.userID}
                      viewMode={true}
                      mode="user-project"
                      className="mt-2 h-[40px] w-[40px] rounded-full object-cover"
                    />
                    <p className="flex items-center">{item?.fullName}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {/* End */}

      {/* Bảng thành viên */}
      <div className="mt-4 h-[700px] max-h-[700px] overflow-y-auto">
        <div className=" rounded-xl border">
          <table className="w-full table-auto">
            <thead className="w-full  ">
              <tr className="   bg-[#F2F2F2]">
                <th className="w-[25%] rounded-tl-xl  py-4 pl-4 text-left">{t('project.member')} </th>
                <th className="w-[25%]  p-4 text-left">{t('project.role')} </th>
                <th className="w-[25%]  p-4 text-left">{t('project.position')}</th>
                <th className="w-[25%] rounded-tr-xl py-3 px-4 text-left">{t('project.due-date')}</th>
              </tr>
            </thead>
            {listMember?.length !== 0 && (
              <tbody>
                {listMember?.map((item, index) => {
                  const dateElement = document.getElementById(`date-user-${index}`);
                  // validate input date new
                  if (dateElement) {
                    dateElement.min = new Date().toLocaleDateString('sv-SE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    });
                  }

                  let location;
                  if (item?.positionDTOList) {
                    location = item?.positionDTOList[0];
                  }
                  return (
                    <tr className="border-t pt-3" key={index}>
                      {/* Ảnh đại diện , tên tài khoản */}
                      <td className=" flex items-center gap-4 rounded-bl-xl bg-white p-2  px-4 text-left">
                        <div onClick={() => changeIdUserInfomation(item?.userId)} className="cursor-pointer">
                          <AttachFile
                            attachType="UserAvatar"
                            entity="users"
                            seq={item?.userId}
                            viewMode={true}
                            mode="form-user"
                            className="mt-2 h-[60px] w-[60px] rounded-full object-cover"
                          />
                        </div>

                        <div>
                          <h2 className="font-semibold">{item?.fullName}</h2>
                          <p className="text-gray-400">@{item?.userName}</p>
                        </div>
                      </td>
                      {/* Vai trò */}
                      <td className="relative cursor-pointer bg-white p-2 text-left font-bold ">
                        <div className="flex">
                          <div
                            className="flex gap-4 rounded-lg  border  p-2"
                            onClick={() => {
                              setProjectRole(true), setProjectRoleID(item?.userId);
                            }}
                          >
                            <h2>
                              {item?.roleNumber === 2 && 'Editor'}
                              {item?.roleNumber === 3 && 'Viewer'}
                            </h2>
                            <Icon name="chevron_down" className="stroke-[#383737]" />
                          </div>
                        </div>
                        {projectRole === true && item?.userId === projectRoleId && (
                          <div
                            className="absolute  z-30 w-[100px] bg-white p-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.24)]"
                            ref={modalRef}
                          >
                            <p
                              className={` p-2 text-center hover:bg-[#D9D9D9] ${
                                item?.roleNumber === 2 && ' bg-[#D9D9D9] text-black'
                              }`}
                              onClick={() => {
                                updateRole(item.userId, 2, null, selectedDates[item.userId]), setProjectRole(false);
                              }}
                            >
                              Editor
                            </p>
                            <p
                              className={`mt-2  p-2 text-center hover:bg-[#D9D9D9] ${
                                item?.roleNumber === 3 && ' bg-[#D9D9D9] text-black'
                              }`}
                              onClick={() => {
                                updateRole(item.userId, 3, null, selectedDates[item.userId]), setProjectRole(false);
                              }}
                            >
                              Viewer
                            </p>
                          </div>
                        )}
                      </td>
                      {/* Vị trí */}
                      <td className="relative cursor-pointer bg-white p-2 text-left font-bold ">
                        <div className="flex">
                          <div
                            className={`flex min-w-[100px] rounded-lg ${
                              typeProject === '' ? 'border-2 hover:border-[#ff9966]' : ''
                            } border  p-2`}
                            onClick={() => {
                              if (!typeProject) {
                                return;
                              }
                              setProjectLocation(true), setProjectRoleID(item?.userId);
                            }}
                          >
                            <div className="flex ">
                              <h2 className="gap-2 border-r-2 pr-3">
                                {!typeProject ? t('project.select-development-model') : location?.positionName}
                              </h2>
                              {item?.positionDTOList !== undefined &&
                                item?.positionDTOList !== 0 &&
                                item?.positionDTOList?.length - 1 !== 0 && (
                                  <p className="pl-3">+{item?.positionDTOList?.length - 1}</p>
                                )}
                            </div>
                            <Icon name="chevron_down" className="stroke-[#383737]" />
                          </div>
                        </div>
                        {projectLocation === true && item?.userId === projectRoleId && (
                          <div
                            className="absolute  z-50 w-[220px]  overflow-auto bg-white p-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.24)]"
                            ref={modalRef}
                          >
                            {roleProject?.map((role, index) => {
                              let exist = item?.positionDTOList?.some((item) => item?.positionId === role?.positionId);
                              return (
                                <div
                                  key={index}
                                  className={`mt-2 flex gap-3 p-2 hover:bg-[#D9D9D9]`}
                                  onClick={() => {
                                    updateRole(item.userId, item.roleNumber, role, selectedDates[item.userId]);
                                  }}
                                >
                                  {exist ? (
                                    <Icon name="checkbox" className="  stroke-black" />
                                  ) : (
                                    <Icon name="checkbox_2" className="  stroke-black" />
                                  )}
                                  <p>{role?.positionName}</p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </td>
                      {/* Thời gian */}
                      <td className="cursor-pointer rounded-br-xl bg-white">
                        <div className="flex gap-6">
                          <div className="w-[60%]">
                            <input
                              id={`date-user-${index}`}
                              className="ml-4 mt-2 w-full rounded-lg border py-1.5 px-2"
                              type="date"
                              value={selectedDates[item.userId] || ''}
                              onChange={(e) => {
                                handleDateChange(item.userId, e.target.value),
                                  updateRole(item.userId, item.roleNumber, null, e.target.value);
                              }}
                            />
                          </div>

                          {/* Thao tác */}
                          <div
                            className="relative cursor-pointer rounded-r-lg p-2  px-4 text-left"
                            onClick={() => {
                              setOperation(!operation), setDeleteIdMember(item.userId);
                            }}
                          >
                            <div>
                              <Icon name="small_button" className="rounded bg-[#F2F2F2] px-3 py-3" />
                            </div>
                            {operation === true && deleteIdMember === item.userId && (
                              <div
                                ref={modalRef}
                                className="absolute -left-40 top-2 z-30 w-[160px] rounded-md bg-white p-2  text-center font-medium text-red-500 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                                onClick={() => handleDelete(item.userId)}
                              >
                                <p className="text-sm">{t('department.remove-member')}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>

        {/* Danh sách member bị trống */}
        {listMember.length === 0 && (
          <div className="mt-6 flex w-full justify-center">
            <div className="text-center">
              <img src={SaveIconWhite} className="m-auto" />
              <p className="text-[#9C9C9C]">
                {t('project.member-list-empty1')} <br /> {t('project.member-list-empty2')}
              </p>
            </div>
          </div>
        )}
        {/* End */}
      </div>
    </div>
  );
};

export default CreateMember;
