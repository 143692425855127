import { useTranslation } from 'react-i18next';

const ConvertTime = (props) => {
  const { t } = useTranslation();

  if (props.timeInMileSec / 1000 < 60) {
    return t('convert-time.just-now');
  }

  if (props.timeInMileSec / (1000 * 60) < 60) {
    const getMinutes = (props.timeInMileSec / (1000 * 60)).toFixed(0);
    console.log('getMinutes', getMinutes);
    return getMinutes + ' ' + (getMinutes < 2 ? t('convert-time.minute-ago') : t('convert-time.minutes-ago'));
  }

  if (props.timeInMileSec / (1000 * 60 ** 2) < 24) {
    const getHours = (props.timeInMileSec / (1000 * 60 ** 2)).toFixed(0);
    return getHours + ' ' + (getHours < 2 ? t('convert-time.hour-ago') : t('convert-time.hours-ago'));
  }

  if (props.timeInMileSec / (1000 * 60 ** 2 * 24) < 7) {
    const getDay = (props.timeInMileSec / (1000 * 60 ** 2 * 24)).toFixed(0);
    return getDay + ' ' + (getDay < 2 ? t('convert-time.day-ago') : t('convert-time.days-ago'));
  }

  if (props?.type !== 'project' && props.timeInMileSec / (1000 * 60 ** 2 * 24 * 7) < 4) {
    const getweeks = (props.timeInMileSec / (1000 * 60 ** 2 * 24 * 7)).toFixed(0);
    return getweeks + ' ' + (getweeks < 2 ? t('convert-time.week-ago') : t('convert-time.weeks-ago'));
  }

  if (props?.type === 'project' && props.timeInMileSec / (1000 * 60 ** 2 * 24 * 7) < 4) {
    const dateString = new Date(new Date().getTime() - props.timeInMileSec);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return dateString?.toLocaleDateString('vi-VN', options);
  }

  if (props?.type !== 'project' && props.timeInMileSec / (1000 * 60 ** 2 * 24 * 31) < 12) {
    return (props.timeInMileSec / (1000 * 60 ** 2 * 24 * 31)).toFixed(0) + ' ' + t('convert-time.months-ago');
  }

  if (props?.type === 'project' && props.timeInMileSec / (1000 * 60 ** 2 * 24 * 31) < 12) {
    const dateString = new Date(new Date().getTime() - props.timeInMileSec);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return dateString?.toLocaleDateString('vi-VN', options);
  }

  if (props?.type !== 'project' && props.timeInMileSec / (1000 * 60 ** 2 * 24 * 31) > 12) {
    return (props.timeInMileSec / (1000 * 60 ** 2 * 24 * 365)).toFixed(0) + ' ' + t('convert-time.year-ago');
  }

  if (props?.type === 'project' && props.timeInMileSec / (1000 * 60 ** 2 * 24 * 31) > 12) {
    const dateString = new Date(new Date().getTime() - props.timeInMileSec);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return dateString?.toLocaleDateString('vi-VN', options);
  }
};

export default ConvertTime;
