const UserAvatar = (props) => {
  if (props.viewMode) {
    if (props.mode === 'form') {
      return (
        <img id="display-img" src={require('../../Assets/Images/notify.png')} alt="" className={props.className} />
      );
    } else {
      return (
        <img
          id={`img-${props.mode}-${props.seq}`}
          src={require('../../Assets/Images/notify.png')}
          alt=""
          className={props.className}
        />
      );
    }
  }
};

export default UserAvatar;
