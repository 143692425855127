import { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import projectBoard from '../../../../../Apis/ProjectManagement/TaskProgress/board';
import { useGlobalContext } from '../../../../../Utils/Context';
import { calendarDateModal, calendarDateModalInput } from '../../../../Calendar/CalendarComponent';
import Icon from '../../../../Common/Icon';

const PopupTime = ({ detailTask, setDetailTask, column, setEditTaskDate, setIsCallBack, isCallBack, projectInfo }) => {
  const { userInfo } = useGlobalContext();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();
  const [openDate, setOpenDate] = useState(false);
  const [enteredDate, setEnteredDate] = useState(detailTask?.endDate ? new Date(detailTask?.endDate) : new Date());
  const [endHour, setEndHour] = useState();
  const [endMinute, setEndMinute] = useState();
  const endMinuteRef = useRef(null);
  let dateTime = new Date(detailTask?.endDate);
  let hours = dateTime?.getHours(); // Lấy giờ
  let minutes = dateTime?.getMinutes();
  const dateEnd = calendarDateModal(enteredDate);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckedDate = () => {
    setIsChecked((prev) => !prev);
  };
  // Cập nhật thời gian
  const onSubmit = async (data) => {
    const time =
      (endHour ? endHour : hours >= 0 && hours <= 9 ? '0' + hours : hours) +
      ':' +
      (endMinute ? endMinute : minutes >= 0 && minutes <= 9 ? '0' + minutes : minutes) +
      ':00';
    const newData = {
      projBoardTaskId: detailTask.projBoardTaskId,
      projBoardColId: column.projBoardColId,
      taskName: detailTask.taskName,
      projBoardId: column?.projBoardId,
      endDate: data.checkedDate === false ? null : dateEnd + (time ? 'T' + time : 'T00:00:00'),
      actionFlag:
        detailTask?.endDate === null ? 'addEndDate' : data.checkedDate === false ? 'deleteEndDate' : 'updateEndDate'
    };
    try {
      await projectBoard.editTaskKanban(newData, 'enddate');
      setDetailTask({
        ...detailTask,
        taskName: detailTask.taskName,
        endDate: data.checkedDate === false ? null : dateEnd + (time ? 'T' + time : 'T00:00:00')
      });
      setEditTaskDate(false);
      setIsCallBack(!isCallBack);
      toast.success(t('project.time-update-successful'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  // Hàm xử lý khi kích ra ngoài input thì tự động cập nhật API
  const onChange = (e) => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    setEnteredDate(e);
  };
  // Lấy thời gian ngày tháng năm
  const handleDate = (e) => {
    if (e.target.value) {
      const dateObject = new Date(e.target.value);
      setEnteredDate(dateObject);
    }
    setOpenDate(false);
  };

  const endHourRef = useRef(null);
  const handleHourChange = (event) => {
    let value = event.target.value;
    setEndHour(String(value).padStart(2, '0'));
    if (!isNaN(value) && value >= 0 && value <= 23) {
      if (value.length === 2) {
        // Nếu đã nhập đủ 2 ký tự, chuyển focus sang ô input thứ hai
        endMinuteRef.current.focus();
      }
    }
  };

  const handleMinuteChange = (event) => {
    let value = event.target.value;
    setEndMinute(String(value).padStart(2, '0'));
  };

  const valueCalendar = localStorage.getItem('i18next');
  const viLocale = {
    locale: valueCalendar,
    weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    monthNames: [
      'Tháng 1',
      'Tháng 2',
      'Tháng 3',
      'Tháng 4',
      'Tháng 5',
      'Tháng 6',
      'Tháng 7',
      'Tháng 8',
      'Tháng 9',
      'Tháng 10',
      'Tháng 11',
      'Tháng 12'
    ]
    // prevLabel: <img src={Left} alt="" className="mx-4" />,
    // nextLabel: <img src={Right} alt="" className="mx-4" />
  };
  return (
    <div className="">
      <div className="mb-1 pl-3">
        <h2 className="text-base font-semibold">{t('menuname.calendar')}</h2>
      </div>
      {/* Lịch */}
      <div className="flex justify-center">
        <Calendar
          tileDisabled={({ date }) => {
            const newDate = new Date(new Date().setDate(new Date().getDate() - 1));
            return date <= newDate;
          }}
          tileClassName={({ date }) => {
            const today = new Date().setHours(0, 0, 0, 0);
            const tileDate = date.setHours(0, 0, 0, 0);
            return tileDate < today ? 'opacity-50' : 'opacity-100 ';
          }}
          className="uda-calendar  top-8 z-50 rounded-b-lg"
          onChange={onChange}
          value={enteredDate}
          min={new Date()}
          {...viLocale}
          calendarType={valueCalendar === 'en' ? 'US' : 'ISO 8601'}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6 px-3">
          <h2 className="text-base font-semibold">{t('job.end-date')}</h2>
          <div className="my-2 flex gap-3">
            {/* Checkbox có thêm ngày giờ hay không */}
            <div className="mt-2">
              <input
                disabled={projectInfo?.roleProject === 3}
                type="checkbox"
                defaultChecked={enteredDate ? 1 : ''}
                defaultValue={false}
                className="h-[15px] w-[15px]"
                {...register('checkedDate')}
                onChange={handleCheckedDate}
              />
            </div>
            {/* Nhập ngày tháng năm */}
            <div className=" w-[160px]  rounded-lg bg-[#F7F8FC] px-1">
              {openDate ? (
                <div className="relative flex gap-2">
                  <p className="  w-[30px]  bg-[#F7F8FC] pt-2.5 pl-2">
                    <Icon name="calendar" className="h-[18px] w-[18px] stroke-black" />
                  </p>
                  <input
                    disabled={projectInfo?.roleProject === 3}
                    type="date"
                    className=" w-[160px] rounded-lg  bg-[#F7F8FC]  py-2  pl-3 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-none  focus:outline-none"
                    defaultValue={calendarDateModal(enteredDate)}
                    onBlur={handleDate}
                  />
                  <p className="absolute top-2.5 right-0 z-30 w-[20px]  bg-[#F7F8FC] text-white">.</p>
                </div>
              ) : (
                <div className={`flex ${isChecked ? 'text-gray-200' : ''} `}>
                  <Icon name="calendar" className="mt-2 h-[18px] w-[18px] stroke-black" />
                  <p
                    className={` w-[80%] rounded-lg bg-[#F7F8FC] px-3  py-2  text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-none  focus:outline-none`}
                    onClick={() => {
                      isChecked ? '' : setOpenDate(true);
                    }}
                  >
                    {calendarDateModalInput(enteredDate, userInfo)}
                  </p>
                </div>
              )}
            </div>
            {/* Nhập giờ phút */}
            <div className={`relative flex rounded-lg bg-[#F7F8FC] ${isChecked ? 'text-gray-200' : ''}`}>
              <Icon name="time" className="stroke-black pl-3" />
              <div className="relative">
                <input
                  type={projectInfo?.roleProject === 3 ? 'disable' : 'number'}
                  min={0}
                  max={23}
                  placeholder="00"
                  defaultValue={hours >= 0 && hours <= 9 ? '0' + hours : hours}
                  className="  w-[45px] rounded-lg bg-[#F7F8FC]  py-2  pl-2 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-none  focus:outline-none"
                  onChange={handleHourChange}
                  ref={endHourRef}
                />
                <div className="absolute top-1 -right-1 w-[20px] bg-[#F7F8FC] text-black">
                  <p className="pl-2">:</p>
                </div>
              </div>
              <p className="pt-1">:</p>
              <div className="relative">
                <input
                  type={projectInfo?.roleProject === 3 ? 'disable' : 'number'}
                  min={0}
                  max={60}
                  defaultValue={minutes >= 0 && minutes <= 9 ? '0' + minutes : minutes}
                  placeholder="00"
                  className="  w-[40px] rounded-lg bg-[#F7F8FC]  py-2   text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-none  focus:outline-none"
                  onChange={handleMinuteChange}
                  ref={endMinuteRef}
                />
                <p className="absolute top-0 right-0 w-[15px] bg-[#F7F8FC] text-white">.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="my-6">
          <div className="flex justify-center gap-4">
            <p
              className="w-[100px] cursor-pointer rounded-md border border-primary-100 py-1 px-3 text-center text-primary-100"
              onClick={() => setEditTaskDate(false)}
            >
              {t('project.cancel')}
            </p>

            <button
              disabled={projectInfo?.roleProject === 3}
              className={`${
                projectInfo?.roleProject === 3 && 'opacity-25'
              } w-[100px] rounded-md bg-primary-100 py-1 px-3 text-center text-white`}
            >
              {t('project.confirm')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PopupTime;
