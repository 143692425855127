import ImageResize from 'quill-image-resize-module-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSend } from 'react-icons/bi';
import { BsFileText } from 'react-icons/bs';
import { FaArrowLeft } from 'react-icons/fa';
import { FaRegCircleQuestion } from 'react-icons/fa6';
import { GoPerson } from 'react-icons/go';
import { IoClose, IoCloseOutline } from 'react-icons/io5';
import { MdOutlineAttachFile, MdOutlineImage } from 'react-icons/md';
import WarningIc from '../../../Assets/Images/Icons/warning-ic.svg';

import { RiBatteryShareLine } from 'react-icons/ri';
import ReactQuill, { Quill } from 'react-quill';

import { useGlobalContext } from '../../../Utils/Context';
import { formats, modules } from './customToolBar';

import 'animate.css/animate.min.css';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router';
import { toast as toastify } from 'react-toastify';
import { toast } from 'sonner';
import { GetUploadKey } from '../../../Apis/AttachFile/files';
import { GetDetailReport, GetListUsers } from '../../../Apis/Reports/Reports';
import '../../../Assets/Css/customReactQuillReport.css';
import AttachFile from '../../../Components/AttachFile/AttachFile';
import Icon from '../../../Components/Common/Icon';
import backendURL from '../../../config';
import { cleanHtml, htmlToText } from '../../../Utils-v2/HtmlUtils';
import SuggestReport from './SuggestReport';

Quill.register('modules/imageResize', ImageResize);

const ReportDetailDraft = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { bzStomp, userInfo } = useGlobalContext();
  const createBy = userInfo?.userID;
  const quillRef = useRef(null);
  const fileInputImgRef = useRef(null);
  const fileInputFileRef = useRef(null);
  const ccInputRef = useRef(null);
  const dropdownRefConfirm = useRef(null);
  const dropdownRefCc = useRef(null);
  const suggestRef = useRef(null);

  const [content, setContent] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [createByUser, setCreateByUser] = useState(createBy);
  // eslint-disable-next-line no-unused-vars
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadKey, setUploadKey] = useState('');
  const [uploadedFilesInfo, setUploadedFilesInfo] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchCc, setIsSearchCc] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersCc, setUsersCc] = useState([]);
  const [confirmUser, setConfirmUser] = useState([]);
  const [searchStringCc, setSearchStringCc] = useState('');
  const [ccUsers, setCcUsers] = useState([]);
  const [idUserCcSelected, setIdUserCcSelected] = useState([]);
  const [reportTitle, setReportTitle] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isSuggest, setIsSuggest] = useState(false);
  const [blurTimeoutId, setBlurTimeoutId] = useState(null);
  const [titleValue, setTitleValue] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    setCreateByUser(createBy);
    if (createBy) {
      setIdUserCcSelected((prevSelected) => {
        if (!prevSelected.includes(createBy)) {
          return [...prevSelected, createBy];
        }
        return prevSelected;
      });
    }
  }, [createBy]);

  function debounce(func, wait) {
    let timeout;

    const debounced = function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };

    debounced.cancel = function () {
      clearTimeout(timeout);
    };

    return debounced;
  }

  useEffect(() => {
    // Assuming you have a function to get the uploadKey
    getUploadKey().then((res) => {
      setUploadKey(res?.data?.data.key);
    });
  }, []);

  useEffect(() => {
    function handleClickOutsideConfirm(event) {
      if (dropdownRefConfirm.current && !dropdownRefConfirm.current.contains(event.target)) {
        setIsSearch(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutsideConfirm);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideConfirm);
    };
  }, []);

  useEffect(() => {
    function handleClickOutsideCc(event) {
      if (dropdownRefCc.current && !dropdownRefCc.current.contains(event.target)) {
        setIsSearchCc(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutsideCc);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCc);
    };
  }, []);

  //Close modal suggest while click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (suggestRef.current && !suggestRef.current.contains(event.target)) {
        setIsSuggest(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [suggestRef]);

  const getUploadKey = async () => {
    const uploadKeyResponse = await GetUploadKey();
    return uploadKeyResponse;
  };

  useEffect(() => {
    GetDetailReport(reportId).then((res) => {
      const quill = quillRef.current.getEditor();
      const data = res?.data?.data;
      setTitleValue(data?.title);
      setReportTitle(data?.title);
      setConfirmUser(data?.revUserDTO);
      setCcUsers(data?.ccUserDTO);
      const element = document.createElement('div');
      element.innerHTML = data?.content.replaceAll('\\', '');
      const html = element.innerHTML;
      const imgElements = element.querySelectorAll('img');
      quill.pasteHTML(0, html);
      const quillDOMNode = quill?.container;
      const imgQuills = quillDOMNode.querySelectorAll('img');
      // Duyệt qua từng phần tử <img> và thực hiện các thay đổi
      imgQuills.forEach((image, index) => {
        image.width = imgElements[index].width;
      });
      setUploadedFilesInfo(data?.attachmentFile);
      if (!data?.ccUserId) {
        setIdUserCcSelected([...idUserCcSelected, createBy]);
      } else {
        const ccUserArr = data.ccUserId.split(',');
        setIdUserCcSelected([...idUserCcSelected, [...ccUserArr]].flat());
      }
    });
  }, [reportId]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (searchString.trim() !== '' && searchStringCc.trim() === '') {
        try {
          const response = await GetListUsers(searchString);
          const idUserCcSelectedNumber = idUserCcSelected.map(Number);
          const fetchedUsers = response?.data?.dataList.map((user) => ({
            ...user,
            isSelected: idUserCcSelectedNumber.includes(user?.userID)
          }));
          setIsSearch(true);
          setUsers(fetchedUsers); // Adjust according to the response structure
        } catch (error) {
          console.error('Failed to fetch users:', error);
          setUsers([]);
          setIsSearch(false);
        }
      } else {
        setUsers([]);
        setIsSearch(false);
      }
      if (searchStringCc.trim() !== '') {
        try {
          const response = await GetListUsers(searchStringCc);
          const fetchedUsersCc = response?.data?.dataList.map((user) => {
            const idUserSelectedNumber = idUserCcSelected.map(Number);
            return {
              ...user,
              isSelected: idUserSelectedNumber.includes(+user.userID)
            };
          });
          setIsSearchCc(true);
          setUsersCc(fetchedUsersCc); // Adjust according to the response structure
        } catch (error) {
          console.error('Failed to fetch users:', error);
          setUsersCc([]);
          setIsSearchCc(false);
        }
      } else {
        setUsersCc([]);
        setIsSearchCc(false);
      }
    };

    // Debounce fetchUsers call
    const debouncedFetchUsers = debounce(fetchUsers, 500);
    debouncedFetchUsers();

    // Cleanup the debounce timeout on component unmount
    return () => {
      debouncedFetchUsers.cancel(); // Make sure to implement cancel in the debounce function
    };
  }, [searchString, searchStringCc]);

  useEffect(() => {
    if (bzStomp.connected) {
      bzStomp.subscribe('/topic/private-notify', (res) => {
        // The socket receives the signal and retrieves the comment list
        const response = JSON.parse(res.body);
        console.log('response socket: ', response);
      });
    }
  }, [bzStomp.connected]);

  const handleInputChange = (event) => {
    setSearchString(event.target.value);
    setIsSearchCc(false);
  };

  const handleInputCcChange = (event) => {
    setSearchStringCc(event.target.value);
    setIsSearch(false);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const maxSize = 10 * 1024 * 1024;
    if (!file) return;
    if (file.size > maxSize) {
      toastify.error(`${t('project.limit-size')} : ${file.name}`, { autoClose: 5000 });
      event.target.value = '';
    } else {
      setSelectedFile(file);

      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('chunks', '0');
      formData.append('chunk', '0');
      formData.append('uploadKey', uploadKey);
      formData.append('file', file);

      try {
        const response = await fetch(`${backendURL}/file`, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();

        if (data && data.data && data.data.seq) {
          const fileSeq = data.data.seq;
          insertToEditor(fileSeq);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const newUploadedFilesInfo = [];
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const invalidFiles = files.filter((file) => file.size > maxSize).map((file) => file.name);

    if (invalidFiles.length > 0) {
      toastify.error(`${t('project.limit-size')} : ${invalidFiles.join(', ')}`, { autoClose: 5000 });
      event.target.value = '';
    } else {
      for (const file of files) {
        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('chunks', '0');
        formData.append('chunk', '0');
        formData.append('uploadKey', uploadKey);
        formData.append('file', file);

        try {
          const uploadResponse = await fetch(`${backendURL}/file`, {
            method: 'POST',
            body: formData
          });

          if (!uploadResponse.ok) throw new Error('Upload failed');

          const uploadResult = await uploadResponse.json();
          const fileSeq = uploadResult.data.seq;

          newUploadedFilesInfo.push({
            fileName: uploadResult?.data?.fileNm,
            uploadTime: uploadResult?.data?.createdAt,
            firstPageImage: uploadResult.firstPageImage,
            fileSeq: fileSeq
          });
        } catch (error) {
          console.error('Error:', error);
          // Optionally handle the error, e.g., by showing a message to the user
        }
      }

      // Append new uploaded files info to the existing state
      setUploadedFilesInfo((prevFiles) => [
        ...prevFiles,
        ...newUploadedFilesInfo.map((file) => ({
          ...file,
          fileUrl: `${backendURL}/file/${file.fileSeq}`
        }))
      ]);
    }
  };

  const insertToEditor = (fileSeq) => {
    const range = quillRef.current.getEditor().getSelection(true) || 1;
    const imageUrl = `${backendURL}/file/displayImg/${fileSeq}`;
    const quillEditor = quillRef.current.getEditor();

    quillEditor.insertEmbed(range.index, 'image', `${imageUrl}`);

    quillEditor.format('align', 'center', Quill.sources.USER);

    // Optionally, adjust the selection after inserting the image
    quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  const deleteFile = (index) => {
    setUploadedFilesInfo((currentFiles) => currentFiles.filter((_, fileIndex) => fileIndex !== index));
  };
  const handleSearch = (user) => {
    const selectedApprover = idUserCcSelected.filter((id) => id !== confirmUser?.userID);
    setIsSearch(false);
    setConfirmUser(user);
    setSearchString('');
    setIdUserCcSelected([...selectedApprover, selectedApprover.includes(user?.userID) ? null : user?.userID]);
  };

  const handleSearchCc = (user, e) => {
    e.preventDefault();
    setIsSearchCc(false);
    setIsSearch(false);
    if (!ccUsers.find((ccUser) => ccUser?.userID === user?.userID)) {
      setCcUsers([...ccUsers, user]);
    }
    setIdUserCcSelected((currentIds) => {
      if (currentIds.includes(user?.userID)) {
        return currentIds.filter((id) => +id !== +user?.userID);
      } else {
        return [...currentIds, user?.userID];
      }
    });
    setSearchStringCc('');
    setTimeout(() => {
      ccInputRef.current.focus();
    }, 10);
    setIsInputFocused(true);
  };

  const removeUserFromCc = (userIdToRemove, e) => {
    e.stopPropagation();
    setCcUsers(ccUsers.filter((user) => +user?.userID !== +userIdToRemove));
    setIdUserCcSelected(idUserCcSelected.filter((id) => +id !== +userIdToRemove));
    setTimeout(() => {
      ccInputRef.current.focus();
    }, 0);
  };
  const [errorLeave, setErrorlever] = useState();
  const [statusDraft, setStatusDraft] = useState();

  const validateForm = () => {
    const errors = {};
    const text = htmlToText(content);
    const textTrim = cleanHtml(content);
    const contentLength = text.length;

    if (reportTitle.trim() === '') {
      errors.title = t('leave-management.can-not-leave-the-title-blank!');
    }
    if (!confirmUser?.userID && statusDraft === true) {
      errors.revUserId = t('reports.please-select-sender');
    }
    if ((content === '' || textTrim === '') && statusDraft === true) {
      errors.content = t('project.please-enter-content');
    }
    if (contentLength > 10000) {
      errors.content = t('reports.maximum-10000-characters');
    }
    if (reportTitle.length > 255) {
      errors.title = t('project.maximum-255-characters');
    }

    setErrorlever(errors);
    return errors;
  };

  const [clickSendRepost, setClickSendRepost] = useState(false);
  useEffect(() => {
    if (clickSendRepost === true) {
      validateForm();
    }
  }, [reportTitle, confirmUser?.userID, content, statusDraft]);
  const formattedDate = new Date().toISOString().slice(0, 19).replace('T', ' ');

  const sendReport = async (isDraft, isSend, e) => {
    setStatusDraft(isSend);
    e.preventDefault();
    const validationErrors = validateForm(isSend);

    if (Object.keys(validationErrors).length) {
      setErrorlever(validationErrors);

      return;
    }

    if (isSend) {
      if (Object.keys(validationErrors).length === 0) {
        const data = {
          title: reportTitle,
          createBy: createBy,
          revUserId: !confirmUser.userID ? null : confirmUser.userID,
          ccUserid: !ccUsers?.length ? null : ccUsers.map((user) => user.userID).join(','),
          isDraft: 0,
          status: 1,
          fileSeq: !uploadedFilesInfo?.length
            ? null
            : uploadedFilesInfo.map((file) => (file.fileSeq ? file.fileSeq : parseInt(file.content))).join(','),
          content: content.replaceAll('"', '\\"'),
          rprId: +reportId
        };

        const queryString = `name=${encodeURIComponent(
          `${formattedDate}-${new Date().toLocaleTimeString()}`
        )}&uploadKey=${uploadKey}`;

        const url = `${backendURL}/report/createReport?${queryString}`;

        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
          });

          if (!response.ok) throw new Error(t('reports.toast-error'));
          setReportTitle('');
          setConfirmUser({});
          setCcUsers([]);
          setContent('');
          setTitleValue('');
          setUploadedFilesInfo([]);
          setIdUserCcSelected([createBy]);
          isDraft
            ? toast.success(t('reports.report-sent-successfully'))
            : toast.success(t('reports.toast-success-reports'));
          isSend ? navigate('/report?type=2') : navigate('/report?type=3');
        } catch (error) {
          toast.warning(t('reports.toast-error'));
        }
      } else {
        setErrorlever(validationErrors);
      }
    } else {
      if (Object.keys(validationErrors).length === 0) {
        const data = {
          title: reportTitle,
          createBy: createBy,
          revUserId: !confirmUser?.userID ? null : confirmUser?.userID,
          ccUserid: !ccUsers?.length ? null : ccUsers.map((user) => user.userID).join(','),
          isDraft: 1,
          status: 1,
          fileSeq: !uploadedFilesInfo?.length
            ? null
            : uploadedFilesInfo.map((file) => (file.fileSeq ? file.fileSeq : parseInt(file.content))).join(','),
          content: content.replaceAll('"', '\\"'),
          rprId: +reportId
        };

        const queryString = `name=${encodeURIComponent(
          `${formattedDate}-${new Date().toLocaleTimeString()}`
        )}&uploadKey=${uploadKey}`;

        const url = `${backendURL}/report/createReport?${queryString}`;

        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
          });

          if (!response.ok) throw new Error(t('reports.toast-error'));
          setReportTitle('');
          setConfirmUser({});
          setCcUsers([]);
          setContent('');
          setTitleValue('');
          setUploadedFilesInfo([]);
          setIdUserCcSelected([createBy]);
          isSend
            ? toast.success(t('reports.report-sent-successfully'))
            : toast.success(t('reports.draft-saved-successfully'));
          isSend ? navigate('/report?type=2') : navigate('/report?type=3');
          validateForm(false);
          setErrorlever();
        } catch (error) {
          toast.warning(t('reports.toast-error'));
        }
      }
    }
  };

  const handleSuggest = () => {
    setIsSuggest(true);
  };

  const handleInputBlur = () => {
    const timeoutId = setTimeout(() => {
      setIsInputFocused(false);
    }, 200);
    setBlurTimeoutId(timeoutId);
  };

  const handleFocus = () => {
    if (blurTimeoutId) {
      clearTimeout(blurTimeoutId);
      setBlurTimeoutId(null);
    }
    setIsInputFocused(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="h-full min-h-screen px-6 pt-6">
      {/* Header */}
      <div>
        <div className="flex cursor-pointer items-center gap-2">
          <div className="transition-opacity hover:opacity-80">
            <FaArrowLeft size={16} onClick={() => navigate(-1)} />
          </div>
          <span className="text-lg font-semibold">{t('reports.details-of-the-report-draft')}</span>
        </div>
        <div className="text-sm text-[#888888]">{t('reports.detail-draft')}</div>
      </div>

      {/* Form Report */}
      <form action="" className="m-auto mt-6 max-w-[90%] overflow-hidden rounded-md">
        {/* Title */}
        <div className="relative flex items-center border-b border-slate-200 bg-slate-200 px-4 py-3 font-semibold">
          <label htmlFor="title" className="cursor-pointer text-sm text-[#686868]">
            {t('reports.title')} <span className="text-red-500">*</span>:
          </label>
          <input
            id="title"
            type="text"
            placeholder={t('reports.enter-a-title')}
            className="w-[90%] bg-transparent px-2 py-2 text-sm placeholder:text-sm focus:border-none focus:outline-none"
            onChange={(e) => {
              setTitleValue(e.target.value);
              setReportTitle(e.target.value);
            }}
            value={titleValue}
          />
          <FaRegCircleQuestion
            size={20}
            className="absolute right-4 cursor-pointer text-[#686868]"
            onClick={() => handleSuggest()}
          />
          <div className="relative">
            {isSuggest && (
              <div
                ref={suggestRef}
                className="shadow-table animate__animated animate__zoomIn animate__faster absolute top-full -right-14 z-30 w-[400px] origin-top-right overflow-hidden rounded-lg bg-blue-50"
              >
                <SuggestReport />
              </div>
            )}
          </div>
        </div>
        {errorLeave?.title && (
          <p className="flex gap-1 pl-5 pb-2">
            <img src={WarningIc} alt="warning-icon" />
            <span className="text-xs font-normal text-red-500">{errorLeave?.title}</span>
          </p>
        )}

        {/* Body Form */}
        <div className="mb-2 rounded-b-lg bg-white px-4 py-3">
          {/* Người phê duyệt */}
          <div className="mb-4">
            <div className="mt-2 flex gap-2">
              <GoPerson size={18} />
              <label htmlFor="approver" className="cursor-pointer text-sm font-semibold text-gray-800">
                <span>{t('reports.approver')} </span>
                <span>
                  ({t('reports.required-when-submitting-reports')} <span className="text-red-500">*</span>)
                </span>
              </label>
            </div>
            <div className="shadow-table relative mt-2 flex items-center rounded-lg border border-slate-200">
              {Object.keys(confirmUser || {}).length !== 0 ? (
                <div
                  htmlFor="approver"
                  className="mr-2 flex w-fit min-w-[200px] items-center justify-between gap-1 whitespace-nowrap rounded-md border border-[#9C9C9C] bg-[#F2F2F2] p-2  text-[13px] shadow"
                >
                  <AttachFile
                    attachType="UserAvatar"
                    entity="users"
                    seq={confirmUser?.userID}
                    viewMode={true}
                    mode="list"
                    className="h-8 w-8 rounded-full object-cover"
                  />
                  <span>{confirmUser?.userName}</span>
                  <IoCloseOutline
                    size={14}
                    onClick={(e) => {
                      setConfirmUser([]), removeUserFromCc(confirmUser?.userID, e);
                    }}
                    className="cursor-pointer"
                  />
                </div>
              ) : (
                ''
              )}
              <input
                id="approver"
                type="text"
                className="min-h-[40px] w-full rounded-lg px-2 py-3 text-[13px] outline-none placeholder:text-[#888888]"
                placeholder={Object.keys(confirmUser || {})?.length === 0 ? t('reports.user-name/account-name') : ''}
                value={searchString}
                onChange={handleInputChange}
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
              {isSearch && (
                <div
                  ref={dropdownRefConfirm}
                  className="shadow-search absolute top-full z-10 max-h-[400px] w-full overflow-hidden overflow-y-auto rounded-lg bg-white"
                >
                  {users.map((user) => (
                    <div
                      key={user.userID}
                      onClick={() => handleSearch(user)}
                      className={`flex cursor-pointer items-center gap-2 py-1 px-2 transition-colors hover:bg-slate-200 ${
                        user.isSelected && 'pointer-events-none text-gray-400 opacity-50'
                      }`}
                    >
                      {/* avatar */}
                      <div>
                        <AttachFile
                          attachType="UserAvatar"
                          entity="users"
                          seq={user?.userID}
                          viewMode={true}
                          mode="list"
                          className="h-10 w-10 rounded-full object-cover"
                        />
                      </div>
                      {/* information search user */}
                      <div className="text-sm">
                        <p className="">{user?.userName} </p>
                        <p>@{user?.fullName}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {errorLeave?.revUserId && (
            <p className="-mt-3 flex gap-1 pb-2">
              <img src={WarningIc} alt="warning-icon" />
              <span className="text-xs font-normal text-red-500">{errorLeave?.revUserId}</span>
            </p>
          )}

          {/* Đồng gửi */}
          <div className="mb-4">
            <div className="mt-2 flex gap-1">
              <RiBatteryShareLine size={18} />
              <label htmlFor="user-account-name" className="cursor-pointer text-sm font-semibold text-gray-800">
                {t('reports.cc')}
              </label>
            </div>
            <div
              ref={dropdownRefCc}
              className="shadow-table relative mt-2 flex flex-wrap items-center gap-1 rounded-lg border border-slate-200"
            >
              {!isInputFocused && ccUsers.length > 2 ? (
                <div
                  className="flex items-center pl-2"
                  onClick={() => ccInputRef.current.focus()}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                >
                  {ccUsers.slice(0, 3).map((user, index) => (
                    <span key={user?.userID} className="px-1 text-sm">{`${user.userName}${
                      index < 2 ? ',' : ' '
                    }`}</span>
                  ))}
                  <span
                    className={`shadow-table ml-2 rounded-lg border border-slate-200 bg-slate-200 px-1 text-sm ${
                      ccUsers.length > 3 ? '' : 'hidden'
                    }`}
                  >
                    {ccUsers.length - 3} {t('reports.more')}
                  </span>
                </div>
              ) : (
                ccUsers.map((userCc) => (
                  <div
                    key={userCc?.userID}
                    className="mr-2 flex w-fit items-center justify-between gap-1 whitespace-nowrap rounded-md border border-[#9C9C9C] bg-[#F2F2F2] p-1 text-[13px] shadow"
                  >
                    <AttachFile
                      attachType="UserAvatar"
                      entity="users"
                      seq={userCc?.userID}
                      viewMode={true}
                      mode="list"
                      className="h-6 w-6 rounded-full object-cover"
                    />
                    <span>{userCc?.userName}</span>
                    <IoCloseOutline
                      size={14}
                      onClick={(e) => {
                        removeUserFromCc(userCc?.userID, e);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                ))
              )}
              <input
                id="user-account-name"
                type="text"
                className={`w-[250px] rounded-lg p-2 text-[13px] outline-none placeholder:text-[#888888] ${
                  !isInputFocused ? 'py-2' : 'py-2'
                }`}
                placeholder={ccUsers?.length === 0 ? t('reports.user-name/account-name') : ''}
                value={searchStringCc}
                onChange={handleInputCcChange}
                ref={ccInputRef}
                onFocus={handleFocus}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />

              {isSearchCc && (
                <div className="shadow-search absolute top-full z-20 max-h-[400px] w-full overflow-hidden overflow-y-auto rounded-lg bg-white">
                  {usersCc.map((user) => {
                    return (
                      <div
                        key={user?.userID}
                        onClick={(e) => handleSearchCc(user, e)}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={() => setIsInputFocused(false)}
                        className={`flex cursor-pointer items-center gap-2 py-2 px-2 transition-colors hover:bg-slate-200 ${
                          user.isSelected && 'pointer-events-none text-gray-400 opacity-50'
                        }`}
                      >
                        {/* avatar */}
                        <div>
                          <AttachFile
                            attachType="UserAvatar"
                            entity="users"
                            seq={user?.userID}
                            viewMode={true}
                            mode="list-ds"
                            className="h-10 w-10 rounded-full object-cover"
                          />
                        </div>
                        {/* information search user */}
                        <div className="text-sm">
                          <p className="">{user?.userName}</p>
                          <p>@{user?.fullName}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/* Nội dung báo cáo */}
          <div>
            <div className="mt-2 flex gap-1">
              <BsFileText size={18} />
              <span className="text-sm font-semibold text-gray-800">
                <span>{t('reports.content')} </span>
                <span>
                  ({t('reports.required-when-submitting-reports')} <span className="text-red-500">*</span>)
                </span>
              </span>
            </div>
            <div className="relative mt-2 mb-4 ">
              <ReactQuill
                ref={quillRef}
                // theme="snow"
                value={content}
                onChange={setContent}
                modules={modules}
                formats={formats}
                placeholder={t('reports.write-content')}
                className="react-quill-report  border border-slate-200"
              />
              {errorLeave?.content && (
                <p className="flex gap-1  pb-2">
                  <img src={WarningIc} alt="warning-icon" />
                  <span className="text-xs font-normal text-red-500">{errorLeave?.content}</span>
                </p>
              )}
              <div className="mt-2 mb-2 flex gap-2">
                {/* Handle Image */}
                <div className="flex gap-1">
                  <label
                    htmlFor="upload-image"
                    className="flex h-[35px] cursor-pointer items-center justify-center gap-1 rounded-full border border-slate-200 px-2"
                  >
                    <MdOutlineImage size={16} />
                    <span className="text-sm">{t('reports.attach-image')}</span>
                  </label>
                  <input
                    id="upload-image"
                    type="file"
                    hidden
                    onChange={handleImageChange}
                    ref={fileInputImgRef}
                    accept=".jpg,.png,.jpeg"
                  />
                </div>
                {/* Handle File */}
                <div>
                  <label
                    htmlFor="upload-file"
                    className="flex h-[35px] cursor-pointer items-center justify-center gap-1 rounded-full border border-slate-200 px-2"
                  >
                    <MdOutlineAttachFile size={16} />
                    <span className="text-sm">{t('reports.attach-file')}</span>
                  </label>
                  <input
                    id="upload-file"
                    type="file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.pptx,.txt"
                    multiple
                    hidden
                    onChange={handleFileChange}
                    ref={fileInputFileRef}
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-3">
                {uploadedFilesInfo.map((fileInfo, index) => (
                  <div
                    key={index}
                    className="file-info relative mb-1 flex items-center justify-between gap-2 rounded-md border border-[#9C9C9C] bg-[#F2F2F2] p-2 text-sm"
                  >
                    <p className="line-clamp-1 cursor-pointer text-sm transition-colors hover:text-primary-100">
                      {fileInfo.fileName}
                    </p>
                    <div className="flex items-center gap-2 ">
                      {/* <a
                        href={fileInfo.content}
                        download={fileInfo.fileName}
                        className="download-button transition-colors hover:text-primary-100"
                      >
                        <MdOutlineFileDownload size={18} />
                      </a> */}
                      {/* Delete button */}
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteFile(index);
                        }}
                        className="delete-button transition-colors hover:text-rose-600"
                      >
                        <IoClose size={18} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Button Cancel, Save, Draft */}
        <div className="mt-4 mb-4 flex items-center justify-center  gap-4 text-sm">
          <div
            onClick={() => navigate(-1)}
            className="flex w-[150px] cursor-pointer items-center justify-center gap-2 rounded-lg border border-[#9C9C9C] px-6 py-2 text-center font-[510] shadow-lg transition-opacity hover:opacity-80"
          >
            <Icon name="close_cross" className="stroke-black" />
            <p>{t('reports.cancel')}</p>
          </div>
          <div className="flex gap-4">
            <button
              onClick={(e) => {
                validateForm();
                setClickSendRepost(true);
                sendReport(true, false, e);
              }}
              className="flex w-[180px] cursor-pointer items-center justify-center gap-2 rounded-md border border-[#9C9C9C] px-6  py-2  font-[510] shadow-lg transition-opacity hover:opacity-80"
            >
              <Icon name="folder_opened" />
              <p>{t('reports.btn-draft')}</p>
            </button>
            <button
              onClick={(e) => {
                validateForm();
                setClickSendRepost(true);
                sendReport(true, true, e);
              }}
              className="flex w-[160px] cursor-pointer items-center justify-center gap-1 rounded-md border border-primary-100 bg-primary-100 px-6 py-2 font-[510] text-slate-100 shadow-lg transition-opacity hover:opacity-80"
            >
              <BiSend size={16} />
              <span>{t('reports.btn-send')}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportDetailDraft;
