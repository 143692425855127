const Pagination = ({ setCurrentPage, currentPage, pagination, itemNumber, setIsSearch, totalP }) => {
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (setIsSearch) {
      setIsSearch(false);
    }
  };

  const renderPagination = () => {
    const totalPages = totalP ?? Math.ceil(pagination?.totalItems / itemNumber);
    const visiblePages = [];
    for (let i = 1; i <= totalPages; i++) {
      visiblePages.push(i);
    }
    let pageNumbers = [];
    if (totalPages <= 5) {
      // Nếu tổng số trang nhỏ hơn hoặc bằng 5, hiển thị tất cả các trang
      pageNumbers = visiblePages;
    } else {
      // Ngược lại, hiển thị tối đa 5 trang và thêm dấu 3 chấm
      if (currentPage + 1 < 4) {
        pageNumbers = [1, 2, 3, 4, '...', totalPages];
      } else if (currentPage > totalPages - 4) {
        pageNumbers = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      } else {
        pageNumbers = [1, '...', currentPage, currentPage + 1, currentPage + 2, '...', totalPages];
      }
    }

    return (
      <ul className="flex justify-end gap-x-3 text-xs font-medium">
        <li>
          <button
            className={`inline-flex h-8 w-8 items-center justify-center rounded-full border bg-neu-500 hover:bg-[#D9D9D9] rtl:rotate-180 ${
              currentPage === 0 ? 'pointer-events-none opacity-50' : ''
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
          >
            <span className="sr-only">Prev Page</span>
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.49989 0.5L1.53022 4.46967C1.23732 4.76256 1.23732 5.23744 1.53022 5.53033L5.49989 9.5"
                stroke="#171717"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </li>
        {pageNumbers.map((page, index) => (
          <li key={index}>
            <button
              onClick={() => {
                if (page !== '...') {
                  handlePageChange(page - 1);
                }
              }}
              disabled={currentPage === page - 1 || page === '...'}
              className={
                currentPage === page - 1
                  ? `block h-8 w-8 rounded-full bg-primary-100 text-center leading-8 text-white`
                  : `block h-8 w-8 rounded-full bg-[#F2F2F2] text-center leading-8 text-gray-900 hover:bg-[#D9D9D9]`
              }
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={+currentPage === +totalPages - 1}
            className={`hover-bg-[#D9D9D9] inline-flex h-8 w-8 items-center justify-center rounded-full border bg-neu-500 hover:bg-[#D9D9D9] rtl:rotate-180 ${
              +currentPage === +totalPages - 1 ? 'pointer-events-none opacity-50' : ''
            }`}
          >
            <span className="sr-only">Next Page</span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.5 4.5L11.4697 8.46967C11.7626 8.76256 11.7626 9.23744 11.4697 9.53033L7.5 13.5"
                stroke="#171717"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </li>
      </ul>
    );
  };

  return <div>{renderPagination()}</div>;
};

export default Pagination;
