import { getNotify } from 'Apis/Notification/Notify';
import IconV2 from 'Components-v2/Common/Icon';
import { Button } from 'Components/Buttons/ButtonComponent';
import ConfirmSavePopup from 'Components/ProjectInformation/ConfirmSavePopup';
import backendURL from 'config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import i18n from 'translation/i18n';
import {
  CheckProject,
  GetAllProject,
  toggleProjectArchiveStatus
} from '../../../Apis/ProjectManagement/projectManager';
import ProjectCreation from '../../../Assets/Images/project_creation.svg';
import AttachFile from '../../../Components/AttachFile/AttachFile';
import Pagination from '../../../Components/Buttons/Pagination';
import { calendarDateModalInput } from '../../../Components/Calendar/CalendarComponent';
import Filter from '../../../Components/Common/Filter';
import Icon from '../../../Components/Common/Icon';
import { useGlobalContext } from '../../../Utils/Context';
import { useSidebarContext } from '../../../Utils/Context/SidebarContext';
import ConvertTime from '../../../Utils/ConvertTime';

const ProjectList = () => {
  const { t } = useTranslation();

  const { setIsFullSidebar } = useSidebarContext();
  const [projectList, setProjectList] = useState();
  // eslint-disable-next-line no-unused-vars
  const [notifications, setNotifications] = useState([]);
  const arrNotifications = notifications.map((item) => item.notifyContentVI);
  console.log(arrNotifications);

  const { userInfo, roleUser } = useGlobalContext();
  const userId = userInfo?.userID;
  const [searchName, setSearchName] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [checkListProject, setCheckListProject] = useState(true);
  const pagination = projectList;
  const itemNumber = 10;
  const [filterProject, setFilterProject] = useState({ name: t('project.create-time'), order: 'createdAt' });
  const [arrange, setArrange] = useState('desc');
  const filterName = filterProject.order;
  const navigate = useNavigate();
  const [workActive, setWorkActive] = useState({ isAnimate: false, isActive: true });
  const [isConfirmSave, setIsConfirmSave] = useState(false);
  const [projectId, setProjectId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [numberPages, setNumberPages] = useState(10);

  const handleIdProject = (data) => {
    navigate(`/project-management/${data?.projectId}/board`);
    localStorage.setItem('project', JSON.stringify(data));
    setIsFullSidebar(false);
  };

  const getProjects = async (flag = 'active') => {
    try {
      if (userId) {
        const { data } = await GetAllProject(currentPage, searchName, filterName, arrange, userId, flag);
        setProjectList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getNotifications = async () => {
    try {
      if (userInfo?.userID) {
        const data = await getNotify(userInfo?.userID, i18n?.language, numberPages);
        setNotifications(data?.data?.body?.listNotify?.content?.filter((data) => !data.tempDelete));
      }
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   // Gọi hàm getProjects khi arrNotifications thay đổi
  //   getProjects('active');
  // }, [arrNotifications]); // Đặt arrNotifications trong danh sách phụ thuộc
  // Thiết lập WebSocket
  useEffect(() => {
    const endPointUrl = backendURL + '/our-websocket?userId=' + encodeURIComponent(userInfo?.userID);
    const socket = new WebSocket(endPointUrl);
    getNotifications();
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'UPDATE_PROJECTS') {
        getProjects(); // Gọi hàm để lấy lại danh sách dự án
      }
    };

    // Đảm bảo đóng kết nối khi component unmount
    return () => {
      socket.close();
    };
  }, []);
  const getProjectByUserId = async () => {
    try {
      if (userId) {
        const { data } = await CheckProject(userId);
        setCheckListProject(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectByUserId();
  }, [userId]);

  useEffect(() => {
    getProjects(workActive?.isActive ? 'active' : 'expire');
  }, [currentPage, searchName, arrange, filterName, userId]);

  // Get project by name
  const handleChangeNameProject = (e) => {
    setSearchName(e.target.value);
    setCurrentPage(0);
  };

  const handleTab = (isActive) => {
    getProjects(isActive ? 'active' : 'expire');
    setWorkActive({ isAnimate: true, isActive: isActive });
  };

  const handleSaveProject = (event, projectId) => {
    event.stopPropagation();

    if (projectId) {
      setProjectId(projectId);
      setIsConfirmSave(true);
    }
  };

  const handleRestoreProject = async (event, projectId) => {
    event.stopPropagation();

    try {
      await toggleProjectArchiveStatus(projectId, false);
      toast.success(t('project.project-has-been-restored'), { position: 'top-right' });
      await getProjects('expire');
    } catch (error) {
      toast.error(t('core-toast.an-error-has-occurred'), { position: 'top-right' });
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isConfirmSave) {
      setProjectId(null);
    }
  }, [isConfirmSave]);

  return (
    <>
      <div className="p-4">
        {/* Top */}
        <div className="">
          <div className="flex gap-2 border-b pb-4">
            <Icon name="home_2_alt" className="stroke-[#686868]" />
            <p className="text-sm font-semibold text-primary-100">{t('project.project')}</p>
          </div>

          {/* Tiêu đề */}
          <div className="mt-4 flex items-center justify-between">
            <div>
              <h2 className="text-2xl font-semibold">{t('project.project-list')}</h2>
              <p className="text-[#686868]">{t('project.project-list-title')}</p>
            </div>

            {/* Tạo dự án */}
            {checkListProject === true && (
              <div className=" ">
                <Link to="/project-management/add-project">
                  <div className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center gap-2 rounded-lg bg-primary-100 px-2 text-sm   text-white hover:bg-blue-800">
                    <Icon name="plus_circle" className="pt-0.5" />
                    <p className="">{t('project.create-project')}</p>
                  </div>
                </Link>
              </div>
            )}
          </div>

          {/* Tab */}
          <div className="mt-6 inline-flex rounded-md border bg-white">
            <button
              type="button"
              className={`relative flex h-10 w-48 cursor-pointer items-center justify-center gap-2 rounded-l-md text-sm font-medium`}
              onClick={() => handleTab(true)}
            >
              <div
                className={`absolute top-0 right-0 h-full w-full rounded-l-md ${
                  workActive?.isActive &&
                  `${
                    workActive?.isAnimate && 'animate__animated animate__faster animate__slideInRight'
                  } bg-blue-700 shadow-[0_6px_12px_rgba(166,198,202,0.25)]`
                }`}
              ></div>
              <div className={`absolute ${workActive?.isActive && 'text-slate-50'}`}>{t('project.active')}</div>
            </button>
            <button
              type="button"
              className={`relative flex h-10 w-48 cursor-pointer items-center justify-center gap-2 rounded-r-md text-sm font-medium`}
              onClick={() => handleTab(false)}
            >
              <div
                className={`absolute h-full w-full rounded-r-md ${
                  !workActive?.isActive &&
                  `${
                    workActive?.isAnimate && 'animate__animated animate__faster animate__slideInLeft'
                  } bg-blue-700 shadow-[0_6px_12px_rgba(166,198,202,0.25)]`
                }`}
              ></div>
              <div className={`absolute ${!workActive?.isActive && 'text-slate-50'}`}>{t('project.archive')}</div>
            </button>
          </div>

          {/* Lọc , tạo dự án */}
          {checkListProject === true && (
            <div className="mt-6 flex justify-between gap-6">
              {/* Tìm kiếm tên dự án */}
              <div className="relative flex w-[80%]">
                <div className="absolute top-2.5 left-2">
                  <Icon name="zoom_search" className="fill-[#9C9C9C]" />
                </div>

                <input
                  type="text"
                  placeholder={t('project.project-name')}
                  className="h-[36px]  w-full rounded-md border border-[#F1F3FD] bg-white pl-8  shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] placeholder:text-sm focus:border-primary-100  focus:outline-none"
                  onChange={handleChangeNameProject}
                />
              </div>

              {/* Lọc theo trạng thái dự án */}
              <div className="w-[20%]">
                <Filter
                  filterList={[
                    { name: t('project.create-time'), order: 'createdAt' },
                    { name: t('project.update-time'), order: 'updatedAt' },
                    { name: t('project.project-name'), order: 'projectName' },
                    { name: t('project.role-project'), order: 'roleProject' }
                  ]}
                  filterName={filterProject}
                  setFilterName={setFilterProject}
                  arrange={arrange}
                  setArrange={setArrange}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>

        {/* End */}
        {checkListProject === true ? (
          <div className="mt-4 h-[71vh] overflow-y-auto">
            <div className="rounded-xl border bg-white">
              <table className=" w-full table-auto ">
                <thead className=" w-full ">
                  <tr className=" bg-[#F2F2F2] ">
                    <th className="w-[40%] py-4 pl-4 text-left">{t('project.project')}</th>
                    <th className="w-[20%] py-4 pl-3 text-left">{t('project.role-project')}</th>
                    <th className="w-[20%] p-4 text-left">{t('project.init-infomation')}</th>
                    <th className="w-[20%] py-3 px-4 text-left">{t('project.update-time')}</th>
                    <th className="w-[20%] whitespace-nowrap py-3 px-4 text-left">{t('project.actions')}</th>
                  </tr>
                </thead>
                <tbody className=" max-h-[500px]  overflow-y-auto">
                  {projectList?.users?.map((item, index) => {
                    return (
                      <tr
                        className={`border-t pt-3 transition-all duration-150 hover:shadow-lg [&>td:last-child]:rounded-r-lg ${
                          !item?.isArchived
                            ? 'cursor-pointer'
                            : 'cursor-not-allowed [&>td:last-child]:opacity-100 [&>td]:opacity-60'
                        }`}
                        onClick={() => {
                          if (!item?.isArchived) {
                            handleIdProject(item);
                          }
                        }}
                        key={index}
                      >
                        <td className=" w-[600px] rounded-l-lg p-2 px-4">
                          <div className="flex items-center gap-4">
                            <AttachFile
                              attachType="ProjectAvatar"
                              entity="project"
                              seq={item?.projectId}
                              viewMode={true}
                              mode="form-projectID"
                              className=" mt-2 h-[64px] w-[64px] rounded-md object-cover"
                            />
                            <div className="w-[600px]">
                              <p className="break-words text-lg font-semibold">{item.projectName}</p>
                              <p className="pt-2 font-medium text-[#911BEE]">
                                {item?.type === 'S' && 'Scrum'}
                                {item?.type === 'K' && 'Kanban'}
                                {item?.type === 'W' && 'Waterfall'}
                              </p>
                            </div>
                          </div>
                        </td>
                        {roleUser !== 'ROLE_ADMIN' ? (
                          <td className="w-[20%] py-2 pl-4 font-bold">
                            {item.roleProject === 1 && 'Owner'}
                            {item.roleProject === 2 && 'Editor'}
                            {item.roleProject === 3 && 'Viewer'}
                          </td>
                        ) : (
                          <td className="w-[20%] py-2 pl-4 font-bold">
                            {item.roleProject === 0 && 'Admin'}
                            {item.roleProject === 1 && 'Owner'}
                          </td>
                        )}

                        <td className=" w-[20%] py-2 px-4">
                          <div className="flex gap-4">
                            <Icon name="row_of_list" className="stroke-black" />
                            <p className="font-medium">{calendarDateModalInput(item.createdAt, userInfo)}</p>
                          </div>
                          <div className="flex gap-4">
                            <Icon name="row_list_project" className="fill-none stroke-black" />
                            <p className="font-medium">{item.userCreateName}</p>
                          </div>
                        </td>
                        <td className="w-[20%] p-2 px-4 text-left text-[#9C9C9C]">
                          {t('project.update')}{' '}
                          <ConvertTime
                            type="project"
                            timeInMileSec={new Date().getTime() - new Date(item?.updatedAt).getTime()}
                          />
                        </td>

                        <td className="p-2 px-4 text-center">
                          {!item?.isArchived ? (
                            <>
                              {+userId === +item?.createdBy || roleUser === 'ROLE_ADMIN' ? (
                                <Button
                                  icon={<IconV2 iconName="folder" className="h-5 w-5" />}
                                  content={t('core-btn.save')}
                                  className="whitespace-nowrap rounded-md border border-primary-100 stroke-primary-100 px-5 py-2 text-sm font-medium text-primary-100 transition-all duration-150 hover:bg-primary-100 hover:stroke-white hover:text-white"
                                  onClick={(event) => handleSaveProject(event, item?.projectId)}
                                />
                              ) : null}
                            </>
                          ) : (
                            <>
                              {+userId === +item?.createdBy || roleUser === 'ROLE_ADMIN' ? (
                                <Button
                                  icon={
                                    <IconV2
                                      iconName="rotate_360"
                                      className="h-5 w-5 transition-all duration-300 group-hover:rotate-[250deg]"
                                    />
                                  }
                                  content={t('core-btn.restore')}
                                  className="group whitespace-nowrap rounded-md border border-neu-800 stroke-neu-800 px-5 py-2 text-sm font-medium text-neu-800 opacity-100 transition-all duration-75 hover:bg-neu-800 hover:stroke-white hover:text-white"
                                  onClick={(event) => handleRestoreProject(event, item?.projectId)}
                                />
                              ) : null}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="flex h-[70vh] items-center justify-center">
            <div>
              <img src={ProjectCreation} alt="" className="m-auto flex w-[100px]" />

              <h2 className="pt-4 text-center text-lg font-semibold">{t('project.project-list-empty')}</h2>
              <p className="w-[700px] py-4 text-center">{t('project.project-list-empty-create')}</p>
              <div className=" ">
                <Link to="/project-management/add-project">
                  <div className="m-auto flex h-[42px] w-[160px] cursor-pointer items-center justify-center gap-4 rounded-md bg-primary-100 px-2  text-white hover:bg-blue-800">
                    <Icon name="plus_circle" className="pt-0.5" />
                    <p className="">{t('project.create-project')}</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
        {projectList?.totalItems > 10 && (
          <div className="mt-3">
            <Pagination {...{ setCurrentPage, currentPage, pagination, itemNumber }} />
          </div>
        )}
      </div>

      <ConfirmSavePopup
        isOpen={isConfirmSave}
        setIsOpen={setIsConfirmSave}
        projectId={projectId}
        getProjects={getProjects}
      />
    </>
  );
};

export default ProjectList;
