import { useEffect, useState } from 'react';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import background from '../../../../Apis/ProjectManagement/background';
import projectBoard from '../../../../Apis/ProjectManagement/TaskProgress/board';
import backendURL from '../../../../config';
import { useGlobalContext } from '../../../../Utils/Context';
import AttachFile from '../../../AttachFile/AttachFile';
import Icon from '../../../Common/Icon';

const CoverTask = ({
  setCoverTaskFile,
  detailTask,
  column,
  setDetailTask,
  dataFilelink,
  coverTaskFile,
  setIsCallBack,
  isCallBack,
  projectInfo
}) => {
  const { t } = useTranslation();

  const { register } = useForm();
  const { callBackFile, setCallBackFile } = useGlobalContext();
  const [getColor, setGetColor] = useState(null);
  const [listColorCreated, setListColorCreated] = useState([]); // Danh sách color đã tạo mới
  const [urlCoverPhoto, setUrlCoverPhoto] = useState(null);
  const [popupColor, setPopupColor] = useState(false);
  const [idColor, setIdColor] = useState(null);
  const [createColor, setCreateColor] = useState(false);
  const [color, setColor] = useState('#605d5d');
  const { valueToHex } = useColorPicker(color, setColor);
  const hexString = valueToHex();
  // Lấy danh sách tất cả các file ảnh có trong hệ thống
  useEffect(() => {
    const getBackGroundImage = async () => {
      const data = await background.backGroundColor();
      setGetColor(data.data);
    };
    getBackGroundImage();
    getAllBackgroundColor();
  }, []);

  const getAllBackgroundColor = async () => {
    const dataColorCreated = await background.backGroundColorNew(column?.projBoardId);
    setListColorCreated(dataColorCreated?.data?.customColorList);
  };

  const [getImage, setGetImage] = useState(null);
  const getBackGroundImage = async () => {
    const { data } = await background.backGroundImage();
    setGetImage(data.dataList);
  };
  useEffect(() => {
    getBackGroundImage();
  }, []);

  // Hàm update ảnh bìa
  const handleUpdateCover = async (data) => {
    if (data?.backgroundColor) {
      setColor(data?.backgroundColor);
    }
    if (projectInfo?.roleProject === 3) {
      return;
    }
    const startsWithHttps = data?.fileName?.startsWith('http');
    const newDataLink = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      coverPath: data.fileName
    };
    const newDataUpload = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      coverImageId: data.content ? data.content : data.projBoardBackgroundId,
      coverImageName: data.fileName ? data.fileName : data.bgrNm,
      coverType: data?.coverType
    };
    const newDataColor = {
      projBoardTaskId: detailTask.projBoardTaskId,
      coverColor: data.backgroundColor
    };
    const newDataDelete = {
      projBoardTaskId: detailTask.projBoardTaskId,
      coverColor: null,
      coverImageId: null,
      coverImageName: null,
      coverPath: null
    };
    try {
      if (data.backgroundColor) {
        // Cập nhật màu cover
        await background.updateBoardTaskCover(newDataColor);
        setIsCallBack(!isCallBack);
        setDetailTask({
          ...detailTask,
          coverColor: data.backgroundColor,
          coverImageId: null,
          coverImageName: null,
          coverPath: null
        });
      } else if (data.delete === 'delete') {
        // Xóa nền cover
        await background.updateBoardTaskCover(newDataDelete);
        setIsCallBack(!isCallBack);
        setDetailTask({
          ...detailTask,
          coverColor: null,
          coverImageId: null,
          coverImageName: null,
          coverPath: null
        });
      } else if (startsWithHttps) {
        // Cập nhật đường dẫn ảnh cover
        await background.updateBoardTaskCover(newDataLink);
        setIsCallBack(!isCallBack);
        setDetailTask({
          ...detailTask,
          coverColor: null,
          coverImageId: null,
          coverImageName: null,
          coverPath: data.fileName
        });
      } else {
        // Cập nhật ảnh cover
        await background.updateBoardTaskCover(newDataUpload);
        setIsCallBack(!isCallBack);
        setDetailTask({
          ...detailTask,
          coverImageId: data.content ? data.content : data.projBoardBackgroundId,
          coverImageName: data.bgrNm ? data.bgrNm : null,
          coverPath: null,
          coverColor: null,
          coverType: data?.coverType
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Hàm thêm ảnh bìa
  const handleImage = async (data) => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    const newData = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      projBoardColId: column.projBoardColId,
      taskName: detailTask?.taskName,
      endDate: detailTask?.endDate,
      description: detailTask?.description,
      uploadKey: data.uploadKey,
      fileSeq: data.seq,
      coverImageName: detailTask?.coverImageName
    };
    try {
      await projectBoard.editTaskKanban(newData, 'upload');
      setCallBackFile(!callBackFile);
    } catch (error) {
      console.log(error);
    }
  };

  const fncCreateBackGroundColorNew = async (data) => {
    // Thêm màu mới cho bảng
    try {
      if (
        getColor?.singleColorList?.some((color) => color?.backgroundColor === data?.colorCode) ||
        listColorCreated?.some((color) => color?.backgroundColor === data?.colorCode)
      ) {
        toast.success(t('project.exist-color'), {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return;
      } else {
        await background.createBackGroundColorNew(data);
        getAllBackgroundColor();
        setCreateColor(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteColorCreated = async (id) => {
    try {
      await background.deleteBackGroundImage(id);
      getAllBackgroundColor();
    } catch (error) {
      console.log(error);
    }
  };

  // Kiểm tra định dnag file ảnh
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const isImageFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };
  // Link ảnh projBoardBackground/displayImg/
  useEffect(() => {
    const imageURL = detailTask?.coverImageId
      ? detailTask?.coverType
        ? detailTask?.coverType === 'D'
          ? backendURL + '/projBoardBackground/displayImg/' + detailTask?.coverImageId
          : backendURL + '/file/' + 'displayImg/' + detailTask?.coverImageId
        : null
      : detailTask?.coverPath;
    setUrlCoverPhoto(imageURL);
  }, [detailTask]);

  return (
    <div className="max-h-[800px] w-[340px] overflow-y-auto p-3">
      <div className="mb-4 flex  justify-between border-b pb-3">
        <p className="text-center text-lg font-semibold">{t('project.cover-photo')} </p>
        <p onClick={() => setCoverTaskFile(false)} className="cursor-pointer">
          <Icon name="close_cross" className="stroke-black" />
        </p>
      </div>
      {/* Ảnh bìa được sử dụng */}
      {(detailTask?.coverColor || detailTask?.coverImageId || detailTask?.coverPath?.startsWith('http')) && (
        <div>
          <p className="mb-1.5 text-sm font-semibold text-[#9C9C9C]">{t('project.cover-photo-used')}</p>
          {/* Ảnh bìa hoặc màu được áp dụng */}
          <div className="h-[120px] w-full rounded-xl  bg-center bg-no-repeat">
            {detailTask?.coverColor ? (
              <div className="h-[120px] w-full rounded-lg" style={{ background: detailTask?.coverColor }}></div> // Màu được áp dụng
            ) : (
              <img src={urlCoverPhoto} className="h-[120px] w-full rounded-xl object-cover" /> // Ảnh được áp dụng
            )}
          </div>
          {/* Xóa ảnh bìa */}
          <div className="mt-3 flex justify-center">
            <div
              className="flex h-[36px] w-full cursor-pointer justify-center gap-3 rounded-lg bg-gray-400  bg-opacity-50  hover:bg-opacity-80"
              onClick={() => handleUpdateCover({ delete: 'delete' })} //Hàm xóa ảnh bìa
            >
              <Icon name="trash_alt" className="fill-black " />
              <p className="flex items-center text-sm font-normal text-black">{t('project.delete-cover-photo')}</p>
            </div>
          </div>
        </div>
      )}

      {/* Màu đơn sắc mặc định */}
      <div className="mt-4">
        <p className="mb-1.5 text-sm font-semibold text-[#9C9C9C]">{t('project.default-monochromatic-color')}</p>
        <div className="grid grid-cols-5 gap-3">
          {getColor?.singleColorList?.map((item, index) => {
            return (
              <p
                className={`h-[30px] w-[50px] cursor-pointer rounded-md `}
                key={index}
                style={{ background: item.backgroundColor }}
                onClick={() => handleUpdateCover(item)}
              ></p>
            );
          })}
        </div>
      </div>
      {/* Màu sắc được thêm mới */}
      <div className="mt-4">
        <p className="mb-1.5 text-sm font-semibold text-[#9C9C9C]">{t('project.created-color')}</p>
        <div className="grid grid-cols-5 gap-3">
          {listColorCreated?.map((item, index) => {
            return (
              <div
                onMouseEnter={() => {
                  if (projectInfo?.roleProject === 3) {
                    return;
                  }
                  setPopupColor(true), setIdColor(item.projBoardBackgroundId);
                }}
                onMouseLeave={() => {
                  if (projectInfo?.roleProject === 3) {
                    return;
                  }
                  setPopupColor(false), setIdColor(null);
                }}
                className="relative"
                key={index}
              >
                <p
                  className={`h-[30px] w-[50px] cursor-pointer rounded-md `}
                  style={{ background: item?.backgroundColor }}
                  onClick={() => handleUpdateCover(item)}
                ></p>
                {popupColor && idColor === item?.projBoardBackgroundId && (
                  <button
                    className="absolute right-3 top-1 h-[16px] w-[16px] text-[10px]"
                    onClick={() => deleteColorCreated(item?.projBoardBackgroundId)}
                  >
                    <Icon name="close_cross" className={`stroke-black hover:stroke-red-500`} />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <div
          className={`mt-4 ${
            !createColor
              ? 'max-h-[0] w-[320px] opacity-0'
              : `bg- flex max-h-[350px] w-[320px] flex-col justify-center rounded pb-1 opacity-100`
          }  transition-max-h overflow-hidden duration-500 ease-in-out`}
        >
          <ColorPicker
            width={320}
            height={120}
            hideControls={true}
            hideOpacity={true}
            value={color}
            onChange={setColor}
          />
          <div className="mt-4 flex justify-between gap-3 border-t px-6 pt-4">
            <div
              className="w-[100px] cursor-pointer rounded-md bg-red-500 py-1.5 text-center text-sm text-white"
              onClick={() => setCreateColor(false)}
            >
              {t('account-management.cancel')}
            </div>
            <button
              disabled
              className={`w-[100px] ${
                hexString === '' ? ' bg-gray-300' : 'hidden'
              } rounded-md border  py-1.5 px-4 text-sm text-neu-300`}
            >
              {t('account-management.save')}
            </button>
            <button
              onClick={() =>
                fncCreateBackGroundColorNew({
                  projBoardId: column?.projBoardId,
                  colorCode: hexString
                })
              }
              className={`w-[100px] ${
                hexString !== '' ? 'bg-primary-100 hover:bg-blue-700' : 'hidden'
              } rounded-md border  py-1.5 px-4 text-sm text-neu-300`}
            >
              {t('account-management.save')}
            </button>
          </div>
        </div>

        <button
          onClick={() => {
            setCreateColor(true);
          }}
          className={`${
            createColor && 'hidden'
          } my-2 flex h-[40px] w-full items-center justify-center gap-2 rounded-[6px] bg-neu-600`}
        >
          <Icon name="plus" className="h-[25px] w-[25px] stroke-black pl-3" />
          <p className="text-[14px]">{t('project.create-color')} </p>
        </button>
      </div>

      {/* Hình ảnh mặc định */}
      <div className="mt-4">
        <p className="mb-1.5 text-sm font-semibold text-[#9C9C9C]">{t('project.default-image')}</p>
        <div className="grid grid-cols-3 gap-3">
          {getImage?.map((item, index) => {
            return (
              <img
                src={`${backendURL + '/projBoardBackground/displayImg/' + item.projBoardBackgroundId}`}
                className="h-[50px] w-[120px] cursor-pointer rounded-lg object-cover"
                key={index}
                onClick={() => handleUpdateCover({ ...item, coverType: 'D' })}
              />
            );
          })}
        </div>
      </div>
      {/* Hình ảnh tải lên */}
      <div className="mt-4">
        <p className="mb-1.5 text-sm font-semibold text-[#9C9C9C]">{t('project.image-uploaded')}</p>
        <div className="grid grid-cols-3 gap-3">
          {dataFilelink?.boardTaskImagesAndLinks?.map((item, index) => {
            const listImage = isImageFile(item.fileName); //Kiểm tả định dạng file
            const startsWithHttps = item.fileName.startsWith('http');
            const filelink = startsWithHttps
              ? item.fileName
              : backendURL + '/file/' + 'displayImg/' + parseInt(item.content);
            if (listImage) {
              return (
                <div key={index}>
                  <img
                    src={filelink} //Nếu là đường dẫn thì hiện ảnh đường dẫn còn ngược lại thì hiện ảnh tải lên
                    className="h-[50px] w-[120px] cursor-pointer rounded-lg object-cover"
                    onClick={() => handleUpdateCover({ ...item, coverType: 'U' })}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
      {/* File tải ảnh lên */}
      {coverTaskFile && projectInfo?.roleProject !== 3 && (
        <div>
          <div className="mt-4">
            <AttachFile
              attachType="ProjectTaskFile"
              entity="ProjBoardTask"
              multi={false}
              editable={true}
              register={register}
              viewMode={false}
              handleUpload={handleImage}
              mode="form"
              filters={{
                max_file_size: '10mb',
                mime_types: [
                  {
                    title: 'Tệp đã được nhận: ',
                    extensions: 'jpg,png,svg'
                  }
                ]
              }}
              className="  rounded-t-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CoverTask;
