import instance from '../../instance';

const projectBoard = {
  getAllBoard: (boardId) => {
    const url = `projboardcol/getAllBoardColumn/${boardId}`;
    return instance.get(url);
  },
  createBoard: (data) => {
    const url = `projboardcol/createBoardColumn`;
    return instance.post(url, data);
  },
  deleteBoard: (boardColumnId) => {
    const url = `projboardcol/deleteBoardColumn/${boardColumnId}`;
    return instance.delete(url);
  },
  arrangeBoard: (boardId, data) => {
    const url = `projboardcol/editBoardColumn/${boardId}`;
    return instance.post(url, data);
  },
  editBoard: (boardColumnId, data) => {
    const url = `projboardcol/updateBoardColumn/${boardColumnId}`;
    return instance.put(url, data);
  },
  getAllTaskKanban: (boardId, searchString, body, isFilter) => {
    const url = `projBoardTask/getAllBoardTask/${boardId}?searchString=${searchString}`;
    if (isFilter) {
      // check xem đó có phải là lần đầu tiên mở bảng hay không
      if (body?.statusTask?.length !== 0 || body?.labelId?.length !== 0 || body?.userListId?.length !== 0) {
        return instance.post(url, body);
      } else {
        return instance.post(url);
      }
    } else {
      return instance.post(url);
    }
  },
  createTaskKanban: (data) => {
    const url = `projBoardTask/createTask`;
    return instance.post(url, data);
  },
  editTaskKanban: (data, flag) => {
    const url = `projBoardTask/updateInfoTask?flag=${flag}`;
    return instance.put(url, data);
  },
  deatailTaskKanban: (boardTaskId) => {
    const url = `projBoardTask/getTaskById/${boardTaskId}`;
    return instance.get(url);
  },
  createChildTask: (data) => {
    const url = `projBoardChildTask/createChildTask`;
    return instance.post(url, data);
  },
  deleteChildTask: (boardChildTaskId) => {
    const url = `projBoardChildTask/deleteChildTask/${boardChildTaskId}`;
    return instance.delete(url);
  },
  editChildTask: (data) => {
    const url = `projBoardChildTask/updateChildTask`;
    return instance.put(url, data);
  },
  searchMemberTask: (taskId, boardId, searchName) => {
    const url = `projBoardTaskMem/listUserForTask?taskId=${taskId}&boardId=${boardId}&searchName=${searchName}`;
    return instance.get(url);
  },
  addMemberChildTask: (data) => {
    const url = `projBoardTaskMem/addMemTask`;
    return instance.post(url, data);
  },
  arrangeTask: (taskId, colId, sortOrder, order) => {
    const url = `projBoardTask/taskSortOrder?taskId=${taskId}&colId=${colId}&sortOrder=${sortOrder}&direction=${order}`;
    return instance.put(url);
  },
  deleteTask: (body, boardTaskId) => {
    const url = `projBoardTask/deleteTask/${boardTaskId}`;
    return instance.post(url, body);
  },
  deleteMemberTask: (memberid, taskId, projBoardId) => {
    const url = `projBoardTaskMem/deleteMemberTask?memberId=${memberid}&taskId=${taskId}&projBoardId=${projBoardId}&actionFlag=deleteMemTask`;
    return instance.delete(url);
  },
  joinBoardTask: (taskId, userId) => {
    const url = `projBoardTaskMem/joinBoardTask?taskId=${taskId}&userId=${userId}`;
    return instance.post(url);
  },
  leaveBoardTask: (taskId, userId) => {
    const url = `projBoardTaskMem/leaveBoardTask?taskId=${taskId}&userId=${userId}`;
    return instance.post(url);
  },
  updateSttTask: (taskId, status, flag) => {
    const url = `projBoardTask/updateSttTask?taskId=${taskId}&status=${status}&flag=${flag}`;
    return instance.put(url);
  },
  updateJobStatus: (taskId, hidden) => {
    const url = `projBoardTask/updateHiddenChildTask?taskId=${taskId}&hidden=${hidden}`;
    return instance.post(url);
  },
  projBoardHistoryActi: (boardId, numberPages) => {
    const url = `projBoardTaskHistoryActi/projBoardHistoryActi/${boardId}?page=0&size=${numberPages}`;
    return instance.get(url);
  },
  findProjBoardTaskFileLinkById: (fileLinkId) => {
    const url = `projBoardTaskFileLink/findProjBoardTaskFileLinkById/${fileLinkId}`;
    return instance.get(url);
  }
};
export default projectBoard;
