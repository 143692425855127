import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import projectBoard from '../../../../Apis/ProjectManagement/TaskProgress/board';
import Icon from '../../../Common/Icon';

const MoveTask = ({ columns, setDetailTask, detailTask, setIsCallBack, isCallBack, setMoveTask }) => {
  const [moveCard, setMoveCard] = useState();
  const { t } = useTranslation();

  const diff = 'DIFF';
  // Di chuyển từ cột này sang cột khác
  const handleMoveTask = async () => {
    try {
      await projectBoard?.arrangeTask(detailTask.projBoardTaskId, moveCard.projBoardColId, 0, diff);
      setDetailTask({
        ...detailTask,
        colName: moveCard.colName
      });
      setIsCallBack(!isCallBack);
      toast.success(`${t('project.move-to-column')} ${moveCard.colName}${t('project.success')}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  return (
    <div className="w-[250px]">
      <div className="mb-2 flex justify-between">
        <p className="ml-4 text-center text-lg font-semibold"> {t('project.move-card')}</p>
        <p onClick={() => setMoveTask(false)} className="cursor-pointer">
          <Icon name="close_cross" className="stroke-black" />
        </p>
      </div>
      <div className="max-h-[300px] min-h-[100px] overflow-y-auto">
        {columns?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => setMoveCard(item)}
              className={`cursor-pointer hover:bg-gray-200 ${
                moveCard?.projBoardColId == item.projBoardColId ? 'bg-gray-200' : ''
              }`}
            >
              <div className="ml-6 flex gap-2">
                <Icon name="arrow_right" className="stroke-black" />
                <p className=" py-1.5 px-3 ">{item?.colName}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="float-right my-4 pr-4 ">
        <button
          className="rounded-lg bg-primary-100 py-1 px-2 text-white"
          onClick={() => {
            setMoveTask(false);
            handleMoveTask();
          }}
        >
          {t('project.move')}
        </button>
      </div>
    </div>
  );
};

export default MoveTask;
