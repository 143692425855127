import { useRef, useState } from 'react';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import background from '../../../../../Apis/ProjectManagement/background';
import WarningIc from '../../../../../Assets/Images/Icons/warning-ic.svg';
import { useBoardProjectContext } from '../../../../../Utils/Context/ProjectManagement/BoardProjectContext';
import labelColor from '../../../../Common/Color';
import Icon from '../../../../Common/Icon';
import validateNoLeadingSpace from '../../../../Common/Validate';

const CreateLable = ({ setIsCreateLabel, setIsTaskLabel, column }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [color, setColor] = useState('#605d5d');
  const { valueToHex } = useColorPicker(color, setColor);
  const hexString = valueToHex();
  const { callBackLable, setCallBackLable } = useBoardProjectContext();
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const onSubmitCreate = async (data) => {
    try {
      const newData = {
        title: data.title,
        colorCode: hexString,
        projBoardId: column.projBoardId
      };
      await background.saveProjBoardLabel(newData);
      setCallBackLable(!callBackLable);
      setIsCreateLabel(false);
      toast.success(t('project.label-created-successfully'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.label-already-exists'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmitCreate)}
        className="h-auto w-[340px] rounded-xl border p-6 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.12)]"
      >
        <div className="mb-2 flex justify-between">
          <Icon
            name="arrow_left"
            className="cursor-pointer rounded-lg stroke-[#111111] p-1  hover:bg-gray-100"
            onClick={() => setIsCreateLabel(false)}
          />
          <p className="text-center text-lg font-semibold">{t('project.create-new-label')}</p>
          <p onClick={() => setIsTaskLabel(false)} className="cursor-pointer">
            <Icon name="close_cross" className="rounded-lg stroke-[#111111] p-1  hover:bg-gray-100" />
          </p>
        </div>
        <div>
          <div className="">
            <div>
              <p className="my-1 text-sm font-semibold">{t('project.selected-color')}</p>
            </div>
            <div className="color-picker">
              <ColorPicker height={200} hideControls={true} hideOpacity={true} value={color} onChange={setColor} />
            </div>
          </div>

          <div className="my-6 border-t"></div>

          <div>
            <p className="text-sm font-semibold">{t('project.label-title')}</p>
            <input
              type="text"
              style={{ backgroundColor: hexString }}
              className="mt-2 w-full rounded-lg border border-[#F1F3FD] bg-white py-1.5 px-3 text-sm font-medium shadow-[0px_0px_20px_0px_rgba(0,0,0,0.12)] placeholder:text-sm placeholder:font-normal focus:outline-none"
              placeholder={t('project.enter-label-title')}
              ref={inputRef}
              {...register('title', {
                required: t('account-management.no-be-eft-blank'),
                maxLength: { value: 20, message: t('account-management.cannot-be-longer-than-20-characters') },
                validate: validateNoLeadingSpace
              })}
            />
            {errors.title && (
              <p className="flex gap-1 px-2 pt-1">
                <img src={WarningIc} alt="warning-icon" />
                <span className="text-xs font-normal text-red-500">{errors.title.message}</span>
              </p>
            )}
          </div>
          <div className="my-6 border-t"></div>
          <div>
            <p className="mb-3 text-sm font-semibold">{t('project.select-color')}</p>
            <div className="grid grid-cols-4 grid-rows-4 gap-3">
              {labelColor?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`h-8 cursor-pointer rounded-md outline-primary-100 ${
                      hexString === item.colorCode ? 'outline outline-2 outline-offset-2' : ''
                    } `}
                    style={{ backgroundColor: item.colorCode }}
                    onClick={() => {
                      setColor(item.colorCode);
                    }}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
        <div className=" mt-4  flex justify-between gap-3 border-t px-6 pt-4">
          <div
            className="w-[100px] cursor-pointer rounded-md bg-red-500 py-1.5 text-center text-sm text-white"
            onClick={() => setIsCreateLabel(false)}
          >
            {t('account-management.cancel')}
          </div>
          <button
            disabled
            className={`w-[100px] ${
              hexString === '' ? ' bg-gray-300' : 'hidden'
            } rounded-md border  py-1.5 px-4 text-sm text-neu-300`}
          >
            {t('account-management.save')}
          </button>
          <button
            className={`w-[100px] ${
              hexString !== '' ? 'bg-primary-100 hover:bg-blue-700' : 'hidden'
            } rounded-md border  py-1.5 px-4 text-sm text-neu-300`}
          >
            {t('account-management.save')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateLable;
