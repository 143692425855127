import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { GetDept } from '../../Apis/department';
import Logo from '../../Assets/Images/logo-colorful.svg';
import CompanyRegulationsSidebar from '../../Modules-v2/Company-regulations/Components/Sidebars/CompanyRegulationsSidebar';
import i18n from '../../translation/i18n';
import { useGlobalContext } from '../../Utils/Context';
import { useSidebarContext } from '../../Utils/Context/SidebarContext';
import Icon from '../Common/Icon';
import Notification from '../Sidebars/Notification';
import SettingSidebar from './SettingSidebar';
import UserInfo from './UserInfo';

const FullSidebar = () => {
  const { userInfo, roleUser } = useGlobalContext();
  const { Menu, setLoadingBzware, setIsFullSidebar } = useSidebarContext();
  const location = useLocation();
  const [isDepartment, setIsDepartment] = useState(true);

  const getDepartment = async () => {
    try {
      if (userInfo?.userID) {
        const departmentList = await GetDept(userInfo?.userID);
        if (
          departmentList?.data?.dataList?.length > 0 ||
          userInfo.userRoleDepts[0]?.role == 2 ||
          userInfo.userRoleDepts[0]?.role == 6
        ) {
          setIsDepartment(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const switchLanguage = () => {
    if (userInfo?.language !== null && userInfo?.language !== undefined && userInfo?.language !== '') {
      i18n.changeLanguage(userInfo?.language);
      localStorage.setItem('i18next', userInfo?.language);
    } else {
      i18n.changeLanguage('vi');
      localStorage.setItem('i18next', 'vi');
    }
  };

  useEffect(() => {
    getDepartment();
    switchLanguage();
  }, [roleUser, userInfo?.language]);

  return (
    <div className="  animate__animated animate__fadeInLeft animate__faster flex w-full flex-1 flex-col gap-y-6">
      {/* User information */}
      <div className="mt-4 flex w-full items-center justify-between gap-x-3  px-5">
        <UserInfo isAvatar={true} isFullName={true} />

        <Notification positionPopup="top-[calc(100%+8px)] left-0" />
      </div>
      <div className="mx-5 border-t"></div>

      <div>
        <div className="scroll-y-2 flex max-h-[calc(100vh-248px)] flex-col overflow-y-auto">
          {/* Top */}
          <ul className="flex flex-col gap-y-2">
            {Menu?.map((menu, index) => {
              if (menu?.role?.includes(roleUser) === true) {
                return (
                  <li key={index} className="px-3">
                    {isDepartment && menu?.display === 'top' && (
                      <Link
                        className={`${
                          location?.pathname === menu?.url
                            ? 'bg-primary-100 text-white'
                            : 'text-black hover:bg-neu-500 '
                        } group flex items-center gap-x-2 rounded-lg px-3 py-2 text-sm font-normal transition-all duration-200`}
                        to={menu?.url}
                        onClick={() => {
                          setLoadingBzware(false), menu.name === 'project-management' && setIsFullSidebar(false);
                        }}
                      >
                        {/* <i>{menu?.icon}</i> */}
                        <Icon
                          name={menu?.icon?.name}
                          className={`  ${
                            location?.pathname === menu?.url ? `${menu?.icon?.type}-white` : `${menu?.icon?.type}-black`
                          }`}
                        />
                        <span className="min-w-[180px]">{menu?.content}</span>
                      </Link>
                    )}
                  </li>
                );
              }
            })}
          </ul>

          {/* line */}
          {isDepartment && <div className="mx-6 my-6 border-spacing-1 border-b border-neu-600"></div>}

          {/* center */}
          <ul className="flex flex-col gap-y-2">
            {Menu?.map((menu, index) => {
              if (menu?.role === null) {
                return (
                  <li key={index} className="px-3">
                    {menu?.display === 'center' && (
                      <Link
                        className={`${
                          location?.pathname === menu?.url
                            ? 'bg-primary-100 text-white '
                            : 'text-black hover:bg-neu-500 '
                        } group flex items-center gap-x-2 rounded-lg px-3 py-2 text-sm font-normal transition-all duration-200`}
                        to={menu?.url}
                        onClick={() => {
                          setLoadingBzware(false), menu.name === 'project-management' && setIsFullSidebar(false);
                        }}
                      >
                        <Icon
                          name={menu?.icon?.name}
                          className={`   ${
                            location?.pathname === menu?.url ? `${menu?.icon?.type}-white` : `${menu?.icon?.type}-black`
                          }`}
                        />
                        <span className="min-w-[180px] capitalize">{menu?.content}</span>
                      </Link>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </div>

        {/* line */}
        <div className="mx-6 my-6 border-spacing-1 border-b border-neu-600"></div>

        {/* Company Regulations */}
        <CompanyRegulationsSidebar
          isIcon={true}
          isContent={true}
          className={`mx-3 mb-2 flex cursor-pointer items-center gap-x-2 rounded-lg px-3 py-2 text-sm font-normal transition-all ${
            location?.pathname === '/company-regulations' ? 'bg-primary-100 text-white' : 'text-black hover:bg-neu-500'
          }`}
        />

        {/* Setting */}
        <SettingSidebar isIcon={true} isContent={true} />
      </div>

      <div className="m-auto mb-5 flex w-[200px] items-center justify-start gap-2 self-end  py-2 text-sm font-medium text-neu-400">
        <div className="rounded-full bg-white">
          <img src={Logo} className="m-2 mr-2 h-6 w-6" alt="logo" />
        </div>

        <p className="text-lg font-semibold text-black">BZWARE</p>
      </div>
    </div>
  );
};
export default FullSidebar;
