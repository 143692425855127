import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import background from '../../../../../Apis/ProjectManagement/background';
import { useBoardProjectContext } from '../../../../../Utils/Context/ProjectManagement/BoardProjectContext';
import Icon from '../../../../Common/Icon';
import CreateLable from './CreateLable';
import EditTaskLable from './EditTaskLable';

// eslint-disable-next-line no-unused-vars
const TaskLabelPopup = ({ setIsTaskLabel, column, detailTask, setDetailTask, projectInfo }) => {
  const [isCreateLabel, setIsCreateLabel] = useState(false);
  const [isEditLabel, setIsEditLabel] = useState(false);
  const [editLable, setEditLable] = useState();
  const [numberDisplay, setNumberDisplay] = useState(10);
  const inputRef = useRef(null);
  const { callBackLable } = useBoardProjectContext();
  const { callBackTask, setCallBackTask } = useBoardProjectContext();
  const { t } = useTranslation();
  const labelListRef = useRef();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCreateLabel, isEditLabel]);

  // Tìm kiếm tên lable
  const [searchLable, setSearchLable] = useState('');
  const handleSearchLable = (e) => {
    setSearchLable(e.target.value);
  };
  // Lấy danh sách các thẻ lable
  const [listLabel, setListlabel] = useState();
  const projBoardLabel = async () => {
    const { data } = await background.getProjBoardLabel(
      numberDisplay,
      column.projBoardId,
      searchLable,
      detailTask.projBoardTaskId
    );
    setListlabel(data);
  };

  useEffect(() => {
    projBoardLabel();
  }, [searchLable, callBackLable, callBackTask, numberDisplay]);

  // Chọn lable cho task
  const handleCheckBoxLable = async (data) => {
    const newData = {
      projBoardLabelId: data.projBoardLabelId,
      projBoardTaskId: detailTask.projBoardTaskId
    };
    await background.addLabelToBoardTask(newData);
    setDetailTask((prevTask) => ({
      ...prevTask,
      projBoardLabelDTOS: [...prevTask.projBoardLabelDTOS, data]
    }));
    setCallBackTask(!callBackTask);
  };

  const handleUnCheckboxLable = async (data) => {
    // So sánh hai projBoardLabelId của mảng  listLable?.projBoardTaskLabelList với  projBoardLabelId được chọn nếu trùng thì lấy ra thông tin của mảng  listLable?.projBoardTaskLabelList
    try {
      const matchingElement = listLabel?.projBoardTaskLabelList.find(
        (obj) => obj.projBoardLabelId === data.projBoardLabelId
      );
      await background.removeTaskLabel(matchingElement.projBoardTaskLabelId);
      const datatest = [...detailTask.projBoardLabelDTOS];
      const index = detailTask.projBoardLabelDTOS.findIndex((item) => item.projBoardLabelId === data.projBoardLabelId);
      datatest.splice(index, 1);
      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardLabelDTOS: datatest
      }));
      setCallBackTask(!callBackTask);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (projectInfo?.roleProject === 3 && labelListRef?.current) {
      const checkboxes = labelListRef?.current?.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.disabled = true;
      });
    }
  }, [projectInfo, listLabel]);

  return (
    <div className="mb-2">
      {isCreateLabel === false && isEditLabel == false && (
        <div>
          <div className="h-auto w-[340px] rounded-xl border bg-white p-6 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.12)]">
            <div className="mb-2 flex justify-between">
              <p className="text-center text-lg font-semibold">{t('project.label')}</p>
              <p onClick={() => setIsTaskLabel(false)} className="cursor-pointer">
                <Icon name="close_cross" className="rounded-lg stroke-[#111111] p-1  hover:bg-gray-100" />
              </p>
            </div>
            <div>
              <div>
                <div className="mt-6 flex items-center gap-2">
                  <Icon name="zoom_search" className="h-[20px] w-[20px] stroke-[black]" />
                  <p className="text-sm font-semibold">{t('project.search')}</p>
                </div>
                <input
                  className="mt-2 w-full rounded-lg border border-[#F1F3FD] bg-white py-1.5 px-3 text-sm font-medium shadow-[0px_0px_20px_0px_rgba(0,0,0,0.12)] placeholder:text-sm placeholder:font-normal focus:border-[#234EEC]  focus:outline-none"
                  placeholder={t('project.label-title')}
                  ref={inputRef}
                  onChange={handleSearchLable}
                />
              </div>
              <div className="my-6 border-t"></div>
              <div>
                <div className="flex items-center gap-2">
                  <Icon name="label_1" className="h-[20px] w-[20px] stroke-[black] stroke-2" />
                  <p className="text-sm font-semibold">{t('project.label')}</p>
                </div>
                <div ref={labelListRef} className="mt-2 gap-3">
                  <div className="max-h-[350px] overflow-y-auto">
                    {listLabel?.projBoardLabelList?.slice(0, numberDisplay)?.map((item, index) => {
                      const exists = detailTask.projBoardLabelDTOS.some(
                        (obj) => obj.projBoardLabelId === item.projBoardLabelId
                      );
                      return (
                        <div className=" mb-2 flex items-center gap-2" key={index}>
                          <input
                            disabled={projectInfo?.roleProject === 3}
                            type="checkbox"
                            className={`${
                              projectInfo?.roleProject === 3 ? 'disabled' : ''
                            } form-check-input float-left  h-5 w-6 cursor-pointer appearance-none rounded-md border border-[#333333] bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:border-primary-100 focus:outline-none`}
                            checked={exists ? true : false}
                            onClick={() => {
                              if (projectInfo?.roleProject !== 3) {
                                exists === false ? handleCheckBoxLable(item) : handleUnCheckboxLable(item);
                              }
                            }}
                          />
                          <div
                            className="flex h-8 w-[90%] cursor-pointer items-center gap-2 rounded-lg p-1 px-2 transition-all duration-200 ease-in-out hover:bg-gray-200"
                            onClick={() => {
                              if (projectInfo?.roleProject !== 3) {
                                exists === false ? handleCheckBoxLable(item) : handleUnCheckboxLable(item);
                              }
                            }}
                            style={{ backgroundColor: item.colorCode }}
                          >
                            <p className="w-full text-xs font-medium">{item.title}</p>
                          </div>
                          <Icon
                            name="edit_2"
                            onClick={() => {
                              setEditLable(item), setIsEditLabel(!isEditLabel);
                            }}
                            className={`${
                              projectInfo?.roleProject === 3 ? 'hidden' : ''
                            } cursor-pointer rounded-full p-1 transition-all duration-200 ease-in-out hover:bg-gray-200`}
                          />
                        </div>
                      );
                    })}
                  </div>

                  {projectInfo?.roleProject !== 3 && (
                    <div
                      className=" mt-4 flex  cursor-pointer  justify-center gap-3 rounded-md bg-gray-200 px-2 py-1.5 transition-all duration-200 ease-in-out hover:bg-gray-300"
                      onClick={() => setIsCreateLabel(!isCreateLabel)}
                    >
                      {/* <Icon name="plus" className="h-4 w-4 stroke-neutral-500" /> */}
                      <p className=" text-[13px]  font-medium text-gray-600 hover:text-gray-800">
                        {t('project.create-new-label')}
                      </p>
                    </div>
                  )}
                  {listLabel?.projBoardLabelList?.length > 9 && (
                    <div
                      className=" mt-4 flex  cursor-pointer  justify-center gap-3 rounded-md bg-gray-200 px-2  py-1.5 transition-all duration-200 ease-in-out hover:bg-gray-300"
                      onClick={() => setNumberDisplay(numberDisplay + 5)}
                    >
                      <p className=" text-[13px] font-medium text-gray-600 hover:text-gray-800">
                        {t('project.show-more-labels')}
                      </p>
                    </div>
                  )}
                  {listLabel?.projBoardLabelList?.length > 10 && (
                    <div
                      className=" mt-4 flex  cursor-pointer  justify-center gap-3 rounded-md bg-gray-200 px-2  py-1.5 transition-all duration-200 ease-in-out hover:bg-gray-300"
                      onClick={() => setNumberDisplay(numberDisplay - 5)}
                    >
                      <p className=" text-[13px] font-medium text-gray-600 hover:text-gray-800">
                        {t('project.collapse')}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCreateLabel === true && (
        <CreateLable column={column} setIsTaskLabel={setIsTaskLabel} setIsCreateLabel={setIsCreateLabel} />
      )}
      {isEditLabel === true && (
        <EditTaskLable
          column={column}
          setIsTaskLabel={setIsTaskLabel}
          setIsEditLabel={setIsEditLabel}
          editLable={editLable}
          setDetailTask={setDetailTask}
          detailTask={detailTask}
        />
      )}
    </div>
  );
};
export default TaskLabelPopup;
