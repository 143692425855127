import { useTranslation } from 'react-i18next';

const SuggestReport = () => {
  const { t } = useTranslation();
  return (
    <div className="rounded-lg border border-slate-200 px-2 py-4">
      {/* Header */}
      <div>
        <p className="text-base font-semibold">{t('reports.suggest-some-report-contents')}</p>
        <p className="text-sm text-[#888888]">
          {t('reports.below-are-some-sentence-structures-commonly-used-in-reports')}
        </p>
      </div>

      <div className="my-2 h-[1px] w-full bg-slate-200"></div>

      <div>
        <p className="text-sm font-semibold text-[#888888]">{t('reports.introduce-the-topic')}</p>
        <ul className="list-disc pl-6">
          <li className="text-sm text-[#5c5b5b]">{t('reports.this-report-presents-the-results-of-research-on')}</li>
          <li className="text-sm text-[#5c5b5b]">{t('reports.the-objective-of-this-report-is')}</li>
        </ul>
      </div>
      <div>
        <p className="text-sm font-semibold text-[#888888]">{t('reports.state-the-purpose')}</p>
        <ul className="list-disc pl-6">
          <li className="text-sm text-[#5c5b5b]">{t('reports.this-report-aims')}</li>
          <li className="text-sm text-[#5c5b5b]">{t('reports.this-report-is-prepared-to')}</li>
        </ul>
      </div>
      <div>
        <p className="text-sm font-semibold text-[#888888]">{t('reports.data-analysis')}</p>
        <ul className="list-disc pl-6">
          <li className="text-sm text-[#5c5b5b]">{t('reports.collected-data-shows')}</li>
          <li className="text-sm text-[#5c5b5b]">{t('reports.analytical-results-show')}</li>
        </ul>
      </div>
      <div>
        <p className="text-sm font-semibold text-[#888888]">{t('reports.reasoning')}</p>
        <ul className="list-disc pl-6">
          <li className="text-sm text-[#5c5b5b]">{t('reports.we-can-confirm')}</li>
          <li className="text-sm text-[#5c5b5b]">
            {t('reports.based-on-the-above-analysis-it-can-be-concluded-that')}
          </li>
        </ul>
      </div>
      <div>
        <p className="text-sm font-semibold text-[#888888]">{t('reports.summary-of-main-content')}</p>
        <ul className="list-disc pl-6">
          <li className="text-sm text-[#5c5b5b]">{t('reports.this-report-presented')}</li>
        </ul>
      </div>
      <div>
        <p className="text-sm font-semibold text-[#888888]">{t('reports.state-the-results')}</p>
        <ul className="list-disc pl-6">
          <li className="text-sm text-[#5c5b5b]">{t('reports.research-results-show-that')}</li>
          <li className="text-sm text-[#5c5b5b]">{t('reports.the-conclusion-of-this-report-is')}</li>
        </ul>
      </div>
      <div>
        <p className="text-sm font-semibold text-[#888888]">{t('reports.proposed-solutions')}</p>
        <ul className="list-disc pl-6">
          <li className="text-sm text-[#5c5b5b]">{t('reports.the-following-solutions-need-to-be-taken-to')}</li>
          <li className="text-sm text-[#5c5b5b]">{t('reports.proposing-some-solutions-to')}</li>
        </ul>
      </div>
    </div>
  );
};

export default SuggestReport;
