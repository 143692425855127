import { t } from 'i18next';
import { useEffect } from 'react';
import ChangeAvatarIcon from '../../../Assets/Images/icon-change-avatar.svg';
import AddFileIc from '../../../Assets/Images/Icons/add-file-ic.svg';
import LinkFileIc from '../../../Assets/Images/Icons/link-file-ic.svg';

const TaskUploadFile = (props) => {
  useEffect(() => {}, [props?.files]);

  if (props.viewMode) {
    if (props.mode === 'view') {
      return (
        <ul className="custom-scroll-x mt-2 h-[100px]  overflow-auto drop-shadow-lg">
          {props?.files?.map((item, index) => {
            return (
              <li key={index} className="m-1 flex h-[50px] items-center rounded-lg border bg-white p-2 text-[14px]">
                <img onClick={() => props?.handleDownLoad(index)} className="mr-2 w-[20px]" src={LinkFileIc} alt="" />
                <div className="">
                  <p className="max-w-[170px] truncate font-bold">{item?.fileNm}</p>
                  <p className="text-[12px]">{item?.createdAt}</p>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  } else {
    if (props.mode === 'displayWctImg') {
      return (
        <div>
          {
            <div className="">
              {props.uploadKey !== '' && (
                <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
              )}

              <div className="upload-btn flex items-center rounded-[6px] bg-[#EDF5F5] p-[8px] text-[12px] font-[500] text-[#1294A4]">
                Thay đổi <img src={ChangeAvatarIcon} className="ml-[6px]" />
              </div>

              {/* <button type="button"
                                    className="flex items-center bg-white p-[8px] rounded-[6px] font-[500] text-[12px] text-black ml-[12px]"
                                    onClick={() => props.handleRemove(0)}>
                                    Gỡ file
                                </button> */}
            </div>
          }
        </div>
      );
    } else {
      return (
        <div>
          <div className="grid grid-cols-4">
            {props.uploadKey !== '' && (
              <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
            )}
            <button
              type="button"
              className="upload-btn relative my-2 h-[140px] w-[120px] rounded-2xl border-2 border-dashed bg-[#F8F8F8]"
            >
              <div className="absolute top-[20px] flex flex-col items-center justify-center p-2 text-[10px]">
                <img src={AddFileIc} className="mb-2 h-[30px] w-[30px] rounded-lg bg-white" alt="" />

                <p>File {t('job.support')}: .png, .jpg, .pdf</p>
                <p>{t('job.maximum-capacity')} 16mb</p>
              </div>
            </button>
            {props?.files?.map((item, index) => {
              return (
                <div className="" key={index}>
                  <div
                    type="button"
                    className="relative my-2 h-[140px] w-[120px] rounded-2xl border-2 border-dashed bg-[#F8F8F8]"
                  >
                    <div className="absolute right-[15px] top-[20px] flex flex-col items-center justify-center p-2 text-[10px]">
                      <img
                        src={ChangeAvatarIcon}
                        className="upload-btn mb-2 h-[30px] w-[30px] cursor-pointer rounded-lg bg-white"
                      />
                      <span className="file-item max-w-[70px] truncate" id={`file-item-${item.seq}`}>
                        {item.fileNm}
                      </span>
                      <p>Size {item?.fileSize} Kb</p>
                      <button
                        type="button"
                        className="my-1 mr-2  flex items-center justify-center rounded border bg-red-200 p-1 hover:bg-red-400"
                        onClick={() => props.handleRemove(index)}
                      >
                        Gỡ file
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
};
export default TaskUploadFile;
