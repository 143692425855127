import instance from '../instance';

export const BigTopicList = (userId) => {
  const url = `/backgroundTopic/listByUser?createdBy=${userId}`;
  return instance.get(url);
};
export const ReadBackgroundChild = (topicId) => {
  const url = `/childTopic/getChildTopic/${topicId}`;
  return instance.get(url);
};
export const ReadBackgroundList = (topicId) => {
  const url = `/childTopic/list?backgroundTopicId=${topicId}`;
  return instance.get(url);
};
export const BackgroundListId = () => {
  const url = `background/listByGroupId`;
  return instance.get(url);
};
export const BackgroundFile = (bgID) => {
  const url = `/background/listByGroupId?groupId=BackgroundTopic-${bgID}`;
  return instance.get(url);
};

export const BackgroundFileChild = (bgID) => {
  const url = `/background/listByGroupId?groupId=ChildTopic-${bgID}`;
  return instance.get(url);
};
export const CreateBigTopic = (bigTopic) => {
  const url = `/backgroundTopic/save`;
  return instance.post(url, bigTopic);
};
// Tạo mơi chủ dề con
export const CreateChildTopic = (bigTopic) => {
  const url = `/childTopic/save`;
  return instance.post(url, bigTopic);
};
// Xóa chủ đề con
export const DeleteChildTopic = (childTopicId) => {
  const url = `/childTopic/delete/${childTopicId}`;
  return instance.delete(url);
};
// Xóa chủ đề lớn
export const DeleteBigTopic = (bigTopicId) => {
  const url = `/backgroundTopic/delete/${bigTopicId}`;
  return instance.delete(url);
};
// Chọn background
export const SelectBackground = (spaceId, params) => {
  const url = `/space/updateBackground/${spaceId}`;
  return instance.put(url, params);
};

export const BackgroundDelete = (backgroundId) => {
  const url = `/background/${backgroundId}
  `;
  return instance.delete(url);
};

// http://localhost:8080/api/backgroundTopic/save

// http://localhost:8080/api/childTopic/save
