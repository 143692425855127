import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import projectBoard from '../../../../Apis/ProjectManagement/TaskProgress/board';
import WarningIc from '../../../../Assets/Images/Icons/warning-ic.svg';
import Icon from '../../../Common/Icon';
import validateNoLeadingSpace from '../../../Common/Validate';

const TaskName = ({ detailTask, setDetailTask, column, setIsCallBack, isCallBack }) => {
  const [editTaskName, setEditTaskName] = useState(false);
  const [validate, setValidate] = useState();
  const { t } = useTranslation();

  // Hàm xử lý khi nhấn phím Enter  thì gọi hàm handleKeyDown và cập nhật API
  const handleKeyDown = async (e) => {
    const invalid = (!e.target.value?.length && validateNoLeadingSpace(e.target.value)) || e.target.value?.length > 55;
    if (invalid) {
      return;
    } else {
      setValidate();
    }
    // Kiểm tra nếu phím là 'Enter', thì cũng gọi hàm updateColumn
    if (e.key === 'Enter') {
      const newData = {
        projBoardTaskId: detailTask.projBoardTaskId,
        projBoardColId: column.projBoardColId,
        taskName: e.target.value,
        endDate: detailTask.endDate,
        projBoardId: column?.projBoardId
      };
      updateTaskName(newData, 'name');
    }
  };
  // End

  // Hàm xử lý khi kích ra ngoài input thì tự động cập nhật API
  const handleBlur = async (e) => {
    if (e.target.value?.length < 1 && validateNoLeadingSpace(e.target.value)) {
      setValidate(t('account-management.no-be-eft-blank'));
      return;
    } else if (e.target.value?.length > 55) {
      setValidate(t('account-management.up-to-55-characters'));
      return;
    } else {
      setValidate('');
    }
    const newData = {
      projBoardTaskId: detailTask.projBoardTaskId,
      projBoardColId: column.projBoardColId,
      taskName: e.target.value,
      endDate: detailTask.endDate,
      projBoardId: column?.projBoardId
    };
    updateTaskName(newData, 'name');
  };

  const updateTaskName = async (data) => {
    try {
      await projectBoard.editTaskKanban(data, 'name');
      // Sau khi cập nhật thành công thì lưu vào state
      setDetailTask({
        ...detailTask,
        taskName: data.taskName
      });
      setEditTaskName(false);
      setIsCallBack(!isCallBack);
      setValidate('');
    } catch (error) {
      setValidate(t('personnel-management.no-be-eft-blank'));
    }
  };

  const handleCheckInvalid = (e) => {
    if (!e.target.value?.length && validateNoLeadingSpace(e.target.value)) {
      return setValidate(t('account-management.no-be-eft-blank'));
    } else if (e.target.value?.length > 55) {
      return setValidate(t('account-management.up-to-55-characters'));
    } else {
      setValidate();
    }
  };

  return (
    <div>
      {editTaskName === true ? (
        <div>
          <div className="flex items-center gap-4">
            <Icon name="credit_card" className="stroke-black" />
            <textarea
              defaultValue={detailTask?.taskName}
              className="w-full resize-none break-words rounded-lg border border-[#F1F3FD] bg-white px-3 py-1.5 text-xl font-medium placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
              name="taskName"
              onKeyDown={handleKeyDown}
              autoFocus
              onBlur={handleBlur}
              onChange={handleCheckInvalid}
            />
          </div>

          {validate && (
            <p className="my-2 mx-10 flex gap-1">
              <img src={WarningIc} alt="warning-icon" />
              <span className="text-xs font-normal text-red-500">{validate}</span>
            </p>
          )}
        </div>
      ) : (
        <div className="mb-1 flex gap-4 text-xl font-semibold text-[#172b4d]">
          <Icon name="credit_card" className="stroke-[#111111]" />
          <h1 className="cursor-pointer overflow-hidden break-words" onClick={() => setEditTaskName(true)}>
            {detailTask?.taskName}
          </h1>
        </div>
      )}
      <p className="ml-10 whitespace-normal break-words text-[13px] text-gray-600">
        {t('project.in-the-list-column')}
        <span className="ml-2 border-b border-black font-medium">{detailTask?.colName}</span>
      </p>
    </div>
  );
};

export default TaskName;
