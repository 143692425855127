import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import projectBoard from '../../../../Apis/ProjectManagement/TaskProgress/board';
import backendURL from '../../../../config';
import { useGlobalContext } from '../../../../Utils/Context';
import AttachFile from '../../../AttachFile/AttachFile';
import { Button } from '../../../Buttons/ButtonComponent';
import { toDateStringYears } from '../../../Calendar/CalendarComponent';
import { colorDesc } from '../../../Common/Color';
import Icon from '../../../Common/Icon';
import Popup from '../../../Popup/Popup';

const TaskDescribe = ({ detailTask, setDetailTask, column, isEditable, dataFilelink }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: colorDesc }],
      [
        {
          background: colorDesc
        }
      ]
    ]
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'image',
    'list',
    'bullet',
    'link',
    'color',
    'indent',
    'background',
    'align',
    'size',
    'font'
  ];
  const [code, setCode] = useState('');
  const { t } = useTranslation();
  const [isShowAction, setIsShowAction] = useState(true);
  const { callBackFile, setCallBackFile, setSeqFile } = useGlobalContext();
  const { projectInfo } = useOutletContext();
  const { register, handleSubmit, reset } = useForm();
  const [editDesc, setEditDesc] = useState(false);
  const modalRef = useRef(null);
  const [selectLinkImage, setSelectLinkImage] = useState();
  const [openFile, setOpenFile] = useState(false);
  const [cursorPositionBeforeList, setCursorPositionBeforeList] = useState(0);
  // useCloseModalOnOutsideClick(modalRef, setEditDesc);
  // eslint-disable-next-line no-unused-vars
  const handleProcedureContentChange = (content, delta, source, editor) => {
    // Tạo một đối tượng DOMParser để check lần đầu đẩy ảnh
    var parser = new DOMParser();
    var doc = parser.parseFromString(content, 'text/html');
    // Kiểm tra xem có thẻ <img> trong Document không
    var hasImgTag = doc.querySelector('img') !== null;
    // Kiểm tra xem nội dung có trống không sau khi xóa không
    const isContentEmpty = editor.getText().trim() === '';
    if (isContentEmpty && !hasImgTag) {
      // Nếu nội dung trống, cập nhật state thành chuỗi trống
      setCode('');
    } else {
      setCode(content);
    }
  };
  // hàm cập nhật mô tả
  const handleEditDesc = async (data) => {
    const newData = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      projBoardColId: column.projBoardColId,
      taskName: detailTask?.taskName,
      endDate: detailTask?.endDate,
      description: code,
      uploadKey: data?.uploadKey
    };

    try {
      await projectBoard.editTaskKanban(newData, 'description');
      setDetailTask({
        ...detailTask,
        description: code
      });
      setEditDesc(false);
      setSeqFile();
      reset();
      setCallBackFile(!callBackFile);
      toast.success(t('project.successfully-updated-description'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  const handleImage = async (data) => {
    const newData = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      projBoardColId: detailTask.projBoardColId,
      taskName: detailTask?.taskName,
      endDate: detailTask?.endDate,
      description: detailTask?.description,
      uploadKey: data.uploadKey,
      fileSeq: data.seq,
      coverImageName: detailTask?.coverImageName
    };

    try {
      await projectBoard.editTaskKanban(newData);
      setDetailTask({
        ...detailTask,
        description: code ? code : detailTask?.description
      });
      setSeqFile();
      setCallBackFile(!callBackFile);
      const url = backendURL + `/file/displayImg/` + data.seq;
      setSelectLinkImage(url);
      setOpenImage(false);
      reset();
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  const quillRef = useRef(null);
  const [heightQuill, setHeightQuill] = useState();

  useEffect(() => {
    if (quillRef.current) {
      const quillContainer = quillRef.current.getEditor();
      const quillHeight = quillContainer.root.scrollHeight;
      setHeightQuill(quillHeight);
    }
  }, [detailTask]);
  // ================

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill?.getSelection()?.index;
      // Chèn hình ảnh từ URL khi trình soạn thảo được khởi tạo
      if (selectLinkImage) {
        quill.insertEmbed(range, 'image', selectLinkImage, {
          src: selectLinkImage,
          style: 'width : 200px'
        }),
          setSelectLinkImage();
      }
    }
  }, [quillRef, selectLinkImage]);

  // eslint-disable-next-line no-unused-vars
  const [openImage, setOpenImage] = useState(false);

  // useEffect(() => {
  //   handleImage(testSeq);
  // }, [trueFalse]);

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const isImageFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  // Hàm kiểm tra file text
  const textExtensions = ['txt', 'doc', 'docx', 'pdf'];
  const isTextFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return textExtensions.includes(fileExtension);
  };

  // eslint-disable-next-line no-unused-vars
  const pushhtmlFileToQuill = (quillDetail, item, startsWithHttps) => {
    const quillRefFile = quillDetail?.current.getEditor();
    quillRefFile?.pasteHTML(cursorPositionBeforeList, '_');
    quillRefFile?.deleteText(cursorPositionBeforeList, 1);
    if (startsWithHttps) {
      quillRefFile?.insertText(cursorPositionBeforeList, `${item?.fileName}`, 'link', item?.fileName);
    } else {
      quillRefFile?.insertText(cursorPositionBeforeList, `${item?.fileName}`);
    }
    quillRefFile?.formatText(cursorPositionBeforeList, cursorPositionBeforeList + item?.fileName?.length, {
      italic: true
    });
    quillRefFile?.pasteHTML(cursorPositionBeforeList + item?.fileName?.length, '_');

    // Set style css for tag member element
    let [leaf] = quillRefFile.getLeaf(cursorPositionBeforeList + 1);
    const insertedElement = leaf?.parent?.domNode;
    insertedElement.contentEditable = 'false';
    insertedElement.id = 'linkUrlfile';
    quillRefFile?.insertText(cursorPositionBeforeList + item?.fileName?.length, ` `);
    quillRefFile?.deleteText(cursorPositionBeforeList + item?.fileName?.length + 1, 1);
    closeModalTaskImage();
  };

  const handleFile = async (data) => {
    const newData = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      projBoardColId: detailTask.projBoardColId,
      taskName: detailTask?.taskName,
      endDate: detailTask?.endDate,
      description: detailTask?.description,
      uploadKey: data.uploadKey,
      fileSeq: data.seq,
      coverImageName: detailTask?.coverImageName
    };
    setCallBackFile(!callBackFile);
    try {
      await projectBoard?.editTaskKanban(newData);
      const dataItem = {
        fileName: data.file.name,
        content: data.seq
      };
      pushhtmlFileToQuill(quillRef, dataItem, false);
      closeModalTaskImage();
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  const closeModalTaskImage = () => {
    setOpenImage(false);
    setOpenFile(false);
  };

  useEffect(() => {
    if (editDesc && quillRef?.current) {
      quillRef?.current?.getEditor()?.focus();
    }
  }, [editDesc]);
  return (
    <div>
      <div className=" ">
        <div className="flex justify-between">
          <div className="mb-4 flex gap-3">
            <Icon name="align_left" />
            <p className="text-[16px] font-semibold text-[#172b4d]">{t('calendar.description')}</p>
          </div>
          <div>
            {projectInfo?.roleProject !== 3 && (
              <div
                className=" flex cursor-pointer gap-2 rounded-md bg-[#E1E6FF] py-1 px-3 hover:bg-[#dbe1fd]"
                onClick={() => setEditDesc(true)}
              >
                <Icon name="edit_2" className="mt-0.5 h-[15px] w-[16px] fill-[#172b4d]" />
                <p className="text-sm text-[#172b4d]">{t('job.edit')}</p>
              </div>
            )}
          </div>
        </div>

        <Popup isOpen={openImage} setIsOpen={closeModalTaskImage}>
          <div className="max-h-[600px] min-h-[200px] w-[450px]  ">
            <div className="mt-4 flex justify-between border-b  pb-2">
              <p></p>
              <div className="flex w-[55%] justify-between">
                <p className="text-base font-bold"> {t('project.select-photo')}</p>
                <div
                  className=" mr-4 flex cursor-pointer items-center justify-center rounded-full bg-gray-300 bg-opacity-60 px-1.5 py-0.5  hover:bg-opacity-90"
                  onClick={() => closeModalTaskImage()}
                >
                  <Icon name="close_cross" className=" h-[16px] w-[16px] stroke-black" />
                </div>
              </div>
            </div>
            <div>
              <div className="ml-8 mt-4">
                <p>{t('project.recently-uploaded-photo')}</p>
              </div>
              <div className="mb-6">
                <div className="flex  ">
                  <AttachFile
                    attachType="ProjectTaskFile"
                    entity="ProjBoardTask"
                    seq={detailTask?.projBoardTaskId}
                    viewMode={true}
                    mode="downloadFile"
                    setSelectLinkImage={setSelectLinkImage}
                    setOpenImage={setOpenImage}
                    className="  mt-2 h-[300px] w-[500px] rounded-md object-cover"
                  />
                </div>
              </div>
              <div className=" mb-4 flex w-full justify-center px-6">
                <AttachFile
                  attachType="ProjectTaskFile"
                  entity="ProjBoardTask"
                  multi={false}
                  editable={true}
                  register={register}
                  viewMode={false}
                  handleUpload={handleImage}
                  mode="form"
                  filters={{
                    max_file_size: '10mb',
                    mime_types: [
                      {
                        title: 'Tệp đã được nhận: ',
                        extensions: 'jpg,png,svg'
                      }
                    ]
                  }}
                  className="  rounded-t-full"
                />
              </div>
            </div>
          </div>
        </Popup>
        <div className="ml-10">
          {editDesc === false ? (
            <div>
              {/* Hiển thị nội dung mô tả */}
              <div
                onClick={() => {
                  if (projectInfo?.roleProject !== 3) {
                    setEditDesc(true);
                  }
                }}
                className="rounded-md  bg-[#F7F8FC]"
              >
                <ReactQuill
                  theme="snow"
                  id="objective"
                  className={` react-quill-hidden relative ${
                    isShowAction ? 'max-h-[400px] min-h-[60px] overflow-y-auto' : 'max-h-full'
                  }`}
                  modules={modules}
                  formats={formats}
                  value={detailTask?.description ? detailTask?.description : t('project.add-detailed-description')}
                  ref={quillRef}
                  onChange={(e) => {
                    if (isEditable) {
                      setDetailTask((prev) => ({ ...prev, description: e.target.value }));
                    }
                  }}
                  readOnly={!isEditable} // Để vô hiệu hóa việc nhập liệu
                />
              </div>

              {console.log('detailTask?.description', detailTask?.description)}
              {detailTask?.description && heightQuill > 400 && (
                <div className="flex justify-center bg-primary-300">
                  <Button
                    icon={
                      <Icon
                        name={isShowAction ? 'arrows_diagonal_contract' : 'arrows_diagonal_maximize'}
                        className="fill-none stroke-primary-100"
                      />
                    }
                    content={isShowAction ? t('project.expand') : t('project.collapse')}
                    onClick={() => setIsShowAction(!isShowAction)}
                    className="bg-primary-300 px-2 py-1 text-primary-100 transition-all duration-300"
                  />
                </div>
              )}
            </div>
          ) : (
            <div ref={modalRef}>
              {/* Form nhập mô tả */}

              <form onSubmit={handleSubmit(handleEditDesc)}>
                <div className="relative  rounded-md bg-[#F7F8FC]">
                  {/* Nhập mô tả */}
                  <ReactQuill
                    theme="snow"
                    id="objective"
                    className={`react-quill relative max-h-full min-h-[100px] pt-2 placeholder:text-sm`}
                    modules={modules}
                    // eslint-disable-next-line no-unused-vars
                    onChangeSelection={(selection, source, editor) => {
                      if (selection === null) {
                        return;
                      }
                      setCursorPositionBeforeList(selection.index);
                    }}
                    formats={formats}
                    defaultValue={detailTask?.description}
                    onChange={handleProcedureContentChange}
                    placeholder={t('project.enter-content')}
                    toolbar={false}
                    ref={quillRef}
                  />
                  <div
                    onClick={() => setOpenImage(true)}
                    className="  absolute right-[220px] top-0 mt-4 cursor-pointer  rounded-lg bg-white py-1"
                  >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 7.5L9.96967 16.7197C10.2626 17.0126 10.7374 17.0126 11.0303 16.7197L17.25 10.5M5.25 11.25L8.46967 8.03033C8.76256 7.73744 9.23744 7.73744 9.53033 8.03033L14.25 12.75M13.2186 4.2187H13.7811M13.2186 4.7812H13.7811M6.75 17.25H11.25C13.3502 17.25 14.4003 17.25 15.2025 16.8413C15.9081 16.4817 16.4817 15.9081 16.8413 15.2025C17.25 14.4003 17.25 13.3502 17.25 11.25V6.75C17.25 4.6498 17.25 3.5997 16.8413 2.79754C16.4817 2.09193 15.9081 1.51825 15.2025 1.15873C14.4003 0.75 13.3502 0.75 11.25 0.75H6.75C4.6498 0.75 3.5997 0.75 2.79754 1.15873C2.09193 1.51825 1.51825 2.09193 1.15873 2.79754C0.75 3.5997 0.75 4.6498 0.75 6.75V11.25C0.75 13.3502 0.75 14.4003 1.15873 15.2025C1.51825 15.9081 2.09193 16.4817 2.79754 16.8413C3.5997 17.25 4.6498 17.25 6.75 17.25ZM14.25 4.5C14.25 4.91421 13.9142 5.25 13.5 5.25C13.0858 5.25 12.75 4.91421 12.75 4.5C12.75 4.08579 13.0858 3.75 13.5 3.75C13.9142 3.75 14.25 4.08579 14.25 4.5Z"
                        stroke="#171717"
                        strokeWidth="0.75"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => setOpenFile(!openFile)}
                    className="absolute right-24 top-[2px] mt-4 flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full py-1 hover:bg-gray-400"
                  >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 4.125V14.625C9 15.4534 8.32843 16.125 7.5 16.125C6.67157 16.125 6 15.4534 6 14.625V4.5C6 2.84315 7.34315 1.5 9 1.5C10.6569 1.5 12 2.84315 12 4.5V11.25"
                        stroke="#171717"
                        strokeWidth="0.75"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>

                  <Popup isOpen={openFile} setIsOpen={closeModalTaskImage}>
                    <div className="max-h-[600px] min-h-[200px] w-[450px]">
                      <div className="mt-4 flex justify-between border-b  pb-2">
                        <p></p>
                        <div className="flex w-[55%] justify-between">
                          <p className="text-base font-bold">{t('project.select-file-new')}</p>
                          <div
                            className=" mr-4 flex cursor-pointer items-center justify-center rounded-full bg-gray-300 bg-opacity-60 px-1.5 py-0.5  hover:bg-opacity-90"
                            onClick={() => closeModalTaskImage()}
                          >
                            <Icon name="close_cross" className=" h-[16px] w-[16px] stroke-black" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="ml-8 mt-4">
                          <p>{t('project.recently-uploaded-file')}</p>
                        </div>
                        <div className="mx-8 mb-6">
                          <div className="my-2 flex h-auto w-full flex-col gap-2 ">
                            {dataFilelink?.boardTaskImagesAndLinks?.map((item, index) => {
                              const startsWithHttps = item.fileName.startsWith('http');
                              const parts = item?.fileName?.split('/').pop();
                              const checkFileImage = isImageFile(item?.fileName); //Kiểm tra xem có phải là file ảnh không
                              if (!checkFileImage) {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => pushhtmlFileToQuill(quillRef, item, startsWithHttps)}
                                    className="flex cursor-pointer gap-4"
                                  >
                                    <div className="">
                                      <div className="flex h-[86px] w-[118px] items-center justify-center rounded-xl border font-semibold uppercase text-[#172b4d]">
                                        {isTextFile(item.fileName) ? (
                                          item.fileName.split('.').pop().toLowerCase()
                                        ) : (
                                          <Icon name="link_2_diagonal" className=" stroke-black" />
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <p className=" block w-[280px] whitespace-normal break-words text-sm font-bold">
                                        {parts}
                                      </p>
                                      <div className="mt-1 flex gap-3 text-xs">
                                        <p>{toDateStringYears(item.updatedAt)}</p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>

                        <div className=" mb-4 flex w-full justify-center px-6 pb-8">
                          <AttachFile
                            attachType="ProjectTaskFile"
                            entity="ProjBoardTask"
                            multi={false}
                            editable={true}
                            register={register}
                            viewMode={false}
                            handleUpload={handleFile}
                            mode="form"
                            filters={{
                              max_file_size: '10mb',
                              mime_types: [
                                { title: 'Document files', extensions: 'pdf,doc,docx,xls,xlsx,ppt,pptx' },
                                { title: 'Archive files', extensions: 'zip,rar,tar,gz' },
                                { title: 'Text files', extensions: 'txt,html,xml' }
                              ]
                            }}
                            className="  rounded-t-full"
                          />
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
                <div></div>
                {/* Xác nhận lưu hoặc hủy */}
                <div className="mt-3 flex gap-4">
                  <button className="rounded-md bg-primary-100 py-0.5 px-3 text-sm   text-white">
                    {t('account-management.save')}
                  </button>
                  <div onClick={() => setEditDesc(false)} className="mt-2 cursor-pointer">
                    <Icon name="close_icon" className="stroke-black" />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskDescribe;
