import { Stomp } from '@stomp/stompjs';
import React, { useContext, useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { CheckLogin, GetAllUser } from '../Apis/user';
import backendURL from '../config';
import { NotifyMe } from './PushNotification';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userInfo, setUserInfo] = React.useState(undefined);
  const [roleUser, setRoleUser] = React.useState('');
  const [allUsers, setAllUsers] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [callBackFile, setCallBackFile] = useState(false);
  const [seqFile, setSeqFile] = useState();
  const [idNotification, setIdNotification] = useState();
  const [imageFileTask, setImageFileTask] = useState();
  const [backgroundFile, setBackgroundFile] = useState();
  const [mainColor, setMainColor] = useState(null);
  const [backGrouldId, setBackgroundId] = useState(null);
  const [isSocketNoti, setIsSocketNoti] = useState(false);
  const [idEventCalendar, setIdEventCalendar] = useState(null);
  const [typeEventCalendar, setTypeEventCalendar] = useState(null);
  const [idUserInfomation, setIdUserInfomation] = useState(null);

  const changeIdUserInfomation = (value) => {
    // Thay đổi giá trị của  biến idUserInfomation khi click vào ảnh đại diện
    setIdUserInfomation(value);
  };
  const changeStatusNoti = () => {
    setIsSocketNoti(false);
  };
  const changeIdEventCalendar = (value) => {
    setIdEventCalendar(value);
  };
  const changeTypeEventCalendar = (value) => {
    setTypeEventCalendar(value);
  };
  const getAllUsers = async () => {
    const data = await GetAllUser();
    setAllUsers(data?.data?.dataList);
  };
  const checkIsLogin = () => {
    setIsLogin(true);
  };
  const checkIsLoginFalse = () => {
    setIsLogin(false);
  };

  const updateUserInfo = async () => {
    const userLogin = await CheckLogin();
    setUserInfo(userLogin?.data?.data);
  };

  const checkLogin = async () => {
    if (isLogin === true) {
      const userLogin = await CheckLogin();
      const dataUser = userLogin?.data?.data;
      setUserInfo(dataUser);
      const newArrUserRoleId = [];
      var isHumanResources = false;
      dataUser?.userRoleDepts.map((role) => {
        newArrUserRoleId.push(role?.role);
        if (role?.department === null) {
          isHumanResources = true;
        }
      });
      if (newArrUserRoleId?.includes(6) === true) {
        setRoleUser('ROLE_ADMIN');
      }
      if (newArrUserRoleId?.includes(2) === true) {
        setRoleUser('ROLE_SUPER_ADMIN');
      }
      if (newArrUserRoleId?.includes(6) === false && newArrUserRoleId?.includes(3) === true && isHumanResources) {
        setRoleUser('ROLE_HUMAN_RESOURCES');
      }
      if (newArrUserRoleId?.includes(6) === false && newArrUserRoleId?.includes(3) === true && !isHumanResources) {
        setRoleUser('ROLE_MANAGER');
      }
      if (
        newArrUserRoleId?.includes(6) === false &&
        newArrUserRoleId?.includes(3) === false &&
        newArrUserRoleId?.includes(1) === true
      ) {
        setRoleUser('ROLE_STAFF');
      }
      if (dataUser?.userRoleDepts?.length === 0) {
        setRoleUser('ROLE_STAFF');
      }
      setIsLogin(false);
    }
  };
  const showSidebar = () => {
    setIsSidebarOpen(true);
  };
  const activeLoad = (value) => {
    setLoading(value);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    checkLogin()
      .then(() => false)
      .catch((e) => console.log(e));
    getAllUsers()
      .then(() => false)
      .catch((e) => console.log(e));
  }, [isLogin, userInfo]);

  // Socket
  const endPointUrl = backendURL + '/our-websocket?userId=' + encodeURIComponent(userInfo?.userID);
  const client = new SockJS(endPointUrl);
  const bzStomp = Stomp.over(() => client);
  // Turn off debug
  bzStomp.debug = () => {};

  useEffect(() => {
    const connectToStomp = () => {
      bzStomp.connect({}, () => {
        bzStomp?.subscribe('/topic/notify', () => {
          setIsSocketNoti(true);
        });

        bzStomp?.subscribe('/user/topic/private-notify', (data) => {
          const response = JSON.parse(data.body);
          setIsSocketNoti(true);
          NotifyMe(response, userInfo);
        });
      });
    };

    connectToStomp();
    // Hủy đăng ký khi component unmount
    return () => {
      bzStomp.disconnect(); // Đảm bảo ngắt kết nối khi component unmount
    };
  }, [bzStomp]);

  return (
    <AppContext.Provider
      value={{
        isSidebarOpen,
        userInfo,
        roleUser,
        allUsers,
        loading,
        activeLoad,
        checkIsLogin,
        checkIsLoginFalse,
        showSidebar,
        closeSidebar,
        updateUserInfo,
        callBackFile,
        setCallBackFile,
        seqFile,
        setSeqFile,
        bzStomp,
        idNotification,
        setIdNotification,
        imageFileTask,
        setImageFileTask,
        backgroundFile,
        setBackgroundFile,
        mainColor,
        setMainColor,
        backGrouldId,
        setBackgroundId,
        isSocketNoti,
        changeStatusNoti,
        idEventCalendar,
        typeEventCalendar,
        changeIdEventCalendar,
        changeTypeEventCalendar,
        idUserInfomation,
        changeIdUserInfomation
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppProvider };
