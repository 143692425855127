import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import background from '../../../../Apis/ProjectManagement/background';
import projectBoard from '../../../../Apis/ProjectManagement/TaskProgress/board';
import backendURL from '../../../../config';
import { useGlobalContext } from '../../../../Utils/Context';
import { Button } from '../../../Buttons/ButtonComponent';
import Icon from '../../../Common/Icon';
import useCloseModalOnOutsideClick from '../../../OnOutsideClick/OnOutsideClick';
import Popup from '../../../Popup/Popup';
import AttachedFiles from './AttachedFiles';
import Attachments from './Attachments';
import Comment from './Comment/Comment';
import CoverImage from './CoverImage';
import CoverTask from './CoverTask';
import MoveTask from './MoveTask';
import TaskDescribe from './TaskDescribe';
import PopupTime from './TaskEndTime/PopupTime';
import TaskDeadline from './TaskEndTime/TaskDeadline';
import TaskLabel from './TaskLable/TaskLabel';
import TaskLabelPopup from './TaskLable/TaskLabelPopup';
import MemberChilTask from './TaskMember/MemberChilTask';
import PopupMember from './TaskMember/PopupMember';
import TaskName from './TaskName';
import TodoList from './TodoList';

const customStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',

    alignItems: 'start',
    padding: '24px 0',
    backgroundColor: '#ffffff',
    zIndex: 999,
    overflowY: 'auto',
    maxWidth: '100vw',
    maxHeight: '100vh'
  },
  content: {
    width: '850px',
    maxWidth: '95vw',
    padding: '0',
    borderRadius: '15px',
    boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    transform: 'none'
  }
};

const CardDetailPopup = ({
  openModal,
  closeModalTask,
  detailTask,
  column,
  setDetailTask,
  columns,
  setIsCallBack,
  isCallBack,
  isClosing
}) => {
  const { projectInfo } = useOutletContext();
  const { callBackFile, idUserInfomation } = useGlobalContext();
  const [editTaskDate, setEditTaskDate] = useState(false);
  const [openDeleteTask, setOpenDeleteTask] = useState(false);
  const [moveTask, setMoveTask] = useState(false);
  const [searchMember, setSearchMember] = useState(false);
  const [coverTaskFile, setCoverTaskFile] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isTaskLabel, setIsTaskLabel] = useState(false);
  const [followTask, setFollowTask] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const { t } = useTranslation();

  const boardRef = useRef(null);
  const moreRef = useRef(null);
  useCloseModalOnOutsideClick(boardRef, setSearchMember);
  useCloseModalOnOutsideClick(boardRef, setEditTaskDate);
  useCloseModalOnOutsideClick(boardRef, setMoveTask);
  useCloseModalOnOutsideClick(boardRef, setOpenDeleteTask);
  useCloseModalOnOutsideClick(moreRef, setCoverTaskFile);
  useCloseModalOnOutsideClick(boardRef, setAttachedFiles);
  useCloseModalOnOutsideClick(boardRef, setIsTaskLabel);

  const { userInfo } = useGlobalContext();
  // Xác định xem user có trong danh sách thành viên không
  const isUserIdInArray = detailTask?.projBoardTaskMemDTOs?.some((item) => item.userId === userInfo?.userID);

  // Hàm xử lý tham gia vào task
  const handleJoin = async () => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    try {
      const { data } = await projectBoard.joinBoardTask(detailTask.projBoardTaskId, userInfo?.userID);

      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardTaskMemDTOs: [...prevTask.projBoardTaskMemDTOs, data?.data]
      }));

      setIsCallBack((prev) => !prev);
      toast.success(t('project.successfully-joined-card'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  // Hàm xử lý rời khỏi task
  const handleLeave = async () => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    try {
      await projectBoard.leaveBoardTask(detailTask.projBoardTaskId, userInfo?.userID);

      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardTaskMemDTOs: prevTask.projBoardTaskMemDTOs.filter((item) => item.userId !== userInfo?.userID)
      }));

      setIsCallBack((prev) => !prev);
      toast.success(t('project.successfully-left-card'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  // Xóa task
  const handleDelateTask = async () => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    try {
      const body = {
        projBoardId: column?.projBoardId,
        projBoardTaskId: detailTask.projBoardTaskId,
        actionFlag: 'delete',
        taskName: detailTask?.taskName
      };
      await projectBoard.deleteTask(body, detailTask.projBoardTaskId);
      setIsCallBack(!isCallBack);
      closeModalTask();
      toast.success(t('job.successful-delete'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
      setOpenDeleteTask(false);
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  const [isOpenTask, setIsOpenTask] = useState(false);

  const [dataFilelink, setDataFileLink] = useState(null);
  const [numberFile, setNumberFile] = useState(4);

  // Hàm xử lý API lấy danh sách tất cả các file trong task
  const getAllByTaskId = async () => {
    const { data } = await background.getAllBoardTaskImagesAndLinks(detailTask?.projBoardTaskId, numberFile);
    setDataFileLink(data);
  };
  useEffect(() => {
    if (openModal) {
      getAllByTaskId();
    }
  }, [openModal, callBackFile, numberFile]);

  const [wait, setWait] = useState(false);

  setTimeout(() => {
    setWait(true);
  }, 2000);

  useEffect(() => {
    setWait(false);
  }, [openModal]);

  useEffect(() => {
    if (detailTask) {
      let arrUserFollowList = detailTask?.userFollowListId?.split(',');
      if (!arrUserFollowList) {
        setFollowTask(false);
        return;
      } else {
        if (arrUserFollowList?.includes(userInfo?.userID.toString())) {
          setFollowTask(true);
          return;
        }
        setFollowTask(false);
      }
    }
  }, [detailTask]);

  const handleChangeFollowTask = async (status) => {
    try {
      const apiURL = `${backendURL}/projBoardTask/followBoardTask`;

      const authToken = localStorage.getItem('token');
      // Tạo đối tượng FormData và thêm dữ liệu vào đó
      const formData = new FormData();
      formData.append('taskId', detailTask?.projBoardTaskId);
      formData.append('userId', userInfo?.userID);
      formData.append('flag', status ? 'unfollow' : 'follow');
      try {
        // Gửi yêu cầu POST sử dụng FormData
        await axios.post(apiURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`
          }
        });
        // Cập nhật lại detailTask
        const newUserFollowListId = changeUserId(detailTask?.userFollowListId);
        setDetailTask({
          ...detailTask,
          userFollowListId: newUserFollowListId
        });
        toast.success(status ? t('project.you-have-unfollowed') : t('project.you-are-following'), {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });

        setIsCallBack(!isCallBack);
      } catch (error) {
        console.error('Error:', error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeUserId = (value) => {
    // Hàm set lại giá trị cho mảng User Follow
    let arrUserIdNumber;
    if (!detailTask?.userFollowListId) {
      arrUserIdNumber = [];
      arrUserIdNumber.push(userInfo?.userID);
      return arrUserIdNumber.join(',');
    }
    arrUserIdNumber = value.split(',').map(Number);
    const index = value.indexOf(userInfo?.userID);

    if (index !== -1) {
      arrUserIdNumber.splice(index, 1);
    } else {
      arrUserIdNumber.push(userInfo?.userID);
    }
    return arrUserIdNumber.length === 1 ? null : arrUserIdNumber.join(',');
  };

  const targetElementRef = useRef(null);
  const handleScrollToElement = () => {
    if (targetElementRef.current) {
      targetElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Popup
      isOpen={openModal && !idUserInfomation}
      setIsOpen={closeModalTask}
      style={customStyles}
      className={`${
        isClosing
          ? 'animate__animated animate__fadeOutDown animate__faster'
          : 'animate__animated animate__fadeInDown animate__faster'
      }`}
    >
      <div className="">
        {wait ? (
          <div className="relative">
            <div className=" absolute right-4 top-4 ">
              <Icon
                name="close_cross"
                className="cursor-pointer rounded-full border bg-[#F2F2F2] bg-opacity-70 stroke-black p-0.5 hover:bg-opacity-100"
                onClick={() => {
                  closeModalTask();
                }}
              />
            </div>
            {(detailTask?.coverImageId || detailTask?.coverColor || detailTask?.coverPath) && (
              <div>
                <CoverImage
                  detailTask={detailTask}
                  column={column}
                  setDetailTask={setDetailTask}
                  closeModalTask={closeModalTask}
                  dataFilelink={dataFilelink}
                  setDataFileLink={setDataFileLink}
                  setIsCallBack={setIsCallBack}
                  isCallBack={isCallBack}
                  projectInfo={projectInfo}
                />
              </div>
            )}

            <div className="mb-8 flex  h-auto min-h-[800px] overflow-y-auto bg-white py-6">
              <div className="flex h-full w-[78%] flex-col gap-y-3  pl-4 ">
                {/* Card name - Colum name */}
                <div>
                  <TaskName
                    setDetailTask={setDetailTask}
                    detailTask={detailTask}
                    column={column}
                    setIsCallBack={setIsCallBack}
                    isCallBack={isCallBack}
                  />
                </div>
                <div className=" ml-10 mt-2 flex flex-wrap gap-4">
                  <div>
                    <div>
                      <div className="mb-1 flex gap-1">
                        {/* <Icon name="group_1" className="h-[13px] w-[20px] pt-2" /> */}
                        <p className="text-[13px] font-[5px] text-[#172b4d]">{t('notification.notification')}</p>
                      </div>
                      <div className="mb-2 ml-1 flex-wrap gap-1 md:flex">
                        <button
                          onClick={() => handleChangeFollowTask(followTask)}
                          className="flex h-[32px] w-auto min-w-[108px] items-center gap-2 rounded-[8px] bg-[#F7F8FC] px-2 text-[14px]"
                        >
                          <Icon name={!followTask ? 'eye' : 'eye_off'} />
                          {!followTask ? t('project.follow') : t('project.unfollow')}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Member */}
                  {detailTask?.projBoardTaskMemDTOs.length !== 0 && (
                    <div>
                      <MemberChilTask
                        detailTask={detailTask}
                        setDetailTask={setDetailTask}
                        setIsCallBack={setIsCallBack}
                        isCallBack={isCallBack}
                      />
                    </div>
                  )}
                  {/* Deadline */}
                  {detailTask?.endDate !== null && (
                    <div>
                      <TaskDeadline
                        setDetailTask={setDetailTask}
                        detailTask={detailTask}
                        column={column}
                        setIsCallBack={setIsCallBack}
                        isCallBack={isCallBack}
                        projectInfo={projectInfo}
                      />
                    </div>
                  )}
                  {/* Label */}
                  {detailTask?.projBoardLabelDTOS?.length > 0 && (
                    <div>
                      <TaskLabel
                        column={column}
                        detailTask={detailTask}
                        setDetailTask={setDetailTask}
                        projectInfo={projectInfo}
                      />
                    </div>
                  )}
                </div>

                {/* Description */}
                <div>
                  <TaskDescribe
                    dataFilelink={dataFilelink}
                    setDataFileLink={setDataFileLink}
                    setDetailTask={setDetailTask}
                    detailTask={detailTask}
                    column={column}
                    setIsCallBack={setIsCallBack}
                    isCallBack={isCallBack}
                  />
                </div>

                {/* Attachments */}
                <div>
                  <Attachments
                    setDetailTask={setDetailTask}
                    detailTask={detailTask}
                    column={column}
                    setIsCallBack={setIsCallBack}
                    isCallBack={isCallBack}
                    dataFilelink={dataFilelink}
                    setDataFileLink={setDataFileLink}
                    numberFile={numberFile}
                    setNumberFile={setNumberFile}
                    projectInfo={projectInfo}
                  />
                </div>

                {/* Todo list */}
                <div>
                  <TodoList
                    setDetailTask={setDetailTask}
                    detailTask={detailTask}
                    column={column}
                    setIsCallBack={setIsCallBack}
                    isCallBack={isCallBack}
                    isOpenTask={isOpenTask}
                    setIsOpenTask={setIsOpenTask}
                    targetElementRef={targetElementRef}
                    handleScrollToElement={handleScrollToElement}
                    projectInfo={projectInfo}
                  />
                </div>

                {/* Action - Comment */}
                <div>
                  <div className="mb-8 flex items-center justify-between">
                    <div className="flex items-center gap-x-2 font-semibold">
                      <Icon name="message_circle3_text" className="h-6 w-6 stroke-black" />
                      <span> {t('project.activity')}</span>
                    </div>
                    <button
                      className="flex w-auto justify-center gap-3 rounded-md bg-[#E1E6FF] py-1 px-2 text-center hover:bg-[#d4dbfa]"
                      onClick={() => setShowActivity(!showActivity)}
                    >
                      <Icon name={showActivity ? 'eye_off' : 'eye'} />
                      <p className="text-sm text-[#172b4d]">
                        {showActivity ? t('project.hide-activity') : t('project.details')}
                      </p>
                    </button>
                  </div>

                  <div className="px-4">
                    <Comment
                      setDetailTask={setDetailTask}
                      showActivity={showActivity}
                      openModal={openModal}
                      projBoardTaskDetail={detailTask}
                      className={``}
                      dataFilelink={dataFilelink}
                      setDataFileLink={setDataFileLink}
                      projectInfo={projectInfo}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-16 w-[22%]">
                <div className="flex justify-center">
                  <div className="  ">
                    <div className="flex gap-4">
                      <Icon name="burger_menu_big" />
                      <p className="text-base font-semibold text-[#172b4d]">{t('project.operation')}</p>
                    </div>
                    <div>
                      {isUserIdInArray ? (
                        <div
                          className={`mt-4 flex h-[32px] w-[150px] gap-4 rounded-[4px] px-2 ${
                            projectInfo?.roleProject === 3
                              ? 'cursor-not-allowed opacity-50'
                              : 'cursor-pointer hover:bg-[#bbb9b9]'
                          } bg-[#D9D9D9]`}
                          onClick={() => {
                            if (projectInfo?.roleProject !== 3) {
                              handleLeave();
                            }
                          }}
                        >
                          <Icon name="exit" className="fill-[#172b4d]" />
                          <p className="flex items-center text-sm font-medium text-[#172b4d]">{t('project.leave')}</p>
                        </div>
                      ) : (
                        <div
                          className={`mt-4 flex h-[32px] w-[150px] gap-2 rounded-[4px] px-2 ${
                            projectInfo?.roleProject === 3
                              ? 'cursor-not-allowed opacity-50'
                              : 'cursor-pointer hover:bg-[#bbb9b9]'
                          } bg-[#D9D9D9]`}
                          onClick={() => {
                            if (projectInfo?.roleProject !== 3) {
                              handleJoin();
                            }
                          }}
                        >
                          <Icon name="enter" className="fill-[#172b4d]" />
                          <p className="flex items-center text-sm font-medium text-[#172b4d]">
                            {t('calendar.participate')}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="relative">
                      <div
                        className={`mt-4 flex h-[32px] w-[150px] gap-2 rounded-[4px] bg-[#D9D9D9] px-2 ${
                          projectInfo?.roleProject === 3
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer hover:bg-[#bbb9b9]'
                        }`}
                        onClick={() => {
                          if (projectInfo?.roleProject !== 3) {
                            setSearchMember(!searchMember);
                          }
                        }}
                      >
                        <Icon name="group_1" className="mt-2 h-[14px] w-[18px] fill-[#172b4d]" />
                        <p className="flex items-center text-sm font-medium text-[#172b4d]">{t('project.member')}</p>
                      </div>
                      {searchMember && (
                        <div className="absolute right-0 top-10 z-30 bg-white" ref={boardRef}>
                          <PopupMember
                            setDetailTask={setDetailTask}
                            detailTask={detailTask}
                            setSearchMember={setSearchMember}
                            setIsCallBack={setIsCallBack}
                            isCallBack={isCallBack}
                            projectInfo={projectInfo}
                          />
                        </div>
                      )}
                    </div>

                    <div className="relative">
                      <div
                        className={`mt-4 flex h-[32px] w-[150px] gap-2 rounded-[4px] bg-[#D9D9D9] px-2 ${
                          projectInfo?.roleProject === 3
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer hover:bg-[#bbb9b9]'
                        }`}
                        onClick={() => {
                          if (projectInfo?.roleProject !== 3) {
                            setEditTaskDate(true);
                          }
                        }}
                      >
                        <Icon name="calendar" className="mt-2 h-[18px] w-[18px] stroke-[#172b4d]" />
                        <p className="flex items-center text-sm font-medium text-[#172b4d]">
                          {t('holiday-management.end-date')}
                        </p>
                      </div>
                      {editTaskDate && (
                        <div
                          className="absolute right-0 z-30 mt-3 rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                          ref={boardRef}
                        >
                          <PopupTime
                            setDetailTask={setDetailTask}
                            detailTask={detailTask}
                            column={column}
                            setEditTaskDate={setEditTaskDate}
                            setIsCallBack={setIsCallBack}
                            isCallBack={isCallBack}
                            projectInfo={projectInfo}
                          />
                        </div>
                      )}
                    </div>

                    {/* Ảnh bìa */}
                    <div className="relative">
                      <div
                        className={`mt-4 flex h-[32px] w-[150px] gap-2 rounded-[4px] bg-[#D9D9D9] px-2 ${
                          projectInfo?.roleProject === 3
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer hover:bg-[#bbb9b9]'
                        }`}
                        onClick={() => {
                          if (projectInfo?.roleProject !== 3) {
                            setCoverTaskFile(true);
                          }
                        }}
                      >
                        <Icon name="color_pallete" className="fill-[#172b4d]" />
                        <p className="flex items-center text-sm font-medium text-[#172b4d]">
                          {t('project.cover-photo')}
                        </p>
                      </div>

                      {/* Popup nền cover */}
                      <div
                        className={`transition-max-h absolute right-0 ${
                          !coverTaskFile ? 'max-h-[0] opacity-0' : 'opacity-100'
                        } z-30 overflow-hidden rounded-lg bg-white pl-2 pr-2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)] duration-500`}
                        ref={moreRef}
                      >
                        <CoverTask
                          column={column}
                          setDetailTask={setDetailTask}
                          detailTask={detailTask}
                          setCoverTaskFile={setCoverTaskFile}
                          coverTaskFile={coverTaskFile}
                          dataFilelink={dataFilelink}
                          setDataFileLink={setDataFileLink}
                          setIsCallBack={setIsCallBack}
                          isCallBack={isCallBack}
                          isUserIdInArray={isUserIdInArray}
                          projectInfo={projectInfo}
                        />
                      </div>
                    </div>

                    {/* Nhãn dán */}
                    <div className="relative">
                      <div
                        className={`mt-4 flex h-[32px] w-[150px] items-center gap-2 rounded-[4px] bg-[#D9D9D9] px-2 ${
                          projectInfo?.roleProject === 3
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer hover:bg-[#bbb9b9]'
                        }`}
                        onClick={() => {
                          if (projectInfo?.roleProject !== 3) {
                            setIsTaskLabel(!isTaskLabel);
                          }
                        }}
                      >
                        <Icon name="label_1" className="h-[14px] w-[18px] fill-[#172b4d]" />
                        <p className="text-sm font-medium text-[#172b4d]">{t('project.label')}</p>
                      </div>

                      {isTaskLabel && (
                        <div className="absolute right-0 top-10 z-30 bg-white" ref={boardRef}>
                          <TaskLabelPopup
                            setDetailTask={setDetailTask}
                            detailTask={detailTask}
                            setIsTaskLabel={setIsTaskLabel}
                            setIsCallBack={setIsCallBack}
                            isCallBack={isCallBack}
                            column={column}
                            projectInfo={projectInfo}
                          />
                        </div>
                      )}
                    </div>

                    {/* File đính kèm */}
                    <div
                      className={`relative ${
                        projectInfo?.roleProject === 3 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer'
                      }`}
                    >
                      <div
                        className="mt-4 flex h-[32px] w-[150px] gap-2 rounded-[4px] bg-[#D9D9D9] px-2 hover:bg-[#bbb9b9]"
                        onClick={() => {
                          if (projectInfo?.roleProject === 3) {
                            return;
                          }
                          setAttachedFiles(true);
                        }}
                      >
                        <Icon name="link_2_diagonal" className="stroke-[#172b4d]" />
                        <p className="flex items-center text-sm font-medium text-[#172b4d]">
                          {t('project.attachment')}
                        </p>
                      </div>
                      <div
                        className={`absolute right-0  z-30   ${
                          !attachedFiles ? 'max-h-[0] opacity-0' : 'max-h-[80vh] opacity-100'
                        }   transition-max-h  overflow-hidden rounded-lg bg-white pl-2 pr-2  shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)] duration-500`}
                        ref={boardRef}
                      >
                        <AttachedFiles
                          column={column}
                          setDetailTask={setDetailTask}
                          detailTask={detailTask}
                          setAttachedFiles={setAttachedFiles}
                          attachedFiles={attachedFiles}
                        />
                      </div>
                    </div>
                    <div className="relative">
                      <div
                        className={`mt-4 flex h-[32px] w-[150px] gap-2 rounded-[4px] bg-[#D9D9D9] px-2 ${
                          projectInfo?.roleProject === 3
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer hover:bg-[#bbb9b9]'
                        }`}
                        onClick={() => {
                          if (projectInfo?.roleProject !== 3) {
                            setIsOpenTask(true);
                            handleScrollToElement();
                          }
                        }}
                      >
                        <Icon name="checkbox" className="fill-[#172b4d]" />
                        <p className="flex items-center text-sm font-medium text-[#172b4d]">{t('project.task')}</p>
                      </div>
                    </div>

                    {/* Hoạt động */}
                    <div className="mt-4 flex gap-4">
                      <Icon name="burger_menu_big" />
                      <p className="text-base font-semibold text-[#172b4d]">{t('project.activity')}</p>
                    </div>
                    {/* Xóa thẻ */}
                    <div className="relative">
                      <div
                        className={`${
                          projectInfo?.roleProject === 3 ? 'cursor-not-allowed opacity-50' : ''
                        } mt-4 flex h-[32px] w-[150px] cursor-pointer gap-3 rounded-[4px] bg-[#D9D9D9] px-2 hover:bg-[#bbb9b9]`}
                        onClick={() => {
                          if (projectInfo?.roleProject !== 3) {
                            setOpenDeleteTask(!openDeleteTask);
                          }
                        }}
                      >
                        <Icon name="trash_alt" className="fill-[#172b4d]" />
                        <p className="flex items-center text-sm font-medium text-[#172b4d]">
                          {t('project.delete-card')}
                        </p>
                      </div>
                      {openDeleteTask && (
                        <div
                          className="absolute right-0 z-30 mt-3 w-[464px] rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                          ref={boardRef}
                        >
                          <div className="flex flex-col items-center gap-y-5 p-4">
                            <Icon name="trash_can_1" />

                            <div className="text-center">
                              <p
                                className="whitespace-normal break-words font-medium text-neu-400"
                                style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                              >
                                {`${t('project.delete-card')} ${detailTask?.taskName}`}
                              </p>
                              <p className="mt-1 text-sm font-normal text-neu-200">
                                {t('project.all-information')} <br /> {t('project.this-irreversible')}
                              </p>
                            </div>

                            <div className="flex justify-center gap-x-10">
                              <Button
                                content={t('project.cancel')}
                                onClick={() => {
                                  setOpenDeleteTask(false);
                                }}
                                className="min-w-[82px] border border-neu-800 py-1.5 px-3 text-sm text-neu-800"
                              />

                              <Button
                                type="submit"
                                content={t('project.confirm')}
                                onClick={() => handleDelateTask()}
                                className="min-w-[82px] cursor-pointer border border-neu-800 bg-neu-800 py-1.5 px-3 text-sm text-neu-300"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Di chuyển */}
                    <div className="relative">
                      <div
                        className={`${
                          projectInfo?.roleProject === 3 ? 'cursor-not-allowed opacity-50' : ''
                        } mt-4 flex h-[32px] w-[150px] cursor-pointer gap-3 rounded-[4px] bg-[#D9D9D9] px-2 hover:bg-[#bbb9b9]`}
                        onClick={() => {
                          if (projectInfo?.roleProject !== 3) {
                            setMoveTask(true);
                          }
                        }}
                      >
                        <Icon name="enter" className="fill-[#172b4d]" />
                        <p className="flex items-center text-sm font-medium text-[#172b4d]">{t('project.move')}</p>
                      </div>
                      {moveTask && (
                        <div
                          className="absolute right-0  z-30  mt-3 rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                          ref={boardRef}
                        >
                          <MoveTask
                            columns={columns}
                            setDetailTask={setDetailTask}
                            detailTask={detailTask}
                            setIsCallBack={setIsCallBack}
                            isCallBack={isCallBack}
                            setMoveTask={setMoveTask}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="    max-h-[80vh] min-h-[80vh]   bg-white p-6">
            <div className="flex h-full max-h-[80vh] min-h-[80vh] items-center justify-center">
              <span className="loaders"></span>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default CardDetailPopup;
