/* eslint-disable no-unused-vars */
import plupload from 'plupload';
import { useEffect, useState } from 'react';
import { DeleteFile, GetListFiles, GetUploadKey } from '../../Apis/AttachFile/files';
import backendURL from '../../config';
import { useGlobalContext } from '../../Utils/Context';
import DownLoadFile from './DownLoadFile';
import NotifyAvatar from './NotifyAvatar';
import ProjectBoardFile from './Project/ProjectBoardFile';
import ProjectFile from './Project/ProjectFile';
import ProjectTaskFile from './Project/ProjectTaskFile';
import SpaceFileAvatar from './SpaceFile';
import CommentFile from './Tasks/CommentFile';
import TaskUploadFile from './Tasks/TaskUploadFile';
import UserAvatar from './UserAvatar';

const AttachFile = (props) => {
  const [files, setFiles] = useState([]);
  const [uploadKey, setUploadKey] = useState('');
  const [hiddenFile, setHiddenDile] = useState();
  const [statusProgress, setStatusProgress] = useState();
  const { callBackFile, setSeqFile, seqFile, setCallBackFile } = useGlobalContext();

  useEffect(() => {
    init();
  }, [props.seq, props.imageId, callBackFile]);
  // Hàm xử lý api get uploadKey , lấy ra danh sách file
  const init = async () => {
    var filesList = [];

    if (props.seq > 0) {
      filesList = await GetListFiles(props.entity, props.seq);

      filesList.data.data?.forEach((d) => {
        d.fileId = '';
      });

      setFiles(filesList.data.data);
      var last = filesList.data.data?.[filesList.data.data.length - 1];

      displayImg(last?.seq, '#img-' + props.mode + '-' + props.seq);
      setHiddenDile(last?.seq, '#img-' + props.mode + '-' + props.seq);
    }

    if (props.editable) {
      GetUploadKey().then((res) => {
        setUploadKey(res.data.data.key);
        initPlUpload(res.data.data.key, filesList.length === 0 ? filesList : filesList.data.data);
      });
    }
  };

  const initPlUpload = (uploadKey, filesList) => {
    var option = { multi_selection: props.multi, filters: props.filters };
    var buttonId = 'upload-btn-' + Date.now();
    var uploadBtn = document.querySelector('.upload-btn');

    if (uploadBtn !== null) {
      uploadBtn.setAttribute('id', buttonId);
    }

    var filters;
    var multi_selection = true;

    if (option.filters !== undefined) {
      filters = option.filters;
    }

    if (option.multi_selection !== undefined) {
      multi_selection = option.multi_selection;
    }

    var upload = new plupload.Uploader({
      runtimes: 'html5,flash,silverlight,html4',
      chunk_size: '10mb',
      browse_button: buttonId,
      multi_selection: multi_selection,
      url: props.attachType === 'ProjectTaskFile' ? backendURL + '/file/uploadBoardTaskFile' : backendURL + '/file',
      filters: props.filters,
      multipart_params: {
        uploadKey: uploadKey
      },
      init: {
        FilesAdded: function (up, filesAdded) {
          if (multi_selection) {
            plupload.each(filesAdded, function (file) {
              filesList.push({
                fileNm: file.name,
                fileSize: file.size,
                fileId: file.id
              });
            });
          } else {
            if (filesList.length > 0) {
              DeleteFile(filesList[0].seq).then(() => {
                filesList.splice(0, 1);
              });
            }
            plupload.each(filesAdded, function (file) {
              filesList.push({
                fileNm: file.name,
                fileSize: file.size,
                fileId: file.id
              });
            });
          }

          upload.start();
        },

        FileUploaded: function (up, file, info) {
          if (props?.handleUpload) {
            props?.handleUpload({ uploadKey: uploadKey, seq: JSON.parse(info.response).data.seq, file: file });
          }

          setFiles(filesList);
          filesList.forEach((f) => {
            if (f.fileId == file.id) {
              f.seq = JSON.parse(info.response).data.seq;
              f.fileId = '';
              if (
                props.attachType === 'UserAvatar' ||
                props.attachType === 'ProjectAvatar' ||
                props.attachType === 'ProjectTaskFile' ||
                props.attachType === 'SpaceAvatar' ||
                props.attachType === 'DownLoadFile'
              ) {
                displayImg(f.seq, '#display-img');
                setSeqFile(f.seq);
              }
              return false;
            }
          });
        },
        Error: function (up, err) {
          alert(err.message);
          setStatusProgress(false);
        }
      }
    });

    upload.init();
    upload.bind('UploadProgress', function (up, file) {
      // Check tiến độ tải ảnh lên
      var percent = file.percent;
      if (percent === 100) {
        setStatusProgress(true);
      }
    });
  };

  const extension = (fileNm) => {
    return fileNm.substring(fileNm.lastIndexOf('.') + 1).toLowerCase();
  };

  const download = (index) => {
    document.querySelector('#temp-frame').setAttribute('src', backendURL + '/file/' + files[index].seq);
  };
  const removeTask = (index) => {
    if (files.length > 0) {
      var fileSeq = files[index].seq;

      DeleteFile(fileSeq).then(() => {
        files.splice(index, 1);
        displayFileTask(undefined, '#display-img');
      });
    }
  };

  const displayFileTask = (fileSeq, tagId) => {
    if (props.attachType === 'TaskFile') {
      if (fileSeq === undefined) {
        init();
      }
    }
  };
  const remove = (index) => {
    if (files.length > 0) {
      let fileSeq = files[index].seq;
      DeleteFile(fileSeq).then(() => {
        files.splice(index, 1);
        displayImg(undefined, '#display-img');
        setFiles([]);
      });
      setCallBackFile(!callBackFile);
    }
  };
  const removeTaskFile = (index) => {
    if (files.length > 0 || seqFile) {
      let fileSeq = files[index]?.seq;
      DeleteFile(seqFile ? seqFile : fileSeq).then(() => {
        const fileDelete = [...files];
        fileDelete.splice(index, 1);
        displayImg(undefined, '#display-img');
        setFiles(fileDelete);
        setCallBackFile(!callBackFile);
      });
    }
  };

  const removeFile = (index) => {
    if (files.length > 0) {
      var fileSeq = files[index].seq;

      DeleteFile(fileSeq).then(() => {
        const fileDelete = [...files];
        fileDelete.splice(index, 1);
        displayImg(undefined, '#display-img');
        setFiles(fileDelete);
      });
    }
  };

  const displayImg = (fileSeq, tagId) => {
    if (
      props.attachType === 'UserAvatar' ||
      props.attachType === 'ProjectAvatar' ||
      props.attachType === 'ProjectTaskFile' ||
      props.attachType === 'SpaceAvatar' ||
      props.attachType === 'NotifyAvatar'
    ) {
      let imgElement = document.querySelector(props.mode === 'form' ? '#display-img' : tagId);
      if (fileSeq === undefined) {
        if (props.attachType === 'SpaceAvatar') {
          imgElement?.setAttribute('src', require('../../Assets/Images/spaceId.jpg'));
        } else if (props.attachType === 'ProjectAvatar') {
          imgElement?.setAttribute('src', require('../../Assets/Images/logoBzcom.jpg'));
        } else if (props.attachType === 'ProjectTaskFile') {
          imgElement?.setAttribute('src', require('../../Assets/Images/whiteImages.jpg'));
        } else {
          imgElement?.setAttribute('src', require('../../Assets/Images/avatar3.png'));
        }
      } else {
        imgElement?.setAttribute('src', backendURL + '/file/' + 'displayImg/' + fileSeq);
      }
    }
  };

  const displayFile = (fileSeq, tagId) => {
    if (props.attachType === 'DownLoadFile') {
      var imgElement = document.querySelector(props.mode === 'form' ? `#file-item-${fileSeq}` : tagId);
      if (fileSeq) {
        imgElement.innerHTML = '';
      }
    }
  };

  if (props.attachType === 'UserAvatar') {
    return (
      <UserAvatar
        uploadKey={uploadKey}
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        handleRemove={remove}
        className={props.className}
        file={files}
      />
    );
  } else if (props.attachType === 'ProjectAvatar') {
    return (
      <ProjectFile
        uploadKey={uploadKey}
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        handleRemove={remove}
        className={props.className}
        file={files}
        statusProgress={statusProgress}
        seqFile={seqFile}
      />
    );
  } else if (props.attachType === 'ProjectTaskFile') {
    return (
      <ProjectTaskFile
        uploadKey={uploadKey}
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        className={props.className}
        files={files}
        handleRemove={removeTaskFile}
        hiddenFile={hiddenFile}
        seqFile={seqFile}
        handleDownLoad={download}
        setSelectLinkImage={props.setSelectLinkImage}
        setOpenImage={props.setOpenImage}
      />
    );
  } else if (props.attachType === 'ProjectBoardFile') {
    return (
      <ProjectBoardFile
        uploadKey={uploadKey}
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        className={props.className}
        files={files}
        handleRemove={removeTaskFile}
        hiddenFile={hiddenFile}
        seqFile={seqFile}
        setSelectLinkImage={props.setSelectLinkImage}
        setOpenImage={props.setOpenImage}
      />
    );
  } else if (props.attachType === 'SpaceAvatar') {
    return (
      <SpaceFileAvatar
        uploadKey={uploadKey}
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        handleRemove={remove}
        className={props.className}
        file={files}
      />
    );
  } else if (props.attachType === 'TaskFile') {
    return (
      <TaskUploadFile
        uploadKey={uploadKey}
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        files={files}
        handleDownLoad={download}
        handleRemove={removeTask}
      />
    );
  } else if (props.attachType === 'CommentFile') {
    return (
      <CommentFile
        uploadKey={uploadKey}
        registerComment={props.registerComment}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        files={files}
        handleDownLoad={download}
        handleRemove={removeFile}
      />
    );
  } else if (props.attachType === 'NotifyAvatar') {
    return (
      <NotifyAvatar
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        className={props.className}
      />
    );
  }

  if (props.attachType === 'DownLoadFile') {
    return (
      <DownLoadFile
        uploadKey={uploadKey}
        register={props.register}
        viewMode={props.viewMode}
        mode={props.mode}
        seq={props.seq}
        files={files}
        handleDownLoad={download}
        handleRemove={removeFile}
        className={props.className}
      />
    );
  }

  if (props.attachType !== 'UserAvatar' || props.attachType !== 'SpaceAvatar') {
    return (
      <>
        <dl className="file-list-rf-2">
          {(props.editable || props.title != '') && (
            <dt>
              {props.title != '' && <span className="mr20">{props.title}</span>}
              <input type="hidden" name="uploadKey" value={uploadKey} />
              {/* { (props.editable) && <span hidden>{filterMessage}</span> } */}
              {props.editable && !props.viewMode && (
                <button type="button" className="upload-btn print-btn btn-rf-2">
                  Upload
                </button>
              )}
              <ul>
                {files?.map((file, index) => {
                  <li className="d-flex-2 rf-li-2">
                    {file.fileId != '' && <strong id={file.fileId}></strong>}
                    <div>
                      <a
                        href="#"
                        style={{
                          color: '#156C26'
                        }}
                        onClick={() => download(index)}
                        className={extension(file.fileNm)}
                      >
                        {file.fileNm}
                      </a>
                      <div>
                        {props.editable && file.fileId == '' && !props.viewMode && (
                          <button type="button" className="del btn-close-rf-2" onClick={() => remove(index)}>
                            X
                          </button>
                        )}
                      </div>
                    </div>
                  </li>;
                })}
              </ul>
            </dt>
          )}
        </dl>
      </>
    );
  }
};

export default AttachFile;
