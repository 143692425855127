import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteNotify,
  deleteNotifyAll,
  getNotify,
  readNewNotify,
  readNotify,
  readNotifyAll
} from '../../Apis/Notification/Notify';
import i18n from '../../translation/i18n';
import { useGlobalContext } from '../../Utils/Context.js';
import { useSidebarContext } from '../../Utils/Context/SidebarContext';
import ConvertTime from '../../Utils/ConvertTime';
import AttachFile from '../AttachFile/AttachFile.js';
import { CloseButton } from '../Buttons/ButtonComponent';

// Convert string to HTML
const StringToHTML = (props) => {
  const { string, className } = props;
  return <p className={className} dangerouslySetInnerHTML={{ __html: string }} />;
};

const Notification = (props) => {
  const {
    userInfo,
    setIdNotification,
    isSocketNoti,
    changeStatusNoti,
    changeIdEventCalendar,
    changeTypeEventCalendar
  } = useGlobalContext();
  const { popupActive, setPopupActive } = useSidebarContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [notifyId, setNotifyId] = useState(null);
  const [newNotification, setNewNotification] = useState(0);
  const [isNotificationSub, setIsNotificationSub] = useState(false);
  const [numberPages, setNumberPages] = useState(10);
  const [totalItem, setTotalItem] = useState(null);
  // Get all notifications
  const getNotifications = async () => {
    try {
      if (userInfo?.userID) {
        const data = await getNotify(userInfo?.userID, i18n?.language, numberPages);
        setTotalItem(data?.data?.body?.totalItems);
        setNotifications(data?.data?.body?.listNotify?.content?.filter((data) => !data.tempDelete));
        setNewNotification(data?.data?.body?.countNotify);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [userInfo?.userID, numberPages]);

  useEffect(() => {
    if (isSocketNoti) {
      getNotifications();
      changeStatusNoti();
    }
  }, [isSocketNoti]);

  const handleNotification = async () => {
    try {
      if (userInfo?.userID) {
        await readNewNotify(userInfo?.userID);
        getNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Mark all as read
  const updateNotificationAll = async (userId) => {
    try {
      if (userId) {
        await readNotifyAll(userId);
        getNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Clear notifications
  const deleteNotificationsAll = async (userId) => {
    try {
      let condition = 'temp';
      if (userId) {
        setIsNotificationSub(false);
        await deleteNotifyAll(userId, condition);
        await getNotifications();
        toast.success(
          <div className="flex items-center justify-around text-sm font-normal text-[#333]">
            <span>{t('notification.removed-all-notifications')}</span>
            <button
              className="cursor-pointer font-semibold hover:border-b hover:border-b-[#1294A4] hover:text-[#1294A4]"
              onClick={async () => {
                condition = 'undo';
                await deleteNotifyAll(userId, condition);
                return getNotifications();
              }}
            >
              {t('notification.undo')}
            </button>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            className: 'cursor-default',
            pauseOnHover: true,
            autoClose: 3000,
            onClose: async () => {
              if (condition === 'temp') {
                condition = 'delete';
                await deleteNotifyAll(userId, condition);
                return getNotifications();
              }
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Mark as read/unread and disable
  const updateNotification = async (type, notifyId, status) => {
    if (type === 'mark' && notifyId) {
      try {
        await readNotify(notifyId, status);
        getNotifications();
      } catch (error) {
        console.log(error);
      }
    }

    if (type === 'remove') {
      try {
        if (notifyId) {
          let condition = 'temp';
          await deleteNotify(notifyId, condition);
          await getNotifications();

          toast.success(
            <div className="flex items-center justify-around text-sm font-normal text-[#333]">
              <span>{t('notification.notification-removed')}</span>
              <button
                className="cursor-pointer font-semibold hover:border-b hover:border-b-[#1294A4] hover:text-[#1294A4]"
                onClick={async () => {
                  condition = 'undo';
                  await deleteNotify(notifyId, condition);
                  return getNotifications();
                }}
              >
                {t('notification.undo')}
              </button>
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              className: 'cursor-default',
              pauseOnHover: true,
              autoClose: 3000,
              onClose: async () => {
                if (condition === 'temp') {
                  condition = 'delete';
                  await deleteNotify(notifyId, condition);
                  return getNotifications();
                }
              }
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Optimize UI
  const [isBottom, setIsBottom] = useState(false);
  const handleNotifyScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom) {
      if (numberPages < totalItem && numberPages < 100) {
        if (numberPages + 10 <= totalItem) {
          setNumberPages(numberPages + 10);
        } else {
          setNumberPages(totalItem);
        }
      }
    }
    setIsBottom(bottom);
  };

  return (
    <div className="relative flex items-center justify-center">
      {/* Notification icon */}
      <button
        className="transition-all duration-[50ms] ease-linear active:scale-90 active:transform"
        onClick={() => {
          handleNotification();
          getNotifications();
          setIsNotificationSub(false);
          if (popupActive === 'isNotification') {
            setPopupActive('');
          } else {
            setPopupActive('isNotification');
          }
        }}
      >
        <i className="flex items-center justify-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.9974 10V13.1808C17.9974 13.6499 18.0707 14.1161 18.2146 14.5626L18.958 16.8697C18.9788 16.934 18.9308 16.9999 18.8632 16.9999H5.09375C5.02981 16.9999 4.98466 16.9351 5.00488 16.8723L5.74334 14.5808C5.89106 14.1224 5.96637 13.6424 5.96637 13.1593V10C5.96637 6.56356 8.67772 4 12 4C15.3223 4 17.9974 6.67772 17.9974 10Z"
              fill="#234EEC"
            />
            <path
              d="M14.6457 20C13.9406 20.6223 13.0144 20.9999 12 20.9999C10.9856 20.9999 10.0594 20.6223 9.35426 20M17.9974 13.1808V10C17.9974 6.67772 15.3223 4 12 4C8.67772 4 5.96637 6.56356 5.96637 10V13.1593C5.96637 13.6424 5.89106 14.1224 5.74334 14.5808L5.00488 16.8723C4.98466 16.9351 5.02981 16.9999 5.09375 16.9999H18.8632C18.9308 16.9999 18.9788 16.934 18.958 16.8697L18.2146 14.5626C18.0707 14.1161 17.9974 13.6499 17.9974 13.1808Z"
              stroke="#234EEC"
              strokeWidth="1.33333"
              strokeLinecap="round"
            />
          </svg>
        </i>
      </button>

      {/* New notification */}
      {newNotification > 0 && (
        <span
          className={`absolute flex items-center justify-center bg-neu-800 text-[10px] font-medium leading-3 text-white ${
            newNotification < 100
              ? '-right-2 -top-2 h-5 w-5 rounded-[100%]'
              : '-right-4 -top-[6px] rounded-full px-1 py-[2px]'
          }`}
        >
          {newNotification < 100 ? newNotification : '99+'}
        </span>
      )}

      {/* Popup */}
      {popupActive === 'isNotification' && (
        <ul
          className={`absolute z-50 h-auto max-h-[calc(100vh-115px)] w-[378px] rounded-xl bg-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.25)] ${props?.positionPopup}`}
        >
          {/* Title */}
          <li className="relative my-6 flex items-center justify-between px-4">
            <h4 className="text-md font-semibold text-neu-400">{t('notification.notification')}</h4>

            <CloseButton
              onClick={async () => {
                try {
                  setPopupActive('');
                  setIsNotificationSub(false);
                  await readNewNotify(userInfo?.userID);
                  getNotifications();
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          </li>

          {notifications?.length > 0 ? (
            <>
              {/* Edit Notification */}
              <li className="flex items-center justify-end gap-x-8 border-b px-4 pb-4 text-sm font-normal">
                <p
                  onClick={() => updateNotificationAll(userInfo?.userID)}
                  className="cursor-pointer whitespace-nowrap text-sm text-neu-700 hover:text-primary-100"
                >
                  {t('notification.mark-all-ass-read')}
                </p>

                <p
                  onClick={() => deleteNotificationsAll(userInfo?.userID)}
                  className="cursor-pointer whitespace-nowrap text-sm text-neu-700 hover:text-red-500"
                >
                  {t('notification.clear-all-notifications')}
                </p>
              </li>

              {/* Notification list */}
              <li
                // ref={containerRef}
                className={`scroll-y h-full max-h-[calc(100vh-250px)] overflow-y-auto bg-white ${
                  isBottom ? 'mb-4' : 'rounded-b-xl'
                }`}
                onScroll={handleNotifyScroll}
              >
                <ul className="notifications-list">
                  {notifications.map((data, index) => {
                    const hasProjectManagement = data?.link?.includes('project-management');
                    const modifiedPath = hasProjectManagement && data?.link?.replace(/\/\d+$/, '');
                    const pathParts = data?.link?.split('/');
                    let lastNumber;
                    if (pathParts?.length) {
                      lastNumber = pathParts[pathParts?.length - 1];
                    } else {
                      lastNumber = 0;
                    }
                    const test = parseInt(lastNumber);
                    return (
                      <li key={index}>
                        {!data?.tempDelete && (
                          <div
                            className={`flex items-center justify-between gap-x-2 border-b px-4 hover:bg-gray-50 ${
                              data?.status && ''
                            }`}
                          >
                            <div
                              onClick={() => {
                                // humebt
                                if (data?.link === '/humebt') {
                                  updateNotification('mark', data?.notifyId, true);
                                  return;
                                }
                                if (pathParts.includes('calendar')) {
                                  changeIdEventCalendar(pathParts[1]);
                                  changeTypeEventCalendar(pathParts[2]);
                                  navigate('/');
                                } else {
                                  navigate(hasProjectManagement ? modifiedPath : data?.link);
                                }
                                setPopupActive('');
                                setIsNotificationSub(false);
                                updateNotification('mark', data?.notifyId, true);
                                setIdNotification(test);
                              }}
                              className="flex cursor-pointer items-center gap-3 py-4"
                            >
                              <AttachFile
                                attachType="NotifyAvatar"
                                entity="users"
                                seq={data?.avatarId}
                                viewMode={true}
                                mode={'list' + index}
                                className="h-8 w-8 rounded-full object-cover"
                              />
                              <div
                                className={`mb-1 w-full text-sm ${
                                  data?.status ? 'font-light text-neu-200' : 'font-normal'
                                }`}
                              >
                                {data?.notifyContentVI && (
                                  <StringToHTML string={data?.notifyContentVI} className="notify-title mb-1" />
                                )}

                                {data?.notifyContentEN && (
                                  <StringToHTML string={data?.notifyContentEN} className="notify-title mb-1" />
                                )}
                                <p
                                  className={`text-xs  ${
                                    data?.status ? 'font-normal text-neu-200' : 'font-medium text-primary-100'
                                  }`}
                                >
                                  <ConvertTime
                                    timeInMileSec={new Date().getTime() - new Date(data?.createdAt).getTime()}
                                  />
                                </p>
                              </div>
                            </div>
                            <div
                              className="group relative flex cursor-pointer items-center justify-center transition-all duration-100 ease-linear active:scale-90 active:transform"
                              onClick={() => {
                                setNotifyId(data?.notifyId);
                                if (notifyId === data?.notifyId) {
                                  setIsNotificationSub(!isNotificationSub);
                                } else {
                                  setIsNotificationSub(true);
                                }
                              }}
                            >
                              <i
                                className={`flex items-center justify-center rounded-[100%] stroke-neu-700 p-2 ${
                                  isNotificationSub && notifyId === data?.notifyId ? 'bg-neu-500' : 'hover:bg-neu-500'
                                }`}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.2813 8.71857L9.2813 9.28107M8.7188 8.71857V9.28107M9.2813 3.46857L9.2813 4.03107M8.7188 3.46857V4.03107M9.2813 13.9686L9.2813 14.5311M8.7188 13.9686V14.5311M9 9.75C8.58579 9.75 8.25 9.41421 8.25 9C8.25 8.58579 8.58579 8.25 9 8.25C9.41421 8.25 9.75 8.58579 9.75 9C9.75 9.41421 9.41421 9.75 9 9.75ZM9 4.5C8.58579 4.5 8.25 4.16421 8.25 3.75C8.25 3.33579 8.58579 3 9 3C9.41421 3 9.75 3.33579 9.75 3.75C9.75 4.16421 9.41421 4.5 9 4.5ZM9 15C8.58579 15 8.25 14.6642 8.25 14.25C8.25 13.8358 8.58579 13.5 9 13.5C9.41421 13.5 9.75 13.8358 9.75 14.25C9.75 14.6642 9.41421 15 9 15Z"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </i>

                              {isNotificationSub && notifyId === data?.notifyId && (
                                <ul className="absolute -top-3 right-[calc(100%+8px)] whitespace-nowrap rounded-md bg-white text-xs font-normal shadow-[0px_6px_58px_rgba(121,145,173,0.2)]">
                                  {/* Mark as read or unread */}
                                  <li
                                    className="cursor-pointer p-2 hover:text-primary-100"
                                    onClick={() => {
                                      if (data?.status) {
                                        updateNotification('mark', data?.notifyId, false);
                                      } else {
                                        updateNotification('mark', data?.notifyId, true);
                                      }
                                    }}
                                  >
                                    {data?.status ? t('notification.mark-as-unread') : t('notification.mark-as-read')}
                                  </li>

                                  {/* Remote this notification */}
                                  <li
                                    className="cursor-pointer p-2 hover:text-red-500"
                                    onClick={() => {
                                      updateNotification('remove', data?.notifyId);
                                    }}
                                  >
                                    {t('notification.remove-this-notifications')}
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            </>
          ) : (
            <li className="pb-6 text-center text-sm">{t('notification.you-have-no-notifications')}</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default Notification;
