import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import IconV2 from '../../../../Components-v2/Common/Icon';

const CompanyRegulationsSidebar = ({ isIcon, isContent, className }) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Link to={'company-regulations'} className={className}>
      <IconV2
        iconName="book"
        className={`h-5 w-5  ${
          location?.pathname === '/company-regulations'
            ? `${isIcon && isContent ? 'stroke-white' : 'stroke-primary-100'}`
            : 'stroke-black'
        }`}
      />

      {isContent && <p className={`${isIcon && isContent && 'min-w-[150px]'}`}> {t('sidebar.company-regulations')} </p>}

      {!isContent && (
        <div className="absolute left-12 hidden rounded-lg bg-neu-500 p-2 group-hover:block">
          <p className="whitespace-nowrap text-xs text-black">{t('sidebar.company-regulations')}</p>
        </div>
      )}
    </Link>
  );
};

export default CompanyRegulationsSidebar;
