// import ScaleLoader from 'react-spinners/FadeLoader';

const Loading = ({ className }) => {
  return (
    <div
      className={`flex h-full ${className ? className : 'min-h-[100vh]'} w-full flex-col items-center justify-center`}
    >
      <span className="loaders"></span>
    </div>
  );
};

export default Loading;
