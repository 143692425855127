import instance from '../instance';

const background = {
  backGroundImage: () => {
    const url = `/projBoardBackground/defaultImgList`;
    return instance.get(url);
  },
  backGroundColor: () => {
    const url = `/projBoardBackground/defaultColorList`;
    return instance.get(url);
  },
  backGroundColorNew: (boardId) => {
    // Lấy ra danh sách các màu được thêm mới
    const url = `/projBoardBackground/customBgColorList?projBoardId=${boardId}`;
    return instance.get(url);
  },
  createBackGroundColorNew: (data) => {
    // Tạo mã màu cover mới trong bảng Kanban
    const url = `/projBoardBackground/saveBackgroundColor`;
    return instance.post(url, data);
  },
  getBackGroundUpload: (boardId) => {
    const url = `/projBoardBackground/getAllUploadImage/${boardId}?page=0&size=10`;
    return instance.get(url);
  },
  deleteBackGroundImage: (boardBackgroundId) => {
    const url = `projBoardBackground/delete/${boardBackgroundId}`;
    return instance.delete(url);
  },
  saveFileLink: (data) => {
    const url = `/projBoardTaskFileLink/saveFileLink`;
    return instance.post(url, data);
  },
  getAllByTaskId: (taskId) => {
    const url = `projBoardTaskFileLink/getAllByTaskId/${taskId}`;
    return instance.get(url);
  },
  getAllBoardTaskImagesAndLinks: (projBoardTaskId, numberFile) => {
    const url = `projBoardTask/getAllBoardTaskImagesAndLinks/${projBoardTaskId}?page=0&size=${numberFile}`;
    return instance.get(url);
  },
  // getAllFileImage: (taskId) => {
  // *
  //   const url = `/listImage/${taskId}`;
  //   return instance.get(url);
  // },
  deleteBoardTaskFile: (fileSeq, boardTaskId) => {
    const url = `file/deleteBoardTaskFile/${fileSeq}?boardTaskId=${boardTaskId}`;
    return instance.delete(url);
  },
  deleteFileLink: (fileLinkId, boardTaskId) => {
    const url = `projBoardTaskFileLink/deleteFileLink/${fileLinkId}?boardTaskId=${boardTaskId}`;
    return instance.delete(url);
  },
  updateBoardTaskCover: (data) => {
    const url = `projBoardTask/updateBoardTaskCover`;
    return instance.post(url, data);
  },
  getProjBoardLabel: (numberDisplay, projBoardId, title, projBoardTaskId) => {
    const url = `projBoardLabel/getBoardLabelByBoardIdUsePaging?page=0&size=${numberDisplay}&projBoardId=${projBoardId}${
      title === '' ? '' : `&title=${title}`
    }${projBoardTaskId ? `&projBoardTaskId=${projBoardTaskId}` : ''}`;
    return instance.get(url);
  },
  getAllBoardColorCode: (projBoardId) => {
    const url = `projBoardLabel/getAllBoardColorCode/${projBoardId}`;
    return instance.get(url);
  },
  saveProjBoardLabel: (data) => {
    const url = `projBoardLabel/saveProjBoardLabel`;
    return instance.post(url, data);
  },
  deleteProjBoardLabel: (projBoardLabelId) => {
    const url = `projBoardLabel/deleteProjBoardLabel/${projBoardLabelId}`;
    return instance.delete(url);
  },
  addLabelToBoardTask: (data) => {
    const url = `projBoardTaskLabel/addLabelToBoardTask`;
    return instance.post(url, data);
  },
  getTaskById: (boardTaskId) => {
    const url = `projBoardTask/getTaskById/${boardTaskId}`;
    return instance.get(url);
  },
  removeTaskLabel: (boardTaskLabelId) => {
    const url = `projBoardTaskLabel/removeTaskLabel/${boardTaskLabelId}`;
    return instance.delete(url);
  }
};
export default background;
