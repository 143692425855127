import Icon from '../Icon';

const Invalid = ({
  isInvalid,
  message,
  className = 'mt-1 flex items-center gap-x-1 text-sm font-normal text-neu-800',
  iconClassName = 'h-4 w-4 stroke-neu-800'
}) => {
  if (isInvalid) {
    return (
      <div className={className}>
        <Icon name="alert" className={iconClassName} />

        <span>{message}</span>
      </div>
    );
  }
};

export default Invalid;
