import { useRef, useState } from 'react';
import Icon from '../../../../Common/Icon';

import { useTranslation } from 'react-i18next';
import projectBoard from '../../../../../Apis/ProjectManagement/TaskProgress/board';
import { toDateCalendar } from '../../../../Calendar/CalendarComponent';
import useCloseModalOnOutsideClick from '../../../../OnOutsideClick/OnOutsideClick';
import PopupTime from './PopupTime';
const TaskDeadline = ({ detailTask, setDetailTask, column, setIsCallBack, isCallBack, projectInfo }) => {
  const { t } = useTranslation();
  let currentDate = new Date();
  let targetDate = new Date(detailTask?.endDate);
  const [editTaskDate, setEditTaskDate] = useState(false);
  const boardRef = useRef(null);
  useCloseModalOnOutsideClick(boardRef, setEditTaskDate);
  // Update status thời gian hoàn thành hay khoong hoàn thành
  const handleUpdateStatus = async () => {
    try {
      const statusDate = detailTask?.status === 1 ? 0 : 1;
      const flagStatus = detailTask?.status === 1 ? 'unMarkEndDate' : 'markEndDate';
      await projectBoard.updateSttTask(detailTask?.projBoardTaskId, statusDate, flagStatus);
      setDetailTask({
        ...detailTask,
        status: statusDate
      });
      setIsCallBack(!isCallBack);
    } catch (error) {
      console.log(t('project.an-error-occurred'));
    }
  };

  return (
    <div>
      <div className="mb-1">
        <div className="mb-1 flex gap-1">
          {/* <Icon name="calendar" className="h-[18px] w-[18px] stroke-black" /> */}
          <p className="text-[13px] font-normal text-[#172b4d]">{t('job.end-date')}</p>
        </div>

        <div className="relative ml-1 flex gap-[5px] ">
          <input
            type="checkbox"
            disabled={projectInfo?.roleProject === 3}
            defaultChecked={detailTask?.status === 1 ? true : false}
            className="w-[15px]"
            onChange={() => handleUpdateStatus()}
          />

          <div
            className="flex cursor-pointer gap-1 rounded-[5px] bg-[#F7F8FC] pr-2 "
            onClick={() => {
              if (projectInfo?.roleProject !== 3) {
                setEditTaskDate(true);
              }
            }}
          >
            <p className=" w-[165px] rounded-lg px-3  py-1.5  text-sm font-semibold text-[#172b4d]  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none">
              {toDateCalendar(detailTask?.endDate)}
            </p>

            {detailTask?.status === 1 && (
              <div className="  ">
                <p className="mr-2 mt-2 rounded-md bg-emerald-700 px-2 text-xs font-medium text-white">
                  {t('job.done')}
                </p>
              </div>
            )}
            {currentDate > targetDate && detailTask?.status === 0 && (
              <div className=" ">
                <p className="mr-2 mt-2 rounded-md bg-red-700 px-2 text-xs font-medium text-white">
                  {t('job.overdue')}
                </p>
              </div>
            )}
            <Icon name="calendar" className="h-[18px] w-[19px] stroke-black pt-3" />
          </div>

          {/* Popup update thời gian */}
          {editTaskDate && (
            <div
              className="absolute top-10 left-6 z-30 rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
              ref={boardRef}
            >
              <PopupTime
                setDetailTask={setDetailTask}
                detailTask={detailTask}
                column={column}
                setEditTaskDate={setEditTaskDate}
                setIsCallBack={setIsCallBack}
                isCallBack={isCallBack}
              />
            </div>
          )}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default TaskDeadline;
