import { Link, useNavigate } from 'react-router-dom';
import Icon from '../Common/Icon';

/**
 * Component Breadcrumb.
 *
 * @component
 *
 * @param {Array} data - The component data.
 * @param {string} data.name - The name of the Breadcrumb.
 * @param {string} [data.link] - Additional class name for styling.
 * @returns {JSX.Element} - The rendered Breadcrumb component.
 */
const BreadcrumbProject = ({ data = [] }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-2 border-b  py-4 text-neu-700">
      {/* Project home */}
      <Icon
        name="home_2_alt"
        onClick={() => navigate('/project-management')}
        className="cursor-pointer stroke-neu-700 hover:stroke-primary-100"
      />

      {data?.length &&
        data?.map((item, index) => {
          return (
            <Link key={index} to={item?.link} className="group flex items-center gap-x-2 text-sm font-normal">
              <p>{'>'}</p>
              <p className="group-last:text-primary-100">{item?.name}</p>
            </Link>
          );
        })}
    </div>
  );
};

export default BreadcrumbProject;
