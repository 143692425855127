import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import ConfirmDel from '../../../Assets/Images/Icons/confirm-delete-ic.svg';
import LinkFileIc from '../../../Assets/Images/Icons/link-file-ic.svg';
import { customDeleteCalendar } from '../../../Utils/CustomCssModals/CalendarCustom';

const CommentFile = (props) => {
  const [isConfirm, setConfirm] = useState(false);
  const [curentIndex, setCurentIndex] = useState(null);
  const [typeConfirm, setTypeConfirm] = useState();
  const { t } = useTranslation();
  const onHandleDelete = (index) => {
    setConfirm(true);
    setCurentIndex(index);
    setTypeConfirm({
      value: 0,
      type: 'file'
    });
  };
  const closeConfirm = () => {
    setConfirm(false);
  };

  useEffect(() => {}, []);
  return (
    <>
      {isConfirm && (
        <Modal
          isOpen={isConfirm}
          onRequestClose={closeConfirm}
          style={customDeleteCalendar}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          {typeConfirm?.value === 0 && <img className="mt-8" src={ConfirmDel} alt="" />}

          <div>
            <h3 className="my-4 text-center text-3xl font-[600]">
              {t('job.confirm')}
              {typeConfirm?.value === 0 && ` xóa ${typeConfirm?.type} `}
            </h3>
            <div className="mt-2 text-center">
              <span className="block">
                {t('department.you-have-deleted')} {typeConfirm?.type}
              </span>
              <span>{t('leave-management.no-trrestore-once-deleted')}</span>
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <button
              onClick={() => closeConfirm()}
              className={`mr-2 w-[120px] cursor-pointer rounded-xl border bg-[#DDDDDD] py-2 text-[#868686]`}
            >
              <span className="py-3 font-medium">{t('space.cancel')}</span>
            </button>
            <button
              onClick={() => {
                props.handleRemove(curentIndex);
                setConfirm(false);
              }}
              className={`ml-2 w-[120px] cursor-pointer rounded-xl border bg-[#1294A4]  py-2 text-white`}
            >
              <span className="py-3 font-medium">{t('job.confirm')}</span>
            </button>
          </div>
        </Modal>
      )}
      {props.viewMode ? (
        <>
          {props.mode === 'view' && (
            <ul className="">
              {props?.files?.map((item, index) => {
                return (
                  <li key={index} className="m-1 flex h-[50px] items-center rounded-lg border bg-white p-2 text-[14px]">
                    <img
                      onClick={() => props?.handleDownLoad(index)}
                      className="mr-2 w-[20px]"
                      src={LinkFileIc}
                      alt=""
                    />
                    <div className="">
                      <p className="w-[170px] truncate font-bold">{item?.fileNm}</p>
                      <p className="text-[12px]">{item?.createdAt}</p>
                    </div>
                    <span
                      onClick={() => onHandleDelete(index)}
                      className="ml-2 flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full bg-red-500 pb-[2px] text-white"
                    >
                      x
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
          {props.mode === 'viewReply' && (
            <ul className="">
              {props?.files?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="m-1 ml-24 flex h-[50px] items-center rounded-lg border bg-white p-2 text-[14px]"
                  >
                    <img
                      onClick={() => props?.handleDownLoad(index)}
                      className="mr-2 w-[20px]"
                      src={LinkFileIc}
                      alt=""
                    />
                    <div className="">
                      <p className="w-[132px] truncate font-bold">{item?.fileNm}</p>
                      <p className="w-[132px] truncate text-[12px]">{item?.createdAt}</p>
                    </div>
                    <span
                      onClick={() => onHandleDelete(index)}
                      className="ml-2 flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full bg-red-500 pb-[2px] text-white"
                    >
                      x
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
          {props.mode === 'viewCmt' && (
            <>
              {props?.files?.map((item, index) => {
                return (
                  <div key={index}>
                    <span
                      onClick={() => props.handleRemove(index)}
                      className="rounded-full border-red-200 p-4 text-red-200"
                    >
                      x
                    </span>
                    <span key={index}>{item?.fileNm}</span>
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <div>
          <img className="upload-btn h-[20px] w-[20px]" src={LinkFileIc} alt="" />
          <div className="">
            {props.uploadKey !== '' && (
              <input type="hidden" {...props.registerComment('uploadKey', { value: props.uploadKey })} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default CommentFile;
