export const handleCheckSpaces = (text) => {
  if (text === '') {
    // Content is empty
    return true;
  } else if (/^\s+$/.test(text)) {
    // Content consists of only spaces
    return true;
  } else {
    // Content has non-space characters
    return false;
  }
};

export const handleCheckTagName = (delta) => {
  // eslint-disable-next-line no-unused-vars
  let index = 0;
  let searchName = '';

  if (delta?.ops[0]?.insert === '@') {
    searchName = 'all';
    return searchName;
  }
};
