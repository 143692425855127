import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import requestClient from '../../../Apis/requestClient';
import CreateMember from '../../../Pages/Project/ProjectManagement/ProjectCreate/CreateMember';
import { useGlobalContext } from '../../../Utils/Context';
import { Button } from '../../Buttons/ButtonComponent';
import BreadcrumbProject from '../BreadcrumbProject';

const AddMember = () => {
  const { t } = useTranslation();

  const { userInfo } = useGlobalContext();
  const { projectId } = useParams();
  const typeProject = JSON.parse(localStorage.getItem('project'))?.type;
  const navigate = useNavigate();
  const [listMember, setListMember] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [openCloseMember, setOpenCloseMember] = useState(true);

  const updatedUserList = listMember.map((user) => {
    // eslint-disable-next-line no-unused-vars
    const { fullName, userName, ...restUser } = user;
    return {
      ...restUser,
      addBy: userInfo.userID,
      roleNumber: user.roleNumber,
      positionDTOList: user.positionDTOList,
      expirationDate: user.expirationDate
    };
  });
  const [waitAddproject, setWaitAddproject] = useState(true);
  const handleSubmit = async () => {
    setWaitAddproject(false);
    try {
      const body = {
        projectId: projectId,
        memberDTOList: updatedUserList
      };
      await requestClient({ endPoint: `/member/addMember`, method: 'post', body: body });
      navigate(-1);
      toast.success(t('space.successfully-added-members'), {
        position: 'top-right'
      });
      setWaitAddproject(true);
    } catch (error) {
      setWaitAddproject(true);
      toast.error(t('project.the-expiration'), {
        position: 'top-right'
      });
      console.log(error);
    }
  };

  return (
    <div className="h-[100vh]  px-4">
      {waitAddproject === false && (
        <div className="flex h-full flex-col items-center justify-center gap-3">
          <div>
            <span className="loaders"></span>
          </div>
          <div>
            <p>{t('project.adding-members')} ...</p>
          </div>
        </div>
      )}
      <div className={`${waitAddproject ? '' : 'hidden'}`}>
        <BreadcrumbProject
          data={[
            { name: t('project.member'), link: -1 },
            { name: t('department.more-members'), link: '' }
          ]}
        />
        {/* Top */}
        <div className="flex justify-between py-4">
          <div>
            <h2 className="text-xl font-semibold text-neu-400">{t('department.more-members')} </h2>
            <p className="text-neu-200">{t('project.members-participate')}</p>
          </div>
        </div>
        <div className={`-mx-6`}>
          <CreateMember
            projectId={projectId}
            listMember={listMember}
            typeProject={typeProject}
            setListMember={setListMember}
            openCloseMember={openCloseMember}
          />
        </div>

        <div className="absolute bottom-0 right-10   mb-4  flex justify-end gap-6 ">
          <Button
            content={t('project.cancel')}
            onClick={() => navigate(-1)}
            className="min-w-[120px] rounded-md border border-primary-100 bg-white px-3 py-2 text-primary-100"
          />

          {listMember?.length ? (
            <Button
              content={t('project.confirm')}
              onClick={() => handleSubmit()}
              className="min-w-[120px] cursor-pointer rounded-lg border bg-primary-100 py-1.5 text-center text-white hover:bg-primary-100"
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default AddMember;
