import { useLocation } from 'react-router-dom';
import { useSidebarContext } from '../../Utils/Context/SidebarContext';
import FullSidebar from '../Sidebars/FullSidebar';
import MiniSidebar from '../Sidebars/MiniSidebar';

const Sidebar = () => {
  const { isFullSidebar, setIsFullSidebar, setPopupActive } = useSidebarContext();
  const location = useLocation();

  return (
    <aside
      id="sidebar"
      aria-label="Sidebar"
      className="relative flex h-full min-h-[100vh] flex-shrink-0 flex-col  duration-200"
    >
      {location?.pathname?.indexOf('project-management') !== 1 && (
        <i
          onClick={() => {
            setPopupActive('');
            setIsFullSidebar(!isFullSidebar);
          }}
          className={`absolute -right-4 top-[50%] z-40 flex -translate-y-[50%] cursor-pointer items-center justify-center rounded-[100%] stroke-[#9C9C9C] p-1 transition-all duration-150 ease-out hover:border hover:border-primary-100 hover:bg-neu-100 hover:stroke-primary-100 active:scale-90 active:transform ${
            isFullSidebar ? 'hover:-right-3' : 'rotate-180 hover:-right-5'
          }`}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.16699 15L4.75625 10.5893C4.43081 10.2638 4.43081 9.73618 4.75625 9.41074L9.16699 5M14.167 15L9.75625 10.5893C9.43081 10.2638 9.43081 9.73618 9.75625 9.41074L14.167 5"
              strokeLinecap="round"
            />
          </svg>
        </i>
      )}

      {location?.pathname?.indexOf('project-management') !== 1 ? (
        isFullSidebar ? (
          <FullSidebar />
        ) : (
          <MiniSidebar />
        )
      ) : (
        <MiniSidebar />
      )}
    </aside>
  );
};

export default Sidebar;
