import { useRef, useState } from 'react';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import background from '../../../../../Apis/ProjectManagement/background';
import WarningIc from '../../../../../Assets/Images/Icons/warning-ic.svg';
import { useBoardProjectContext } from '../../../../../Utils/Context/ProjectManagement/BoardProjectContext';
import labelColor from '../../../../Common/Color';
import Icon from '../../../../Common/Icon';
import validateNoLeadingSpace from '../../../../Common/Validate';
import useCloseModalOnOutsideClick from '../../../../OnOutsideClick/OnOutsideClick';

// eslint-disable-next-line
const EditTaskLable = ({ setIsTaskLabel, column, setIsEditLabel, editLable, setDetailTask, detailTask }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [color, setColor] = useState(editLable.colorCode);
  const { valueToHex } = useColorPicker(color, setColor);
  const hexString = valueToHex();
  const inputRef = useRef(null);
  const { callBackLable, setCallBackLable } = useBoardProjectContext();
  const { callBackTask, setCallBackTask } = useBoardProjectContext();
  const { t } = useTranslation();

  const [removeLable, setRemoveLable] = useState(false);
  const boardRef = useRef(null);
  useCloseModalOnOutsideClick(boardRef, setRemoveLable);
  // Sửa nhãn dán
  const onSubmitEdit = async (data) => {
    try {
      const newData = {
        projBoardLabelId: editLable.projBoardLabelId,
        title: data.title,
        colorCode: hexString,
        projBoardId: column.projBoardId
      };
      await background.saveProjBoardLabel(newData);
      // Kiểm tra nếu trùng id thì sửa màu hoặc tên nhãn dán
      const updatedData = detailTask?.projBoardLabelDTOS?.map((item) => {
        if (item.projBoardLabelId === editLable.projBoardLabelId) {
          return { ...item, title: data.title, colorCode: hexString };
        }
        return item;
      });
      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardLabelDTOS: updatedData
      }));
      setIsEditLabel(false);
      setCallBackTask(!callBackTask);
      setCallBackLable(!callBackLable);
      toast.success(t('project.successfully-edited-label'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.label-already-exists'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  // Xóa nhãn dán
  const handleDelete = async (projBoardLabelId) => {
    try {
      await background.deleteProjBoardLabel(projBoardLabelId);
      setIsEditLabel(false);
      setCallBackLable(!callBackLable);
      setCallBackTask(!callBackTask);
      const updatedData = detailTask?.projBoardLabelDTOS?.filter((item) => item.projBoardLabelId !== projBoardLabelId);
      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardLabelDTOS: updatedData
      }));
      toast.success(t('project.sticker-removed-successfully'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmitEdit)}
        className="h-auto w-[340px] rounded-xl border p-6 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.12)]"
      >
        <div className="mb-2 flex justify-between">
          <Icon
            name="arrow_left"
            className="cursor-pointer rounded-lg stroke-[#111111] p-1  hover:bg-gray-100"
            onClick={() => setIsEditLabel(false)}
          />
          <p className="text-center text-lg font-semibold">{t('project.edit-stickers')}</p>
          <p onClick={() => setIsTaskLabel(false)} className="cursor-pointer">
            <Icon name="close_cross" className="rounded-lg stroke-[#111111] p-1  hover:bg-gray-100" />
          </p>
        </div>
        <div>
          <div className="">
            <div>
              <p className="my-1 text-sm font-semibold">{t('project.selected-color')}</p>
            </div>
            <div className="color-picker">
              <ColorPicker height={200} hideControls={true} hideOpacity={true} value={color} onChange={setColor} />
            </div>
          </div>
          <div className="my-6 border-t"></div>
          {/* Tiêu đề nhãn dán */}
          <div>
            <p className="text-sm font-semibold">{t('project.label-title')}</p>
            <input
              type="text"
              style={{ backgroundColor: hexString }}
              className="mt-2 w-full rounded-lg border border-[#F1F3FD] bg-white py-1.5 px-3 text-sm font-medium shadow-[0px_0px_20px_0px_rgba(0,0,0,0.12)] placeholder:text-sm placeholder:font-normal focus:border-[#234EEC]  focus:outline-none"
              placeholder={t('project.enter-label-title')}
              ref={inputRef}
              defaultValue={editLable.title}
              {...register('title', {
                required: t('account-management.no-be-eft-blank'),
                maxLength: { value: 20, message: t('account-management.cannot-be-longer-than-20-characters') },
                validate: validateNoLeadingSpace
              })}
            />
            {errors.title && (
              <p className="flex gap-1 px-2 pt-1">
                <img src={WarningIc} alt="warning-icon" />
                <span className="text-xs font-normal text-red-500">{errors.title.message}</span>
              </p>
            )}
          </div>
          <div className="my-6 border-t"></div>
          {/* Chọn màu nhãn dán */}
          <div>
            <p className="mb-3 text-sm font-semibold">{t('project.select-color')}</p>
            <div className="grid grid-cols-4 grid-rows-4 gap-3">
              {labelColor?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`h-8 cursor-pointer rounded-md outline-primary-100 ${
                      hexString === item.colorCode ? 'outline outline-2 outline-offset-2' : ''
                    } `}
                    style={{ backgroundColor: item.colorCode }}
                    onClick={() => {
                      setColor(item.colorCode);
                    }}
                  ></div>
                );
              })}
            </div>
          </div>
          <div className="my-6 border-t"></div>
        </div>
        {/* Xóa nhãn dán */}
        <div className=" ">
          <p className="mb-3 text-sm font-semibold">{t('project.operation')}</p>
          <div className=" relative">
            <div
              className=" flex w-[150px] cursor-pointer justify-center gap-2 rounded-md border bg-[#D64A4040] p-1.5"
              onClick={() => setRemoveLable(true)}
            >
              <Icon name="trash_alt" className="fill-red-500" />
              <p className="text-sm text-red-500">{t('project.delete-label')}</p>
            </div>

            {removeLable && (
              <div
                className="absolute bottom-0 z-30 mt-3 w-full rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                ref={boardRef}
              >
                <div className="flex flex-col items-center gap-y-5 p-4">
                  {/* <Icon name="trash_can_1" /> */}

                  <div className="text-center">
                    <p className="text-base font-bold text-neu-400">{`${t('project.delete-label')} ${
                      editLable.title
                    }`}</p>
                    <p className="mt-1 text-sm font-normal text-neu-200">{t('project.this-action-will')}</p>
                  </div>

                  <div className="flex justify-center gap-x-10">
                    <div
                      onClick={() => setRemoveLable(false)}
                      className="min-w-[82px] cursor-pointer rounded-lg border border-neu-800 py-1.5 px-3 text-center text-sm text-neu-800"
                    >
                      {t('project.cancel')}
                    </div>

                    <div
                      onClick={() => handleDelete(editLable.projBoardLabelId)}
                      className={` min-w-[82px] cursor-pointer rounded-lg bg-neu-800   py-1.5 px-3 text-sm text-neu-300`}
                    >
                      {t('project.confirm')}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Lưu hoặc hủy nhãn dán */}
        <div className="mt-6 flex justify-evenly border-t pt-6">
          <div
            className={`min-w-[100px] cursor-pointer rounded-lg border border-black py-1.5 px-4 text-center text-sm text-black`}
            onClick={() => setIsEditLabel(false)}
          >
            {t('project.cancel')}
          </div>
          <button className={`min-w-[100px] rounded-lg border bg-primary-100 py-1.5 px-4 text-sm text-neu-300`}>
            {t('account-management.save')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTaskLable;
