import 'animate.css';
import 'rc-pagination/assets/index.css';
import * as React from 'react';
import 'react-calendar/dist/Calendar.css';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'sonner';
import LayoutProjectManagement from './Components/Layouts/LayoutProjectManagement';
import Loading from './Components/Layouts/Loading';
import MainLayout from './Components/Layouts/MainLayout';
import PrivateRoute from './Components/Layouts/PrivateRoute';
import ProtectedRoute from './Components/Layouts/ProtectedRoute';
import UserInfomation from './Components/Modal/Users/UserInfomation';
import CardDetailPopup from './Components/ProjectInformation/Board/Card/CardDetailPopup';
import AddMember from './Components/ProjectInformation/Member/AddMember';
import Member from './Components/ProjectInformation/Member/Member';
import ProjectRole from './Components/ProjectInformation/ProjectRole/ProjectRole';
import { Setting } from './Components/Sidebars/Icon';
import ProjectCreate from './Pages/Project/ProjectManagement/ProjectCreate/ProjectCreate';
import ProjectList from './Pages/Project/ProjectManagement/ProjectList';
import ProjectManagement from './Pages/Project/ProjectManagement/ProjectManagement';
import FormUserStory from './Pages/Project/TaskProgress/FormTaskProgress/FormUserStory';
import ReportDetailDraft from './Pages/Reports/components/ReportDetailDraft';
import ReportDetailReceived from './Pages/Reports/components/ReportDetailReceived';
import ReportDetailSended from './Pages/Reports/components/ReportDetailSended';
import { ContextBoardProject } from './Utils/Context/ProjectManagement/BoardProjectContext';
import { ContextTaskProgress } from './Utils/Context/ProjectManagement/TaskProgress';
import { SidebarContext } from './Utils/Context/SidebarContext';
import { WorkspaceContext } from './Utils/Context/WorkspaceContext';

const CalendarPage = React.lazy(() => import('./Pages/Calenders/CalendarPage'));
const TimeOffManager = React.lazy(() => import('./Pages/Calenders/TimeOffManager'));
const UserManagement = React.lazy(() => import('./Pages/Users/UserManagement'));
const DeptManagementPage = React.lazy(() => import('./Pages/Department/DeptManagementPage'));
const HRManagement = React.lazy(() => import('./Pages/HumanResource/HRManagement'));
const AcceptPage = React.lazy(() => import('./Pages/Accepts/AcceptPage'));
const CheckIn = React.lazy(() => import('./Pages/CheckIn/CheckIn'));
const Login = React.lazy(() => import('./Pages/Auth/Login'));
const Report = React.lazy(() => import('./Pages/Reports/Report'));
const CreateReport = React.lazy(() => import('./Pages/Reports/components/CreateReport'));

// v1.0.3
const Feature = React.lazy(() => import('./Pages/Feature'));

// v1.1.0
const Workspace = React.lazy(() => import('./Pages/Workspace/Workspace'));
const SpaceDefault = React.lazy(() => import('./Pages/Workspace/SpaceDefault'));

// Phase 2
const ProjectInformation = React.lazy(() => import('./Pages/Project/ProjectInformation'));
const GeneralInformation = React.lazy(() =>
  import('./Components/ProjectInformation/GeneralInformation/GeneralInformation')
);
const BoardList = React.lazy(() => import('./Components/ProjectInformation/Board/BoardsList'));
const BoardDetail = React.lazy(() => import('./Components/ProjectInformation/Board/BoardDetail'));

// Modules-v2
const CompanyRegulations = React.lazy(() => import('./Modules-v2/Company-regulations/pages/CompanyRegulations'));

const App = () => {
  return (
    <>
      <UserInfomation />
      <SidebarContext>
        <Routes>
          <Route
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            <Route
              path={'/'}
              element={
                <React.Suspense fallback={<Loading />}>
                  <CalendarPage />
                </React.Suspense>
              }
            />

            <Route
              path={'time-off'}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TimeOffManager />
                </React.Suspense>
              }
            />

            <Route
              path={'user'}
              element={
                <ProtectedRoute accessRole={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES']}>
                  <React.Suspense fallback={<Loading />}>
                    <UserManagement />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />

            <Route
              path="department"
              element={
                <React.Suspense fallback={<Loading />}>
                  <DeptManagementPage />
                </React.Suspense>
              }
            />

            <Route
              path="hr-management"
              element={
                <ProtectedRoute accessRole={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES']}>
                  <React.Suspense fallback={<Loading />}>
                    <HRManagement />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route path="project-management" element={<ProjectManagement />}>
              <Route
                index
                element={
                  <React.Suspense fallback={<Loading />}>
                    <ProjectList />
                  </React.Suspense>
                }
              />
              <Route
                path="add-project"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <ProjectCreate />
                  </React.Suspense>
                }
              />
            </Route>

            <Route
              path="project-management/:projectId"
              element={
                <React.Suspense fallback={<Loading />}>
                  <ProjectInformation />
                </React.Suspense>
              }
            >
              {/* Thông tin chung */}
              <Route index element={<GeneralInformation />} />

              {/* Thành viên */}
              <Route path="member" element={<Member />} />

              {/* Thêm thành viên */}
              <Route path="member/add-member" element={<AddMember />} />

              {/* Vị trí */}
              <Route path="project-role" element={<ProjectRole />} />

              {/* Danh sách bảng */}
              <Route
                path="board"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <BoardList />
                  </React.Suspense>
                }
              />

              {/* Chi tiết bảng */}
              <Route
                path="board/:boardId"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <ContextBoardProject>
                      <BoardDetail />
                    </ContextBoardProject>
                  </React.Suspense>
                }
              />
              <Route
                path="board/:boardId/:taskId"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <CardDetailPopup />
                  </React.Suspense>
                }
              />

              {/* Tiến độ tác vụ */}
              <Route
                path="task-progress"
                element={
                  <ContextTaskProgress>
                    <LayoutProjectManagement />
                  </ContextTaskProgress>
                }
              >
                <Route path="form" element={<FormUserStory />} />
              </Route>

              {/* Thông tin chung */}
              <Route path="general-information" element={<GeneralInformation />} />

              {/*Cài đặt */}
              <Route path="setting" element={<Setting />} />
            </Route>

            <Route
              path="accept"
              element={
                <React.Suspense fallback={<Loading />}>
                  <AcceptPage />
                </React.Suspense>
              }
            />

            {/* v1.1.0 */}
            <Route path="workspace">
              <Route
                index
                element={
                  <WorkspaceContext>
                    <React.Suspense>
                      <SpaceDefault />
                    </React.Suspense>
                  </WorkspaceContext>
                }
              />
              <Route
                path=":lastSpaceId"
                element={
                  <WorkspaceContext>
                    <React.Suspense fallback={<Loading />}>
                      <Workspace />
                    </React.Suspense>
                  </WorkspaceContext>
                }
              />
            </Route>
            {/* End */}

            <Route
              path="check-in"
              element={
                <React.Suspense fallback={<Loading />}>
                  <CheckIn />
                </React.Suspense>
              }
            />

            <Route
              path="report"
              element={
                <React.Suspense fallback={<Loading />}>
                  <Report />
                </React.Suspense>
              }
            />
            <Route
              path="create-report"
              element={
                <React.Suspense fallback={<Loading />}>
                  <CreateReport />
                </React.Suspense>
              }
            />
            <Route
              path="report-received/:reportId"
              element={
                <React.Suspense fallback={<Loading />}>
                  <ReportDetailReceived />
                </React.Suspense>
              }
            />

            <Route
              path="report-sended/:reportId"
              element={
                <React.Suspense fallback={<Loading />}>
                  <ReportDetailSended />
                </React.Suspense>
              }
            />

            <Route
              path="report-draft/:reportId"
              element={
                <React.Suspense fallback={<Loading />}>
                  <ReportDetailDraft />
                </React.Suspense>
              }
            />

            <Route path="feat">
              <Route
                path=":featId"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <Feature />
                  </React.Suspense>
                }
              />
            </Route>

            <Route
              path="company-regulations"
              element={
                <React.Suspense fallback={<Loading />}>
                  <CompanyRegulations />
                </React.Suspense>
              }
            />
          </Route>

          <Route path="/login">
            <Route
              index
              element={
                <React.Suspense fallback={<Loading />}>
                  <Login />
                </React.Suspense>
              }
            />
          </Route>
        </Routes>
      </SidebarContext>

      <ToastContainer autoClose={1000} />
      <Toaster position="top-right" richColors />
    </>
  );
};

export default App;
