import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import background from '../../../../Apis/ProjectManagement/background';
import projectBoard from '../../../../Apis/ProjectManagement/TaskProgress/board';
import { useGlobalContext } from '../../../../Utils/Context';
import AttachFile from '../../../AttachFile/AttachFile';
import Icon from '../../../Common/Icon';

const AttachedFiles = ({ detailTask, column, setAttachedFiles, attachedFiles }) => {
  const { register, handleSubmit } = useForm();
  const { callBackFile, setCallBackFile } = useGlobalContext();
  const { t } = useTranslation();

  const handleImage = async (data) => {
    const newData = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      projBoardColId: column.projBoardColId,
      taskName: detailTask?.taskName,
      endDate: detailTask?.endDate,
      description: detailTask?.description,
      uploadKey: data.uploadKey,
      fileSeq: data.seq,
      coverImageName: detailTask?.coverImageName
    };
    try {
      await projectBoard.editTaskKanban(newData, 'upload');
      setCallBackFile(!callBackFile);
      setAttachedFiles(false);
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  const [errorLink, setErrorLink] = useState(null);
  // Api get Link tải lên
  const handleSubmitLink = async (data) => {
    if (data?.fileLink.startsWith('http://') || data?.fileLink.startsWith('https://')) {
      const newData = {
        fileLink: data?.fileLink,
        projBoardTaskId: detailTask?.projBoardTaskId
      };
      await background.saveFileLink(newData);
      setCallBackFile(!callBackFile);
    } else {
      setErrorLink(t('project.invalid-link'));
    }
  };
  return (
    <div className="w-[300px] py-1 px-3">
      <div className="mb-2 mt-3 flex  justify-between border-b pb-2">
        <p className="text-center text-lg font-semibold">{t('project.attachment')}</p>
        <p onClick={() => setAttachedFiles(false)} className="cursor-pointer">
          <Icon
            name="close_cross"
            className="h-[22px] w-[22px] rounded-full bg-gray-400 bg-opacity-30 stroke-black p-0.5 hover:bg-opacity-80"
          />
        </p>
      </div>

      {/* Tải file từ máy tính */}
      <div className=" mt-3 w-full border-b  pb-4 ">
        <h2 className="mb-2 text-sm font-semibold"> {t('project.attach-files-from-your-computer')}</h2>
        <p className="text-sm font-normal text-[#9C9C9C]">{t('project.select-a-file')}</p>
        {attachedFiles && (
          <div className="mt-4">
            <AttachFile
              attachType="ProjectTaskFile"
              entity="ProjBoardTask"
              multi={false}
              editable={true}
              register={register}
              viewMode={false}
              handleUpload={handleImage}
              mode="form"
              filters={{
                max_file_size: '10mb',
                mime_types: [
                  {
                    title: 'Tệp đã được nhận: ',
                    extensions: 'jpg,png,svg,txt,doc,docx,pdf'
                  }
                ]
              }}
              className="  rounded-t-full"
            />
            <p className="mb-2 pt-2 text-sm font-normal text-[#9C9C9C]">
              Choose files (.xlsx, .ppt, .pptx, .txt, .csv, .docx, .doc, .pdf, .png, .jpg, .jpeg, .zip, .rar)
            </p>
          </div>
        )}
      </div>
      {/* Dán đường liên kết từ bên ngoài  */}
      <form onSubmit={handleSubmit(handleSubmitLink)}>
        <div className="mb-4 mt-3">
          <p className="mb-2 text-sm font-semibold">{t('project.paste-the-link')}</p>
          <input
            type="text"
            className="w-full rounded-lg border border-[#F1F3FD] bg-white px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
            placeholder={t('project.paste-affiliate-link')}
            {...register('fileLink', { required: true })}
          />
          <p className="ml-1 text-[13px] text-red-500">{errorLink}</p>
          <div className="mt-2 flex justify-end gap-3">
            <p
              className="mt-2 w-[80px] cursor-pointer rounded-lg border  py-1 px-2 text-center text-sm text-black"
              onClick={() => setAttachedFiles(false)}
            >
              {t('project.cancel')}
            </p>
            <button className="mt-2 w-[80px] cursor-pointer rounded-lg border bg-primary-100 py-1 px-2 text-sm text-white">
              {t('account-management.save')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AttachedFiles;
