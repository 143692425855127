import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import './Assets/Css/main.css';

import { I18nextProvider } from 'react-i18next';
import { HashRouter } from 'react-router-dom';
import App from './App';
import i18n from './translation/i18n';
import { AppProvider } from './Utils/Context';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <AppProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </AppProvider>
  </HashRouter>
);
