const labelColor = [
  { id: 1, colorCode: '#B9BCFF' },
  { id: 2, colorCode: '#FABDFC' },
  { id: 3, colorCode: '#FFC2C2' },
  { id: 4, colorCode: '#FFDDBD' },
  { id: 5, colorCode: '#F97316' },
  { id: 6, colorCode: '#FACC15' },
  { id: 7, colorCode: '#EC4899' },
  { id: 8, colorCode: '#D946EF' },
  { id: 9, colorCode: '#3B82F6' },
  { id: 10, colorCode: '#6366F1' },
  { id: 11, colorCode: '#8B5CF6' },
  { id: 12, colorCode: '#0EA5E9' },
  { id: 13, colorCode: '#4ADE80' },
  { id: 14, colorCode: '#84CC16' },
  { id: 15, colorCode: '#10B981' },
  { id: 16, colorCode: '#2DD4BF' },
  { id: 17, colorCode: '#9c5c5c' },
  { id: 18, colorCode: '#e63e3e' },
  { id: 19, colorCode: '#ed0c0c' },
  { id: 20, colorCode: '#8f0606' }
];
export default labelColor;
export const colorDesc = [
  '#000000',
  '#FFFFFF',
  '#FF0000',
  '#0000FF',
  '#00FF00',
  '#FFFF00',
  '#FFA500',
  '#FFC0CB',
  '#800080',
  '#A52A2A',
  '#808080',
  '#000080',
  '#ADD8E6',
  '#90EE90',
  '#FFFFE0',
  '#8A2BE2',
  '#FFD700',
  '#32CD32',
  '#FF4500',
  '#DA70D6',
  '#00CED1',
  '#FF6347',
  '#008080',
  '#DAA520',
  '#6A5ACD',
  '#FF69B4',
  '#CD5C5C',
  '#556B2F',
  '#9400D3',
  '#F08080',
  '#2F4F4F',
  '#4B0082',
  '#DC143C',
  '#00FA9A',
  '#BDB76B',
  '#696969',
  '#20B2AA',
  '#9370DB',
  '#FF7F50',
  '#1E90FF',
  '#FF8C00',
  '#9932CC'
];
