import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getWorkContractByUserId } from '../../Apis/HRManagerment/workContract.js';
import { Signup } from '../../Apis/user';
import WarningIc from '../../Assets/Images/Icons/warning-ic.svg';
import IconV2 from '../../Components-v2/Common/Icon.js';
import PopupV2 from '../../Components-v2/Common/Popup.js';
import { useGlobalContext } from '../../Utils/Context';
import { useSidebarContext } from '../../Utils/Context/SidebarContext';
import AttachFile from '../AttachFile/AttachFile.js';
import { CloseButton } from '../Buttons/ButtonComponent.js';
import { toDateStringss } from '../Calendar/CalendarComponent';
import Icon from '../Common/Icon.js';
import useCloseModalOnOutsideClick from '../OnOutsideClick/OnOutsideClick.js';
import { Card, ExitAlt, KeyRefresh } from './Icon';

const UserInfo = (props) => {
  const { t } = useTranslation();
  const { userInfo, updateUserInfo, checkIsLoginFalse, activeLoad } = useGlobalContext();
  const { popupActive, setPopupActive } = useSidebarContext();
  const navigate = useNavigate();

  // Display user information
  const [isInfo, setIsInfo] = useState(false);

  // Update user information
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm();

  const [isUpdateInfo, setIsUpdateInfo] = useState(false);
  const [form, setForm] = useState(1);
  const oldPassword = useRef({});
  oldPassword.current = watch('oldPassword', '');
  const password = useRef({});
  password.current = watch('password', '');

  const showForm = async (num) => {
    reset({
      fullName: userInfo?.fullName,
      email: userInfo?.email,
      gender: userInfo?.gender,
      phoneNumber: userInfo?.phoneNumber,
      birthDate: userInfo?.birthDate,
      address: userInfo?.address
    });
    setIsUpdateInfo(true);
    setForm(num);
  };

  const closeUpdateInfo = () => {
    return setIsUpdateInfo(false);
  };

  const onSubmitUpdate = async (data) => {
    const params = {
      uploadKey: data?.uploadKey,
      userID: userInfo?.userID,
      userName: userInfo?.userName,
      fullName: data?.fullName || userInfo?.fullName,
      password: data?.password,
      email: data?.email || userInfo?.email,
      address: data?.address || userInfo?.address,
      position: userInfo?.position,
      disable: 'N',
      gender: data?.gender || userInfo?.gender,
      birthDate: data?.birthDate || userInfo?.birthDate,
      phoneNumber: data?.phoneNumber || userInfo?.phoneNumber,
      startDate: userInfo?.startDate,
      userCode: userInfo?.userCode,
      oldPassword: data?.oldPassword,
      seq: userInfo?.seq,
      notify: true
    };

    try {
      await Signup(params);
      await updateUserInfo();
      if (form === 1) {
        toast.success(t('user-info.update-account-successfully'), {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        setTimeout(() => window.location.reload(false), 2000);
      } else {
        toast.success(t('user-info.change-password-successfully'), {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        closeUpdateInfo();
        props.handleUserInfo(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(t('user-info.incorrect-current-password'), {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      } else {
        toast.error(t('user-info.an-error-occurred'), {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      }
      console.log(error);
    }
  };

  const logOut = () => {
    activeLoad(true);
    localStorage.removeItem('token');
    checkIsLoginFalse();
    setTimeout(() => {
      activeLoad(false);
      navigate('/login');
    }, 500);
  };
  // eslint-disable-next-line no-unused-vars
  const [isOpenAvatar, setIsOpenAvatar] = useState(false);
  const boardRef = useRef(null);
  useCloseModalOnOutsideClick(boardRef, setIsOpenAvatar);
  useCloseModalOnOutsideClick(boardRef, setPopupActive);
  const [userInfoPopup, setUserInfoPopup] = useState(true);
  const [currentWorkContract, setCurrentWorkContract] = useState(null);
  const handlePersonInfo = async () => {
    if (!isOpenAvatar) {
      const { data } = await getWorkContractByUserId(userInfo?.userID);
      setCurrentWorkContract(data.data);
    }
  };
  useEffect(() => {
    if (userInfo?.userID) {
      handlePersonInfo();
    }
  }, [userInfoPopup]);
  const closeInfo = () => {
    setIsInfo(false);
    setUserInfoPopup(true);
  };
  return (
    <div className="relative">
      <div
        className="flex cursor-pointer items-center gap-x-2"
        onClick={() => {
          if (popupActive === 'isUserInfo') {
            setPopupActive('');
          } else {
            setPopupActive('isUserInfo');
          }
        }}
      >
        {props?.isAvatar && (
          <div className="h-10 w-10">
            <AttachFile
              attachType="UserAvatar"
              entity="users"
              seq={userInfo?.userID}
              viewMode={true}
              mode="current-user"
              className="h-10 w-10 rounded-full object-cover transition-all duration-75 ease-linear active:scale-95 active:transform active:hue-rotate-15"
            />
          </div>
        )}
        {props?.isFullName && <p className="min-w-[150px] text-[15px] font-medium">{userInfo?.fullName} </p>}
      </div>

      {popupActive === 'isUserInfo' && (
        <ul
          ref={boardRef}
          className={`absolute flex flex-col gap-y-2 rounded-lg bg-white p-3 py-[14px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.25)] ${props?.positionPopup}`}
        >
          <li
            className="flex cursor-pointer items-center gap-x-2 whitespace-nowrap rounded-md px-3 py-2 text-sm font-medium hover:bg-neu-500"
            onClick={async () => {
              await updateUserInfo();
              setIsInfo(true);
            }}
          >
            <Card className="h-4 w-4 stroke-neu-400" />
            {t('user-info.watch-info')}
          </li>

          <li
            className="flex cursor-pointer items-center gap-x-2 whitespace-nowrap rounded-md px-3 py-2 text-sm font-medium hover:bg-neu-500"
            onClick={() => showForm(2)}
          >
            <KeyRefresh className="h-4 w-4 stroke-neu-400" />
            {t('user-info.change-password')}
          </li>

          <li
            className="group flex cursor-pointer items-center gap-x-2 whitespace-nowrap rounded-md px-3 py-2 text-sm font-medium hover:bg-neu-500 hover:text-neu-800"
            onClick={async () => {
              logOut();
              setPopupActive('');
            }}
          >
            <ExitAlt className="h-4 w-4 fill-neu-400 group-hover:fill-neu-800" />
            {t('other.logout')}
          </li>
        </ul>
      )}

      {/* Popup: Display user information */}
      <PopupV2 isOpen={isInfo} setIsOpen={closeInfo}>
        {userInfoPopup ? (
          <div className="w-full min-w-[768px] max-w-3xl rounded-xl bg-white px-6 py-8 focus:outline-none">
            {/* Title */}
            <div className="mb-6 flex justify-between">
              <p className="text-lg font-semibold">{t('account-management.basic-information')}</p>
              <CloseButton onClick={closeInfo} />
            </div>

            <div className="mb-9 ml-6 flex items-center justify-center">
              <div className="flex items-center">
                <AttachFile
                  attachType="UserAvatar"
                  entity="users"
                  seq={userInfo?.userID}
                  viewMode={true}
                  mode="form"
                  className="mr-4 h-[108px] w-[108px] rounded-xl object-cover"
                />
              </div>
            </div>

            <div>
              <ul className="mb-6 grid grid-cols-2 gap-x-8 gap-y-5 px-6">
                <li>
                  <label className="text-xs font-normal">{t('account-management.employee-code')}</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {userInfo?.userCode}
                  </p>
                </li>

                <li>
                  <label className="text-xs font-normal">{t('account-management.position')}</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {userInfo?.position}
                  </p>
                </li>

                <li>
                  <label className="text-xs font-normal">{t('account-management.full-name')}</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {userInfo?.fullName}
                  </p>
                </li>

                <li>
                  <label className="text-xs font-normal">{t('account-management.date-of-birth')}</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {!userInfo?.birthDate ? '--/--/----' : toDateStringss(userInfo?.birthDate, userInfo)}
                  </p>
                </li>

                <li>
                  <label className="text-xs font-normal">Email</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {userInfo?.email}
                  </p>
                </li>

                <li>
                  <label className="text-xs font-normal">{t('account-management.gender')}</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {userInfo?.gender === 'male' && <>{t('account-management.male')}</>}{' '}
                    {userInfo?.gender === 'female' && <>{t('account-management.female')}</>}
                  </p>
                </li>

                <li>
                  <label className="text-xs font-normal">{t('account-management.phone-number')}</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {userInfo?.phoneNumber}
                  </p>
                </li>

                <li>
                  <label className="text-xs font-normal">{t('account-management.start-date')}</label>
                  <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                    {!userInfo?.startDate ? '--/--/----' : toDateStringss(userInfo?.startDate, userInfo)}
                  </p>
                </li>

                <li className="col-span-2">
                  <label className="text-xs font-normal">{t('account-management.address')}</label>
                  <p className="mt-1 flex max-h-[100px] min-h-[40px] items-center rounded-[6px] bg-primary-400 px-3 py-1 text-base font-medium">
                    {userInfo?.address}
                  </p>
                </li>
              </ul>

              <div className="flex justify-center gap-6">
                <div
                  className="flex h-[40px] w-[130px] cursor-pointer items-center justify-center gap-2 rounded-md bg-[#E1E6FF] px-2"
                  onClick={() => setUserInfoPopup(false)}
                >
                  <IconV2 iconName="document_2" className="h-5 w-5 fill-primary-100" />
                  <p className="text-sm text-primary-100">{t('personnel-management.contract')}</p>
                </div>

                <div
                  onClick={() => {
                    showForm(1);
                    closeInfo();
                  }}
                  className="flex h-[40px] min-w-[140px] max-w-[180px]  cursor-pointer justify-center gap-2 rounded-lg border bg-primary-100 px-2 py-2 text-sm text-white"
                >
                  <Icon name="edit_2" className="stroke-white" />
                  <p>{t('personnel-management.edit-information')}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full min-w-[768px] max-w-3xl rounded-xl bg-white px-6 py-8">
            {/* Title */}
            <div className="flex items-center justify-between">
              <div>
                <div className="flex gap-2">
                  <Icon
                    name="arrow_left"
                    className="cursor-pointer stroke-black"
                    onClick={() => setUserInfoPopup(true)}
                  />
                  <p className="text-lg font-semibold">{t('personnel-management.contract-information')}</p>
                </div>

                <p className="ml-2 mt-1 text-sm font-medium text-neu-200">
                  {t('personnel-management.contentlabor-contract')}
                </p>
              </div>

              <div>
                <CloseButton onClick={closeInfo} />
              </div>
            </div>

            {/* Table */}
            {currentWorkContract ? (
              <div className="m-auto mt-6 w-full">
                <ul className="grid grid-cols-2 rounded-lg border bg-white">
                  {/* Mã hợp đồng */}
                  <li className="border-b border-r p-3">
                    <label className="text-xs font-normal">{t('project.contract-code')}</label>
                    <p className="font-medium">{currentWorkContract?.contractNumber}</p>
                  </li>

                  {/* Số điện thoại */}
                  <li className="border-b p-3">
                    <label className="text-xs">{t('personnel-management.phone-number')}</label>
                    <p className="font-medium">{currentWorkContract?.phoneNumber}</p>
                  </li>

                  {/* Ngày sinh */}
                  <li className="border-b border-r p-3">
                    <label className="text-xs font-normal">{t('personnel-management.date-of-irth')}</label>
                    <p className="font-medium">{toDateStringss(currentWorkContract?.birthDate, userInfo)}</p>
                  </li>

                  {/* CCCD/CMND/Hộ chiếu */}
                  <li className="border-b p-3">
                    <label className="text-xs">{t('personnel-management.id-card/passport-number')}</label>
                    <p className=" font-medium">{currentWorkContract?.identify}</p>
                  </li>

                  {/* Mã số thuế */}
                  <li className="border-b border-r p-3">
                    <label className="text-xs">{t('personnel-management.tax-code')}</label>
                    <p className="font-medium">{currentWorkContract?.tax}</p>
                  </li>

                  {/* Loại hợp đồng */}
                  <li className="border-b p-3">
                    <label className="text-xs">{t('personnel-management.contract-term')}</label>
                    <p className="font-medium">
                      {currentWorkContract?.type === '1' && t('personnel-management.definite-term-contract')}
                      {currentWorkContract?.type === '2' && t('personnel-management.indefinite-term-contract')}
                    </p>{' '}
                  </li>

                  {/* Ngân hàng */}
                  <li className="border-b border-r p-3">
                    <label className="text-xs">{t('personnel-management.bank')}</label>
                    <p className="font-medium">{currentWorkContract?.bankName}</p>
                  </li>

                  {/* Số tài khoản */}
                  <li className="border-b p-3">
                    <label className="text-xs">{t('personnel-management.account-number')}</label>
                    <p className="font-medium">{currentWorkContract?.accountNumber}</p>
                  </li>

                  {/* Họ và tên người liên hệ khẩn cấp */}
                  <li className="border-b border-r p-3">
                    <label className="text-xs">{t('personnel-management.name-of-emergency-contact')}</label>
                    <p className="font-medium">{currentWorkContract?.contactName}</p>
                  </li>

                  {/* Số điện thoại người liên hệ khẩn cấp */}
                  <li className="border-b p-3">
                    <label className="text-xs ">{t('personnel-management.emergency-contact-number')}</label>
                    <p className="font-medium">{currentWorkContract?.contactNumber}</p>
                  </li>

                  {/* Số BHYT */}
                  <li className="border-b border-r p-3">
                    <label className="text-xs">{t('personnel-management.health-insurance-number')}</label>
                    <p className=" font-medium">{currentWorkContract?.medicalNumber}</p>
                  </li>

                  {/* Số BHXH */}
                  <li className="border-b p-3">
                    <label className="text-xs">{t('personnel-management.social-insurance-number')}</label>
                    <p className="font-medium">{currentWorkContract?.socialNumber}</p>
                  </li>

                  {/* Ngày vào công ty */}
                  <li className="col-span-1 border-b border-r p-3">
                    <label className="text-xs">{t('personnel-management.day-start-work')}</label>
                    <p className="font-medium">{toDateStringss(currentWorkContract?.dayStartWork, userInfo)}</p>
                  </li>

                  {/* Thời gian bắt đầu hợp đồng */}
                  <li className="border-b p-3">
                    <label className="text-xs">{t('personnel-management.contract-start-date')}</label>
                    <p className="font-medium">{toDateStringss(currentWorkContract?.startTime, userInfo)}</p>
                  </li>

                  {/* Dạng hợp đồng */}
                  <li className="border-b border-r p-3">
                    <label className="text-xs">{t('personnel-management.contract-type')}</label>
                    <p className="font-medium">
                      {currentWorkContract?.status === '1' && t('personnel-management.internship')}
                      {currentWorkContract?.status === '2' && t('personnel-management.probation')}
                      {currentWorkContract?.status === '3' && t('personnel-management.full-time')}
                    </p>{' '}
                  </li>

                  {/* Thời gian kêt thúc hợp đồng */}
                  <li className="border-b p-3">
                    <label className="text-xs">{t('personnel-management.contract-end-date')}</label>
                    <p className=" font-medium">{toDateStringss(currentWorkContract?.endTime, userInfo)}</p>
                  </li>

                  {/* Địa chỉ thường trú */}
                  <li className="border-r p-3">
                    <label className="text-xs">{t('personnel-management.permanent-address')}</label>
                    <p className="font-medium">{currentWorkContract?.permanentAdress}</p>
                  </li>

                  {/* Địa chỉ cư trú */}
                  <li className="p-3">
                    <label className="text-xs">{t('personnel-management.residential-address')}</label>
                    <p className="font-medium">{currentWorkContract?.residentialAddress}</p>
                  </li>
                </ul>

                {/* File hợp đồng */}
                <div className="scroll-x overflow-x-auto pt-3">
                  <AttachFile
                    attachType="DownLoadFile"
                    entity="workContract"
                    seq={currentWorkContract?.wcontractID}
                    viewMode={true}
                    mode="download"
                  />
                </div>
              </div>
            ) : (
              <div className="flex h-[450px] items-center justify-center">
                <p>{t('personnel-management.no-contract')}</p>
              </div>
            )}
          </div>
        )}
      </PopupV2>

      {/* Popup: Update user information */}
      {/* form === 1: edit personal information */}
      {/* form === 2: change password */}
      <PopupV2
        isOpen={isUpdateInfo}
        setIsOpen={closeUpdateInfo}
        className={`mt-[120px] h-auto ${form === 1 && 'w-[800px]'} ${form === 2 && ' w-[500px]'} `}
        title={form === 1 ? t('user-info.edit-personal-information') : t('user-info.change-password')}
      >
        <div className="relative rounded-xl bg-white py-6">
          {/* Title */}
          <div className="mb-6 flex justify-between px-6">
            <p className="text-lg font-semibold">
              {form === 1 ? t('user-info.edit-personal-information') : t('user-info.change-password')}
            </p>

            <CloseButton onClick={closeUpdateInfo} />
          </div>

          <form onSubmit={handleSubmit(onSubmitUpdate)} className="relative pb-[68px]">
            <div className="px-6">
              {/* Edit personal information */}
              {form === 1 && (
                <>
                  <div className="mb-3 flex items-center justify-center">
                    <div className="relative">
                      <AttachFile
                        attachType="UserAvatar"
                        entity="users"
                        seq={userInfo?.userID}
                        viewMode={true}
                        mode="form"
                        className="h-[108px] w-[108px] rounded-xl object-cover"
                      />
                      <div className="absolute bottom-1 right-0" onClick={() => setIsOpenAvatar(!isOpenAvatar)}>
                        <Icon name="edit_2" className="rounded-full bg-gray-300 p-2" />
                      </div>
                      {isOpenAvatar && (
                        <div className=" absolute top-24 left-28 " ref={boardRef}>
                          <AttachFile
                            attachType="UserAvatar"
                            entity="users"
                            seq={userInfo?.userID}
                            multi={false}
                            editable={true}
                            register={register}
                            viewMode={false}
                            mode="form"
                            filters={{
                              max_file_size: '10mb',
                              mime_types: [
                                {
                                  title: 'Tệp đã được nhận: ',
                                  extensions: 'jpg,png,svg'
                                }
                              ]
                            }}
                            className="h-8 w-8 rounded-t-full object-cover"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <ul className="grid grid-cols-2 gap-6">
                    {/* Họ và tên - Full name */}
                    <li className="col-span-2">
                      <label className="text-xs font-normal">
                        <span>{t('personnel-management.full-name')} </span>
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                        placeholder={t('account-management.full-name')}
                        {...register('fullName', { required: true, maxLength: 55 })}
                      />
                      {errors?.fullName?.type === 'required' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('account-management.can-empty-them-and-name')}
                          </span>
                        </p>
                      )}
                      {errors?.fullName?.type === 'maxLength' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('account-management.up-to-55-characters')}
                          </span>
                        </p>
                      )}
                    </li>

                    {/* Email */}
                    <li>
                      <label className="text-xs font-normal">
                        <span>Email </span>
                        <span className="ml-[2px] text-red-500">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                        type="email"
                        placeholder="Email"
                        {...register('email', { required: true, maxLength: 55 })}
                      />
                      {errors?.email?.type === 'required' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('account-management.email-is-required')}
                          </span>
                        </p>
                      )}
                      {errors?.email?.type === 'maxLength' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('account-management.up-to-55-characters')}
                          </span>
                        </p>
                      )}
                    </li>

                    {/* Giới tính - Gender */}
                    <li>
                      <label className="text-xs font-normal">{t('account-management.gender')}</label>
                      <select
                        className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                        {...register('gender')}
                      >
                        <option value="" defaultChecked hidden>
                          {t('job.select')}
                        </option>
                        <option className="text-sm font-medium" value="male">
                          {t('account-management.male')}
                        </option>
                        <option className="text-sm font-medium" value="female">
                          {t('account-management.female')}
                        </option>
                      </select>
                    </li>

                    {/* Số điện thoại - Phone number */}
                    <li>
                      <label className="text-xs font-normal">
                        <span>{t('account-management.phone-number')} </span>
                        <span className="ml-[2px] text-red-500">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                        type="text"
                        placeholder={t('account-management.phone-number')}
                        {...register('phoneNumber', {
                          required: true,
                          pattern: {
                            value: /^[0-9]+$/
                          },
                          maxLength: 11
                        })}
                      />
                      {errors?.phoneNumber?.type === 'required' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('account-management.no-be-eft-blank')}
                          </span>
                        </p>
                      )}

                      {errors?.phoneNumber?.type === 'pattern' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('account-management.please-enter-a-number')}
                          </span>
                        </p>
                      )}

                      {errors?.phoneNumber?.type === 'maxLength' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('personnel-management.up-to-11-characters')}
                          </span>
                        </p>
                      )}
                    </li>

                    {/* Ngày sinh - Date of birth */}
                    <li>
                      <label className="text-xs font-normal">{t('account-management.date-of-birth')}</label>
                      <input
                        className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                        type={`date`}
                        {...register('birthDate', { required: false })}
                      />
                    </li>

                    {/* Địa chỉ - Address */}
                    <li className="col-span-2">
                      <label className="text-xs font-normal">
                        <span>{t('account-management.address')} </span>
                        <span className="ml-[2px] text-red-500">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                        type="text"
                        placeholder={t('account-management.address')}
                        {...register('address', { required: true, maxLength: 155 })}
                      />
                      {errors?.address?.type === 'required' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">
                            {t('account-management.no-be-eft-blank')}
                          </span>
                        </p>
                      )}
                      {errors?.address?.type === 'maxLength' && (
                        <p className="flex gap-1 px-2 pt-1">
                          <img src={WarningIc} alt="warning-icon" />
                          <span className="text-xs font-normal text-red-500">{t('account-management.max-155')}</span>
                        </p>
                      )}
                    </li>
                  </ul>
                </>
              )}

              {/* Change password */}
              {form === 2 && (
                <ul className="grid gap-y-3">
                  {/* Nhập mật khẩu cũ - Enter old password  */}
                  <li className="">
                    <svg
                      width="75"
                      height="88"
                      viewBox="0 0 75 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="m-auto"
                    >
                      <path
                        d="M62.5 29.3334H70.8333C71.9384 29.3334 72.9982 29.7724 73.7796 30.5538C74.561 31.3352 75 32.395 75 33.5001V83.5001C75 84.6051 74.561 85.665 73.7796 86.4464C72.9982 87.2278 71.9384 87.6667 70.8333 87.6667H4.16667C3.0616 87.6667 2.00179 87.2278 1.22039 86.4464C0.438987 85.665 0 84.6051 0 83.5001V33.5001C0 32.395 0.438987 31.3352 1.22039 30.5538C2.00179 29.7724 3.0616 29.3334 4.16667 29.3334H12.5V25.1667C12.5 18.5363 15.1339 12.1775 19.8223 7.48908C24.5107 2.80067 30.8696 0.166748 37.5 0.166748C44.1304 0.166748 50.4893 2.80067 55.1777 7.48908C59.8661 12.1775 62.5 18.5363 62.5 25.1667V29.3334ZM54.1667 29.3334V25.1667C54.1667 20.7465 52.4107 16.5072 49.2851 13.3816C46.1595 10.256 41.9203 8.50008 37.5 8.50008C33.0797 8.50008 28.8405 10.256 25.7149 13.3816C22.5893 16.5072 20.8333 20.7465 20.8333 25.1667V29.3334H54.1667ZM33.3333 54.3334V62.6667H41.6667V54.3334H33.3333ZM16.6667 54.3334V62.6667H25V54.3334H16.6667ZM50 54.3334V62.6667H58.3333V54.3334H50Z"
                        fill="#7895FF"
                        fillOpacity="0.4"
                      />
                    </svg>
                  </li>
                  <li>
                    <label className="text-xs font-normal">
                      <span>{t('user-info.current-password')} </span>
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                      type="password"
                      placeholder={t('user-info.current-password')}
                      {...register('oldPassword', { required: true })}
                    />

                    {errors?.oldPassword?.type === 'required' && (
                      <p className="flex gap-1 px-2 pt-1">
                        <img src={WarningIc} alt="warning-icon" />
                        <span className="text-xs font-normal text-red-500">
                          {t('user-info.please-enter-your-current-password')}
                        </span>
                      </p>
                    )}
                  </li>

                  {/* Mật khẩu mới - New password */}
                  <li>
                    <label className="text-xs font-normal">
                      <span>{t('user-info.new-password')} </span>
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                      type="password"
                      placeholder={t('user-info.new-password')}
                      {...register('password', {
                        required: true,
                        maxLength: 20,
                        minLength: 8,
                        pattern: {
                          value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                          message: 'Password should contain at least one uppercase letter, lowercase'
                        },
                        validate: (value) => value !== oldPassword.current
                      })}
                    />

                    {errors?.password?.type === 'validate' && (
                      <p className="flex gap-1 px-2 pt-1">
                        <img src={WarningIc} alt="warning-icon" />
                        <span className="text-xs font-normal text-red-500">
                          {t('user-info.your-new-password-cannot-be-the-same-as-your-current-password')}
                        </span>
                      </p>
                    )}

                    {errors?.password?.type === 'pattern' && (
                      <p className="flex gap-1 px-2 pt-1">
                        <img src={WarningIc} alt="warning-icon" />
                        <span className="text-xs font-normal text-red-500">
                          {t('account-management.password-should-contain')}
                        </span>
                      </p>
                    )}

                    {(errors?.password?.type === 'minLength' || errors?.password?.type === 'maxLength') && (
                      <p className="flex gap-1 px-2 pt-1">
                        <img src={WarningIc} alt="warning-icon" />
                        <span className="text-xs font-normal text-red-500">
                          {t('account-management.min-8-max-20')}{' '}
                        </span>
                      </p>
                    )}
                  </li>

                  {/* Nhập lại mật khẩu mới - Re-enter password  */}
                  <li>
                    <label className="text-xs font-normal">
                      <span>{t('user-info.confirm-new-password')} </span>
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-primary-100 focus:bg-[#E1E6FF] focus:outline-none"
                      type="password"
                      placeholder={t('user-info.confirm-new-password')}
                      {...register('rePassword', {
                        required: true,
                        validate: (value) => value === password.current
                      })}
                    />

                    {errors?.rePassword?.type === 'validate' && (
                      <p className="flex gap-1 px-2 pt-1">
                        <img src={WarningIc} alt="warning-icon" />
                        <span className="text-xs font-normal text-red-500">
                          {t('user-info.your-password-does-not-match')}
                        </span>
                      </p>
                    )}
                  </li>
                </ul>
              )}
            </div>

            <div className="absolute bottom-0 w-full ">
              <div className="flex w-full justify-center gap-4">
                <div
                  className="flex h-[35px] w-[110px] cursor-pointer items-center justify-center gap-2 rounded-md border"
                  onClick={() => setIsUpdateInfo(false)}
                >
                  <Icon name="close_cross" className="h-5 w-5 stroke-black" />
                  <p className="text-sm">{t('account-management.cancel')}</p>
                </div>
                <button className="flex h-[35px] w-[110px] cursor-pointer items-center justify-center gap-2 rounded-md border bg-primary-100 text-white">
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                  >
                    <path
                      d="M12.3327 1.66675L5.47075 8.52868C5.2104 8.78903 4.78829 8.78903 4.52794 8.52868L1.66602 5.66675"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>

                  <span className="text-sm">{t('job.confirm')}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </PopupV2>
    </div>
  );
};

export default UserInfo;
