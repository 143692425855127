//Url development environment
// const backendURL = 'http://192.168.100.22:8081/api';

//Url production environment
// const backendURL = 'http://123.25.30.53:48080/api';

//Url localhost environment
// eslint-disable-next-line no-undef
const backendURL = process.env.REACT_APP_API_URL;
export default backendURL;
