import instance from './instance';

export const Signin = (user) => {
  const url = `/auth/signin`;
  return instance.post(url, user);
};
export const Signup = (user) => {
  const url = `user`;
  return instance.post(url, user);
};

export const GetAllUser = () => {
  const url = `/user`;
  return instance.get(url);
};

export const GetAllUserPaginate = (currentPage, param, paramDept, itemNumber, valueFilter) => {
  const url = `/user/paginate?page=${currentPage}&size=${itemNumber}${
    param.userName === undefined ? '' : '&userName=' + param.userName
  }${!valueFilter?.status ? '' : '&status=' + valueFilter?.status}${
    !valueFilter?.orders || '' ? `&orders=userCode+asc` : `&orders=${valueFilter?.orders}`
  }${!valueFilter?.type && !valueFilter?.type ? '' : `&type=${valueFilter?.type}`}`;
  return instance.post(url, valueFilter?.deptArr ? { deptArr: valueFilter?.deptArr } : {});
};

export const RemoveUser = (id) => {
  const url = `/user/${id}`;
  return instance.delete(url);
};

export const GetAllDeptListSearch = () => {
  const url = `/department`;
  return instance.get(url);
};

export const GetAllDeptListApi = (userId) => {
  const url = `/department/listByUpper/0?page=0&size=20&userId=${userId}&lockAt=N`;
  return instance.get(url);
};
export const searchDeptForUser = (key) => {
  const url = `/department/searchDeptForUser?deptname=${key}`;
  return instance.get(url);
};

// eslint-disable-next-line no-unused-vars
export const GetUserListByDept = (deptID, currentPage, status, type, valueFilter) => {
  const url = `/user/byDept/${deptID ? deptID : 0}?page=${currentPage}&size=10${
    !valueFilter?.memberName ? '' : '&memberName=' + valueFilter?.memberName
  }${!status ? '&status=undefined' : `&status=${status}`}${!type ? '&type=undefine' : `&type=${type}`}${
    !valueFilter?.orders ? `&orders=userRoleDeptID+desc` : `&orders=${valueFilter?.orders}`
  }`;
  return instance.get(url);
};

export const GetUserAddToDept = (deptID, status, typeDept, searchValueAddMember, groupId) => {
  const url = `user/addOrChangeDeptMem?deptID=${deptID ? deptID : 0}&status=${status}&type=${typeDept}${
    !searchValueAddMember ? '' : '&memberName=' + searchValueAddMember
  }${groupId ? `&groupId=${groupId}` : ''}`;
  return instance.get(url);
};

export const CheckLogin = () => {
  const url = `/user/checkLogin`;
  return instance.get(url);
};
export const ChangeStatusUser = (params) => {
  const url = `/user/disable`;
  return instance.get(url, { params });
};

export const searchUserForCalendar = (key) => {
  const url = `/user/searchUserForCalendar?searchString=${key}`;
  return instance.get(url);
};

export const GetUserManager = (id) => {
  const url = `/user/searchManagerTimeOff?userId=${id}`;
  return instance.get(url);
};

export const updateLanguage = (language, userId) => {
  const url = `/user/changeLanguage?language=${language}&userId=${userId}`;
  return instance.post(url);
};
