import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateProject } from '../../../../Apis/ProjectManagement/projectManager';
import WarningIc from '../../../../Assets/Images/Icons/warning-ic.svg';
import ProjectCreation from '../../../../Assets/Images/project_creation.svg';
import AttachFile from '../../../../Components/AttachFile/AttachFile';
import Invalid from '../../../../Components/Common/Form/Invalid';
import Icon from '../../../../Components/Common/Icon';
import { useGlobalContext } from '../../../../Utils/Context';
import CreateMember from './CreateMember';

const ProjectCreate = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm();
  const [openAndClose, setOpenAndClose] = useState(true);
  const [openCloseMember, setOpenCloseMember] = useState(true);
  const [typeProject, setTypeProject] = useState('');
  const { userInfo } = useGlobalContext();
  const navigate = useNavigate();
  const [listMember, setListMember] = useState([]);
  const { t } = useTranslation();

  const updatedUserList = listMember.map((user) => {
    // eslint-disable-next-line no-unused-vars
    const { fullName, userName, ...restUser } = user;
    return {
      ...restUser,
      addBy: userInfo.userID,
      roleNumber: user.roleNumber,
      positionDTOList: user.positionDTOList,
      expirationDate: user.expirationDate
    };
  });
  const [waitAddproject, setWaitAddproject] = useState(true);
  const onSubmit = async (data) => {
    setWaitAddproject(false);
    try {
      const newData = {
        projectDTO: {
          projectName: data.projectName.trim(),
          createdBy: userInfo.userID,
          disable: false,
          type: data.type,
          uploadKey: data.uploadKey,
          startDate: data.startDate ? data.startDate + 'T00:00:00' : '',
          endDate: data.endDate ? data.endDate + 'T00:00:00' : '',
          description: data.description,
          isArchived: false
        },
        memberDTOList: updatedUserList
      };
      await CreateProject(newData);
      toast.success(t('project.create-project-success'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
      setWaitAddproject(true);
      navigate('/project-management');
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
      setWaitAddproject(true);
    }
  };
  const isEndDateValid = (endDate, startDate) => {
    return !startDate || new Date(endDate) > new Date(startDate);
  };
  return (
    <div className=" h-[100vh] w-full overflow-y-auto">
      {waitAddproject === false && (
        <div className="flex h-full flex-col items-center justify-center gap-3">
          <div>
            <span className="loaders"></span>
          </div>
          <div>
            <p>{t('project.creating-project')} ...</p>
          </div>
        </div>
      )}
      <div className={`${waitAddproject ? '' : 'hidden'}`}>
        {/* Link đường dẫn */}
        <div className="mx-6 flex items-center gap-2 border-b px-2 py-3">
          <Link to="/project-management">
            <Icon name="home_2_alt" className=" stroke-[#686868] pt-2 hover:stroke-primary-100" />
          </Link>
          <Link to="/project-management">
            <span className="cursor-pointer pl-2 text-sm text-gray-400 hover:text-primary-100">
              {t('project.project')}
            </span>
          </Link>

          <p className="text-sm">{'>'}</p>
          <p className="text-sm text-primary-100">{t('project.init-project')}</p>
        </div>
        {/* Tiêu đề */}
        <div className=" h-[94vh] overflow-y-auto">
          <div className="m-6 flex  h-[145px] items-center rounded-lg  bg-[#F2F2F2] px-6 ">
            <img src={ProjectCreation} alt="" className="w-[80px]" />
            <div className=" pl-6">
              <h1 className="  text-xl font-semibold">{t('project.init-project')}</h1>
              <p className="text-sm">{t('project.init-project-title')}</p>
            </div>
          </div>
          <p className="mx-6 border-b"></p>

          {/* Form nhập thông tin cơ bản */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-6  mt-3 border-b ">
              <div className="flex justify-between gap-2 px-6 pt-4">
                <div>
                  <div className="flex gap-3 text-lg font-semibold">
                    <p>{t('project.general-info')}</p>
                  </div>
                  <p className="text-sm text-gray-500">{t('project.inc-basic-info')}</p>
                </div>
                <div onClick={() => setOpenAndClose(!openAndClose)}>
                  <div className="mt-2 flex cursor-pointer gap-4 rounded-md border border-primary-100 bg-[#E1E6FF] p-2">
                    <p className="font-semibold text-primary-100">
                      {openAndClose === true ? t('project.collapse') : t('project.expand')}
                    </p>
                    <Icon
                      name="arrows_diagonal_contract"
                      className="h-[24px] w-[24px] fill-none stroke-[#234EEC] pt-1"
                    />
                  </div>
                </div>
              </div>
              {/* Thông tin chung */}
              <div
                className={`m-auto mt-4 mb-6 overflow-hidden px-6 transition-all duration-1000 ${
                  openAndClose === true ? 'max-h-[2000px]' : 'max-h-[0px]'
                }`}
              >
                {/* Thêm ảnh */}
                <div className="flex ">
                  <div className=" px-6 pt-6 pb-4">
                    <div className="mb-3 flex gap-4 rounded-xl  py-4">
                      <div className="   ">
                        <AttachFile
                          attachType="ProjectAvatar"
                          entity="project"
                          viewMode={true}
                          mode="form"
                          filters={{
                            max_file_size: '10mb',
                            mime_types: [
                              {
                                title: 'Tệp đã được nhận: ',
                                extensions: 'jpg,png'
                              }
                            ]
                          }}
                          className="h-[150px] w-[150px] rounded-lg object-cover "
                        />
                      </div>

                      <div className=" mt-4 ">
                        <AttachFile
                          attachType="ProjectAvatar"
                          entity="project"
                          multi={false}
                          editable={true}
                          register={register}
                          viewMode={false}
                          mode="form"
                          filters={{
                            max_file_size: '10mb',
                            mime_types: [
                              {
                                title: 'Tệp đã được nhận: ',
                                extensions: 'jpg,png'
                              }
                            ]
                          }}
                          className="h-8 w-8 rounded-t-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tên dự án */}
                <div>
                  <p className="pb-2 text-sm ">
                    {t('project.project-name')} <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder={t('project.enter-project-name')}
                    className="w-full rounded-lg border border-[#F1F3FD] bg-white px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                    {...register('projectName', {
                      required: { value: true, message: t('project.warnning-mess-input-field') },
                      maxLength: { value: 100, message: t('project.exceeds-allowed-100') },
                      validate: (value) => value.trim() !== '' || t('project.please-enter-characters')
                    })}
                  />
                  <Invalid
                    isInvalid={
                      errors?.projectName?.type === 'required' ||
                      errors?.projectName?.type === 'maxLength' ||
                      errors?.projectName?.type === 'validate'
                    }
                    message={errors?.projectName?.message}
                  />
                </div>
                {/* Mô hình dự án */}
                <div className="mt-6   gap-4">
                  <p className="pb-2 text-sm ">
                    {t('project.develop-model')} <span className="text-red-500">*</span>
                  </p>
                  <select
                    className="w-[500px] rounded-lg border border-[#F1F3FD] bg-white px-3  py-1.5 text-sm font-medium   focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                    {...register('type', {
                      required: { value: true, message: t('project.warnning-mess-input-field') }
                    })}
                    onChange={(e) => setTypeProject(e?.target?.value)}
                    defaultValue={''}
                  >
                    <option value={''} disabled>
                      {t('project.select-development-model')}
                    </option>
                    <option value="S">Scrum</option>
                    <option value="W">Waterfall</option>
                    <option value="K">Kanban</option>
                  </select>
                  <Invalid isInvalid={errors?.type?.type === 'required'} message={errors?.type?.message} />
                </div>
                {/* Mô tả */}
                <div className="mt-6">
                  <p className="pb-2 text-sm ">{t('job.description')}</p>
                  <textarea
                    type="text"
                    placeholder={t('project.add-description')}
                    className="h-[100px] w-full rounded-lg border border-[#F1F3FD] bg-white px-3  py-2 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                    {...register('description', {
                      required: false,
                      maxLength: { value: 255, message: t('project.maximum-255-characters') }
                    })}
                  />
                  <Invalid
                    isInvalid={errors?.description?.type === 'maxLength'}
                    message={errors?.description?.message}
                  />
                </div>
                {/* Thời gian bắt đầu , thời gian kết thúc */}
                <div className="mt-6 flex gap-4">
                  <div className="w-full">
                    <p className="pb-2 text-sm ">{t('job.start-time')}</p>
                    <input
                      type="date"
                      id="startDate"
                      placeholder={t('project.enter-project-name')}
                      className="startDate w-full rounded-lg border border-[#F1F3FD] bg-white px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                      {...register('startDate')}
                    />
                    <p>{errors.startDate?.message}</p>
                  </div>
                  <div className="w-full">
                    <p className="pb-2 text-sm ">{t('job.end-time')}</p>
                    <input
                      type="date"
                      placeholder={t('project.enter-project-name')}
                      min={getValues('startDate')}
                      className="w-full rounded-lg border border-[#F1F3FD] bg-white px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                      {...register('endDate', {
                        validate: (value) =>
                          isEndDateValid(value, document.querySelector('input[name="startDate"]').value) ||
                          t('project.end-time-must')
                      })}
                    />
                    {errors.endDate && (
                      <p className="flex gap-1 px-2 pt-1">
                        <img src={WarningIc} alt="warning-icon" />
                        <span className="text-xs font-normal text-red-500">{errors.endDate.message}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Thành viên */}
            <div className="mx-6 pb-6">
              <div className="flex justify-between gap-2 px-6 pt-4">
                <div>
                  <div className="flex gap-3 text-lg font-semibold">
                    <p>{t('job.member')}</p>
                  </div>
                  <p className="text-sm text-gray-500">{t('project.member-title')}</p>
                </div>
                <div onClick={() => setOpenCloseMember(!openCloseMember)}>
                  <div className="mt-3 flex cursor-pointer gap-4 rounded-md border border-primary-100 bg-white  p-2 transition-colors duration-1000 ">
                    <p className="font-semibold text-primary-100">
                      {openCloseMember === true ? t('project.collapse') : t('project.expand')}
                    </p>
                    <Icon
                      name="arrows_diagonal_contract"
                      className="h-[24px] w-[24px] fill-none stroke-[#234EEC] pt-1"
                    />
                  </div>
                </div>
              </div>

              {/* {openCloseMember === true && ( */}
              <div className={``}>
                <CreateMember
                  listMember={listMember}
                  typeProject={typeProject}
                  setListMember={setListMember}
                  openCloseMember={openCloseMember}
                />
              </div>

              {/* )} */}
            </div>

            <div className="absolute bottom-0 right-10   mb-4  flex justify-end gap-6 ">
              <Link to="/project-management">
                <div className="w-[120px] cursor-pointer rounded-lg border border-primary-100  py-1.5 text-center text-primary-100 ">
                  {t('project.cancel')}
                </div>
              </Link>

              <button className="w-[120px] cursor-pointer rounded-lg border bg-primary-100 py-1.5 text-center text-white hover:bg-blue-700">
                {t('job.confirm')}
              </button>
            </div>
          </form>
        </div>
        {/* Tiêu đề */}
      </div>
    </div>
  );
};

export default ProjectCreate;
