import React, { useContext, useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { useGlobalContext } from '../../Utils/Context';

const Context = React.createContext();

const SidebarContext = ({ children }) => {
  // v1.1.0
  const { userInfo } = useGlobalContext();
  // v1.1.0 end
  const [isFullSidebar, setIsFullSidebar] = useState(true);
  const [popupActive, setPopupActive] = useState('');
  const [loadingBzware, setLoadingBzware] = useState(true);

  // v1.1.0
  const [workspaceUrl, setWorkspaceUrl] = useState('/workspace');
  // v1.1.0 end
  // const location = useLocation();
  const Menu = [
    {
      name: 'user',
      url: '/user',
      content: <Translation>{(t) => <p>{t('menuname.users-management')}</p>}</Translation>,
      role: ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_SUPER_ADMIN'],
      display: 'top',
      icon: { name: 'user_circle', type: 'stroke' }
    },
    {
      name: 'hr-management',
      url: '/hr-management',
      content: <Translation>{(t) => <p>{t('menuname.hr-management')} </p>}</Translation>,
      role: ['ROLE_HUMAN_RESOURCES', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      display: 'top',
      icon: { name: 'user_double', type: 'fill' }
    },

    {
      name: 'department',
      url: '/department',
      content: <Translation>{(t) => <p>{t('menuname.departments-management')}</p>}</Translation>,
      role: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_STAFF', 'ROLE_HUMAN_RESOURCES', 'ROLE_SUPER_ADMIN'],
      display: 'top',
      icon: { name: 'bento_menu_1', type: 'stroke' }
    },
    {
      name: 'accept',
      url: '/accept',
      content: <Translation>{(t) => <p>{t('menuname.approval-management')}</p>}</Translation>,
      role: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_SUPER_ADMIN'],
      display: 'top',
      icon: { name: 'checkbox_3', type: 'fill' }
    },
    {
      name: 'calendar',
      url: '/',
      content: <Translation>{(t) => <p>{t('menuname.calendar')}</p>}</Translation>,
      role: null,
      display: 'center',
      icon: { name: 'calendar_3', type: 'stroke' }
    },
    // v1.0.1
    // {
    //   name: 'workspace',
    //   url: workspaceUrl,
    //   content: <Translation>{(t) => <p>{t('space.workspace')}</p>}</Translation>,
    //   role: null,
    //   display: 'center',
    //   icon: (
    //     <MindMap
    //       className={`h-[18px] w-[18px] ${
    //         location?.pathname === workspaceUrl ? 'stroke-primary-100' : 'stroke-neu-200'
    //       }`}
    //     />
    //   )
    // },
    {
      name: 'project-management',
      url: '/project-management',
      content: <Translation>{(t) => <p>{t('project.project-management')} </p>}</Translation>,
      role: null,
      display: 'center',
      icon: { name: 'briefcase_3', type: 'stroke' }
    },
    // v1.0.1 end
    // v1.0.3
    // {
    //   name: 'chat',
    //   url: '/feat/2',
    //   content: <Translation>{(t) => <p> {t('menuname.messages')} </p>}</Translation>,
    //   role: null,
    //   display: 'center',
    //   icon: { name: 'message_square_double_1', type: 'stroke' }
    // },
    {
      name: 'report',
      url: '/report',
      content: <Translation>{(t) => <p> {t('menuname.report')} </p>}</Translation>,
      role: null,
      display: 'center',
      icon: { name: 'document_2', type: 'fill' }
    },
    {
      name: 'check-in',
      url: '/check-in',
      content: <Translation>{(t) => <p>{t('menuname.timekeeping')} </p>}</Translation>,
      role: null,
      display: 'center',
      icon: { name: 'pointer_framed', type: 'fill' }
    }
    //Ẩn chức năng mail_07/03/2024
    // {
    //   name: 'mail',
    //   url: '/feat/3',
    //   content: 'Mail',
    //   role: null,
    //   display: 'center',
    //   icon: { name: 'mail', type: 'stroke' }
    // }
  ];

  // v1.1.0
  useEffect(() => {
    if (userInfo?.lastSpaceId) {
      setWorkspaceUrl('/workspace/' + userInfo?.lastSpaceId);
    } else {
      setWorkspaceUrl('/workspace');
    }
  }, [userInfo]);

  // v1.1.0 end

  return (
    <Context.Provider
      value={{
        isFullSidebar,
        setIsFullSidebar,
        Menu,
        popupActive,
        setPopupActive,
        // v1.1.0
        workspaceUrl,
        setWorkspaceUrl,
        loadingBzware,
        setLoadingBzware
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSidebarContext = () => {
  return useContext(Context);
};

export { SidebarContext };
