import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { GetDept } from '../../Apis/department';
import Logo from '../../Assets/Images/logo-colorful.svg';
import CompanyRegulationsSidebar from '../../Modules-v2/Company-regulations/Components/Sidebars/CompanyRegulationsSidebar';
import i18n from '../../translation/i18n';
import { useGlobalContext } from '../../Utils/Context';
import { useSidebarContext } from '../../Utils/Context/SidebarContext';
import Icon from '../Common/Icon';
import Notification from '../Sidebars/Notification';
import SettingSidebar from './SettingSidebar';
import UserInfo from './UserInfo';

const MiniSidebar = () => {
  const { userInfo, roleUser } = useGlobalContext();
  const location = useLocation();
  const { Menu } = useSidebarContext();
  const [isDepartment, setIsDepartment] = useState(true);
  const [departement, setOverDepartement] = useState(false);
  const [hover, setHover] = useState();
  const { t } = useTranslation();

  const getDepartment = async () => {
    try {
      if (userInfo?.userID) {
        const departmentList = await GetDept(userInfo?.userID);
        if (
          departmentList?.data?.dataList?.length > 0 ||
          userInfo.userRoleDepts[0]?.role == 2 ||
          userInfo.userRoleDepts[0]?.role == 6
        ) {
          setIsDepartment(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const switchLanguage = () => {
    if (userInfo?.language !== null && userInfo?.language !== undefined && userInfo?.language !== '') {
      i18n.changeLanguage(userInfo?.language);
      localStorage.setItem('i18next', userInfo?.language);
    } else {
      i18n.changeLanguage('vi');
      localStorage.setItem('i18next', 'vi');
    }
  };

  useEffect(() => {
    getDepartment();
    switchLanguage();
  }, [roleUser, userInfo?.language]);

  return (
    <div className="animate__animated animate__fadeInLeft animate__faster relative z-50 flex flex-1 flex-col items-start">
      {/* User information */}
      <div className="mb-3 mt-6 flex w-full flex-col items-center justify-center gap-y-6 px-3">
        <div className="relative">
          <UserInfo isAvatar={true} positionPopup="top-0 right-0 translate-x-[calc(100%+26px)]" />
        </div>

        <Notification positionPopup="top-0 right-0 translate-x-[calc(100%+28px)]" />
      </div>

      <div>
        <div className="scroll-y-2 flex max-h-[calc(100vh-218px)] w-full flex-col overflow-y-auto" id="sidebar-items">
          {/* Top */}
          <ul className="flex flex-col gap-y-2">
            {Menu?.map((menu, index) => {
              if (menu?.role?.includes(roleUser) === true) {
                return (
                  <li
                    key={index}
                    className=" flex px-3"
                    onMouseOver={() => {
                      setOverDepartement(true), setHover(menu?.name);
                    }}
                    onMouseOut={() => setOverDepartement(false)}
                  >
                    {isDepartment && menu?.display === 'top' && (
                      <Link
                        className={`${
                          location?.pathname === menu?.url ? 'bg-neu-100 text-primary-100' : 'hover:bg-neu-500'
                        } group flex items-center justify-center rounded-lg px-3 py-2 text-sm font-normal transition-all duration-200`}
                        to={menu?.url}
                      >
                        <Icon
                          name={menu?.icon?.name}
                          className={`group-hover:${menu?.icon?.type}-primary-100  ${
                            location?.pathname === menu?.url
                              ? `${menu?.icon?.type}-primary-100`
                              : `${menu?.icon?.type}-black`
                          }`}
                        />
                      </Link>
                    )}
                    {departement === true && menu?.name === hover ? (
                      <div className="absolute z-50 float-left ml-12 whitespace-nowrap rounded-lg bg-neu-500 p-2 text-xs text-black">
                        {menu?.name === 'accept' && t('menuname.approval-management')}
                        {menu?.name === 'hr-management' && t('menuname.hr-management')}
                        {menu?.name === 'department' && t('menuname.departments-management')}
                        {menu?.name === 'user' && t('menuname.users-management')}
                        {menu?.name === 'report' && t('menuname.report')}
                      </div>
                    ) : null}
                  </li>
                );
              }
            })}
          </ul>

          {/* line */}
          {isDepartment && <div className="mx-3 my-6 border-spacing-1 border-b border-neu-600"></div>}

          {/* center */}
          <ul className="flex flex-col gap-y-2">
            {Menu?.map((menu, index) => {
              if (menu?.role === null) {
                return (
                  <li
                    key={index}
                    className="flex px-3"
                    onMouseOver={() => {
                      setOverDepartement(true), setHover(menu?.name);
                    }}
                    onMouseOut={() => setOverDepartement(false)}
                  >
                    {menu?.display === 'center' && (
                      <Link
                        className={`${
                          location?.pathname === menu?.url ? 'bg-neu-100 text-primary-100' : 'hover:bg-neu-500'
                        } group flex items-center justify-center gap-x-2 rounded-lg px-3 py-2 text-sm font-normal transition-all duration-200 `}
                        to={menu?.url}
                      >
                        <Icon
                          name={menu?.icon?.name}
                          className={`group-hover:${menu?.icon?.type}-primary-100  ${
                            location?.pathname === menu?.url
                              ? `${menu?.icon?.type}-primary-100`
                              : `${menu?.icon?.type}-black`
                          }`}
                        />
                      </Link>
                    )}
                    {departement === true && menu?.name === hover ? (
                      <div className="absolute z-50 float-left ml-12 whitespace-nowrap rounded-lg bg-neu-500 p-2 text-xs text-black">
                        {menu?.name === 'calendar' && t('menuname.calendar')}
                        {menu?.name === 'project-management' && t('menuname.project-management')}
                        {menu?.name === 'task' && t('space.space')}
                        {menu?.name === 'chat' && t('menuname.messages')}
                        {menu?.name === 'mail' && 'Mail'}
                        {menu?.name === 'check-in' && t('menuname.timekeeping')}
                        {menu?.name === 'report' && t('menuname.report')}
                      </div>
                    ) : null}
                  </li>
                );
              }
            })}
          </ul>
        </div>

        {/* line */}
        <div className="mx-3 my-6 border-spacing-1 border-b border-neu-600"></div>

        <CompanyRegulationsSidebar
          isIcon={true}
          className={`group relative mx-3 mb-2 flex cursor-pointer items-center gap-x-2 rounded-lg px-3 py-2 text-sm font-normal duration-200 ${
            location?.pathname === '/company-regulations' ? 'bg-neu-100 text-white' : 'text-black hover:bg-neu-500'
          }`}
        />

        {/* Setting */}
        <SettingSidebar isIcon={true} />
      </div>
      <div className="mb-6 mt-auto flex w-full items-center justify-center gap-2  ">
        <div className="rounded-full bg-white">
          <img src={Logo} className="m-2 mr-2 h-6 w-6" alt="logo" />
        </div>
      </div>
    </div>
  );
};
export default MiniSidebar;
