import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useProgressTaskContext } from '../../Utils/Context/ProjectManagement/TaskProgress';

const LayoutProjectManagement = () => {
  // eslint-disable-next-line no-unused-vars
  const { typeTaskProgress, changeOnClickCreate } = useProgressTaskContext();
  const [showMoreSprint, setShowMoreSprint] = useState(false);

  const handleClickCreateButton = () => {
    changeOnClickCreate(true);
  };
  return (
    <div className="mx-4 flex h-full w-full flex-col items-center justify-start overflow-y-auto pb-8">
      <div
        className={`${
          showMoreSprint ? 'h-80 max-h-[400px]' : 'h-20'
        } transition-height w-full p-4 duration-500 ease-in-out`}
      >
        <h1
          onClick={() => setShowMoreSprint(!showMoreSprint)}
          className="mb-4 flex cursor-pointer items-center text-2xl font-bold"
        >
          <div className={`${showMoreSprint ? ' rotate-90' : ' rotate-0'} duration-300`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.57019 5.82567L7.73057 11.5144C7.89836 11.8164 7.89836 12.1836 7.73057 12.4857L4.57019 18.1743C4.09271 19.0338 5.01216 19.9941 5.89156 19.5544L19.2115 12.8944C19.9486 12.5259 19.9486 11.4741 19.2115 11.1056L5.89156 4.4456C5.01216 4.0059 4.09271 4.96621 4.57019 5.82567Z"
                fill="#000000"
              />
            </svg>
          </div>
          Sprint Backlog - Sprint
        </h1>
        <p className="mb-4 text-gray-600">Duration: 2023-01-01 to 2023-01-01</p>
        <ul className={`${showMoreSprint ? '' : 'hidden'} pr-8`}>
          <li className="mr-4 flex items-center justify-between border-b border-gray-300 py-2">
            <span className="flex-1">Thực hiện lập trình chức năng 1</span>
            <span className="text-gray-500">Assigned to: Vương Toàn Tuấn</span>
          </li>
          <li className="mr-4 flex items-center justify-between border-b border-gray-300 py-2">
            <span className="flex-1">Thực hiện lập trình chức năng 2</span>
            <span className="text-gray-500">Assigned to: Nguyễn Đắc Phương</span>
          </li>
        </ul>
      </div>
      <div className={`mr-20 flex w-full justify-end gap-4 `}>
        <div>
          <select className="w-40 rounded border p-2" name="" id="">
            <option value="">Xắp xếp</option>
            <option value="">Xắp xếp</option>
            <option value="">Xắp xếp</option>
            <option value="">Xắp xếp</option>
          </select>
        </div>
        <div>
          <select className="w-40 rounded border p-2" name="" id="">
            <option value="">Xắp xếp</option>
            <option value="">Xắp xếp</option>
            <option value="">Xắp xếp</option>
            <option value="">Xắp xếp</option>
          </select>
        </div>
        <div>
          <button className="h-full w-40 rounded border bg-blue-200 p-2" name="" id="">
            {' '}
            Reset
          </button>
        </div>
        <div className="flex items-center">
          <input className="w-40 rounded-l border border-r-0 p-2 outline-none" name="" id="" />
          <button className="h-full cursor-pointer rounded-r border border-l-0 pr-2">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_6522_696)">
                <path
                  d="M16.9848 17.5152C17.1313 17.6616 17.3687 17.6616 17.5152 17.5152C17.6616 17.3687 17.6616 17.1313 17.5152 16.9848L16.9848 17.5152ZM13.9632 7.54412C13.9632 11.0893 11.0893 13.9632 7.54412 13.9632V14.7132C11.5035 14.7132 14.7132 11.5035 14.7132 7.54412H13.9632ZM7.54412 13.9632C3.99894 13.9632 1.125 11.0893 1.125 7.54412H0.375C0.375 11.5035 3.58472 14.7132 7.54412 14.7132V13.9632ZM1.125 7.54412C1.125 3.99894 3.99894 1.125 7.54412 1.125V0.375C3.58472 0.375 0.375 3.58472 0.375 7.54412H1.125ZM7.54412 1.125C11.0893 1.125 13.9632 3.99894 13.9632 7.54412H14.7132C14.7132 3.58472 11.5035 0.375 7.54412 0.375V1.125ZM12.1319 12.6622L16.9848 17.5152L17.5152 16.9848L12.6622 12.1319L12.1319 12.6622Z"
                  fill="#234EEC"
                />
              </g>
              <defs>
                <clipPath id="clip0_6522_696">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <div>
          <button
            onClick={() => handleClickCreateButton()}
            className="h-full w-40 rounded border bg-blue-700 p-2 text-white"
            name=""
            id=""
          >
            {' '}
            Create
          </button>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default LayoutProjectManagement;
