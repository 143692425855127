import { t } from 'i18next';
import ChangeAvatarIcon from '../../Assets/Images/icon-change-avatar.svg';
import icDispatch from '../../Assets/Images/Icons/ic-dispatch.svg';
import backendURL from '../../config';

const DownLoadFile = (props) => {
  // Hàm kiểm tra file text
  const textExtensions = ['txt', 'doc', 'docx', 'pdf'];
  // eslint-disable-next-line no-unused-vars
  const isTextFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return textExtensions.includes(fileExtension);
  };

  // Hàm kiểm tra file ảnh
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const isImageFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };
  if (props.viewMode) {
    if (props.mode === 'form') {
      return (
        <img
          id="display-img"
          src={require('../../Assets/Images/avatar3.png')}
          alt="avatar"
          className={props.className}
        />
      );
    }
    if (props.mode === 'download') {
      return (
        <div className="  mt-3  flex   ">
          {props.files?.map((item, index) => {
            return (
              <div key={index} className={`${props.className ? props.className : `mr-3 h-[141px] w-[240px]`}`}>
                <div className=" rounded-lg border">
                  <div className="">
                    {props.uploadKey !== '' && (
                      <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
                    )}
                  </div>
                  <div className="">
                    <img
                      id={`img-${props.mode}-${item.fileSeq}`}
                      src={`${backendURL + '/file/' + 'displayImg/' + item.fileSeq}`}
                      alt="avatar"
                      className="relative h-[141px] w-[240px]"
                    />

                    <button
                      className="  mr-2 flex h-[35px] w-[240px] items-center justify-center rounded-md  bg-white px-4"
                      type="submit"
                      onClick={() => props.handleDownLoad(index)}
                    >
                      <p className="text-xs">{item.fileNm}</p>
                      <img src={icDispatch} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <img
          id={`img-${props.mode}-${props.seq}`}
          src={require('../../Assets/Images/avatar2.png')}
          alt="GroupWare Logo"
          className="w.5 mr-2 h-[48px] w-[48px] rounded-full object-cover text-base"
        />
      );
    }
  } else {
    if (props.mode === 'displayWctImg') {
      return (
        <div>
          {
            <div className="">
              {props.uploadKey !== '' && (
                <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
              )}

              <div className="upload-btn flex items-center rounded-[6px] bg-[#EDF5F5] p-[8px] text-[12px] font-[500] text-[#1294A4]">
                Thay đổi <img src={ChangeAvatarIcon} className="ml-[6px]" />
              </div>

              {/* <button type="button"
                                    className="flex items-center bg-white p-[8px] rounded-[6px] font-[500] text-[12px] text-black ml-[12px]"
                                    onClick={() => props.handleRemove(0)}>
                                    Gỡ file
                                </button> */}
            </div>
          }
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-4 gap-2">
          <div className="flex  gap-2">
            <div className="flex flex-col justify-items-start">
              {props.uploadKey !== '' && (
                <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
              )}

              <button
                type="button"
                className="upload-btn flex h-[120px] w-[200px] items-center justify-center rounded-[6px] border-2 border-dashed border-[#9C9C9C] bg-[#F2F2F2] p-[8px] text-center text-[12px] font-[500] text-black"
              >
                <div>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="m-auto"
                  >
                    <path
                      d="M16.5 12H8.5M12.5 16V8M1.5 12C1.5 18.0751 6.42487 23 12.5 23C18.5751 23 23.5 18.0751 23.5 12C23.5 5.92487 18.5751 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12Z"
                      stroke="#171717"
                      strokeLinecap="round"
                    />
                  </svg>
                  <p className="pt-2">
                    File {t('job.support')}: .png, .jpg, .pdf <br /> {t('job.maximum-capacity')} 10mb
                  </p>
                </div>
              </button>
              <p>{props.fileNm}</p>
            </div>
            {props.files?.map((item, index) => {
              const checkFileImage = isImageFile(item.fileNm); //Kiểm tra xem có phải là file ảnh không
              return (
                <div className=" " key={index} type="button">
                  <div className="relative  h-[120px] w-[200px] rounded-md border">
                    <button type="button" onClick={() => props.handleRemove(index)} className="absolute p-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="rounded-full bg-gray-500 p-1"
                      >
                        <path d="M12 4L4 12M12 12L4 4.00001" stroke="white" strokeLinecap="round" />
                      </svg>
                    </button>
                    <div>
                      {checkFileImage ? (
                        <img
                          id={`img-${props.mode}-${item.seq}`}
                          src={`${backendURL + '/file/' + 'displayImg/' + item.seq}`}
                          alt="Girl in a jacket"
                          className="h-[120px] w-[200px] rounded-md border"
                        />
                      ) : (
                        <div className="flex h-[120px] w-[200px] items-center justify-center rounded-xl border font-semibold uppercase text-[#686868]">
                          {item.fileNm.split('.').pop().toLowerCase()}
                        </div>
                      )}
                    </div>

                    {/* <div className="w-[200px] text-center"> */}
                    {props.uploadKey !== '' && (
                      <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
                    )}
                    {/* <p className="file-item" id={`file-item-${item.seq}`}>
                        {item.fileNm}
                      </p>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
};
export default DownLoadFile;
