import { Outlet, useLocation } from 'react-router-dom';
import { useSidebarContext } from '../../Utils/Context/SidebarContext';
import Sidebar from './Sidebar';

const MainLayout = () => {
  const { isFullSidebar } = useSidebarContext();
  const location = useLocation();

  return (
    <div>
      <div
        className={`animate__fadeInLeft animate__faster fixed top-0 left-0 z-10 border-spacing-1 border-r bg-white duration-700  ${
          location?.pathname?.indexOf('project-management') !== 1
            ? isFullSidebar
              ? 'w-[256px] max-w-[256px]'
              : 'w-[72px] max-w-[72px]'
            : 'w-[72px] max-w-[72px]'
        }`}
      >
        <Sidebar />
      </div>

      <main
        className={`z-50 bg-[#F7F9FF] ${location?.pathname === '/' ? '' : 'duration-700'}  ${
          location?.pathname?.indexOf('project-management') !== 1
            ? isFullSidebar
              ? ' ml-[256px]'
              : ' ml-[68px]'
            : ' ml-[68px]'
        }`}
      >
        <Outlet />
      </main>

      {/* Download file */}
      <iframe id="temp-frame" className="hidden"></iframe>
    </div>
  );
};

export default MainLayout;
