import instance from '../../instance';
// Api lấy vị trí của thành viên trong dự án
const apiMemberProject = {
  getPosition: (params) => {
    const url = `position/getAllPositionDFAndProjectId`;
    return instance.get(url, { params });
  },
  createBoard: (data) => {
    const url = `projboardcol/createBoardColumn`;
    return instance.post(url, data);
  },
  deleteBoard: (boardColumnId) => {
    const url = `projboardcol/deleteBoardColumn/${boardColumnId}`;
    return instance.delete(url);
  }
};
export default apiMemberProject;
