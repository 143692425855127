import instance from '../instance';

export const GetListUsers = (searchString) => {
  const url = `/user/listUser?searchString=${searchString}`;
  return instance.get(url);
};

export const GetCountReportSended = (userId) => {
  const url = `/report/countReport?isDraft=0&createBy=${userId}`;
  return instance.get(url);
};

export const GetCountPendingReceived = (userId) => {
  const url = `/report/countReport?isDraft=0&userId=${userId}&flag=pending`;
  return instance.get(url);
};

export const GetCountProcessedReceived = (userId) => {
  const url = `/report/countReport?isDraft=0&userId=${userId}&flag=processed`;
  return instance.get(url);
};

export const GetCountDraft = (userId) => {
  const url = `/report/countReport?isDraft=1&createBy=${userId}`;
  return instance.get(url);
};

export const GetDetailReport = (userId) => {
  const url = `/report/getReportById/${userId}`;
  return instance.get(url);
};

export const PostStatusSeen = (userId, reportId) => {
  const url = `/report/markAsRead?userId=${userId}&reportId=${reportId}`;
  return instance.post(url);
};
