import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../Common/Icon';
import useCloseModalOnOutsideClick from '../../../../OnOutsideClick/OnOutsideClick';
import TaskLabelPopup from './TaskLabelPopup';

const TaskLabel = ({ detailTask, setDetailTask, column, projectInfo }) => {
  const [isTaskLabel, setIsTaskLabel] = useState(false);
  const boardRef = useRef(null);
  useCloseModalOnOutsideClick(boardRef, setIsTaskLabel);
  const { t } = useTranslation();

  return (
    <div>
      <div className="z-50 mb-1 flex gap-1">
        {/* <Icon name="label_1" className="h-[13px] w-[16px] pt-2" /> */}
        <p className="text-[13px] font-normal text-[#172b4d]">{t('project.label')}</p>
      </div>
      <div className=" ml-1 flex flex-wrap items-center justify-start gap-1">
        {detailTask.projBoardLabelDTOS?.map((item, index) => (
          <div
            key={index}
            className=" inline-flex h-[30px] min-w-[50px] items-center justify-center gap-2.5 rounded-[5px] px-2.5 py-1.5"
            style={{ backgroundColor: item.colorCode }}
          >
            <p className="text-[13px] font-normal">{item.title}</p>
          </div>
        ))}
        <div className="relative">
          <div
            onClick={() => setIsTaskLabel(!isTaskLabel)}
            className="cursor-pointer rounded-md bg-gray-200 hover:bg-gray-300"
          >
            <Icon name="plus" className=" h-8 w-8 cursor-pointer   stroke-gray-600 p-2.5 " />
          </div>
          {isTaskLabel && (
            <div
              className={`absolute ${
                detailTask?.endDate === null ? 'right-[30%]' : 'left-[30%]'
              }  top-10 z-50 bg-white`}
              ref={boardRef}
            >
              <TaskLabelPopup
                setDetailTask={setDetailTask}
                detailTask={detailTask}
                setIsTaskLabel={setIsTaskLabel}
                column={column}
                projectInfo={projectInfo}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TaskLabel;
