import React, { useContext, useState } from 'react';

const BoardProjectContext = React.createContext();

// Context quản lý trạng thái state của bảng kanban
const ContextBoardProject = ({ children }) => {
  const [searchString, setSearchString] = useState('');
  const [isFilter, setIsFilter] = useState(false);
  const [statusTaskFilter, setStatusTaskFilter] = useState([]);
  const [listUserIdFilter, setListUserIdFilter] = useState([]);
  const [listLabelFilter, setListLabelFilter] = useState([]);
  const [callBackLable, setCallBackLable] = useState(false);
  const [callBackTask, setCallBackTask] = useState(false);
  const updateSearchString = (value) => {
    setSearchString(value);
  };

  const updateStatusTaskFilter = (value) => {
    setIsFilter(true);
    // Xử lý dữ liệu truyền vào trạng thái để lọc
    const isSelected = statusTaskFilter.includes(value);
    if (isSelected) {
      setStatusTaskFilter((prevListStatusTask) => prevListStatusTask.filter((id) => id !== value));
    } else {
      setStatusTaskFilter([...statusTaskFilter, value]);
    }
  };

  const updateUserListIdFilter = (value) => {
    setIsFilter(true);
    // Xử lý dữ liệu truyền vào id user để lọc
    const isSelected = listUserIdFilter.includes(value);
    if (isSelected) {
      setListUserIdFilter((prevSelectedUserIds) => prevSelectedUserIds.filter((id) => id !== value));
    } else {
      setListUserIdFilter([...listUserIdFilter, value]);
    }
  };

  const updateListLabelFilter = (value) => {
    setIsFilter(true);
    // Xử lý dữ liệu truyền vào label để lọc
    const isSelected = listLabelFilter.includes(value);
    if (isSelected) {
      setListLabelFilter((prevListLabelFilter) => prevListLabelFilter.filter((id) => id !== value));
    } else {
      setListLabelFilter([...listLabelFilter, value]);
    }
  };

  const resetSearchAndFilter = () => {
    // Xóa hết dữ liệu tìm kiếm với lọc
    setIsFilter(false);
    setSearchString('');
    setStatusTaskFilter([]);
    setListUserIdFilter([]);
    setListLabelFilter([]);
  };

  return (
    <BoardProjectContext.Provider
      value={{
        searchString,
        updateSearchString,
        statusTaskFilter,
        updateStatusTaskFilter,
        listUserIdFilter,
        updateUserListIdFilter,
        listLabelFilter,
        updateListLabelFilter,
        resetSearchAndFilter,
        isFilter,
        callBackLable,
        setCallBackLable,
        callBackTask,
        setCallBackTask
      }}
    >
      {children}
    </BoardProjectContext.Provider>
  );
};

export const useBoardProjectContext = () => {
  return useContext(BoardProjectContext);
};

export { ContextBoardProject };
