const validateNoLeadingSpace = (value) => {
  // Kiểm tra xem giá trị có dấu cách ở đầu không
  const hasLeadingSpace = /^\s/.test(value);

  // Kiểm tra xem sau dấu cách có ký tự khác không
  const hasCharAfterSpace = /\s\S/.test(value);

  if (hasLeadingSpace && !hasCharAfterSpace) {
    return 'Vui lòng nhập thông tin';
  }
  return true;
};
export default validateNoLeadingSpace;
