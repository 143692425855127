import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import apiMemberProject from '../../../Apis/ProjectManagement/Infomation/Member';
import requestClient from '../../../Apis/requestClient';
import AttachFile from '../../AttachFile/AttachFile';
import { Button } from '../../Buttons/ButtonComponent';
import Icon from '../../Common/Icon';
import Popup from '../../Popup/Popup';

const UpdateMemberPopup = ({ isOpen, setIsOpen, projectId, memberInfo, getMemberByProjectId, projectType }) => {
  const { register, reset, handleSubmit } = useForm();
  const [positionList, setPositionList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [positionIdList, setPositionIdList] = useState([]);
  const [isPosition, setIsPosition] = useState(false);
  const { t } = useTranslation();

  const resetForm = () => {
    reset({
      roleNumber: memberInfo?.roleNumber,
      expirationDate: memberInfo?.expirationDate
        ? new Date(memberInfo.expirationDate).toLocaleDateString('en-CA') // Định dạng ngày thành YYYY-MM-DD
        : ''
    });
  };

  const handleOptionSelect = (option) => {
    setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, option?.positionName]);
    setPositionIdList((prevSelectedOptions) => [...prevSelectedOptions, option?.positionId]);
  };

  const handleOptionDeselect = (option) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions?.filter((selectedOption) => selectedOption !== option)
    );
    setPositionIdList((prevSelectedOptions) =>
      prevSelectedOptions.filter(
        (setPositionIdList) =>
          setPositionIdList !== positionList?.find((element) => element?.positionName === option)?.positionId
      )
    );
  };

  const onsubmit = async (data) => {
    const body = {
      memberId: memberInfo?.memberId,
      roleNumber: Number(data?.roleNumber),
      projectId: projectId,
      expirationDate: `${data?.expirationDate ? `${data?.expirationDate}T00:00:00` : memberInfo.expirationDate}`,
      positionIdList: positionIdList
    };
    try {
      await requestClient({
        endPoint: `/member/updateMember`,
        method: 'post',
        body: body
      });
      setIsOpen(false);
      getMemberByProjectId();
      toast.success(t('personnel-management.update-successful'), { position: 'top-right' });
    } catch (error) {
      toast.error(t('project.the-expiration'), { position: 'top-right' });
      console.log(error);
    }
  };

  const getPosition = async () => {
    try {
      const listPositionProject = await apiMemberProject.getPosition({
        modelInfo: projectType,
        projectId: projectId ? projectId : 0
      });
      setPositionList(listPositionProject?.data?.dataList);
    } catch (error) {
      console.log(error);
    }
  };

  const resetAll = () => {
    setSelectedOptions([]);
    setPositionList([]);
    setIsPosition(false);
  };

  const dateElement = document.getElementById('date-user');
  // validate input date new
  if (dateElement) {
    dateElement.min = new Date().toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  useEffect(() => {
    getPosition();
    resetForm();

    if (memberInfo && memberInfo.positionDTOList) {
      const positionsId = memberInfo.positionDTOList.map((position) => position.positionId);
      const positionsName = memberInfo.positionDTOList.map((position) => position.positionName);

      setPositionIdList(positionsId);
      setSelectedOptions(positionsName);
    }
  }, [isOpen]);

  return (
    <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="max-h-[90vh] w-[980px] max-w-[95vw] rounded-xl bg-white p-6 focus:outline-none">
        <div className="flex justify-between">
          <p className="text-lg font-semibold">{t('project.edit-member')}</p>

          <Icon
            name="close_cross"
            onClick={() => {
              setIsOpen(false);
              resetAll();
            }}
            className="h-[18px] w-[18px] cursor-pointer stroke-black transition-all duration-75 ease-in-out hover:stroke-neu-400 hover:stroke-2 active:scale-90"
          />
        </div>

        <div className="mt-6 inline-flex items-center rounded-lg border border-neu-200 p-1">
          <AttachFile
            attachType="UserAvatar"
            entity="users"
            seq={memberInfo?.userId}
            viewMode={true}
            mode="user-avatar"
            className="mr-4 h-10 w-10 rounded-full object-cover"
          />
          <div className="text-sm">
            <p>{memberInfo?.fullName}</p>
            <p className="text-neu-200">{memberInfo?.userName ? `@${memberInfo?.userName}` : ''}</p>
          </div>
        </div>

        <div className="mt-6">
          <form onSubmit={handleSubmit(onsubmit)} className="mt-4">
            <label className="w-full">
              <p className="text-sm">
                <span className="text-neu-200">{t('project.role')}</span>
                <span className="text-neu-800"> *</span>
              </p>
              {!memberInfo?.isAdmin && memberInfo?.roleNumber !== 1 ? (
                <select
                  {...register('roleNumber')}
                  placeholder={t('project.role')}
                  className="mt-1 w-full rounded-md bg-primary-50 py-2 px-3 focus:border-primary-100 focus:outline-none"
                >
                  <option value="" disabled hidden>
                    {t('project.role')}
                  </option>

                  <option value="1">Owner</option>
                  <option value="2">Editor</option>
                  <option value="3">Viewer</option>
                </select>
              ) : (
                <div className="mt-1 w-full rounded-md bg-primary-50 py-2 px-3">
                  <p>
                    {memberInfo?.roleNumber === 1 && 'Owner'}
                    {memberInfo?.roleNumber !== 1 && 'Admin'}
                  </p>
                </div>
              )}
            </label>

            {!memberInfo?.isAdmin && (
              <div className="mt-4 inline-block w-full">
                <p className="text-sm">
                  <span className="text-neu-200">{t('project.position')} </span>
                  <span className="text-neu-800">*</span>
                </p>

                <div className="relative">
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsPosition(!isPosition);
                    }}
                    className="mt-1 flex w-full cursor-pointer items-center justify-between rounded-md bg-primary-50 py-2 px-3 pr-0.5 focus:border-primary-100 focus:outline-none"
                  >
                    <ul className="flex flex-wrap gap-1 text-sm font-normal">
                      {selectedOptions.map((item, index) => {
                        return (
                          <li key={index} className="flex justify-between gap-x-1 rounded-lg border p-1">
                            <p>{item}</p>

                            <Icon
                              name="close_cross"
                              className={` h-5 w-5 stroke-black transition-all hover:stroke-red-500`}
                              onClick={(event) => {
                                if (positionIdList?.length <= 1) {
                                  return;
                                }
                                event.stopPropagation();
                                handleOptionDeselect(item);
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>

                    <Icon name="chevron_down" className={`stroke-black transition-all`} />
                  </div>

                  {isPosition && (
                    <div
                      className={`${
                        isPosition ? 'max-h-[624px]' : 'hidden max-h-12'
                      } transition-max-h my-6 flex w-full flex-col gap-4 overflow-hidden duration-700 ease-in-out`}
                    >
                      <ul className="scroll-y flex h-auto max-h-[168px] flex-col gap-y-4 overflow-auto">
                        {positionList.map((item, index) => {
                          return (
                            <li key={index} className="flex items-center justify-start px-3">
                              <label className="flex cursor-pointer items-center text-sm font-medium">
                                <input
                                  type="checkbox"
                                  checked={positionIdList.includes(item?.positionId)}
                                  onClick={() => {
                                    if (positionIdList?.length <= 1 && selectedOptions.includes(item?.positionName)) {
                                      return;
                                    }
                                    selectedOptions.includes(item?.positionName)
                                      ? handleOptionDeselect(item.positionName)
                                      : handleOptionSelect(item);
                                  }}
                                  className="form-check-input mr-3 h-5 w-5 cursor-pointer appearance-none rounded-md
                              border border-[#333333] bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:border-primary-100 focus:outline-none"
                                />

                                {item?.positionName}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}

            <label className="mt-4 inline-block w-full">
              <p className="text-sm text-neu-200">{t('project.due-date')}</p>

              <input
                id="date-user"
                type="date"
                {...register('expirationDate')}
                className="mt-1 w-full rounded-md bg-primary-50 py-2 px-3 placeholder:text-sm focus:border-primary-100 focus:outline-none"
              />
            </label>

            <div className="mt-6 flex justify-center gap-x-4">
              <Button
                content={t('project.cancel')}
                onClick={() => {
                  setIsOpen(false);
                  resetAll();
                }}
                className="min-w-[98px] border border-primary-100 px-3 py-2 text-primary-100"
              />

              <Button
                type="submit"
                content={t('project.confirm')}
                className="min-w-[98px] border border-primary-100 bg-primary-100 px-3 py-2 text-white"
              />
            </div>
          </form>
        </div>
      </div>
    </Popup>
  );
};

export default UpdateMemberPopup;
