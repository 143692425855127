import instance from '../instance';

export const GetWorkContract = (currentPage) => {
  const url = `/contract/list?page=${currentPage}&size=10`;
  return instance.get(url);
};

// Api list danh sách, phân trang , lọc theo tên , trạng thái của các users có hợp đồng
export const GetWorkContractFilter = (
  searchUserName,
  searchStatus,
  searchType,
  searchContractNumber,
  searchPeriod,
  currentPage,
  valueFilter
) => {
  if (!valueFilter) {
    valueFilter = {};
  }
  if (valueFilter.period == null) {
    valueFilter.period = searchPeriod;
  }
  if (searchPeriod === 2 || searchPeriod === 1) {
    valueFilter.period = searchPeriod;
  }
  // if (!searchPeriod) {
  //   valueFilter.period = null;
  // }

  const url = `/contract/list?page=${searchUserName === '' ? currentPage : 0}&size=10&${
    searchUserName === undefined ? '' : 'fullName=' + searchUserName
  }${searchContractNumber === undefined ? '' : '&contractNumber=' + searchContractNumber}${
    !valueFilter.status ? '' : '&status=' + valueFilter.status
  }${!valueFilter.type ? '' : '&type=' + valueFilter.type}${
    !valueFilter.period ? '' : '&period=' + valueFilter.period
  }${!valueFilter.orders || valueFilter.orders === '' ? `&orders=createdAt+asc` : `&orders=${valueFilter.orders}`}`;

  return instance.get(url);
};

export const getWorkContractByUserId = (userId) => {
  const url = `/contract/getWorkContractByUserId/${userId}`;
  return instance.get(url);
};
// End
export const UpdateWorkContract = (id, data) => {
  const url = `/contract`;
  return instance.post(url, data);
};

export const AddWorkContract = (data) => {
  const url = `/contract`;

  return instance.post(url, data);
};
