import { toast } from 'react-toastify';

export const NotifyMe = (data, userInfo) => {
  const contentNotification = userInfo?.language === 'en' ? data?.notifyContentEN : data?.notifyContentVi;

  // Sử dụng regex để loại bỏ thẻ HTML
  const textContent = contentNotification.replace(/<\/?[^>]+(>|$)/g, '');
  if (!window.Notification) {
    console.log(
      userInfo?.language === 'en'
        ? 'The browser does not support notifications!'
        : 'Trình duyệt không hỗ trợ thông báo!'
    );
  } else {
    // check if permission is already granted
    if (Notification.permission === 'granted') {
      const notify = new Notification(
        userInfo?.language === 'en' ? 'You receive new notifications' : 'Nhận được thông báo mới',
        {
          title: userInfo?.language === 'en' ? data?.notifyContentEN : data?.notifyContentVi,
          body: textContent,
          data: {
            status: 'open'
          },
          // eslint-disable-next-line no-undef
          icon: process.env.PUBLIC_URL + '/apple-touch-icon.png',
          requireInteraction: true,
          silent: true
        }
      );

      notify.onclick = function () {
        // eslint-disable-next-line no-undef
        window.open(process.env.PUBLIC_URL, '_blank');
      };

      notify.onclose = function () {};

      // notify.onerror = function (event) {
      //   console.log('Có lỗi xảy ra trong quá trình hiển thị thông báo');
      // };

      // notify.onshow = function (event) {
      //   // eslint-disable-next-line no-undef
      //   console.log(process.env.PUBLIC_URL + data?.link);
      // };

      // setTimeout(() => {
      //   notify.close();
      // }, 5000);
    } else {
      // request permission from user
      Notification.requestPermission()
        .then(function (p) {
          // eslint-disable-next-line no-empty
          if (p === 'granted') {
          } else {
            toast.success(
              <p
                id="notify-title-toast"
                className={'notify-title'}
                dangerouslySetInnerHTML={{
                  __html: userInfo?.language === 'en' ? data?.notifyContentEN : data?.notifyContentVi
                }}
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
              }
            );
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  }
};
