import instance from './instance';

export const GetAllDept = (userId, deptName, userID, currentPage, itemNumber, valueFilterDept) => {
  const url = `/department/listByUpper/${userId}?page=${currentPage}&size=${itemNumber}${
    deptName === undefined ? '' : '&deptName=' + deptName
  }${userID === undefined ? '' : '&userId=' + userID}${
    valueFilterDept?.lockAt === undefined ? '&lockAt=N' : '&lockAt=' + valueFilterDept?.lockAt
  }`;
  return instance.get(url);
};

export const GetAllDepartment = (upperID, currentPage, itemNumber, valueFilterDept, valueSearchDept, userId) => {
  const url = `/department/listByUpper/${upperID}?page=${currentPage}&size=${itemNumber}${
    !valueSearchDept ? '' : '&deptName=' + valueSearchDept
  }${userId === undefined ? '' : '&userId=' + userId}${
    valueFilterDept?.lockAt === undefined ? '&lockAt=N' : '&lockAt=' + valueFilterDept?.lockAt
  }`;
  return instance.get(url);
};

export const GetAllUserInCompany = (currentPage, valueFilter) => {
  const url = `/company/getAllMemberCompany?page=${currentPage}&size=10${
    !valueFilter?.memberName ? '' : '&searchString=' + valueFilter?.memberName
  }${!valueFilter?.orders ? `` : `&orders=${valueFilter?.orders}`}`;
  return instance.get(url);
};

export const GetDetailCompany = () => {
  const url = `/company/getCompany`;
  return instance.get(url);
};

export const GetDetailDeptOrGroup = (id) => {
  const url = `/department/getDeptInfo/${id}`;
  return instance.get(url);
};

export const GetDept = (userID) => {
  const url = `/department/listByUpper/0?userId=${userID}`;
  return instance.get(url);
};

export const CreateDept = (dept) => {
  const url = `/department`;
  return instance.post(url, dept);
};

export const ExistsDepartment = (deptName, upperID) => {
  const url = `/department/existsByDeptName?deptName=${deptName}&upperID=${upperID}`;
  return instance.get(url);
};

export const CheckUserIsGroupLeader = (upperID, userID, deptId) => {
  const url = `/user/checkUserIsTeamLeader?upperID=${upperID}&userID=${userID}&deptId=${deptId}`;
  return instance.get(url);
};

export const updateInformationCompany = (data) => {
  // Cập nhật thông tin Phòng ban Company
  const url = `/company/updateCompany`;
  return instance.put(url, data);
};
