import { t } from 'i18next';
import { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import EditorToolbar, { formats, modules } from '../../../../../Utils/Quills/EditorToolbar';
import { Button } from '../../../../Buttons/ButtonComponent';
import Icon from '../../../../Common/Icon';

const CommentQuill = forwardRef(
  (
    {
      setIsComment,
      setComment,
      comment,
      handleChange,
      onSubmit,
      handlePaste,
      isEmpty = false,
      onKeyDownValue,
      detailTask,
      setDetailTask,
      dataFilelink,
      lengthValueComment,
      setLengthValueComment
    },
    ref
  ) => {
    return (
      <div className="w-full">
        {/* ReactQuill component with forwarded ref */}
        <EditorToolbar dataFilelink={dataFilelink} setDetailTask={setDetailTask} detailTask={detailTask} quill={ref} />
        <ReactQuill
          ref={ref}
          onBlur={handlePaste}
          // theme="snow"
          id="bzCardComment"
          className="w-full bg-neu-500"
          modules={modules}
          formats={formats}
          // value={comment}
          onChange={handleChange}
          placeholder={t('project.enter-comment')}
          onKeyDown={onKeyDownValue}
        />

        {/* Buttons and Icons */}
        <div className="mt-3 flex items-center gap-x-4">
          <Button
            content={t('account-management.save')}
            onClick={onSubmit}
            disabled={!(lengthValueComment < 4000 && comment.slice(2, -4).trim())}
            className={`border px-3 py-1 text-base ${
              lengthValueComment < 4000 && comment.slice(2, -4).trim()
                ? 'cursor-pointer border-primary-100 bg-primary-100 text-white'
                : 'cursor-not-allowed bg-neu-500 text-neu-200'
            }`}
          />

          {/* Icon for closing */}
          <Icon
            name="close_cross"
            onClick={() => {
              setLengthValueComment(0);
              setIsComment(false);
              if (isEmpty) {
                setComment('');
              }
            }}
            className="cursor-pointer stroke-black hover:stroke-neu-800"
          />
        </div>

        {lengthValueComment >= 4000 && (
          <div className={`mt-1 flex items-center gap-x-2 text-sm font-normal text-neu-800`}>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.5 6V10.5M9.5 12.75V12.7575M17.75 9C17.75 13.5563 14.0563 17.25 9.5 17.25C4.94365 17.25 1.25 13.5563 1.25 9C1.25 4.44365 4.94365 0.75 9.5 0.75C14.0563 0.75 17.75 4.44365 17.75 9Z"
                stroke="#D64A40"
                strokeWidth="0.75"
                strokeLinecap="round"
              />
            </svg>

            <span>{t('project.comment-to-long')}</span>
          </div>
        )}
      </div>
    );
  }
);

CommentQuill.displayName = 'CommentQuill';

export default CommentQuill;
