import instance from '../instance';

export const ReadLastSpace = (userId) => {
  const url = `/space/lastSpace/${userId}`;
  return instance.get(url);
};

export const CreateWorkspace = (params) => {
  const url = `/space`;
  return instance.post(url, params);
};

// export const ReadWorkspace = (spaceId) => {
//   const url = `/space/${spaceId}`;
//   return instance.get(url);
// };

export const ReadWorkspace = (spaceId) => {
  const url = `/space/${spaceId}?checkNumber=${true}`;
  return instance.get(url);
};
export const ReadWorkspaceOne = (spaceId) => {
  const url = `/space/${spaceId}?checkNumber=${false}`;
  return instance.get(url);
};
// Danh sách space gần nhất
export const RecentWorkspace = (spaceId) => {
  const url = `/space/recent?userId=${spaceId}`;
  return instance.get(url);
};

export const moveWorkspaceToTrash = (data, spaceId) => {
  const url = `/space/disable?disable=${data}&spaceId=${spaceId}`;
  return instance.post(url);
};
// Xóa space
export const DeleteWorkspace = (spaceId) => {
  const url = `/space/${spaceId}`;
  return instance.delete(url);
};

/*Params: 
  userId === number (user sing in)
  disable === "N" (un disable)
  disable === "Y" (disable)
*/
export const ReadSpaceList = (disable, page, size, userId) => {
  const url = `/space?disable=${disable}&page=${page}&size=${size}&userId=${userId}`;
  return instance.get(url);
};

export const ReadSpaceAll = (disable, userId) => {
  const url = `/space?disable=${disable}&userId=${userId}`;
  return instance.get(url);
};

export const ReadUserList = (spaceId) => {
  const url = `/space/usersList?spaceId=${spaceId}`;
  return instance.get(url);
};
// Api list danh sách space bị ẩn
export const ListSpaceHide = (spaceId) => {
  const url = `/space/recent?userId=${spaceId}&disable=Y`;
  return instance.get(url);
};
export const UpdateWorkspaceTitle = (spaceId, params) => {
  const url = `/space/${spaceId}`;
  return instance.put(url, params);
};
export const UpdateUserSpace = (spaceId, params) => {
  const url = `/space/changeUsers/${spaceId}`;
  return instance.put(url, params);
};
// Api List danh sách space được ghim
export const ListSpacePin = (userId) => {
  const url = `/space/pined?userId=${userId}`;
  return instance.get(url);
};
// Api ghim space
export const PinCreateSpace = (status, spaceId) => {
  const url = `/space/pined?pined=${status}&spaceId=${spaceId}`;
  return instance.post(url);
};
// Api Space đang được  chọn
export const ListSpaceCurrent = () => {
  const url = `/space/current`;
  return instance.get(url);
};
// Api thay đổi vị trí space
export const ChangePosition = (spaceStart, spaceEnd) => {
  const url = `/space/swap?spaceStart=${spaceStart}&spaceEnd=${spaceEnd}`;
  return instance.post(url);
};
// Api rời khỏi space
export const LeaveSpace = (userId, spaceId) => {
  const url = `/space/leftSpace?userId=${userId}&spaceId=${spaceId}`;
  return instance.post(url);
};
// Api xóa space

// space/8
