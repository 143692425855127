import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import projectPosApi from '../../../Apis/ProjectManagement/Infomation/Position';
import WarningIc from '../../../Assets/Images/Icons/warning-ic.svg';
import { useGlobalContext } from '../../../Utils/Context';
import { Button } from '../../Buttons/ButtonComponent';
import Pagination from '../../Buttons/Pagination';
import { calendarDateModalInput } from '../../Calendar/CalendarComponent';
import Filter from '../../Common/Filter';
import Icon from '../../Common/Icon';
import useCloseModalOnOutsideClick from '../../OnOutsideClick/OnOutsideClick';
import Popup from '../../Popup/Popup';
import BreadcrumbProject from '../BreadcrumbProject';

const ProjectRole = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const retrievedProject = JSON.parse(localStorage.getItem('project'));
  const { t } = useTranslation();
  const { userInfo } = useGlobalContext();

  const [typePosDefault, setTypePosDefault] = useState(false);
  const [callBackRole, setCallBackRole] = useState(false);
  const [deleteIdMember, setDeleteIdMember] = useState();
  const [addEditPosition, setAddEditPosition] = useState();
  const [modalDelete, setModalDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchName, setSearchName] = useState('');
  const [modalPosition, setModalPosition] = useState(false);
  const [operation, setOperation] = useState(false);
  const [idPosition, setIdPosition] = useState();
  const { roleUser } = useGlobalContext();
  const modalRef = useRef(null);
  useCloseModalOnOutsideClick(modalRef, setOperation);
  const itemNumber = 10;
  const [listMember, setListMember] = useState();
  const pagination = listMember;
  const [filterProject, setFilterProject] = useState({ name: t('project.create-time'), order: 'createdAt' });
  const filterName = filterProject.order;
  const [arrange, setArrange] = useState('desc');
  const { projectId } = useParams();
  // Lấy danh danh vị trí
  const getAllPos = async () => {
    try {
      const data = await projectPosApi.getAllPos(
        currentPage,
        searchName,
        filterName,
        arrange,
        typePosDefault ? 0 : projectId,
        retrievedProject?.type
      );
      setListMember(data?.data);
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  // Phương thức tìm kiếm tên dự án
  const handleChangeNameProject = (e) => {
    setSearchName(e.target.value);
    setCurrentPage(0);
  };
  useEffect(() => {
    getAllPos();
  }, [typePosDefault, currentPage, searchName, arrange, filterName, callBackRole]);
  //  Thêm , sửa vị trí
  const onSubmitPosition = async (data) => {
    try {
      if (addEditPosition) {
        const newData = {
          projectId: projectId,
          positionName: data.positionName,
          modelInfo: retrievedProject.type,
          description: data.description
        };
        await projectPosApi.createPosition(newData);
      } else {
        const newDataEdit = {
          positionId: idPosition?.positionId,
          positionName: data.positionName,
          modelInfo: retrievedProject.type,
          description: data.description
        };
        await projectPosApi.editPosition(newDataEdit);
      }
      toast.success(
        addEditPosition ? t('project.successfully-created-position') : t('project.successfully-edited-position'),
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        }
      );
      setModalPosition(false);
      setTypePosDefault(false);
      setCallBackRole(!callBackRole);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-right'
      });
    }
  };
  // Hiển thị chi tiết vị trí muốn xóa
  const handleEditPosition = async (item) => {
    setIdPosition(item);
    const { data } = await projectPosApi.getReadPos(item?.positionId);
    reset(data.data);
    setModalPosition(true);
  };
  // Xóa vị trí
  const handleDelete = async () => {
    try {
      await projectPosApi.deletePosition(idPosition?.positionId);
      setModalDelete(false);
      toast.success(t('project.successfully-deleted-position'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
      setCallBackRole(!callBackRole);
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  const handleCreate = () => {
    setModalPosition(true), setAddEditPosition(true), reset({ description: '', positionName: '' });
  };
  return (
    <div className="h-[100vh]  px-4">
      {/* Đường dẫn */}
      <BreadcrumbProject data={[{ name: t('project.role'), link: '' }]} />
      {/* Tiêu đề , thêm vai trò */}
      <div className="flex justify-between py-4">
        <div>
          <h2 className="text-[20px] font-semibold">{t('project.location-list')}</h2>
          <p className="text-sm text-[#686868]">{t('project.list-of-all-positions')}</p>
        </div>
        <div className="flex justify-end gap-4">
          {(roleUser === 'ROLE_ADMIN' || roleUser === 'ROLE_SUPER_ADMIN' || retrievedProject?.roleProject === 1) && (
            <button
              onClick={() => {
                handleCreate();
              }}
              className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center gap-2 rounded-lg bg-primary-100 px-3  text-white hover:bg-blue-800"
            >
              <Icon name="plus_circle" className="pt-0.5" />
              <p className="text-[14px]">{t('project.create-location')}</p>
            </button>
          )}
        </div>
      </div>
      {/* Chuyển tab giữa vị trí mặc định và vị trí khác (Vị trí tạo thêm) */}
      <div>
        <button
          className={`${
            !typePosDefault ? 'bg-primary-100 text-white' : 'border-b border-primary-100'
          } h-[40px] max-h-[40px] w-[150px] rounded-t-[8px] text-center text-black`}
          onClick={() => setTypePosDefault(false)}
        >
          {t('project.other-position')}
        </button>
        <button
          className={`${
            typePosDefault ? 'bg-primary-100 text-white' : 'border-b border-primary-100'
          } h-[40px] max-h-[40px] w-[150px] rounded-t-[8px]  text-center text-black`}
          onClick={() => setTypePosDefault(true)}
        >
          {t('project.default-location')}
        </button>
      </div>
      {/* End */}
      {/* Lọc , tạo vị trí*/}
      <div className="mt-6 flex justify-between gap-6">
        {/* Tìm kiếm vị trí */}
        <div className="relative flex w-[70%]">
          <div className="absolute left-3 translate-y-1/2">
            <Icon name="zoom_search" className="fill-black" />
          </div>
          <input
            type="text"
            placeholder={t('project.location_name')}
            className="w-full rounded-lg bg-white py-2 pr-3 pl-10 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)]  placeholder:text-sm focus:border-primary-100 focus:outline-none"
            onChange={handleChangeNameProject}
          />
        </div>
        {/* Lọc theo  điều kiện , thời gian tạo , cập nhật , tên vị trí */}
        <div className="w-[30%]">
          <Filter
            filterList={[
              { name: t('project.create-time'), order: 'createdAt' },
              { name: t('project.update-time'), order: 'updatedAt' },
              { name: t('project.location_name'), order: 'positionName' }
            ]}
            filterName={filterProject}
            setFilterName={setFilterProject}
            arrange={arrange}
            setArrange={setArrange}
            setCurrentPage={setCurrentPage}
          />
        </div>
        {/* End */}
      </div>
      {/* Danh sách vị trí */}
      <div className="mt-4 h-[67vh] overflow-y-auto text-[14px]">
        <div className="rounded-xl border">
          <table className=" w-full table-auto rounded-xl bg-[#F2F2F2]">
            <thead className=" w-full ">
              <tr className="  ">
                <th className="w-[25%]  p-4 text-left">{t('project.role_name')}</th>
                <th className="w-[50%] py-3 px-4 text-left">{t('calendar.description')}</th>
                <th className="w-[25%]   py-3 px-4 text-left ">
                  <p>{t('project.create-time')}</p>
                  <p>{t('project.update-time')}</p>
                </th>
                <th className=""></th>
              </tr>
            </thead>

            <tbody className=" ">
              {listMember?.positions?.map((item, index) => {
                return (
                  <tr className="cursor-pointer rounded-xl  border-t bg-white " key={index}>
                    {/* Tên vị trí */}
                    <td className="  p-2 px-4 text-left font-semibold">
                      <p>
                        <span className="rounded-lg border border-primary-100 py-2 px-2">{item?.positionName}</span>
                      </p>
                    </td>
                    {/* Mô tả */}
                    <td className="p-2 px-4 text-left ">
                      <p className="text-[#686868]">{item?.description}</p>
                    </td>
                    <td className=" p-2 px-4 text-left ">
                      {/* Thời gain tạo */}
                      <p className="flex items-center text-[#686868]">
                        <Icon name="row_of_list" className="stroke-[#9C9C9C]" />{' '}
                        <span className="pl-3 font-semibold text-black">
                          {calendarDateModalInput(item?.createdAt, userInfo)}
                        </span>
                      </p>
                      {/* Thời gian cập nhật */}
                      <p className="flex items-center py-1 text-[#686868]">
                        <Icon name="row_of_list_2" className="stroke-[#9C9C9C]" />
                        <span className="pl-3  font-semibold text-black">
                          {calendarDateModalInput(item?.updatedAt, userInfo)}
                        </span>
                      </p>
                    </td>
                    {/* Thao tác  sửa , xóa thành viên */}
                    <td
                      className="relative  p-2  px-4 text-left"
                      onClick={() => {
                        setOperation(!operation), setDeleteIdMember(item.positionId);
                      }}
                    >
                      {typePosDefault === false &&
                        (roleUser === 'ROLE_ADMIN' ||
                          roleUser === 'ROLE_SUPER_ADMIN' ||
                          retrievedProject?.roleProject === 1) && (
                          <div className="rounded bg-[#F2F2F2] px-4 py-2">
                            <Icon name="small_button" />
                          </div>
                        )}
                      {operation === true && deleteIdMember === item.positionId && (
                        <div
                          ref={modalRef}
                          className="absolute  -left-44 top-2 z-30 w-[160px] rounded-md bg-white p-2 text-center  text-lg font-medium  shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                        >
                          <div className=" flex  gap-4  p-1 hover:bg-gray-200">
                            <Icon name="edit_2" className="fill-[#171717]" />
                            <p
                              className="py-1 text-sm"
                              onClick={() => {
                                handleEditPosition(item), setAddEditPosition(false);
                              }}
                            >
                              {t('project.edit-location')}
                            </p>
                          </div>
                          <div className="flex  gap-4 border-t p-1 hover:bg-gray-200">
                            <Icon name="trash_alt" className="fill-red-500" />
                            <p
                              className="  py-1 text-sm text-red-500"
                              onClick={() => {
                                setIdPosition(item), setModalDelete(true);
                              }}
                            >
                              {t('project.delete-location')}
                            </p>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Phân trang - Pagination */}
        {typePosDefault === false && listMember?.totalItems > 9 && (
          <div className=" mt-2 flex w-full justify-end px-6">
            <Pagination {...{ setCurrentPage, currentPage, pagination, itemNumber }} />
          </div>
        )}
        {/* Form tạo , sử vị trí */}
        <Popup isOpen={modalPosition} setIsOpen={setModalPosition}>
          <form onSubmit={handleSubmit(onSubmitPosition)}>
            <div className="relative  max-h-[90vh] w-[700px] rounded-xl bg-white p-8 ">
              <div className="mb-5 flex justify-between">
                <p className="text-lg font-semibold">
                  {' '}
                  {addEditPosition ? t('project.create-location') : t('project.edit-location')}
                </p>
                <Icon
                  name="close_cross"
                  onClick={() => {
                    setModalPosition(false);
                  }}
                  className="h-[18px] w-[18px] cursor-pointer stroke-black transition-all duration-75 ease-in-out hover:stroke-neu-400 hover:stroke-2 active:scale-90"
                />
              </div>
              <div>
                <p className="mb-2 text-sm">
                  {t('project.location_name')} <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3 py-1.5 text-sm font-medium placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                  placeholder={t('project.enter-location-name')}
                  {...register('positionName', {
                    required: true,
                    maxLength: 25,
                    validate: (value) => value.trim() !== '' || t('account-management.no-be-eft-blank') // Kiểm tra không để trống hoặc toàn khoảng trắng
                  })}
                />
                {errors?.positionName?.type === 'required' && (
                  <p className="flex gap-1 px-2 pt-1">
                    <img src={WarningIc} alt="warning-icon" />
                    <span className="text-xs font-normal text-red-500">{t('account-management.no-be-eft-blank')}</span>
                  </p>
                )}
                {errors?.positionName?.type === 'maxLength' && (
                  <p className="flex gap-1 px-2 pt-1">
                    <img src={WarningIc} alt="warning-icon" />
                    <span className="text-xs font-normal text-red-500">{t('project.maximum-25-characters')}</span>
                  </p>
                )}
                {errors?.positionName?.type === 'validate' && (
                  <p className="flex gap-1 px-2 pt-1">
                    <img src={WarningIc} alt="warning-icon" />
                    <span className="text-xs font-normal text-red-500">{t('account-management.no-be-eft-blank')}</span>
                  </p>
                )}
              </div>

              <div className="my-4">
                <p className="mb-2 text-sm">{t('calendar.description')}</p>
                <input
                  type="text"
                  className="w-full rounded-lg border border-[#F1F3FD] bg-[#F1F3FD] px-3  py-1.5 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                  placeholder={t('project.add-description')}
                  {...register('description', { maxLength: 55 })}
                />
                {errors?.description?.type === 'maxLength' && (
                  <p className="flex gap-1 px-2 pt-1">
                    <img src={WarningIc} alt="warning-icon" />
                    <span className="text-xs font-normal text-red-500">
                      {' '}
                      {t('account-management.up-to-55-characters')}
                    </span>
                  </p>
                )}
              </div>
              <div className="flex justify-center gap-8">
                <div
                  className="w-[100px] cursor-pointer rounded-lg border border-primary-100 py-1.5  text-center text-primary-100"
                  onClick={() => setModalPosition(false)}
                >
                  {t('project.cancel')}
                </div>
                <button className="w-[100px] rounded-lg  bg-primary-100 py-1.5  text-center text-white">
                  {t('project.confirm')}
                </button>
              </div>
            </div>
          </form>
        </Popup>
        {/* End */}
        {/* Popuo xóa vị trí */}
        <Popup isOpen={modalDelete} setIsOpen={setModalDelete}>
          <div className="max-h-[90vh] w-[464px] rounded-xl bg-white p-4">
            <div className="text-end">
              <Icon
                name="close_cross"
                onClick={() => setModalDelete(false)}
                className="h-[18px] w-[18px] cursor-pointer stroke-black transition-all duration-75 ease-in-out hover:stroke-neu-400 hover:stroke-2 active:scale-90"
              />
            </div>
            <form onSubmit={handleSubmit(onsubmit)} className="flex flex-col items-center gap-y-5 p-4">
              <Icon name="trash_can_1" />
              <div className="text-center">
                <p className="font-medium text-neu-400">{`${t('project.delete-location')} ${
                  idPosition?.positionName
                }`}</p>
                <p className="mt-1 font-normal text-neu-200">{t('project.once-deleted-members')}</p>
              </div>
              <div className="flex justify-center gap-x-10">
                <Button
                  content={t('project.cancel')}
                  onClick={() => {
                    setModalDelete(false);
                  }}
                  className="min-w-[82px] border border-neu-800 py-2 px-3 text-neu-800"
                />
                <Button
                  type="submit"
                  content={t('project.confirm')}
                  className={`min-w-[82px] border bg-neu-800 py-2 px-3 text-neu-300 `}
                  onClick={() => handleDelete()}
                />
              </div>
            </form>
          </div>
        </Popup>
      </div>
      {/* END */}
    </div>
  );
};

export default ProjectRole;
