import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import projectBoard from '../../../../../Apis/ProjectManagement/TaskProgress/board';
import { useGlobalContext } from '../../../../../Utils/Context';
import AttachFile from '../../../../AttachFile/AttachFile';
import Icon from '../../../../Common/Icon';
import useCloseModalOnOutsideClick from '../../../../OnOutsideClick/OnOutsideClick';
import PopupMember from './PopupMember';

const MemberChildTask = ({ detailTask, setDetailTask, setIsCallBack, isCallBack }) => {
  const { changeIdUserInfomation } = useGlobalContext();
  const [deleteMember, setDeleteMember] = useState(false);
  const [popupAllMember, setpopupAllMember] = useState(false);
  const [searchMember, setSearchMember] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const boardRef = useRef(null);
  const { boardId } = useParams();
  useCloseModalOnOutsideClick(boardRef, setSearchMember);
  useCloseModalOnOutsideClick(boardRef, setDeleteMember);
  useCloseModalOnOutsideClick(boardRef, setpopupAllMember);
  const { t } = useTranslation();

  // Xóa thành viên ra khỏi task
  const handleDeleteMember = async (data) => {
    try {
      await projectBoard.deleteMemberTask(data.memberId, detailTask.projBoardTaskId, boardId);
      let updatedMemberTask = [...detailTask.projBoardTaskMemDTOs];
      const index = detailTask.projBoardTaskMemDTOs.findIndex(
        (item) => item.projBoardTaskMemId === data.projBoardTaskMemId
      );
      updatedMemberTask.splice(index, 1);
      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardTaskMemDTOs: updatedMemberTask
      }));
      toast.success(t('project.successfully-removed-member'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
      setIsCallBack(!isCallBack);
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  return (
    <div>
      <div className="mb-1 flex gap-1">
        {/* <Icon name="group_1" className="h-[13px] w-[20px] pt-2" /> */}
        <p className="text-[13px] font-[5px] text-[#172b4d]">{t('project.member')}</p>
      </div>
      <div className="mb-2 ml-1 flex flex-wrap items-end gap-1">
        {detailTask?.projBoardTaskMemDTOs?.slice(0, 10)?.map((item, index) => {
          return (
            <div key={index} className="relative">
              <div
                className=" cursor-pointer  "
                onClick={() => {
                  setMemberId(item), setDeleteMember(true);
                }}
              >
                <AttachFile
                  attachType="UserAvatar"
                  entity="users"
                  seq={item?.userId}
                  viewMode={true}
                  mode={'user-project-text' + index}
                  className="h-[32px] w-[32px] rounded-full border object-cover"
                />
              </div>
              {deleteMember && item?.userId === memberId?.userId && (
                <div className="absolute z-30 mt-2 h-[170px] w-[300px] rounded-md border bg-white " ref={boardRef}>
                  <div className="relative h-[74px] bg-blue-500">
                    <p onClick={() => setDeleteMember(false)} className="absolute right-0 cursor-pointer p-2">
                      <Icon name="close_cross" className="h-[18px] w-[18px] stroke-white" />
                    </p>
                    <div className="absolute flex gap-2 px-4 ">
                      <div onClick={() => changeIdUserInfomation(item?.userId)} className="mt-[30px] cursor-pointer">
                        <AttachFile
                          attachType="UserAvatar"
                          entity="users"
                          seq={item?.userId}
                          viewMode={true}
                          mode={'user-projects'}
                          className="h-[80px] w-[80px] rounded-full border object-cover"
                        />
                      </div>

                      <div className="flex items-center ">
                        <div>
                          <p className="text-sm font-bold text-white">{item.fullName}</p>
                          <p className="text-xs text-white">@{item.userName}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" mt-12 cursor-pointer border-t   pt-3" onClick={() => handleDeleteMember(item)}>
                    <p className="px-3 py-0.5 text-[15px] hover:bg-gray-200">{t('project.delete-member')}</p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {detailTask?.projBoardTaskMemDTOs?.length > 10 && (
          <div className="relative">
            <p
              className="flex h-[50px] w-[50px]  cursor-pointer items-center justify-center rounded-full bg-[#E1E6FF] text-center"
              onClick={() => setpopupAllMember(!popupAllMember)}
            >
              {detailTask?.projBoardTaskMemDTOs?.length}
            </p>
            {popupAllMember && (
              <div
                className="absolute left-0 z-30  mt-3  w-[600px] rounded-lg bg-white p-4  shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                ref={boardRef}
              >
                <div className="flex justify-between py-3">
                  <p className="font-bold">{t('project.list-members-card')}</p>
                  <p onClick={() => setpopupAllMember(false)} className="cursor-pointer">
                    <Icon name="close_cross" className="stroke-black" />
                  </p>
                </div>
                <div className="grid  max-h-[500px] grid-cols-2 gap-4">
                  {detailTask?.projBoardTaskMemDTOs?.map((item, index) => {
                    return (
                      <div key={index} className="relative ">
                        <div className="flex cursor-pointer gap-2 rounded-md border p-2">
                          <AttachFile
                            attachType="UserAvatar"
                            entity="users"
                            seq={item?.userId}
                            viewMode={true}
                            mode="user-project-text-2"
                            className="h-[50px] w-[50px] rounded-full border"
                          />
                          <div>
                            <p>{item?.fullName}</p>
                            <p>@{item?.userName}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="relative">
          <div onClick={() => setSearchMember(true)}>
            <Icon
              name="plus"
              className=" h-[30px] w-[30px] cursor-pointer rounded-full bg-gray-200 stroke-gray-600 p-2.5 hover:bg-gray-300"
            />
          </div>
          {searchMember && (
            <div className="absolute  top-10 z-30 bg-white" ref={boardRef}>
              <PopupMember
                setDetailTask={setDetailTask}
                detailTask={detailTask}
                setSearchMember={setSearchMember}
                setIsCallBack={setIsCallBack}
                isCallBack={isCallBack}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberChildTask;
