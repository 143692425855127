import axios from 'axios';
import jwtDecode from 'jwt-decode';
import backendURL from '../config';

const instance = axios.create({ baseURL: backendURL });

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode(token);

      if (decodedToken && decodedToken.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Thời gian hiện tại
        if (currentTime > decodedToken.exp) {
          // Token đã hết hạn, đăng xuất người dùng và chuyển hướng đến trang đăng nhập
          localStorage.removeItem('token');
          window.location.href = '#/login';
        } else {
          // Nếu token chưa hết hạn, thêm Authorization header vào yêu cầu
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
