const SideBarProject = () => {
  // const { t } = useTranslation();

  return (
    // <div className="relative max-h-[100vh] w-[250px] border-r px-3">
    //   <div className="mt-4 flex gap-3 border-b py-2">
    //     <Icon name="briefcase" className="stroke-black" />
    //     <p className="font-semibold">{t('project.project-management')}</p>
    //   </div>
    // </div>
    <div></div>
  );
};

export default SideBarProject;
