import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import background from '../../../../Apis/ProjectManagement/background';
import backendURL from '../../../../config';
import { useGlobalContext } from '../../../../Utils/Context';
import { Button } from '../../../Buttons/ButtonComponent';
import { toDateStringYears } from '../../../Calendar/CalendarComponent';
import Icon from '../../../Common/Icon';
import useCloseModalOnOutsideClick from '../../../OnOutsideClick/OnOutsideClick';
import Popup from '../../../Popup/Popup';
import AttachedFiles from './AttachedFiles';

// eslint-disable-next-line no-unused-vars
const Attachments = ({
  detailTask,
  setDetailTask,
  column,
  dataFilelink,
  setDataFileLink,
  numberFile,
  setNumberFile,
  projectInfo
}) => {
  const customStyles = {
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      padding: '24px 0',
      backgroundColor: 'rgba(104, 104, 104, 0.70)',
      zIndex: 999,
      overflowY: 'auto',
      maxWidth: '100vw',
      maxHeight: '100vh'
    },
    content: {
      maxWidth: '150vw',
      maxHeight: '150vw',
      padding: '0',
      borderRadius: '8px',
      boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.12)',
      backgroundColor: '#F7F8FC',
      overflow: 'hidden',
      transform: 'none'
    }
  };
  const { callBackFile, setCallBackFile } = useGlobalContext();
  const [attachedFiles, setAttachedFiles] = useState(false);
  const [idFile, setIdFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(false);
  const boardRef = useRef(null);
  useCloseModalOnOutsideClick(boardRef, setAttachedFiles);
  useCloseModalOnOutsideClick(boardRef, setDeleteFile);
  const { t } = useTranslation();

  const handleCoverImage = async (data) => {
    const startsWithHttps = data.fileName.startsWith('http');
    const newDataLink = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      coverPath: data.fileName,
      fileSeq: data.content
    };
    const newDataUpload = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      coverImageId: data.content,
      coverImageName: data.fileName,
      fileSeq: data.content,
      coverType: 'U'
    };
    try {
      if (startsWithHttps) {
        // Cập nhật đường dẫn ảnh
        await background.updateBoardTaskCover(newDataLink);
        setCallBackFile(!callBackFile);
        setDetailTask({
          ...detailTask,
          coverImageId: null,
          coverImageName: null,
          coverPath: data.fileName
        });
      } else {
        // Cập nhật  ảnh tải lên từ máy
        await background.updateBoardTaskCover(newDataUpload);
        setCallBackFile(!callBackFile);
        setDetailTask({
          ...detailTask,
          coverImageId: data.content,
          coverImageName: data.fileName,
          coverPath: null,
          coverType: 'U'
        });
      }
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  const unCoverPhoto = async () => {
    // Gỡ ảnh bìa
    const newDataDelete = {
      projBoardTaskId: detailTask.projBoardTaskId,
      coverColor: null,
      coverImageId: null,
      coverImageName: null,
      coverPath: null
    };
    await background.updateBoardTaskCover(newDataDelete);
    setCallBackFile(!callBackFile);
    setDetailTask({
      ...detailTask,
      coverColor: null,
      coverImageId: null,
      coverImageName: null,
      coverPath: null
    });
  };

  // Xóa file tải lên
  const handleRemove = async (item) => {
    await background.deleteBoardTaskFile(parseInt(item.seq), detailTask?.projBoardTaskId);
    setDataFileLink((prevTask) => ({
      ...prevTask,
      boardTaskImagesAndLinks: prevTask.boardTaskImagesAndLinks.filter(
        (boardTaskImages) => boardTaskImages.content !== item.seq
      )
    }));
    if (detailTask?.coverImageId === item?.seq) {
      // Gỡ ảnh bìa khi file đó đang là ảnh bìa của task
      setDetailTask({
        ...detailTask,
        coverColor: null,
        coverImageId: null,
        coverImageName: null,
        coverPath: null
      });
    }
  };
  // Xóa link file
  const handleRemoveLink = async (item) => {
    await background.deleteFileLink(parseInt(item.seq), detailTask?.projBoardTaskId);
    setDataFileLink((prevTask) => ({
      ...prevTask,
      boardTaskImagesAndLinks: prevTask.boardTaskImagesAndLinks.filter(
        (boardTaskImages) => boardTaskImages.content !== item.seq
      )
    }));
    if (detailTask?.coverPath === item?.coverPath) {
      // Gỡ ảnh bìa khi file đó đang là ảnh bìa của task
      setDetailTask({
        ...detailTask,
        coverColor: null,
        coverImageId: null,
        coverImageName: null,
        coverPath: null
      });
    }
  };
  // Dowload file
  const handleDownLoad = (index) => {
    document
      .querySelector('#temp-frame')
      .setAttribute('src', backendURL + '/file/' + dataFilelink?.boardTaskImagesAndLinks[index].content);
  };

  // Hàm kiểm tra file ảnh
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const isImageFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  // Hàm kiểm tra file text
  const textExtensions = ['txt', 'doc', 'docx', 'pdf'];
  const isTextFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return textExtensions.includes(fileExtension);
  };
  const closeModalTask = () => {
    setIsPopupPriview(false);
  };
  // eslint-disable-next-line no-unused-vars
  const [privewFile, setPriviewFile] = useState();
  const [isPopupPriview, setIsPopupPriview] = useState(false);
  return (
    <>
      {dataFilelink?.length !== 0 && (
        <div>
          <div className="flex justify-between">
            <div className="mb-4 flex gap-3">
              <Icon name="link_3_diagonal" className="h-[22px] w-[22px] stroke-[#111111]" />
              <p className="text-[16px] font-semibold text-[#172b4d]">{t('project.attachment')}</p>
            </div>

            <div className="relative">
              <div
                className={`${
                  projectInfo?.roleProject === 3 ? 'cursor-not-allowed opacity-50' : ''
                } flex cursor-pointer gap-2 rounded-md bg-[#E1E6FF] py-1 px-3 hover:bg-[#dbe1fd]`}
                onClick={() => {
                  if (projectInfo?.roleProject === 3) {
                    return;
                  }
                  setAttachedFiles(!attachedFiles);
                }}
              >
                <Icon name="plus" className="mt-1 h-[13px] w-[14px] stroke-[#172b4d]" />
                <p className="text-sm text-[#172b4d]">{t('project.add-files')}</p>
              </div>

              <div
                className={`absolute right-0 z-30  mt-2   ${
                  !attachedFiles ? 'max-h-[0] opacity-0' : 'max-h-[80vh] opacity-100'
                }   transition-max-h  overflow-hidden rounded-lg bg-white pl-2 pr-2  shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)] duration-500`}
                ref={boardRef}
              >
                <AttachedFiles
                  column={column}
                  setDetailTask={setDetailTask}
                  detailTask={detailTask}
                  setAttachedFiles={setAttachedFiles}
                  attachedFiles={attachedFiles}
                />
              </div>
            </div>
          </div>
          <div className=" ">
            <div className=" ml-10 mt-3 ">
              {dataFilelink?.boardTaskImagesAndLinks?.map((item, index) => {
                const startsWithHttps = item.fileName.startsWith('http');
                const filelink = startsWithHttps
                  ? item.fileName
                  : backendURL + '/file/' + 'displayImg/' + parseInt(item.content);
                const parts = item.fileName.split('/').pop();
                const checkFileImage = isImageFile(item.fileName); //Kiểm tra xem có phải là file ảnh không
                return (
                  <div key={index}>
                    <div className=" mt-4 flex justify-between ">
                      {/* Nếu là link http thì cho click vào link  và ngược lại thì không cho */}
                      {startsWithHttps ? (
                        <div className="flex gap-4">
                          <a href={item.fileName}>
                            <div className="">
                              {checkFileImage ? (
                                <img
                                  src={filelink}
                                  alt="avatar"
                                  className="relative h-[86px] w-[120px]  rounded-xl object-cover"
                                />
                              ) : (
                                <div className="flex h-[86px] w-[118px] items-center justify-center rounded-xl border font-semibold uppercase text-[#686868]">
                                  {isTextFile(item.fileName) ? (
                                    item.fileName.split('.').pop().toLowerCase()
                                  ) : (
                                    <Icon name="link_2_diagonal" className=" stroke-black" />
                                  )}
                                </div>
                              )}
                            </div>
                          </a>

                          <div>
                            <a href={item.fileName} target="_blank" rel="noreferrer nofollow noopener">
                              <p className=" block w-[300px] whitespace-normal break-words text-sm font-bold text-[#172b4d]">
                                {item.fileName}
                              </p>
                            </a>

                            <div className="mt-1 flex gap-3 text-xs text-[#172b4d]">
                              <p>{item.updatedAt}</p>
                            </div>
                            {checkFileImage && detailTask?.coverPath === item?.fileName ? (
                              <p
                                className="  cursor-pointer border-black pt-2  text-sm text-[#172b4d] hover:text-black "
                                onClick={() => unCoverPhoto(item)}
                              >
                                {t('project.remove-cover-photo')}
                              </p>
                            ) : (
                              <p
                                className="  cursor-pointer border-black pt-2  text-sm text-[#172b4d] hover:text-black"
                                onClick={() => handleCoverImage(item)}
                              >
                                {t('project.set-cover-photo')}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="flex gap-4">
                          <div className="">
                            {checkFileImage ? (
                              <img
                                src={filelink}
                                alt="avatar"
                                className="relative h-[86px] w-[120px] rounded-xl object-cover"
                                onClick={() => {
                                  setIsPopupPriview(true);
                                  setPriviewFile(item);
                                }}
                              />
                            ) : (
                              <div
                                className={`flex h-[86px] w-[118px] items-center justify-center rounded-xl border font-semibold uppercase text-white ${
                                  item.fileName.endsWith('.doc') || item.fileName.endsWith('.docx')
                                    ? 'bg-blue-500' // Màu xanh dương cho file Word
                                    : item.fileName.endsWith('.xls') || item.fileName.endsWith('.xlsx')
                                    ? 'bg-green-500' // Màu xanh lá cây cho file Excel
                                    : item.fileName.endsWith('.csv')
                                    ? 'bg-teal-500' // Màu teal cho file CSV
                                    : item.fileName.endsWith('.pdf')
                                    ? 'bg-red-500' // Màu đỏ cho file PDF
                                    : item.fileName.endsWith('.ppt') || item.fileName.endsWith('.pptx')
                                    ? 'bg-orange-500' // Màu cam cho file PowerPoint
                                    : item.fileName.endsWith('.txt')
                                    ? 'bg-yellow-500' // Màu vàng cho file Text
                                    : item.fileName.endsWith('.zip') || item.fileName.endsWith('.rar')
                                    ? 'bg-purple-500' // Màu tím cho file ZIP/RAR
                                    : 'bg-gray-300' // Màu xám cho các loại file khác
                                }`}
                              >
                                {isTextFile(item.fileName) ? (
                                  item.fileName.split('.').pop().toLowerCase()
                                ) : (
                                  <Icon name="link_2_diagonal" className="stroke-white" />
                                )}
                              </div>
                            )}
                          </div>
                          <div>
                            <p className="block w-[280px] whitespace-normal break-words text-sm font-bold">{parts}</p>
                            <div className="mt-1 flex gap-3 text-xs">
                              <p>{toDateStringYears(item.updatedAt)} </p>
                            </div>
                            {checkFileImage ? (
                              detailTask?.coverImageId === item?.content ? (
                                <p
                                  className="cursor-pointer border-black pt-2 text-sm text-[#172b4d] hover:text-black"
                                  onClick={() => unCoverPhoto(item)}
                                >
                                  {t('project.remove-cover-photo')}
                                </p>
                              ) : (
                                <p
                                  className="cursor-pointer border-black pt-2 text-sm text-[#172b4d] hover:text-black"
                                  onClick={() => handleCoverImage(item)}
                                >
                                  {t('project.set-cover-photo')}
                                </p>
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      )}

                      <div className={` flex  justify-end gap-6`}>
                        {/* Tải tập tin về thiết bị */}
                        {/* {(isTextFile(item.fileName) || isImageFile(item.fileName)) && */}
                        {/* !item.fileName.startsWith('http') && ( */}
                        {!startsWithHttps && (
                          <p className="cursor-pointer bg-white" type="submit" onClick={() => handleDownLoad(index)}>
                            <Icon name="download" className="" />
                          </p>
                        )}

                        {/* )} */}
                        {/* Xóa ảnh hoặc link tập tin */}
                        <div className="relative">
                          <p
                            type="button"
                            onClick={() => {
                              if (projectInfo?.roleProject === 3) {
                                return;
                              }
                              setDeleteFile(!deleteFile), setIdFile(item.content);
                            }}
                            className={`${
                              projectInfo?.roleProject === 3 ? 'cursor-not-allowed opacity-50' : ''
                            }  cursor-pointer bg-white`}
                          >
                            <Icon name="trash_alt" className="fill-black" />
                          </p>
                          {deleteFile && idFile === item.content && (
                            <div
                              className="absolute right-0 z-30 mt-3 w-[350px] rounded-lg bg-white p-4 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                              ref={boardRef}
                            >
                              <p onClick={() => setDeleteFile(false)} className="float-right cursor-pointer">
                                <Icon
                                  name="close_cross"
                                  className="h-[22px] w-[22px] rounded-full bg-gray-400 bg-opacity-30 stroke-black p-0.5 hover:bg-opacity-80"
                                />
                              </p>
                              <div className="flex flex-col items-center gap-y-5">
                                <Icon name="trash_can_1" />
                                <div className="w-full text-center">
                                  <p className="break-words text-[15px] font-medium text-[#172b4d]">
                                    {`${t('project.delete-attachments')} ${parts}`}
                                  </p>
                                  <p className="mt-1 text-sm font-normal text-neu-200">
                                    {t('project.the-attachment')} <br />
                                    {t('project.action-cannot-undone')}
                                  </p>
                                </div>
                                <div className="flex justify-center gap-x-10">
                                  <Button
                                    content={t('project.cancel')}
                                    onClick={() => setDeleteFile(false)}
                                    className="min-w-[82px] border border-neu-800 py-1 px-3 text-sm text-neu-800"
                                  />
                                  <Button
                                    type="submit"
                                    content={t('project.confirm')}
                                    onClick={() => {
                                      startsWithHttps
                                        ? handleRemoveLink({
                                            seq: item.content,
                                            index: index,
                                            coverPath: item?.fileName
                                          })
                                        : handleRemove({ seq: item.content, index: index });
                                      setCallBackFile(!callBackFile);
                                    }}
                                    className="min-w-[82px] cursor-pointer border border-neu-800 bg-neu-800 py-1 px-3 text-sm text-neu-300"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex gap-2">
              {dataFilelink?.totalItems - numberFile > 0 && (
                <div
                  className="mt-4 ml-10 flex w-[180px] cursor-pointer justify-center rounded-md bg-gray-300 px-2 py-1 text-xs font-medium text-[#172b4d] hover:bg-gray-400 "
                  onClick={() => setNumberFile(numberFile + 4)}
                >
                  <p>
                    {t('job.see-more')} {dataFilelink?.totalItems - numberFile} {t('project.attachment')}
                  </p>
                </div>
              )}
              {numberFile > 4 && (
                <div
                  className="mt-4 ml-10 flex w-[180px] cursor-pointer  justify-center rounded-md bg-gray-300 px-2 py-1 text-xs font-medium text-[#172b4d] hover:bg-gray-400 "
                  onClick={() => setNumberFile(numberFile - 4)}
                >
                  <p>{t('project.collapse-attachments')}</p>
                </div>
              )}
            </div>
          </div>
          {/* Xem trước ảnh hoặc file */}
          <Popup isOpen={isPopupPriview} setIsOpen={closeModalTask} style={customStyles}>
            <div>
              {privewFile?.fileName.endsWith('pdf') ? (
                <iframe
                  title="PDF Viewer"
                  width="100%"
                  height="500px"
                  src={backendURL + '/file/displayImg/' + privewFile.content}
                />
              ) : (
                <div className="">
                  <p onClick={() => closeModalTask()} className="absolute right-2 mt-2 cursor-pointer">
                    <Icon
                      name="close_cross"
                      className="h-[22px] w-[22px] rounded-full bg-gray-400 bg-opacity-30 stroke-black p-0.5 hover:bg-opacity-80"
                    />
                  </p>
                  <img
                    src={backendURL + '/file/' + 'displayImg/' + parseInt(privewFile?.content)}
                    alt="avatar"
                    className="   object-contain"
                    // onClick={() => {
                    //   setIsPopupPriview(true), setPriviewFile(item);
                    // }}
                  />
                </div>
              )}
            </div>
          </Popup>
        </div>
      )}
    </>
  );
};

export default Attachments;
