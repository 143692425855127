import instance from '../../instance';

const projectPosApi = {
  getAllPos: (currentPage, searchName, filterName, arrange, idProject, modelInfo) => {
    const url = `position/getAllPosition/${idProject}?page=${currentPage}&size=10&orders=${filterName}+${arrange}&positionName=${searchName}&modelInfo=${modelInfo}`;
    return instance.get(url);
  },
  getReadPos: (idPosition) => {
    const url = `position/getById/${idPosition}`;
    return instance.get(url);
  },
  createPosition: (data) => {
    const url = `position/create`;
    return instance.post(url, data);
  },
  editPosition: (data) => {
    const url = `position/update`;
    return instance.put(url, data);
  },
  deletePosition: (idPosition) => {
    const url = `position/delete/${idPosition}`;
    return instance.delete(url);
  },
  getAllBoard: (id) => {
    const url = `projboardcol/getAllBoardColumn/${id}`;
    return instance.get(url);
  }
};

export default projectPosApi;
