import { useRef, useState } from 'react';
import backendURL from '../../../../config';
import Icon from '../../../Common/Icon';
import useCloseModalOnOutsideClick from '../../../OnOutsideClick/OnOutsideClick';
import CoverTask from './CoverTask';

// eslint-disable-next-line no-unused-vars
const CoverImage = ({
  detailTask,
  column,
  setDetailTask,
  closeModalTask,
  dataFilelink,
  setDataFileLink,
  setIsCallBack,
  isCallBack,
  projectInfo
}) => {
  const boardRef = useRef(null);
  const [coverTaskFile, setCoverTaskFile] = useState(false);
  useCloseModalOnOutsideClick(boardRef, setCoverTaskFile);

  // Link ảnh

  const imageURL = detailTask?.coverImageId
    ? detailTask?.coverType
      ? detailTask?.coverType === 'D'
        ? backendURL + '/projBoardBackground/displayImg/' + detailTask?.coverImageId
        : backendURL + '/file/' + 'displayImg/' + detailTask?.coverImageId
      : null
    : detailTask?.coverPath;
  return (
    <div className="border-b">
      <div className=" relative">
        {/* Nếu cso id ảnh hoặc đường dẫn ảnh thì hiện ảnh ngược lại hiện màu */}
        {detailTask?.coverImageId || detailTask?.coverPath ? (
          <div
            className="flex h-[170px]  w-full justify-center bg-cover bg-center bg-no-repeat opacity-60"
            style={{ backgroundImage: `url(${imageURL})`, filter: 'blur(1px)' }}
          ></div>
        ) : (
          <div className="h-[150px] w-full " style={{ background: detailTask.coverColor }}></div>
        )}
        {/* Hiện ảnh tải lên hoặc đường dẫn ảnh */}
        <div className="absolute top-0 flex w-full justify-center">
          {(detailTask?.coverImageId || detailTask?.coverPath) && (
            <img src={imageURL} className=" h-[170px] w-[320px] object-cover" />
          )}
        </div>
        {/* Icon đóng task */}
        <div className="absolute  right-4 top-4">
          <div className="relative">
            <Icon
              name="close_cross"
              className="cursor-pointer rounded-full bg-[#F2F2F2] bg-opacity-70 stroke-black p-0.5 hover:bg-opacity-100"
              onClick={() => closeModalTask(true)}
            />
          </div>
        </div>
        {/* Popup  danh hiện danh sách ảnh cover tải lên , ảnh mặc định , màu cover*/}
        <div className="absolute  right-4 bottom-4">
          <div className="relative">
            <Icon
              name="edit_2"
              className="cursor-pointer rounded-full bg-[#F2F2F2] bg-opacity-70 fill-black p-1.5 hover:bg-opacity-100"
              onClick={() => setCoverTaskFile(true)}
            />

            <div
              className={`absolute right-0  ${
                !coverTaskFile ? 'max-h-[0] opacity-0' : 'max-h-[80vh] opacity-100'
              }   transition-max-h  z-30  overflow-hidden rounded-lg bg-white pl-2 pr-2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)] duration-500`}
              ref={boardRef}
            >
              {/* Popup danh sách ảnh cover tải lên , ảnh mặc định , màu cover */}
              <CoverTask
                column={column}
                setDetailTask={setDetailTask}
                detailTask={detailTask}
                setCoverTaskFile={setCoverTaskFile}
                coverTaskFile={coverTaskFile}
                dataFilelink={dataFilelink}
                setDataFileLink={setDataFileLink}
                setIsCallBack={setIsCallBack}
                isCallBack={isCallBack}
                projectInfo={projectInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverImage;
