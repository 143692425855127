import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import backendURL from '../../../config';
import Popup from '../../Popup/Popup';
// Popup config
const customStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(104, 104, 104, 0.70)',
    zIndex: 999
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '100vw',
    maxHeight: '100vh',
    padding: '0',
    borderRadius: '8px',
    boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#F7F8FC',
    overflow: 'auto',
    transform: 'none'
  }
};
const ProjectFile = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  if (props.viewMode) {
    if (props.mode === 'form') {
      return (
        <div className="">
          <img
            onClick={() => {
              if (!props?.file[0]?.seq && !props?.seqFile) {
                return;
              }
              setIsOpen(!isOpen);
            }}
            id="display-img"
            src={require('../../../Assets/Images/imageProject.jpg')}
            alt="avatar"
            className={`${props.className}` + ' cursor-pointer'}
          />
          {/* Hiển thị ảnh đại diện lớn của dự án khi thao tác click */}
          <Popup isOpen={isOpen} setIsOpen={setIsOpen} style={customStyles}>
            <div
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="flex h-[80vh] w-[80vw] justify-center bg-white"
            >
              <div className="h-auto max-h-[80vh] max-w-[80vw]">
                <img
                  id={!props?.file[0]?.seq ? 'display-img' : `img-${props.mode}-${props?.file[0]?.seq}`}
                  src={
                    !props?.file[0]?.seq
                      ? props?.seqFile
                        ? backendURL + '/file/' + 'displayImg/' + props?.seqFile
                        : require('../../../Assets/Images/imageProject.jpg')
                      : props?.seqFile
                      ? backendURL + '/file/' + 'displayImg/' + props?.seqFile
                      : backendURL + '/file/' + 'displayImg/' + props?.file[0]?.seq
                  }
                  alt="avatar"
                  className="h-full w-full object-center"
                />
              </div>
            </div>
          </Popup>
        </div>
      );
    } else {
      return (
        <>
          <img
            id={`img-${props.mode}-${props.seq}`}
            src={require('../../../Assets/Images/logoBzcom.jpg')}
            alt="avatar"
            className={props.className}
          />
        </>
      );
    }
  } else {
    return (
      <div className="relative flex justify-center">
        {props.uploadKey !== '' && <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />}
        <div className="w-full">
          <div className="mt-5 mb-2 flex w-full items-center text-center">
            <button
              type="button"
              className={` 
                upload-btn  rounded-lg bg-blue-600  py-[8px]  px-3 text-[12px] font-[500] text-white
               `}
            >
              {t('project.upload-images')}
            </button>
          </div>
          <p className="text-sm text-[#9C9C9C]">{t('project.10-photo-limit')}</p>
          {props.statusProgress && (
            <div>
              <p className="pt-2 text-sm text-green-400">{t('project.photo-uploaded-successfully')}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default ProjectFile;
