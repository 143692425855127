/**
 * Converts HTML content to plain text by removing all HTML tags.
 *
 * @param {string} html - The HTML string to convert.
 * @returns {string} The plain text content.
 */
export const htmlToText = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

/**
 * Cleans HTML content by removing all HTML tags and trimming whitespace.
 *
 * @param {string} html - The HTML string to clean.
 * @returns {string} The cleaned and trimmed plain text content.
 */
export const cleanHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const text = doc.body.textContent || '';
  return text.trim();
};
