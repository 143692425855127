import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import projectBoard from '../../../../Apis/ProjectManagement/TaskProgress/board';
import WarningIc from '../../../../Assets/Images/Icons/warning-ic.svg';
import { useGlobalContext } from '../../../../Utils/Context';
import { Button } from '../../../Buttons/ButtonComponent';
import { calendarDateModal, toDateStringss } from '../../../Calendar/CalendarComponent';
import Icon from '../../../Common/Icon';
import validateNoLeadingSpace from '../../../Common/Validate';
import useCloseModalOnOutsideClick from '../../../OnOutsideClick/OnOutsideClick';

const TodoList = ({
  detailTask,
  setDetailTask,
  setIsCallBack,
  isCallBack,
  isOpenTask,
  setIsOpenTask,
  targetElementRef,
  projectInfo
}) => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors, // Thêm clearErrors từ react-hook-form
    getValues, // Thêm getValues từ react-hook-form
    formState: { errors }
  } = useForm();
  const { userInfo } = useGlobalContext();
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [idTaskChild, setIdTaskChild] = useState(null);
  const [idChildTask, setIdChildTask] = useState(null);
  const [editSmallTask, setEditSmallTask] = useState(false);
  const [openDeleteTodoList, setOpenDeleteTodoList] = useState(false);
  const { t } = useTranslation();

  const modalRef = useRef(null);
  const todoListModalRef = useRef(null);
  useCloseModalOnOutsideClick(modalRef, setEditSmallTask);
  useCloseModalOnOutsideClick(modalRef, setIsOpenTask);
  // Phương thức tạo task

  const onSubmit = async (data) => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    const dateCheck = detailTask.endDate ? data.endDate + 'T00:00:00' <= detailTask?.endDate : true;
    if (data.endDate === '' ? true : dateCheck) {
      const newData = {
        projBoardTaskId: detailTask?.projBoardTaskId,
        childTaskName: data.childTaskName,
        endDate: data.endDate + (data.endDate ? 'T00:00:00' : ''),
        status: 0
      };
      try {
        const dataDetail = await projectBoard.createChildTask(newData);
        setDetailTask((prevTask) => ({
          ...prevTask,
          projBoardChildTaskDTOs: [...detailTask.projBoardChildTaskDTOs, dataDetail?.data?.data]
        }));
        setIsCallBack(!isCallBack);
        reset();
      } catch (error) {
        toast.error(t('project.an-error-occurred'), {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      }
    } else {
      toast.error(t('project.please-choose-an-end'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };
  // Phương thức xóa task
  const handleDeleteChildTask = async (childTaskId) => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    try {
      await projectBoard.deleteChildTask(childTaskId);
      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardChildTaskDTOs: prevTask.projBoardChildTaskDTOs.filter(
          (childTask) => childTask.projBoardChildTaskId !== childTaskId
        )
      }));
      setOpenDeleteTodoList(false);
      setIsCallBack(!isCallBack);
    } catch (error) {
      console.log(error);
    }
  };
  // Phương thức cập nhật task nhỏ
  const hanleUpdatePercent = async (data) => {
    if (projectInfo?.roleProject === 3) {
      return;
    }
    try {
      const newData = {
        projBoardChildTaskId: data?.projBoardChildTaskId,
        projBoardTaskId: detailTask?.projBoardTaskId,
        childTaskName: data?.childTaskName,
        endDate: data?.endDate,
        status: data.status === 1 ? 0 : 1
      };
      const newDataEdit = {
        projBoardChildTaskId: parseInt(data?.projBoardChildTaskId),
        projBoardTaskId: detailTask?.projBoardTaskId,
        childTaskName: data?.childTaskName,
        endDate: data?.endDate ? data?.endDate + 'T00:00:00' : null,
        status: parseInt(data.status)
      };
      const updatedTask = editSmallTask ? newDataEdit : newData;
      // Gọi API để cập nhật child task
      await projectBoard.editChildTask(updatedTask);
      setIsCallBack(!isCallBack);

      const updatedTasks = [...detailTask.projBoardChildTaskDTOs];
      // Tìm và cập nhật task tương ứng
      const index = updatedTasks.findIndex((task) => task.projBoardChildTaskId === updatedTask.projBoardChildTaskId);
      if (index !== -1) {
        updatedTasks[index] = updatedTask;
        // Cập nhật state với mảng mới
        setDetailTask((prevTask) => ({
          ...prevTask,
          projBoardChildTaskDTOs: updatedTasks
        }));
      }
      reset();
      setEditSmallTask(false);
    } catch (error) {
      console.error(error);
    }
  };

  const totalTasks = detailTask?.projBoardChildTaskDTOs?.length;
  // Tính tổng số task có status bằng 1
  const tasksWithStatus1 = detailTask?.projBoardChildTaskDTOs?.filter((task) => task?.status === 1);
  const totalTasksWithStatus1 = tasksWithStatus1?.length;

  // Tính phần trăm của tất cả các task
  const percentageTotalTasks = (totalTasksWithStatus1 / totalTasks) * 100;
  // Nếu hidden == 0 hoặc bằng null thì sẽ hiển thị ra tất cả các task còn ngược lại thì lấy ra những task chưa hoàn thành

  // Phương thức sử lý API cập nhật trạng thái hidden
  const handleSelected = async (hiddenId) => {
    try {
      await projectBoard.updateJobStatus(detailTask.projBoardTaskId, hiddenId);
      setDetailTask({
        ...detailTask,
        hidden: hiddenId === 0 ? false : true
      });
    } catch (error) {
      console.log(error);
    }
  };
  const filteredData =
    detailTask?.hidden !== true
      ? detailTask?.projBoardChildTaskDTOs
      : detailTask?.projBoardChildTaskDTOs.filter((item) => item.status === 0);

  useEffect(() => {
    if (projectInfo?.roleProject === 3 && targetElementRef?.current) {
      const checkboxes = targetElementRef?.current?.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.disabled = true;
      });
    }
  }, [projectInfo, targetElementRef, detailTask]);

  useEffect(() => {
    const dateElement = document.getElementById('todolist-date');
    // validate input date todolist-date
    if (dateElement && !editSmallTask) {
      dateElement.min = new Date().toLocaleDateString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    }
  }, [isOpenTask, editSmallTask]);

  return (
    <div>
      {/* Ẩn hiện công việc hoàn thành */}
      <div className="flex justify-between">
        <div className="mb-4 flex gap-3">
          <Icon name="document_5" className="h-[20px] w-[20px] fill-[#172b4d]" />
          <p className="text-[16px] font-semibold text-[#172b4d]">{t('project.category')}</p>
        </div>
        <div className="">
          {detailTask?.hidden !== true ? (
            <div
              className="flex w-[190px] cursor-pointer justify-center gap-3 rounded-md bg-[#E1E6FF] py-1 px-2 text-center hover:bg-[#d4dbfa]"
              onClick={() => handleSelected(1)}
            >
              <Icon name="eye_off" />
              <p className="text-sm text-[#172b4d]">{t('project.hide-selected-item')}</p>
            </div>
          ) : (
            <div
              className="flex w-[190px] cursor-pointer justify-center gap-3 rounded-md bg-[#E1E6FF] py-1 px-2 text-center hover:bg-[#d4dbfa]"
              onClick={() => handleSelected(0)}
            >
              <Icon name="eye" />
              <p className="text-sm text-[#172b4d]">{t('project.show-selected-item')}</p>
            </div>
          )}
        </div>
      </div>

      <div className="ml-10 bg-[#F7F8FC] px-3 pt-3 pb-2" ref={targetElementRef}>
        {/* Hiển thị phần trăm công việc hoàn thành */}
        <div className="flex ">
          <div className=" mt-3 h-[5px] w-full  rounded-lg bg-[#ccc]">
            <div
              className="transition-width relative h-full rounded-full bg-primary-100 duration-300 ease-in-out"
              style={{
                width: `${percentageTotalTasks ? percentageTotalTasks : 0}%`
              }}
            ></div>
          </div>
          <p className=" pl-3 pt-1 text-sm ">{percentageTotalTasks ? Math.round(percentageTotalTasks) : 0}%</p>
        </div>
        {/* List danh sách các task vụ nhỏ */}
        {filteredData?.map((item, index) => {
          return (
            <div
              className="relative flex gap-3 rounded-md p-2 hover:bg-gray-200"
              key={index}
              onMouseEnter={() => {
                if (projectInfo?.roleProject === 3) {
                  return;
                }
                setMouseIsOver(true);
                setIdTaskChild(item?.projBoardChildTaskId);
              }}
              onMouseLeave={() => {
                setMouseIsOver(false);
              }}
            >
              <input
                type="checkbox"
                defaultChecked={item?.status === 1 ? true : false}
                checked={item?.status === 1 ? item?.status === 1 : ''}
                className={`w-[15px]  ${
                  editSmallTask === true && idChildTask === item?.projBoardChildTaskId && 'hidden'
                }`}
                onClick={() => hanleUpdatePercent(item)}
              />

              {editSmallTask && idChildTask === item?.projBoardChildTaskId ? (
                <>
                  {/* Form sửa task vụ công việc */}
                  <form onSubmit={handleSubmit(hanleUpdatePercent)} className="w-full" ref={modalRef}>
                    <div className="rounded-lg bg-white p-3 ">
                      <div className="flex gap-3">
                        <div className="w-[78%]">
                          <input
                            defaultValue={item?.projBoardChildTaskId}
                            className="hidden"
                            {...register('projBoardChildTaskId')}
                          />
                          <input defaultValue={item?.status} className="hidden" {...register('status')} />
                          <input
                            placeholder={t('project.please-enter-content')}
                            defaultValue={item?.childTaskName}
                            className={`w-full rounded-lg border border-[#F1F3FD] bg-white px-3 py-1.5 text-sm font-medium placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none ${
                              projectInfo?.roleProject === 3 ? 'pointer-events-none cursor-default opacity-70' : ''
                            }`}
                            {...register('childTaskName', {
                              required: t('account-management.no-be-eft-blank'),
                              maxLength: { value: 155, message: t('account-management.max-155') },
                              validate: validateNoLeadingSpace
                            })}
                            readOnly={projectInfo?.roleProject === 3} // Thêm thuộc tính readOnly
                          />
                          {errors.childTaskName && (
                            <p className="flex gap-1 px-2 pt-1">
                              <img src={WarningIc} alt="warning-icon" />
                              <span className="text-xs font-normal text-red-500">{errors.childTaskName.message}</span>
                            </p>
                          )}
                        </div>
                        <div className="w-[26%]">
                          <input
                            id="todolist-date"
                            type="date"
                            defaultValue={item?.endDate ? calendarDateModal(item?.endDate) : null}
                            className={`w-full rounded-lg border border-[#F1F3FD] bg-white px-3 py-1.5 text-sm font-medium placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none ${
                              projectInfo?.roleProject === 3 ? 'pointer-events-none cursor-default opacity-70' : ''
                            }`}
                            {...register('endDate')}
                            readOnly={projectInfo?.roleProject === 3} // Thêm thuộc tính readOnly
                          />
                        </div>
                      </div>
                      <div className="mt-2 flex gap-4">
                        <button
                          className="rounded-lg bg-primary-100 py-1 px-3 text-sm text-white"
                          disabled={projectInfo?.roleProject === 3} // Vô hiệu hóa nút Lưu
                          type="submit"
                        >
                          Lưu
                        </button>
                        <div>
                          <p
                            className="cursor-pointer rounded-lg bg-gray-200 px-3 py-1 text-sm text-[#172b4d] hover:bg-gray-300"
                            onClick={() => {
                              setEditSmallTask(false), reset();
                            }}
                          >
                            {t('account-management.cancel')}
                          </p>
                        </div>
                      </div>
                      {projectInfo?.roleProject === 3 && (
                        <p className="mt-2 text-sm text-red-500">{t('project.no-permission-to-edit')}</p>
                      )}
                    </div>
                  </form>
                </>
              ) : (
                <div
                  className="flex w-[90%] cursor-pointer "
                  onClick={() => {
                    if (projectInfo?.roleProject !== 3) {
                      setEditSmallTask(true);
                      reset();
                      setIdChildTask(item?.projBoardChildTaskId);
                    }
                  }}
                >
                  <p
                    className={`block ${
                      item?.endDate ? 'w-[70%]' : 'w-[90%]'
                    } whitespace-normal break-words text-sm text-[#172b4d]`}
                  >
                    {item?.childTaskName}
                  </p>
                  {item?.endDate && (
                    <div className={`${item?.endDate ? 'w-[30%]' : 'w-[10%]'}`}>
                      <div className="flex gap-3">
                        <Icon name="time" />
                        <p>{toDateStringss(item?.endDate, userInfo)}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {mouseIsOver && idTaskChild === item?.projBoardChildTaskId && (
                <Icon
                  name="close_icon"
                  className={`cursor-pointer ${
                    editSmallTask === true && idChildTask === item?.projBoardChildTaskId && 'hidden'
                  }`}
                  onClick={() => setOpenDeleteTodoList(true)}
                />
              )}

              {openDeleteTodoList && idTaskChild === item?.projBoardChildTaskId && (
                <div
                  className="absolute right-0 z-30 mt-3 w-[464px] rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                  ref={todoListModalRef}
                >
                  <div className="flex flex-col items-center gap-y-5 p-4">
                    <Icon name="trash_can_1" />

                    <div className="text-center">
                      <p className="break-words font-medium text-neu-400">
                        {t('project.delete-job')} {''}
                        <span
                          style={{
                            display: 'inline-block',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                            width: '100%'
                          }}
                          className="w-full overflow-hidden text-ellipsis font-bold"
                        >
                          {item?.childTaskName}
                        </span>
                      </p>
                      <p className="mt-1 break-words text-sm font-normal text-neu-200">
                        {t('project.all-information-within')} <br /> {t('project.this-action-irreversible')}
                      </p>
                    </div>

                    <div className="flex justify-center gap-x-10">
                      <Button
                        content={t('account-management.cancel')}
                        onClick={() => {
                          setOpenDeleteTodoList(false);
                        }}
                        className="min-w-[82px] border border-neu-800 py-1.5 px-3 text-sm text-neu-800"
                      />

                      <Button
                        type="submit"
                        content={t('account-management.confirm')}
                        onClick={() => handleDeleteChildTask(item?.projBoardChildTaskId)}
                        className="min-w-[82px] cursor-pointer border border-neu-800 bg-neu-800 py-1.5 px-3 text-sm text-neu-300"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {isOpenTask ? (
          <form onSubmit={handleSubmit(onSubmit)} ref={modalRef} className="mt-4">
            {/* Form thêm task công việc */}
            <div className="rounded-lg border-primary-100 bg-white p-3">
              <div className="flex gap-3">
                <div className="w-[72%]">
                  <input
                    placeholder={t('project.please-enter-content')}
                    className="w-full rounded-lg border border-[#F1F3FD] bg-white px-3  py-1.5 text-sm font-medium placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                    {...register('childTaskName', {
                      required: t('account-management.no-be-eft-blank'),
                      maxLength: { value: 155, message: t('account-management.max-155') },
                      validate: validateNoLeadingSpace
                    })}
                    onPaste={(e) => {
                      const pastedContent = e.clipboardData.getData('text');
                      if (pastedContent.trim() !== '') {
                        // Nếu dán nội dung có nội dung hợp lệ thì bỏ qua validate
                        clearErrors('childTaskName');
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); // Ngăn Enter thực hiện submit nếu cần
                        if (getValues('childTaskName').trim() !== '') {
                          handleSubmit(onSubmit)(); // Gọi submit nếu có nội dung
                        }
                      }
                    }}
                  />
                  {errors.childTaskName && (
                    <p className="flex gap-1 px-2 pt-1">
                      <img src={WarningIc} alt="warning-icon" />
                      <span className="text-xs font-normal text-red-500">{errors.childTaskName.message}</span>
                    </p>
                  )}
                </div>
                <div className="w-[28%]">
                  <input
                    id="todolist-date"
                    type="date"
                    className="w-full rounded-lg border border-[#F1F3FD] bg-white px-3 py-1.5 text-sm font-medium placeholder:text-xs placeholder:font-normal focus:border-[#234EEC] focus:bg-[#E1E6FF] focus:outline-none"
                    {...register('endDate')}
                  />
                </div>
              </div>
              {/* Hủy Lưu */}
              <div className="mt-4 flex gap-4">
                <button className="rounded-lg bg-primary-100 py-1 px-3 text-sm text-white">
                  {t('account-management.save')}
                </button>
                <div>
                  <p
                    className="cursor-pointer rounded-lg bg-gray-200 px-3 py-1 text-sm text-[#172b4d] hover:bg-gray-300"
                    onClick={() => {
                      setIsOpenTask(false);
                      reset();
                    }}
                  >
                    {t('account-management.cancel')}
                  </p>
                </div>
              </div>
            </div>
          </form>
        ) : projectInfo?.roleProject === 3 ? (
          ''
        ) : (
          <div
            className="mt-1.5 flex cursor-pointer gap-2 rounded-md py-1 hover:bg-gray-200"
            onClick={() => setIsOpenTask(!isOpenTask)}
          >
            <Icon name="plus" className="h-[25px] w-[25px] stroke-black pl-3" />
            <p className="pt-0.5 text-sm">{t('project.add-task')} </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TodoList;
