import { toggleProjectArchiveStatus } from 'Apis/ProjectManagement/projectManager';
import { Button } from 'Components/Buttons/ButtonComponent';
import Modals from 'Components/Modal/modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ConfirmSavePopup = (props) => {
  const { isOpen, setIsOpen, projectId, getProjects } = props;
  const { t } = useTranslation();

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const handleConfirm = async () => {
    try {
      await toggleProjectArchiveStatus(projectId, true);
      toast.success(t('project.project-has-been-archived'), { position: 'top-right' });
      await getProjects();
      setIsOpen(false);
    } catch (error) {
      toast.error(t('core-toast.an-error-has-occurred'), { position: 'top-right' });
      console.log(error);
    }
  };

  return (
    <Modals isOpen={isOpen} setIsOpen={setIsOpen} className={`mt-20`}>
      <div className="relative w-[464px] p-8">
        <div className="flex flex-col items-center justify-center gap-y-5">
          <div>
            <svg width="73" height="61" viewBox="0 0 73 61" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M36.7828 4.84951V3.85852C36.7828 2.24952 35.4779 0.944702 33.8689 0.944702H3.48511C1.87611 0.944702 0.571289 2.24952 0.571289 3.85852V56.4026H72.9942V7.76332C72.9942 6.15433 71.6894 4.84951 70.0804 4.84951H36.7828Z"
                fill="#C0CBFF"
              />
              <path d="M70.4573 9.04883H3.78711V56.9361H70.4573V9.04883Z" fill="white" />
              <path
                d="M35.7495 15.0359L34.674 18.0161H3.48511C1.87611 18.0173 0.571289 19.3221 0.571289 20.9311V58.0309C0.571289 59.6399 1.87611 60.9448 3.48511 60.9448H70.0804C71.6894 60.9448 72.9942 59.6399 72.9942 58.0309V16.0256C72.9942 14.4166 71.6894 13.1118 70.0804 13.1118H38.4907C37.2632 13.1106 36.1672 13.8807 35.7495 15.0359Z"
                fill="#C0CBFF"
              />
              <path
                d="M72.9939 58.0308V33.8428C52.8772 53.409 17.985 58.6452 1.38086 60.043C1.91075 60.597 2.65671 60.9446 3.48475 60.9446H70.0801C71.6891 60.9446 72.9939 59.6398 72.9939 58.0308Z"
                fill="#C0CBFF"
              />
              <path
                d="M29.9817 20.3624H5.35547C4.05548 20.3624 3.00293 21.4162 3.00293 22.715V27.927C7.14552 25.0892 16.4929 21.514 29.9817 20.3624Z"
                fill="#C0CBFF"
              />
            </svg>
          </div>

          <div className="text-center">
            <div className="text-base font-medium text-neu-400">{t('project.confirm-project-archiving')}</div>
            <div className="mt-1 text-sm font-normal text-neu-200">
              {t('project.confirm-project-archiving-description')}
            </div>
          </div>

          <div className="flex justify-center gap-x-2">
            <Button
              content={t('core-btn.cancel')}
              className="min-w-[100px] rounded-md border px-5 py-2 text-sm font-medium"
              onClick={handleClosePopup}
            />

            <Button
              content={t('core-btn.confirm')}
              className="min-w-[100px] rounded-md border bg-primary-100 px-5 py-2 text-sm font-medium text-white"
              onClick={handleConfirm}
            />
          </div>
        </div>
      </div>
    </Modals>
  );
};

export default ConfirmSavePopup;
