import instance from '../instance';

export const GetUploadKey = () => {
  const url = `/file/key`;
  return instance.get(url);
};
export const GetUploadKeyBg = () => {
  const url = `/background/key`;
  return instance.get(url);
};

export const DeleteFile = (fileSeq) => {
  const url = `/file/${fileSeq}`;
  return instance.delete(url);
};

export const GetListFiles = (entity, seq) => {
  const url = `/file/list/${entity}-${seq}`;
  return instance.get(url);
};
export const GetListFilesBackground = (entity, seq) => {
  const url = `/background/listByGroupId?groupId=${entity}-${seq}`;
  return instance.get(url);
};
// http://localhost:8080/api/background/listByGroupId?groupId=BackgroundTopic-2
