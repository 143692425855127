import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../../Assets/Css/customReactQuill.css';

const FormUserStory = () => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: ['red', '#785412'] }],
      [{ background: ['red', '#785412'] }]
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font'
  ];

  const [code, setCode] = useState('hellllo');
  // eslint-disable-next-line no-unused-vars
  const handleProcedureContentChange = (content, delta, source, editor) => {
    setCode(content);
    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };
  return (
    <div className="mt-4">
      <div>
        <h3 className="bold text-3xl">Tạo mới Sprint</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
          standard dummy text ever since the 1500s,
        </p>
      </div>
      <div>
        <label htmlFor="sprintName">tên Sprint</label>
        <input className="w-full p-2" type="text" name="" id="sprintName" />
      </div>
      <hr className="mx-8 my-2" />
      <div className="flex justify-start gap-4">
        <span>
          Ngày bắt đầu : <input className="h-10 rounded border p-2 outline-none" type="date" />
        </span>
        <span>
          Ngày kết thúc : <input className="h-10 rounded border p-2 outline-none" type="date" />
        </span>
      </div>

      <hr className="mx-8 my-2" />
      <div className="flex w-full">
        <span className="w-40">Mục tiêu</span>
        <div className="w-full">
          <ReactQuill
            theme="snow"
            id="objective"
            className="objective-quill relative h-[300px] pt-8"
            modules={modules}
            formats={formats}
            value={code}
            onChange={handleProcedureContentChange}
            toolbar
          />
        </div>
      </div>

      <hr className="mx-8 my-2" />

      <div className="flex w-full">
        <span className="w-40">Chuyển giao</span>
        <div className="w-full">
          <ReactQuill
            theme="snow"
            id="objective"
            className="objective-quill relative h-[300px] pt-8"
            modules={modules}
            formats={formats}
            value={code}
            onChange={handleProcedureContentChange}
            toolbar
          />
        </div>
      </div>

      <hr className="mx-8 my-2" />

      <div className="flex w-full">
        <span className="w-40">Xác nhận</span>
        <div className="w-full">
          <ReactQuill
            theme="snow"
            id="objective"
            className="objective-quill relative h-[300px] pt-8"
            modules={modules}
            formats={formats}
            value={code}
            onChange={handleProcedureContentChange}
            toolbar
          />
        </div>
      </div>
      <div className="mt-4 flex justify-end gap-4">
        <button className="flex h-12 w-20 items-center justify-center rounded border p-2">Hủy</button>
        <button className="flex h-12 w-20 items-center justify-center rounded border-blue-400 bg-blue-400 p-2">
          Lưu
        </button>
      </div>
    </div>
  );
};

export default FormUserStory;
