import { Navigate } from 'react-router-dom';
import { useGlobalContext } from '../../Utils/Context';

const ProtectedRoute = (props) => {
  const { roleUser } = useGlobalContext();
  const accessRole = (accessRole) => roleUser === accessRole;

  if (props.accessRole.find(accessRole)) {
    return props.children;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
