/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoAdd, IoClose } from 'react-icons/io5';
import { MdOutlineFileDownload } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'sonner';
import { GetDetailReport } from '../../../Apis/Reports/Reports';
import iconXLS from '../../../Assets/Images/icon_xls.svg';
import filePptx from '../../../Assets/Images/text_color_ppt.svg';
import fileRar from '../../../Assets/Images/text_color_rar.svg';
import fileTxt from '../../../Assets/Images/text_color_txt.svg';
import fileZip from '../../../Assets/Images/text_color_zip.svg';
import { formats, module } from './customToolBar';

import ReactQuill from 'react-quill';
import AttachFile from '../../../Components/AttachFile/AttachFile';
import Icon from '../../../Components/Common/Icon';
import Popup from '../../../Components/Popup/Popup';
import backendURL from '../../../config';
import { useGlobalContext } from '../../../Utils/Context';
import { checkFileFormat } from '../../../Utils/FileFormat';
import { formatDays, formatHours } from '../../../Utils/formatTime';
const ReportDetailReceived = () => {
  const { reportId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { userInfo, updateUserInfo } = useGlobalContext();

  const popupRef = useRef(null);
  const quillRef = useRef(null);

  const [userId, setUserId] = useState(null);
  const [infoDetail, setInfoDetail] = useState({});
  const [ccUsers, setCcUsers] = useState([]);
  const [isPopupInfo, setIsPopupInfo] = useState(false);
  const [content, setContent] = useState('');
  const [formatTime, setFormatTime] = useState('');
  const [isOpenRefuse, setIsOpenRefuse] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [response, setResponse] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm();

  const { register: register1, handleSubmit: handleSubmit1, reset: reset1, getValues: getValues1 } = useForm();

  useEffect(() => {
    const initGetInfoUser = async () => {
      const res = await updateUserInfo();
      console.log('res UserInfo:', res);
    };

    initGetInfoUser();
    setUserId(userInfo?.userID);
  }, [userInfo?.userID]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupInfo(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  // GET Api chi tiết báo cáo
  useEffect(() => {
    GetDetailReport(reportId)
      .then((res) => {
        const quill = quillRef.current.getEditor();
        setInfoDetail(res?.data?.data);
        setCcUsers(res?.data?.data?.ccUserDTO);
        const element = document.createElement('div');
        element.innerHTML = res?.data?.data?.content?.replaceAll('\\', '');
        const html = element.innerHTML;
        const imgElements = element.querySelectorAll('img');
        quill.pasteHTML(0, html);
        const quillDOMNode = quill?.container;
        const imgQuills = quillDOMNode.querySelectorAll('img');
        // Duyệt qua từng phần tử <img> và thực hiện các thay đổi
        imgQuills.forEach((image, index) => {
          image.width = imgElements[index].width;
          image.addEventListener('click', function () {
            setIsOpenImg(true);
            handleImageClick(image.src);
          });
        });
        setFormatTime(res?.data?.data?.updatedAt);
        setResponse(res?.data?.data?.response);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [reportId]);

  const handleRefuseReport = () => {
    setIsOpenRefuse(true);
    reset1();
    reset();
  };

  const handleConfirmReport = () => {
    setIsOpenConfirm(true);
    reset1();
    reset();
  };

  const onSubmit = async (data) => {
    let bodySend = {};
    if (data?.response) {
      bodySend.response = data?.response;
    }
    try {
      const url = `${backendURL}/report/updateStatus?reportId=${reportId}&status=3`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodySend)
      });
      setResponse(data?.response);
      toast.success(t('reports.you-have-denied-report'));
      setIsOpenRefuse(false); // Close the popup
      fetchReportDetail();
    } catch (error) {
      console.error('Failed to confirm report:', error);
      toast.error(t('account-management.an-error-occurred'));
    }
  };

  const onSubmit1 = async (data) => {
    let bodySend = {};
    if (data?.response) {
      bodySend.response = data?.response;
    }
    try {
      const url = `${backendURL}/report/updateStatus?reportId=${reportId}&status=2`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodySend)
      });

      toast.success(t('reports.approved-successfully'));
      setIsOpenConfirm(false); // Close the popup
      fetchReportDetail();
      setResponse(data?.response);
    } catch (error) {
      console.error('Failed to confirm report:', error);
      toast.error(t('account-management.an-error-occurred'));
    }
  };

  const handleError = (errors) => {
    // Check specific fields for errors and show appropriate toast messages
    if (errors.response) {
      if (errors.response.type === 'required') {
        toast.warning(t('reports.description-confirmed-warning')); // Show this toast if the response is required but missing
      } else if (errors.response.type === 'maxLength') {
        toast.warning(t('reports.maximum-500')); // Customize this message as needed
      }
      // Add more conditions as needed for other validation rules
    }
  };

  const fetchReportDetail = async () => {
    try {
      const res = await GetDetailReport(reportId);
      setInfoDetail(res?.data?.data);
      setCcUsers(res?.data?.data?.ccUserDTO);
      // setContent(res?.data?.data?.content);
      setFormatTime(res?.data?.data?.updatedAt);
    } catch (error) {
      console.error('Failed to fetch report details:', error);
    }
  };

  function timeAgo(dateString) {
    const createdAt = new Date(dateString);
    const now = new Date();
    const secondsAgo = Math.floor((now - createdAt) / 1000);

    if (secondsAgo < 60) {
      return `${secondsAgo} ${t('reports.second-ago')}`;
    } else if (secondsAgo < 3600) {
      return `${Math.floor(secondsAgo / 60)} ${t('reports.minutes-ago')}`;
    } else if (secondsAgo < 86400) {
      return `${Math.floor(secondsAgo / 3600)} ${t('reports.hours-ago')}`;
    } else {
      return `${Math.floor(secondsAgo / 86400)} ${t('reports.days-ago')}`;
    }
  }
  const [imgContent, setImgContent] = useState(null);
  const handleImageClick = (imageUrl) => {
    setImgContent(imageUrl);
    // alert('Đường dẫn của ảnh: ' + imageUrl);
  };
  const [isOpenImg, setIsOpenImg] = useState(false);
  const handleImageDownload = () => {
    fetch(imgContent)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'imageBzcom.jpg'); // Thay đổi 'image.jpg' thành tên tệp bạn muốn
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Lỗi khi tải xuống tệp tin:', error));
  };
  return (
    <>
      <div className="h-full min-h-screen px-6 pt-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <div className="flex cursor-pointer items-center gap-2">
              <div className="transition-opacity hover:opacity-80">
                <FaArrowLeft size={16} onClick={() => navigate(-1)} />
              </div>
              <span className="text-lg font-semibold">{t('reports.details-of-the-report-received')}</span>
            </div>
            <div className="text-sm text-[#888888]">{t('reports.displays-full-details-of-the-selected-report')}</div>
          </div>

          {/* Button create report */}
          <div>
            <button
              onClick={() => navigate('/create-report')}
              className="flex cursor-pointer items-center gap-1 rounded-md bg-primary-100 px-5 py-2 text-white transition-opacity hover:opacity-80"
            >
              <IoAdd size={20} />
              <span>{t('reports.create-report')}</span>
            </button>
          </div>
        </div>

        {/* Display detail report */}
        <div className="mt-8 w-full overflow-hidden  rounded-lg shadow-lg">
          {/* Header Detail */}
          <div className=" bg-slate-200 py-4 px-6">
            <div className="flex items-center justify-between gap-4">
              <p className="block w-[90%] break-words text-lg font-bold">{infoDetail?.title}</p>
              {+infoDetail?.status === 1 ? (
                <span className="rounded-lg bg-slate-300 px-2 py-1 text-sm text-[#686868]">
                  {t('reports.processing')}
                </span>
              ) : (
                ''
              )}
              {+infoDetail?.status === 2 ? (
                <span className="rounded-lg border border-blue-200 bg-blue-100 px-2 py-1 text-sm text-primary-100">
                  {t('reports.approve')}
                </span>
              ) : (
                ''
              )}
              {+infoDetail?.status === 3 ? (
                <span className="rounded-lg border border-rose-200 bg-rose-100 px-2 py-1 text-sm text-rose-500">
                  {t('reports.refuse')}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          {/* Popup info detail */}
          {isPopupInfo && (
            <div
              ref={popupRef}
              className="animate__animated animate__zoomIn animate__faster absolute   top-56 left-[500px] z-30 flex max-w-[600px] origin-top-left flex-col gap-2 rounded-md bg-slate-100 p-3 text-sm shadow-lg"
            >
              {/* From */}
              <div className="flex  gap-4">
                <span className="w-[15%] items-end  text-end  text-[#686868]">{t('reports.from')}:</span>
                <span className="w-[80%] font-semibold">{infoDetail?.fullName}</span>
              </div>

              {/* To */}
              <div className="flex gap-4">
                <span className="w-[15%] items-end  text-end  text-[#686868]">{t('reports.to')}:</span>
                <span className="w-[80%] font-semibold">{infoDetail?.revUserDTO?.userName}</span>
              </div>

              {/* Time */}
              <div className="flex  gap-4">
                <span className="w-[15%] items-end text-end  text-[#686868]">{t('reports.time')}:</span>
                <span className="w-[80%] font-semibold">
                  {formatHours(formatTime)}
                  {''} {formatDays(formatTime)}
                  {''} {timeAgo(formatTime)}
                </span>
              </div>

              {/* Title */}
              <div className="flex gap-4 ">
                <span className=" w-[15%] items-end text-end  text-[#686868]">{t('reports.title')}:</span>
                <span className="block w-[80%]  break-words font-semibold">{infoDetail?.title}</span>
              </div>

              {/* Cc */}
              {ccUsers?.length ? (
                <div className="flex gap-4">
                  <span className={` w-[15%] items-end text-end  text-[#686868] `}>Cc: </span>
                  <span className="w-full font-semibold">
                    {ccUsers?.length === 1 && ccUsers[0].userName}
                    {ccUsers?.length > 1 && ccUsers.map((user) => user.userName).join(', ')}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {/* Body Report */}
          <div className="min-h-[240px] bg-white px-6 py-4">
            {/* Header Body */}
            <div className="relative flex items-center justify-between">
              {/* Avatar, name sender, receiver, cc */}
              <div className="flex items-center gap-2">
                {/* avatar */}
                <div className="w-10 object-contain">
                  <AttachFile
                    attachType="UserAvatar"
                    entity="users"
                    seq={infoDetail?.userId}
                    viewMode={true}
                    mode="list-user"
                    className="h-10 w-10 rounded-full object-cover"
                  />
                </div>
                {/* info name */}
                <div>
                  <p className="text-sm font-semibold">{infoDetail?.fullName}</p>
                  <div className="flex items-center">
                    <p className="text-sm">
                      {t('reports.to')}{' '}
                      <span className="text-sm font-semibold">
                        {+infoDetail?.revUserId === +userId ? t('reports.me') : infoDetail?.revUserDTO?.userName}
                      </span>
                    </p>
                    <IoMdArrowDropdown
                      size={16}
                      className="cursor-pointer hover:opacity-70"
                      onClick={() => setIsPopupInfo(true)}
                    />
                  </div>
                </div>
              </div>

              {/* Time Report */}
              <div className="text-sm text-[#888888] ">
                {formatHours(formatTime)}
                {''} {formatDays(formatTime)}
                {''} {timeAgo(formatTime)}
              </div>
            </div>
            <div className="my-6 h-[1px] bg-slate-200"></div>

            {/* Content Report */}

            <ReactQuill
              ref={quillRef}
              // theme="snow"
              value={content}
              onChange={setContent}
              modules={module}
              formats={formats}
              placeholder={t('reports.write-content')}
              className="react-quill-hidden-calendar relativ 'max-h-full'"
              readOnly={true}
            />

            <div className="my-6 h-[1px] bg-slate-200"></div>
            <div className="flex flex-wrap gap-4 text-sm">
              {infoDetail?.attachmentFile
                ? infoDetail?.attachmentFile.map((file) => {
                    return (
                      <div key={file?.content} className="!flex gap-2 text-xs">
                        {checkFileFormat(file?.fileName) === 'txt' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src={fileTxt} alt="docx" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon docx */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_text_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 z-10 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="z-10] absolute bottom-0 -right-5 h-0 w-0 border-[10px] border-primary-100"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_self');
                              }}
                              className="icon-download absolute flex w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* File .docx */}
                        {checkFileFormat(file?.fileName) === 'docx' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src="/icon_doc.svg" alt="docx" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon docx */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_word_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 z-10 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="z-10] absolute bottom-0 -right-5 h-0 w-0 border-[10px] border-red-500"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_self');
                              }}
                              className="icon-download absolute flex w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* File .docx */}
                        {checkFileFormat(file?.fileName) === 'doc' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src="/icon_doc.svg" alt="docx" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon docx */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_word_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 z-10 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="z-10] absolute bottom-0 -right-5 h-0 w-0 border-[10px] border-red-500"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_self');
                              }}
                              className="icon-download absolute flex w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* File .pptx */}
                        {checkFileFormat(file?.fileName) === 'pptx' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src={filePptx} alt="docx" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon docx */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_powerpoint_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 z-10 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="z-10] absolute bottom-0 -right-5 h-0 w-0 border-[10px] border-[#ff7537]"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_self');
                              }}
                              className="icon-download absolute flex w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* File .zip */}
                        {checkFileFormat(file?.fileName) === 'zip' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src={fileZip} alt="docx" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon rar */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_2_archive_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 z-10 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="z-10] absolute bottom-0 -right-5 h-0 w-0 border-[10px] border-[#686868]"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_self');
                              }}
                              className="icon-download absolute flex w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* File .rar */}
                        {checkFileFormat(file?.fileName) === 'rar' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src={fileRar} alt="docx" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon docx */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_2_archive_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 z-10 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="z-10] absolute bottom-0 -right-5 h-0 w-0 border-[10px] border-[#686868]"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_self');
                              }}
                              className="icon-download absolute flex w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* File .xlsx */}
                        {checkFileFormat(file?.fileName) === 'xlsx' || checkFileFormat(file?.fileName) === 'xls' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src={iconXLS} alt="xls" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon docx */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_excel_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 z-10 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="z-10] absolute bottom-0 -right-5 h-0 w-0 border-[10px] border-[#0DB664]"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_self');
                              }}
                              className="icon-download absolute flex w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* File .pdf */}
                        {checkFileFormat(file?.fileName) === 'pdf' ? (
                          <div className="custom-hover-report relative w-[200px] cursor-pointer hover:bg-blend-overlay">
                            {/* Image */}
                            <div className="flex justify-center border border-slate-200 text-center">
                              <img className="z-20 w-[122px] object-cover p-4" src="/icon_pdf.svg" alt="pdf" />
                            </div>
                            <div className="info-report relative flex items-center gap-2 bg-slate-200 px-2 py-2">
                              {/* icon pdf */}
                              <div>
                                <img
                                  className="w-[16px]"
                                  src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_3_pdf_x16.png"
                                  alt=""
                                />
                              </div>
                              <p className="line-clamp-1 w-[80%] font-semibold text-[#686868]">{file?.fileName}</p>
                            </div>

                            <div className="after:after-custom absolute bottom-0 right-0 h-5 w-5 border-r-[20px] border-transparent after:absolute after:bottom-0 after:-right-5 after:text-[0px]">
                              <div className="absolute bottom-0 -right-5 z-40 h-0 w-0 border-[10px] border-[#fb4c2f]"></div>
                            </div>

                            <div className="custom-report absolute inset-0 bg-[#e1e0e0]"></div>
                            <div
                              onClick={() => {
                                window.open(`${backendURL}/file/displayImg/${file.content}`, '_blank');
                              }}
                              className="icon-download absolute w-fit rounded-md border border-slate-400 bg-slate-400 p-[6px] text-white transition-opacity hover:opacity-70"
                            >
                              <MdOutlineFileDownload size={16} />
                              <span>{t('reports.download')}</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })
                : ''}
            </div>
            {/* Response */}

            {response ? (
              <div className="mt-5 mb-4 overflow-hidden rounded-lg border text-sm">
                {/* Header */}
                <div className="bg-slate-200 py-4 px-6 font-semibold">{t('reports.report-comments')}</div>
                <div className="bg-white">
                  <textarea value={response} className="h-[200px] max-h-[600px] w-full py-4 px-6" disabled></textarea>
                </div>
              </div>
            ) : (
              ''
            )}

            {/* Button Refuse and Confirm */}
            {infoDetail?.status === 1 && +infoDetail?.revUserId === +userId ? (
              <div className="mt-6 flex items-center justify-center gap-6 text-sm">
                <button
                  onClick={() => handleRefuseReport()}
                  className="flex min-w-[130px] items-center justify-center gap-1 rounded-lg bg-rose-500 py-2 px-4 text-white transition-opacity hover:opacity-75"
                >
                  <IoClose size={16} />
                  <span>{t('reports.refuse')}</span>
                </button>
                <button
                  onClick={() => handleConfirmReport()}
                  className="flex min-w-[130px] items-center justify-center gap-1 rounded-lg bg-primary-100 py-2 px-4 text-white transition-opacity hover:opacity-75"
                >
                  <FaCheck size={16} />
                  <span>{t('reports.approveBtn')}</span>
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {/* Popup Refuse */}
      <Popup isOpen={isOpenRefuse} setIsOpen={setIsOpenRefuse}>
        <form onSubmit={handleSubmit(onSubmit, handleError)}>
          <div className="relative  flex flex-col items-center justify-center gap-2 px-8 py-8 text-sm">
            <IoClose
              size={24}
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setIsOpenRefuse(false)}
            />
            <img src="/cancel_icon.svg" alt="cancel" className="w-[60px] object-cover" />
            <p className="mt-2 font-semibold">{t('reports.confirmed-refusal')}</p>
            <p className="text-[#686868]">{t('reports.description-confirmed-refusal')}</p>
            <textarea
              {...register(`response`, {
                required: true, // The message here is just a placeholder, actual error handling is done in handleError
                maxLength: {
                  value: 500
                }
              })}
              name="response"
              placeholder={t('reports.enter-comment-content')}
              className="mt-2 h-[200px] w-full resize-none rounded-md border border-slate-200 bg-slate-200 p-2 outline-none"
            ></textarea>
            {/* {errors?.userReveicerId?.type === 'maxLength' && (
              <p className="mt-2 flex gap-1 pl-12">
                <img src={WarningIc} alt="warning-icon" />
                <span className="text-xs font-normal text-red-500">Tối đa 500 ký tự</span>
              </p>
            )} */}

            {/* Button cancel and confirm */}
            <div className="mt-3 flex gap-2">
              <button
                onClick={() => setIsOpenRefuse(false)}
                className="w-[120px] cursor-pointer rounded-lg border border-slate-300 px-6 py-2 transition-opacity hover:opacity-75"
              >
                {t('reports.cancel')}
              </button>
              <button
                type="submit"
                className="w-[120px] cursor-pointer rounded-lg border border-slate-300 bg-primary-100 px-6 py-2 text-white transition-opacity hover:opacity-75"
              >
                {t('project.confirm')}
              </button>
            </div>
          </div>
        </form>
      </Popup>

      <Popup isOpen={isOpenConfirm} setIsOpen={setIsOpenConfirm}>
        <form onSubmit={handleSubmit1(onSubmit1, handleError)}>
          <div className="relative  flex flex-col items-center justify-center gap-2 px-8 py-8 text-sm">
            <IoClose
              size={24}
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setIsOpenConfirm(false)}
            />
            <img src="/icon_checked.svg" alt="cancel" className="w-[60px] object-cover" />
            <p className="mt-2 font-semibold">{t('reports.confirmed-approval')}</p>
            <p className="text-[#686868]">{t('reports.description-confirmed-approval')}</p>
            <textarea
              {...register1(`response`, {
                maxLength: {
                  value: 500
                }
              })}
              name="response"
              placeholder={t('reports.enter-comment-content')}
              className="mt-2 h-[200px] w-full resize-none rounded-md border border-slate-200 bg-slate-200 p-2 outline-none"
            ></textarea>
            {/* Button cancel and confirm */}
            <div className="mt-3 flex gap-2">
              <button
                onClick={() => setIsOpenConfirm(false)}
                className="w-[120px] cursor-pointer rounded-lg border border-slate-300 px-6 py-2 transition-opacity hover:opacity-75"
              >
                {t('reports.cancel')}
              </button>
              <button className="w-[120px] cursor-pointer rounded-lg border border-slate-300 bg-primary-100 px-6 py-2 text-white transition-opacity hover:opacity-75">
                {t('project.confirm')}
              </button>
            </div>
          </div>
        </form>
      </Popup>
      <Popup isOpen={isOpenImg} setIsOpen={setIsOpenImg} className="">
        <div className=" relative flex max-w-[963px] flex-col items-center justify-center gap-2   text-sm">
          <div className="relative">
            <Icon
              name="close_cross"
              className="absolute top-2 right-2 h-8 w-8 cursor-pointer rounded-full border stroke-white hover:bg-[#F2F2F2] hover:stroke-black "
              onClick={() => setIsOpenImg(false)}
            />
            <div>
              <img src={imgContent} alt="Ảnh" />
            </div>
            <div
              className="absolute bottom-4 right-2 float-right mb-2 flex w-[137px] cursor-pointer justify-center rounded-md border bg-[#F2F2F2] hover:bg-[#d4d3d3]"
              onClick={handleImageDownload}
            >
              <Icon name="download" className=" stroke-black " />
              <p className="   px-2 py-1 text-center text-black">{t('reports.download')}</p>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default ReportDetailReceived;
