import { t } from 'i18next';
import { useOutletContext } from 'react-router-dom';
import Icon from '../../Common/Icon';

const ProjectBoardFile = (props) => {
  const { projectInfo } = useOutletContext();
  if (props.viewMode) {
    if (props.mode === 'form') {
      return (
        <div className="relative">
          <div>
            <img
              id="display-img"
              src={require('../../../Assets/Images//cloud-upload.png')}
              alt="avatar"
              className={props.className}
            />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="w-full">
        {props.uploadKey !== '' && <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />}
        <div className=" flex h-[110px] w-full items-center rounded-xl  bg-[#F2F2F2]">
          <button
            type="button"
            className={`${projectInfo?.roleProject === 3 ? '' : 'upload-btn'} w-full rounded-lg py-[6px] text-white`}
          >
            <Icon name="plus" className="rounded-lg bg-[#D9D9D9] stroke-black py-2.5 px-3" />
            <p className="pt-1.5 text-center text-[13px] font-normal text-black">
              {t('project.add-your-background-image')}
            </p>
          </button>
        </div>
      </div>
    );
  }
};

export default ProjectBoardFile;
