import instance from '../instance';

/* language: String
language: 'vi' (VietNamese)
language: 'en' (English)
*/
export const getNotify = (userId, language, numberPages) => {
  const url = `/notify?userId=${userId}&language=${language}&page=0&size=${numberPages}`;
  return instance.get(url);
};

export const readNewNotify = (userId) => {
  const url = `/notify/checkNotify?userId=${userId}`;
  return instance.post(url);
};

/* status: Boolean
  status: true (read)
  status: false (unread)
*/
export const readNotify = (notifyId, status) => {
  const url = `/notify/markAsRead?notifyId=${notifyId}&status=${status}`;
  return instance.post(url);
};

export const readNotifyAll = (userId) => {
  const url = `/notify/markAllRead?userId=${userId}`;
  return instance.post(url);
};

/* statusDelete: String
statusDelete: 'temp' (temporarily delete notifications)
statusDelete : 'delete' (delete notifications)
statusDelete : 'undo' (undo notification)
*/
export const deleteNotify = (notifyId, statusDelete) => {
  const url = `/notify/disable?notifyId=${notifyId}&statusDelete=${statusDelete}`;
  return instance.post(url);
};

export const deleteNotifyAll = (userId, statusDelete) => {
  const url = `/notify/disableAll?userId=${userId}&statusDelete=${statusDelete}`;
  return instance.post(url);
};
