import { useTranslation } from 'react-i18next';
import EditIcon from '../../Assets/Images/Icon.svg';

export const ButtonComponent = ({ typeBtn, content, style, background, icon, onHandleClick, border }) => {
  return (
    <button
      type={typeBtn}
      onClick={() => {
        if (onHandleClick === undefined) {
          return false;
        } else {
          onHandleClick();
        }
      }}
      className={`mx-1 flex h-[48px] items-center rounded-[14px] ${
        border === undefined ? 'shadow-[0_6px_12px_rgba(18,148,164,0.26)]' : ''
      } ${background} ${border}`}
    >
      <img src={icon} alt="" className={`${icon ? 'ml-6' : ''}`} />
      <span className={`${icon ? 'ml-2' : 'ml-6'} mr-6 text-sm ${style} font-medium`}>{content}</span>
    </button>
  );
};

export const EditButton = (props) => {
  const { t } = useTranslation();
  return (
    <button
      type={props.type || 'button'}
      onClick={props.onClick}
      className={`${
        props.className ||
        'flex items-center justify-center gap-x-2 rounded-md bg-[#F1F3FD] p-2 text-xs font-medium text-primary-100'
      }`}
    >
      <span>{props.content || t('account-management.edit')}</span>
      <img src={props.imgSrc || EditIcon} alt="" />
    </button>
  );
};

export const SubmitButton = (props) => {
  const { t } = useTranslation();
  return (
    <button
      type={props.type || 'submit'}
      onClick={props.onClick}
      className={`${
        props.className || 'flex h-[40px] rounded-md bg-[#234EEC] py-3 px-6 text-xs font-medium text-white'
      }`}
    >
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mt-1 mr-2"
      >
        <path
          d="M12.3327 1.66675L5.47075 8.52868C5.2104 8.78903 4.78829 8.78903 4.52794 8.52868L1.66602 5.66675"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>

      <span>{props.content || t('job.confirm')}</span>
    </button>
  );
};

export const CloseButton = (props) => {
  return (
    <button onClick={props.onClick}>
      <i className="stroke-gray-500 transition-all duration-75 ease-in-out hover:stroke-neu-400 hover:stroke-2 active:scale-90">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4L4 12M12 12L4 4.00001" strokeLinecap="round" />
        </svg>
      </i>
    </button>
  );
};

// v1.0.1
export const CreateButton = ({ onClick, className, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${className} flex items-center justify-center rounded-[100%] bg-primary-100`}
    >
      <i className="">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0007 3.3335V16.6668M16.6673 10.0002L3.33398 10.0002"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </i>
    </button>
  );
};

/**
 * Button component.
 *
 * @param {Object} props - The properties of the Button component.
 * @param {string} [props.type='button'] - The type of the button (e.g., 'button', 'submit').
 * @param {boolean} [props.defaultStyle=true] - Whether to apply default styling to the button.
 * @param {ReactNode} [props.icon] - The icon element to be displayed inside the button.
 * @param {ReactNode} [props.content] - The content to be displayed inside the button.
 * @param {function} [props.onClick] - The function to be called when the button is clicked.
 * @param {string} [props.className] - Additional CSS classes to be applied to the button.
 * @param {boolean} [props.disabled] - Whether the button is disabled.
 * @returns {ReactNode} The rendered Button component.
 */
export const Button = ({ type = 'button', defaultStyle = true, icon, content, onClick, className, disabled }) => {
  return (
    <button
      type={type}
      className={`${defaultStyle && 'flex items-center justify-center gap-x-2 rounded-lg'} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <>{icon}</>}
      {content && <span>{content}</span>}
    </button>
  );
};

// End
