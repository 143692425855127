import React, { useContext, useEffect, useState } from 'react';

const TaskProgressContext = React.createContext();

const ContextTaskProgress = ({ children }) => {
  const [typeTaskProgress, setTypeTaskProgress] = useState(1);
  const [typeProgressModal, setTypeProgressModal] = useState('');
  const [onClickCreate, setOnClickCreate] = useState(false);

  const changeTypeTaskProgress = (value) => {
    setTypeTaskProgress(value);
    onCloseClickCreate();
  };
  const logDemo = () => {
    console.log(123);
  };

  useEffect(() => {
    if (typeTaskProgress === 1) {
      setTypeProgressModal('list');
    } else if (typeTaskProgress === 2) {
      setTypeProgressModal('board');
    } else if (typeTaskProgress === 3) {
      setTypeProgressModal('calendar');
    }
  }, [typeTaskProgress]);

  const createNewButton = (value) => {
    return value;
  };

  const changeOnClickCreate = (value) => setOnClickCreate(value);
  const onCloseClickCreate = () => setOnClickCreate(false);

  return (
    <TaskProgressContext.Provider
      value={{
        typeTaskProgress,
        changeTypeTaskProgress,
        logDemo,
        createNewButton,
        typeProgressModal,
        onClickCreate,
        changeOnClickCreate,
        onCloseClickCreate
      }}
    >
      {children}
    </TaskProgressContext.Provider>
  );
};

export const useProgressTaskContext = () => {
  return useContext(TaskProgressContext);
};

export { ContextTaskProgress };
