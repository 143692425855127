import { t } from 'i18next';
import { useEffect, useState } from 'react';
import requestClient from '../../../Apis/requestClient';
import { useGlobalContext } from '../../../Utils/Context';
import AttachFile from '../../AttachFile/AttachFile';
import { toDateStringss } from '../../Calendar/CalendarComponent';
import Modals from '../modal';

const UserInfomation = () => {
  const { idUserInfomation, changeIdUserInfomation } = useGlobalContext();
  const [isInfo, setIsInfo] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [userInfomation, setUserInfomation] = useState(null);

  const closeInfo = () => {
    setIsInfo(false);
    changeIdUserInfomation(null);
  };

  const getDetailUser = async () => {
    // Gọi đến api để hiển thị dữ liệu chi tiết của user
    const data = await requestClient({
      endPoint: `/user/findUserById/${idUserInfomation}`,
      method: 'get'
    });
    setUserInfomation(data?.data?.data);
  };

  useEffect(() => {
    if (idUserInfomation) {
      getDetailUser();
      setIsInfo(true);
    } else {
      setIsInfo(false);
      setUserInfomation(null);
    }
  }, [idUserInfomation]);

  return (
    <Modals
      isOpen={isInfo}
      setIsOpen={closeInfo}
      className=" relative mt-[120px]  h-auto  w-[734px] "
      title={t('account-management.account_information')}
    >
      <div className="max-h-[calc(100vh-172px)] w-[734px] max-w-[734px] rounded-xl bg-white px-6 py-8">
        <div className="mb-9 ml-6 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <AttachFile
              attachType="UserAvatar"
              entity="users"
              seq={idUserInfomation}
              viewMode={true}
              mode="form"
              className="h-[108px] w-[108px] rounded-xl object-cover"
            />
            <p className="text-base font-normal text-[#868686]">@{userInfomation?.userName}</p>
          </div>
        </div>

        <div>
          {/* <h4 className="mb-6 ml-5 text-lg font-medium text-[#F59D34]">{t('user-info.basic-information')}</h4> */}

          <ul className="mb-6 grid grid-cols-2 gap-x-8 gap-y-5 px-6">
            <li>
              <label className="text-xs font-normal">{t('account-management.employee-code')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {userInfomation?.userCode}
              </p>
            </li>

            <li>
              <label className="text-xs font-normal">{t('account-management.position')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {userInfomation?.position}
              </p>
            </li>

            <li>
              <label className="text-xs font-normal">{t('account-management.full-name')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {userInfomation?.fullName}
              </p>
            </li>

            <li>
              <label className="text-xs font-normal">{t('account-management.date-of-birth')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {!userInfomation?.birthDate ? '--/--/----' : toDateStringss(userInfomation?.birthDate, userInfomation)}
              </p>
            </li>

            <li>
              <label className="text-xs font-normal">Email</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {userInfomation?.email}
              </p>
            </li>

            <li>
              <label className="text-xs font-normal">{t('account-management.gender')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {userInfomation?.gender === 'male' && <>{t('account-management.male')}</>}{' '}
                {userInfomation?.gender === 'female' && <>{t('account-management.female')}</>}
              </p>
            </li>

            <li>
              <label className="text-xs font-normal">{t('account-management.phone-number')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {userInfomation?.phoneNumber}
              </p>
            </li>

            <li>
              <label className="text-xs font-normal">{t('account-management.start-date')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {!userInfomation?.startDate ? '--/--/----' : toDateStringss(userInfomation?.startDate, userInfomation)}
              </p>
            </li>

            <li className="col-span-2">
              <label className="text-xs font-normal">{t('account-management.address')}</label>
              <p className="mt-1 flex h-[40px] items-center rounded-[6px] bg-primary-400 px-3 text-base font-medium">
                {userInfomation?.address}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Modals>
  );
};

export default UserInfomation;
