import EmojiPicker, { SuggestionMode } from 'emoji-picker-react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Quill } from 'react-quill';
import { toast } from 'react-toastify';
import projectBoard from '../../Apis/ProjectManagement/TaskProgress/board';
import '../../Assets/Css/emojiPicker.css';
import AttachFile from '../../Components/AttachFile/AttachFile';
import { toDateStringYears } from '../../Components/Calendar/CalendarComponent';
import Icon from '../../Components/Common/Icon';
import Popup from '../../Components/Popup/Popup';
import backendURL from '../../config';
import { useGlobalContext } from '../Context';

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
let cursorPositionBeforeList;
// handle them correctly
const CustomUndo = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 11.25C6.25 11.75 7.2 12.75 9 12.75C10.8 12.75 11.75 11.75 12 11.25M6.23428 6.98435H6.51553M6.23428 7.2656H6.51553M11.4843 6.98435H11.7655M11.4843 7.2656H11.7655M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM6.75 7.125C6.75 7.33211 6.58211 7.5 6.375 7.5C6.16789 7.5 6 7.33211 6 7.125C6 6.91789 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.91789 6.75 7.125ZM12 7.125C12 7.33211 11.8321 7.5 11.625 7.5C11.4179 7.5 11.25 7.33211 11.25 7.125C11.25 6.91789 11.4179 6.75 11.625 6.75C11.8321 6.75 12 6.91789 12 7.125Z"
      stroke="#111111"
      strokeWidth="0.75"
      strokeLinecap="round"
    />
  </svg>
);

const CustomImage = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.75 7.5L9.96967 16.7197C10.2626 17.0126 10.7374 17.0126 11.0303 16.7197L17.25 10.5M5.25 11.25L8.46967 8.03033C8.76256 7.73744 9.23744 7.73744 9.53033 8.03033L14.25 12.75M13.2186 4.2187H13.7811M13.2186 4.7812H13.7811M6.75 17.25H11.25C13.3502 17.25 14.4003 17.25 15.2025 16.8413C15.9081 16.4817 16.4817 15.9081 16.8413 15.2025C17.25 14.4003 17.25 13.3502 17.25 11.25V6.75C17.25 4.6498 17.25 3.5997 16.8413 2.79754C16.4817 2.09193 15.9081 1.51825 15.2025 1.15873C14.4003 0.75 13.3502 0.75 11.25 0.75H6.75C4.6498 0.75 3.5997 0.75 2.79754 1.15873C2.09193 1.51825 1.51825 2.09193 1.15873 2.79754C0.75 3.5997 0.75 4.6498 0.75 6.75V11.25C0.75 13.3502 0.75 14.4003 1.15873 15.2025C1.51825 15.9081 2.09193 16.4817 2.79754 16.8413C3.5997 17.25 4.6498 17.25 6.75 17.25ZM14.25 4.5C14.25 4.91421 13.9142 5.25 13.5 5.25C13.0858 5.25 12.75 4.91421 12.75 4.5C12.75 4.08579 13.0858 3.75 13.5 3.75C13.9142 3.75 14.25 4.08579 14.25 4.5Z"
      stroke="#171717"
      strokeWidth="0.75"
      strokeLinecap="round"
    />
  </svg>
);

const CustomFile = ({ onClick }) => {
  return (
    <svg
      onClick={() => onClick()}
      className="h-[14px] w-[14px]"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z" />
    </svg>
  );
};

// Undo and redo functions for Custom Toolbar
// eslint-disable-next-line no-unused-vars
function undoChange(emojiData, event) {
  const cursorPosition = this.quill.getSelection().index;

  // Chèn giá trị emoji tại vị trí con trỏ hiện tại
  this.quill?.insertText(cursorPosition, emojiData, 'emoji');
  // this.quill.history.undo();
}

// eslint-disable-next-line no-unused-vars
function imageChange(emojiData, event) {
  const cursorPosition = this.quill.getSelection().index;
  cursorPositionBeforeList = cursorPosition;
}

// eslint-disable-next-line no-unused-vars
function fileChange(event) {
  const cursorPosition = this.quill?.getSelection()?.index;
  cursorPositionBeforeList = cursorPosition;
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Image = Quill.import('formats/image');
Quill.register(Image, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageChange,
      file: fileChange
    },
    align: {
      // Tùy chỉnh căn lề
      options: ['left', 'center', 'right', 'justify']
    },
    indent: {
      // Tùy chọn thụt lề
      options: ['1', '2', '3'] // giá trị thụt lề (có thể thay đổi)
    },
    color: [], // Màu chữ
    background: [] // Màu nền
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header', // Cấp độ tiêu đề
  'font', // Font chữ
  'size', // Kích thước chữ
  'bold', // In đậm
  'italic', // In nghiêng
  'underline', // Gạch dưới
  'strike', // Gạch ngang
  'blockquote', // Trích dẫn
  'list', // Danh sách
  'bullet', // Danh sách dạng bullet
  'indent', // Thụt lề
  'align', // Căn lề
  'color', // Màu chữ
  'background', // Màu nền chữ
  'link', // Liên kết
  'image', // Hình ảnh
  'video', // Video
  'code', // Đoạn mã
  'code-block', // Khối mã
  'script', // Dạng script trên/dưới (sub/superscript)
  'direction', // Hướng văn bản (LTR/RTL)
  'formula' // Công thức toán học
];

// Quill Toolbar component
// eslint-disable-next-line no-unused-vars
export const QuillToolbar = ({ quill, detailTask, setDetailTask, dataFilelink }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [selectLinkImage, setSelectLinkImage] = useState();

  const { callBackFile, setCallBackFile } = useGlobalContext();
  // eslint-disable-next-line no-unused-vars
  const { register, handleSubmit, reset } = useForm();

  // eslint-disable-next-line no-unused-vars
  const handleChangValueQuill = (emojiData, event) => {
    const quillNew = quill?.current?.getEditor();
    const cursorPosition = quillNew.getSelection().index;
    quillNew.insertText(cursorPosition, emojiData?.emoji, 'emoji');
  };

  const closeModalTaskImage = () => {
    setOpenImage(false);
    setOpenFile(false);
  };

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const isImageFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  // eslint-disable-next-line no-unused-vars
  const handleImage = async (data) => {
    const newData = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      projBoardColId: detailTask.projBoardColId,
      taskName: detailTask?.taskName,
      endDate: detailTask?.endDate,
      description: detailTask?.description,
      uploadKey: data.uploadKey,
      fileSeq: data.seq,
      coverImageName: detailTask?.coverImageName
    };

    setCallBackFile(!callBackFile);
    try {
      await projectBoard?.editTaskKanban(newData);
      const url = backendURL + `/file/displayImg/` + data.seq;
      setSelectLinkImage(url);
      closeModalTaskImage();
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  // Hàm kiểm tra file text
  const textExtensions = ['txt', 'doc', 'docx', 'pdf'];
  const isTextFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return textExtensions.includes(fileExtension);
  };

  // eslint-disable-next-line no-unused-vars
  const pushhtmlFileToQuill = (quillDetail, item, startsWithHttps) => {
    const quillRef = quillDetail?.current.getEditor();
    quillRef?.pasteHTML(cursorPositionBeforeList, '_');
    quillRef?.deleteText(cursorPositionBeforeList, 1);
    if (startsWithHttps) {
      quillRef?.insertText(cursorPositionBeforeList, `${item?.fileName}`, 'link', item?.fileName);
    } else {
      quillRef?.insertText(cursorPositionBeforeList, `${item?.fileName}`);
    }
    quillRef?.formatText(cursorPositionBeforeList, cursorPositionBeforeList + item?.fileName?.length, {
      italic: true
    });
    quillRef?.pasteHTML(cursorPositionBeforeList + item?.fileName?.length, '_');

    // Set style css for tag member element
    let [leaf] = quillRef.getLeaf(cursorPositionBeforeList + 1);
    const insertedElement = leaf?.parent?.domNode;
    insertedElement.contentEditable = 'false';
    insertedElement.id = 'linkUrlfile';
    quillRef?.insertText(cursorPositionBeforeList + item?.fileName?.length, ` `);
    quillRef?.deleteText(cursorPositionBeforeList + item?.fileName?.length + 1, 1);
    closeModalTaskImage();
  };

  const handleFile = async (data) => {
    const newData = {
      projBoardTaskId: detailTask?.projBoardTaskId,
      projBoardColId: detailTask.projBoardColId,
      taskName: detailTask?.taskName,
      endDate: detailTask?.endDate,
      description: detailTask?.description,
      uploadKey: data.uploadKey,
      fileSeq: data.seq,
      coverImageName: detailTask?.coverImageName
    };
    setCallBackFile(!callBackFile);
    try {
      await projectBoard?.editTaskKanban(newData);
      const dataItem = {
        fileName: data.file.name,
        content: data.seq
      };
      pushhtmlFileToQuill(quill, dataItem, false);
      closeModalTaskImage();
    } catch (error) {
      toast.error(t('project.an-error-occurred'), {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
    }
  };

  useEffect(() => {
    if (quill?.current) {
      const quillRef = quill?.current.getEditor();
      if (selectLinkImage) {
        quillRef.insertEmbed(cursorPositionBeforeList ? cursorPositionBeforeList : 0, 'image', selectLinkImage, {
          src: selectLinkImage,
          style: 'width : 200px'
        }),
          setSelectLinkImage();
      }
    }
  }, [quill, selectLinkImage]);

  return (
    <div
      style={{ backgroundColor: '#F2F2F2', border: 'none', borderRadius: '10px 10px 0 0' }}
      id="toolbar"
      className="quill w-full border-0"
    >
      <strong></strong>
      <span className="ql-formats">
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-indent" value="-1"></button> {/* Thụt lề trái */}
        <button className="ql-indent" value="+1"></button> {/* Thụt lề phải */}
        <select className="ql-color"></select>
        <select className="ql-background"></select>
        <select className="ql-align">
          <option defaultValue></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>

      <span className="ql-formats">
        <button className="ql-link" />
      </span>
      <span className="ql-formats">
        <button className="ql-undo" onClick={() => setShowPicker(!showPicker)}>
          <CustomUndo />
          {showPicker ? (
            <div id="emojiPicker" style={{ width: '350px', maxWidth: '350px', height: '400px' }}>
              <EmojiPicker
                className="z-10"
                onEmojiClick={handleChangValueQuill}
                autoFocusSearch={false}
                height={400}
                width={300}
                showPreview={false}
                searchDisabled={true}
                emojiVersion="0.6"
                lazyLoadEmojis={false}
                previewConfig={{
                  defaultCaption: 'Pick one!',
                  defaultEmoji: '1f92a'
                }}
                suggestedEmojisMode={SuggestionMode.RECENT}
              />
            </div>
          ) : (
            ''
          )}
        </button>

        <span className="ql-formats">
          <button className="ql-image" onClick={() => setOpenImage(true)}>
            <CustomImage />
          </button>
          <Popup isOpen={openImage} setIsOpen={closeModalTaskImage}>
            <div className="max-h-[600px] min-h-[200px] w-[600px]">
              <div className="mt-4 flex justify-between border-b  pb-2">
                <p></p>
                <div className="flex w-[55%] justify-between">
                  <p className="text-base font-bold">{t('project.select-photo')}</p>
                  <div
                    className=" mr-4 flex cursor-pointer items-center justify-center rounded-full bg-gray-300 bg-opacity-60 px-1.5 py-0.5  hover:bg-opacity-90"
                    onClick={() => closeModalTaskImage()}
                  >
                    <Icon name="close_cross" className=" h-[16px] w-[16px] stroke-black" />
                  </div>
                </div>
              </div>
              <div>
                <div className="ml-8 mt-4">
                  <p>{t('project.recently-uploaded-photo')}</p>
                </div>
                <div className="mx-8 mb-6">
                  <div className="grid h-auto w-full grid-cols-3 ">
                    {dataFilelink?.boardTaskImagesAndLinks?.map((item, index) => {
                      const listImage = isImageFile(item.fileName); //Kiểm tả định dạng file
                      const startsWithHttps = item.fileName.startsWith('http'); //Kiểm tra xem có phải là đường dẫn HTTP không
                      const url = startsWithHttps
                        ? item.fileName
                        : `${backendURL + '/file/' + 'displayImg/' + item.content}`;
                      if (listImage) {
                        return (
                          <div onClick={() => setSelectLinkImage(url)} key={index}>
                            <img
                              src={url} //Nếu là đường dẫn thì hiện ảnh đường dẫn còn ngược lại thì hiện ảnh tải lên
                              className="mt-2 h-[80px] w-[110px] rounded-md object-cover"
                              onClick={() => setSelectLinkImage(url)}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>

                <div className=" mb-4 flex w-full justify-center px-6 pb-8">
                  <AttachFile
                    attachType="ProjectTaskFile"
                    entity="ProjBoardTask"
                    multi={false}
                    editable={true}
                    register={register}
                    viewMode={false}
                    handleUpload={handleImage}
                    mode="form"
                    filters={{
                      max_file_size: '10mb',
                      mime_types: [
                        {
                          title: 'Tệp đã được nhận: ',
                          extensions: 'jpg,png'
                        }
                      ]
                    }}
                    className="  rounded-t-full"
                  />
                </div>
              </div>
            </div>
          </Popup>
          <button className="ql-file">
            <CustomFile onClick={() => setOpenFile(!openFile)} />
            <Popup isOpen={openFile} setIsOpen={closeModalTaskImage}>
              <div className="max-h-[600px] min-h-[200px] w-[450px]">
                <div className="mt-4 flex justify-between border-b  pb-2">
                  <p></p>
                  <div className="flex w-[55%] justify-between">
                    <p className="text-base font-bold">{t('project.select-file-new')}</p>
                    <div
                      className=" mr-4 flex cursor-pointer items-center justify-center rounded-full bg-gray-300 bg-opacity-60 px-1.5 py-0.5  hover:bg-opacity-90"
                      onClick={() => closeModalTaskImage()}
                    >
                      <Icon name="close_cross" className=" h-[16px] w-[16px] stroke-black" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="ml-8 mt-4">
                    <p>{t('project.recently-uploaded-file')}</p>
                  </div>
                  <div className="mx-8 mb-6">
                    <div className="my-2 flex h-auto w-full flex-col gap-2 ">
                      {dataFilelink?.boardTaskImagesAndLinks?.map((item, index) => {
                        const startsWithHttps = item.fileName.startsWith('http');
                        const parts = item?.fileName?.split('/').pop();
                        const checkFileImage = isImageFile(item?.fileName); //Kiểm tra xem có phải là file ảnh không
                        if (!checkFileImage) {
                          return (
                            <div
                              key={index}
                              onClick={() => pushhtmlFileToQuill(quill, item, startsWithHttps)}
                              className="flex cursor-pointer gap-4"
                            >
                              <div className="">
                                <div className="flex h-[86px] w-[118px] items-center justify-center rounded-xl border font-semibold uppercase text-[#172b4d]">
                                  {isTextFile(item.fileName) ? (
                                    item.fileName.split('.').pop().toLowerCase()
                                  ) : (
                                    <Icon name="link_2_diagonal" className=" stroke-black" />
                                  )}
                                </div>
                              </div>
                              <div>
                                <p className=" block w-[280px] whitespace-normal break-words text-sm font-bold">
                                  {parts}
                                </p>
                                <div className="mt-1 flex gap-3 text-xs">
                                  <p>{toDateStringYears(item.updatedAt)}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>

                  <div className="mb-4 w-full justify-center px-6 pb-8">
                    <p className="mb-2 text-sm font-normal text-[#787878]">
                      Choose files (.xlsx, .ppt, .pptx, .txt, .csv, .docx, .doc, .pdf, .png, .jpg, .jpeg, .zip, .rar)
                    </p>
                    <AttachFile
                      attachType="ProjectTaskFile"
                      entity="ProjBoardTask"
                      multi={false}
                      editable={true}
                      register={register}
                      viewMode={false}
                      handleUpload={handleFile}
                      mode="form"
                      filters={{
                        max_file_size: '10mb',
                        mime_types: [
                          { title: 'Document files', extensions: 'pdf,doc,docx,xls,xlsx,ppt,pptx' },
                          { title: 'Archive files', extensions: 'zip,rar,tar,gz' },
                          { title: 'Text files', extensions: 'txt,html,xml' }
                        ]
                      }}
                      className="rounded-t-full"
                    />
                  </div>
                </div>
              </div>
            </Popup>
          </button>
        </span>

        <span className="ql-formats"></span>
      </span>
    </div>
  );
};

export default QuillToolbar;
