import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation';
import translationVI from '../locales/vi/translation';

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    lng: 'vi',
    fallbackLng: 'vi',
    debug: false,
    resources: {
      en: {
        translation: translationEN
      },
      vi: {
        translation: translationVI
      }
    },
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
      cookieName: 'i18n',
      cookieSecure: false,
      cookieMaxAge: 365 * 24 * 60 * 60 * 1000
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
