import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { updateLanguage } from '../../Apis/user';
import UnitedKingdomFlag from '../../Assets/Images/Flag/flag-united-kingdom.png';
import VietNameFlag from '../../Assets/Images/Flag/flag-vietnam.png';
import i18n from '../../translation/i18n';
import { useGlobalContext } from '../../Utils/Context';
import { useSidebarContext } from '../../Utils/Context/SidebarContext';
// import { Question, Setting, VaIcon } from './Icon';
import useCloseModalOnOutsideClick from '../OnOutsideClick/OnOutsideClick';
import { Setting, VaIcon } from './Icon';

const SettingSidebar = (props) => {
  const { t } = useTranslation();
  const { userInfo, updateUserInfo } = useGlobalContext();
  const { popupActive, setPopupActive } = useSidebarContext();

  const switchLanguage = () => {
    if (userInfo?.language !== null && userInfo?.language !== undefined && userInfo?.language !== '') {
      i18n.changeLanguage(userInfo?.language);
      localStorage.setItem('i18next', userInfo?.language);
    } else {
      i18n.changeLanguage('vi');
      localStorage.setItem('i18next', 'vi');
    }
  };

  const handleLanguage = async (language) => {
    await updateLanguage(language, userInfo?.userID);
    await updateUserInfo();
  };

  useEffect(() => {
    switchLanguage();
  }, [userInfo?.language]);
  const boardRef = useRef(null);

  useCloseModalOnOutsideClick(boardRef, setPopupActive);
  return (
    <div className={`relative ${props?.isIcon && props?.isContent && 'min-w-[150px]'}`}>
      <div
        onClick={() => {
          if (popupActive === 'isSetting') {
            setPopupActive('');
          } else {
            setPopupActive('isSetting');
          }
        }}
        className={`mx-3 flex cursor-pointer items-center gap-x-2 rounded-lg px-3 py-2 duration-200 ${
          popupActive === 'isSetting' ? 'bg-neu-100' : 'hover:bg-neu-500'
        }`}
      >
        {props?.isIcon && (
          <i>
            <Setting className={`h-5 w-5 ${popupActive === 'isSetting' ? 'stroke-primary-100' : 'stroke-black'}`} />
          </i>
        )}

        {props?.isContent && (
          <p className={`text-sm font-normal ${popupActive === 'isSetting' ? 'text-primary-100' : 'text-black'}`}>
            {t('sidebar.setting')}
          </p>
        )}
      </div>

      {/* Popup */}
      {popupActive === 'isSetting' && (
        <ul
          ref={boardRef}
          className="absolute bottom-0 right-0 flex w-44 translate-x-[calc(100%+8px)] flex-col gap-y-2 rounded-xl bg-white p-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.25)]"
        >
          <li className="rounded-md bg-primary-400 text-sm font-normal">
            <div className="flex border-spacing-1 items-center gap-x-2 border-b px-3 py-2">
              <VaIcon className="h-[18px] w-[18px] fill-neu-400" />
              <label className="whitespace-nowrap text-sm font-normal">{t('other.language')}</label>
            </div>

            <div
              className="px-3 py-2"
              onClick={() => {
                handleLanguage('vi');
              }}
            >
              <div
                className={`mb-1 flex cursor-pointer items-center gap-x-2 rounded-md px-3 py-2 ${
                  userInfo?.language === 'vi' ? 'bg-white' : 'hover:bg-white'
                }`}
              >
                <img src={VietNameFlag} alt="" className="w-[18px] object-cover" />
                <p className="w-full">{t('other.Vietnamese')}</p>
              </div>

              <div
                onClick={() => {
                  handleLanguage('en');
                }}
                className={`mb-1 flex cursor-pointer items-center gap-x-2 rounded-md px-3 py-2 ${
                  userInfo?.language === 'en' ? 'bg-white' : 'hover:bg-white'
                }`}
              >
                <img src={UnitedKingdomFlag} alt="" className="w-[18px] object-cover" />
                <p>{t('other.English')}</p>
              </div>
            </div>
          </li>

          {/* <li className="flex items-center justify-start gap-x-2 rounded-md px-3 py-2 hover:bg-neu-500">
            <Question className="h-[18px] w-[18px] stroke-neu-400" />
            <label className="text-sm font-normal">Report</label>
          </li> */}
        </ul>
      )}
    </div>
  );
};

export default SettingSidebar;
